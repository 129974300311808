import { isAllowedEndpoint } from '@/api/api'
import i18n from '@/plugins/i18n'
import { useStore } from '@/store'
import { Notification } from 'element-ui'
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'

function handleUiError(context, message) {
  context.$notify({
    title: context.$t('ui.components.notifications.titles.error'),
    message: message,
    type: 'error'
  })
}

function handleUiWarning(context, message) {
  context.$notify({
    title: context.$t('ui.components.notifications.titles.error'),
    message: message,
    type: 'warning'
  })
}
function handleApiError(context, error, messages = []) {
  const title = context.$t('ui.components.notifications.titles.error')
  let message = error.message
  if (error && error.response) {
    if (error.response.status === 401 && !isAllowedEndpoint(error)) {
      let msg = ''
      if (error.response.data && error.response.data.length > 0) {
        msg = error.response.data[0]
      } else {
        msg = 'Session has expired.'
      }
      context.$store.dispatch('clearLoginType')
      const route = context.$route.path
      if (route && typeof route === 'string' && !route.includes('/login')) {
        context.$router.push({
          path: `/login?err=${msg}`
        })
      }
    } else {
      const { status } = error.response
      if (messages.length && messages[status]) {
        error.message = messages[status]
      }
      message = context.$t(error.message)
    }
  } else {
    if (messages && messages.length > 0) {
      message = messages.join()
    }
  }

  if (message && message.length > 0) {
    context.$notify({
      title,
      message,
      type: 'error'
    })
  }
  if (context.$loading) {
    context.$loading().close()
  }
}

export class NotFoundError extends Error {
  constructor(message = 'Not Found', ...params) {
    super(params)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NotFoundError)
    }

    this.name = 'Not Found Error'
    this.message = message
    this.status = 404
    this.date = new Date()
  }
}

export class NotAllowedError extends Error {
  constructor(message = 'Not Allowed', ...params) {
    super(params)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NotAllowedError)
    }

    this.name = 'Not Allowed Error'
    this.message = message
    this.status = 409
    this.date = new Date()
  }
}

export function useErrorNotification() {
  const $store = useStore()
  const $route = useRoute()
  const $router = useRouter()

  function notifyApiError(error, messages, config = {}) {
    const title = i18n.t('ui.components.notifications.titles.error')
    const message = ref('')

    if (error && error.response) {
      if (error.response.status === 401) {
        let msg = ''
        if (error.response.data && error.response.data.length > 0) {
          msg = error.response.data[0]
        } else {
          msg = 'Session has expired.'
        }
        $store.dispatch('clearLoginType')
        const route = $route.path
        if (route && typeof route === 'string' && !route.includes('/login')) {
          $router.push({
            path: `/login?err=${msg}`
          })
        }
      } else {
        const { status } = error.response
        if (messages.length && messages[status]) {
          error.message = messages[status]
        }
        message.value = i18n.t(error.message)
      }
    } else {
      if (messages && messages.length > 0) {
        message.value = messages.filter((m) => m?.length > 0).join(', ')
      } else {
        message.value = i18n.t('api.common.errors.unknownError')
      }
    }

    if (message.value?.length > 0) {
      Notification({
        title,
        message,
        type: 'error',
        ...config
      })
    }
  }

  return {
    notifyApiError
  }
}

export default {
  handleApiError,
  handleUiError,
  handleUiWarning
}
