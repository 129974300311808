export default [
  {
    name: 'ProjectEvaluateBuild',
    path: 'build',
    meta: { area: 'evaluate' },
    component: () =>
      import('@/views/projects/project-view/project-sub-views/evaluate/evaluate-build.vue')
  },
  {
    name: 'ProjectEvaluateTeam',
    path: 'team',
    meta: { area: 'evaluate' },
    component: () =>
      import('@/views/projects/project-view/project-sub-views/evaluate/evaluate-team.vue')
  },
  {
    name: 'ProjectEvaluateMeetings',
    path: 'meetings',
    meta: { area: 'evaluate' },
    component: () =>
      import('@/views/projects/project-view/project-sub-views/evaluate/evaluate-meetings.vue')
  },
  {
    name: 'ProjectEvaluateSupplierStatus',
    path: 'supplier-status',
    meta: { area: 'evaluate' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/evaluate/evaluate-supplier-status.vue'
      )
  }
]
