import { api } from '@/api/api'

export const actions = {
  withdrawAllSubmissions(_, tenderId) {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/submissions/withdraw/${tenderId}`
    return api.put(url).then((response) => {
      const withdrawnSubmissions = response.data.data.submissionDTO.values
      return withdrawnSubmissions
    })
  }
}

export default {
  namespaced: false,
  actions
}
