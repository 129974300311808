import * as types from '../../mutation-types'
import accountInfo from './account-info.js'
import dropdownSettings from './dropdown-settings.js'
import libraryContent from './library-content.js'
import systemContent from './system-content.js'

export const state = {
  saveRequired: false,
  controlPanelErrors: []
}

export const getters = {
  getControlPanelSaveRequired: (state) => state.saveRequired,
  getControlPanelErrors: (state) => state.controlPanelErrors
}

export const actions = {
  updateControlPanelSaveRequired: ({ commit }, value) => {
    commit(types.CONTROL_PANEL_SAVE_REQUIRED, value)
  },
  setControlPanelError: ({ commit }, error) => {
    commit(types.CONTROL_PANEL_ERROR_SET, error)
  },
  unsetControlPanelError: ({ commit }, error) => {
    commit(types.CONTROL_PANEL_ERROR_UNSET, error)
  },
  clearControlPanelErrors: ({ commit }) => {
    commit(types.CONTROL_PANEL_ERROR_UNSET_ALL)
  }
}

export const mutations = {
  [types.CONTROL_PANEL_SAVE_REQUIRED](state, value) {
    state.saveRequired = value
  },
  [types.CONTROL_PANEL_ERROR_SET](state, error) {
    if (state.controlPanelErrors.indexOf(error) === -1) {
      state.controlPanelErrors.push(error)
    }
  },
  [types.CONTROL_PANEL_ERROR_UNSET](state, error) {
    state.controlPanelErrors = state.controlPanelErrors.filter((err) => err !== error)
  },
  [types.CONTROL_PANEL_ERROR_UNSET_ALL](state) {
    state.controlPanelErrors = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    accountInfo,
    systemContent,
    libraryContent,
    dropdownSettings
  }
}
