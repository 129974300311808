import Loading from 'element-ui/lib/loading'
import { nextTick, ref } from 'vue'

function showLoading(context, force = false) {
  if (context.$loading || force) {
    context.$loading({
      fullscreen: true,
      lock: true,
      text: 'Please Wait'
    })
  }
}

function hideLoading(context) {
  if (context.$loading) {
    context.$loading().close()
  }
}

export function useElementLoading(
  options = {
    fullscreen: true,
    lock: true,
    text: 'Please Wait'
  }
) {
  const loader = ref(null)

  function showLoading() {
    loader.value = Loading.service(options)
  }

  async function hideLoading() {
    loader.value.close()
    await nextTick()
    loader.value = null
  }

  return {
    showLoading,
    hideLoading,
    loader
  }
}

export function setTreeNodeDisabled(node, disabled) {
  const newNode = {
    ...node,
    disabled
  }
  if (node.children) {
    newNode.children = newNode.children.map((child) => setTreeNodeDisabled(child, disabled))
  }
  return newNode
}

export default {
  showLoading,
  hideLoading
}
