import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  procurementMethodLimitedTypes: [],
  procurementMethodLimitedType: null
}

export const getters = {
  getProcurementMethodLimitedTypes: (state) => state.procurementMethodLimitedTypes,
  getProcurementMethodLimitedTypeNew: (state) => state.procurementMethodLimitedType
}
export const actions = {
  fetchProcurementMethodLimitedTypes: ({ commit }) => {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/ProcurementMethodLimitedTypes'
    return api.get(url).then((response) => {
      const procurementMethodLimitedTypes =
        response?.data?.data?.procurementMethodLimitedTypeDTO?.values || []
      commit(types.PROCUREMENT_METHOD_LIMITED_TYPES_SET, procurementMethodLimitedTypes)
      return procurementMethodLimitedTypes
    })
  },
  saveProcurementMethodLimitedType: ({ commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ProcurementMethodLimitedTypes`
    return api.post(url, model).then((response) => {
      const procurementMethodLimitedType =
        response.data.data.procurementMethodLimitedTypeDTO.values[0]
      commit(types.PROCUREMENT_METHOD_LIMITED_TYPE_SET, procurementMethodLimitedType)
      return procurementMethodLimitedType
    })
  },
  updateProcurementMethodLimitedType: ({ commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ProcurementMethodLimitedTypes`
    return api.put(url, model).then((response) => {
      const procurementMethodLimitedType =
        response.data.data.procurementMethodLimitedTypeDTO.values[0]
      commit(types.PROCUREMENT_METHOD_LIMITED_TYPE_SET, procurementMethodLimitedType)
      return procurementMethodLimitedType
    })
  },
  deleteProcurementMethodLimitedType: (_, id) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ProcurementMethodLimitedTypes?procurementMethodLimitedTypeId=${id}`
    return api.delete(url)
  }
}
export const mutations = {
  [types.PROCUREMENT_METHOD_LIMITED_TYPES_SET](state, procurementMethodLimitedTypes) {
    state.procurementMethodLimitedTypes = procurementMethodLimitedTypes
  },
  [types.PROCUREMENT_METHOD_LIMITED_TYPE_SET](state, procurementMethodLimitedType) {
    state.procurementMethodLimitedType = procurementMethodLimitedType
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
