import { api } from '@/api/api'
import util from '@/utils/utility'
import * as types from '../../mutation-types'

const state = {
  tenderTableRows: [],
  currRow: null
}

export const getters = {
  getTenderTableRows: (state) => state.tenderTableRows,
  getCurrRow: (state) => state.currRow
}
export const actions = {
  fetchTenderTableRows: ({ commit }, tenderTableId) => {
    commit(types.TENDERTABLEROW_CLEAR)

    const url = `/api/v1/customer/{customerId}/node/{nodeId}/TenderTableRows/${tenderTableId}`
    return api.get(url).then((response) => {
      commit(types.TENDERTABLEROW_SET_FROMDTO, {
        tenderTableRows: response.data.data.tenderTableRowDTO.values,
        tenderTableId
      })
      return ''
    })
  },
  createTenderTableRow: ({ commit }, tenderTableId) => {
    commit(types.TENDERTABLEROW_CREATE, tenderTableId)
  },
  setTenderTableRows: ({ commit }, props) => {
    commit(types.TENDERTABLEROW_SET_FROMDTO, props)
  },
  setTenderTableRow: ({ commit }, props) => {
    commit(types.TENDERTABLEROW_SET, props)
  },
  reorderTenderTableRows({ commit }, props) {
    commit(types.TENDERTABLEROW_REORDER, props)
  },
  deleteTenderTableRow: ({ commit }, tenderTableRowId) => {
    commit(types.TENDERTABLEROW_DELETE, tenderTableRowId)
  },
  clearTenderTableRows: ({ commit }) => {
    commit(types.TENDERTABLEROW_CLEAR)
  }
}
export const mutations = {
  [types.TENDERTABLEROW_SET_FROMDTO](state, vals) {
    state.tenderTableRows = vals.tenderTableRows
      .map(function (ttr) {
        return {
          id: ttr.id,
          tenderTableId: vals.tenderTableId,
          isRequired: ttr.isRequired,
          sortOrder: ttr.sortOrder,
          isDynamicRow: ttr.isDynamicRow,
          deleted: ttr.deleted
        }
      })
      .sort(function (a, b) {
        return a.sortOrder - b.sortOrder
      })
  },
  [types.TENDERTABLEROW_SET](state, updatedRow) {
    const rowIndex = state.tenderTableRows.findIndex((ttr) => ttr.id === updatedRow.id)
    Object.assign(state.tenderTableRows[rowIndex], updatedRow)
  },
  [types.TENDERTABLEROW_CREATE](state, tenderTableId) {
    const nextSortOrder = state.tenderTableRows.filter((ttc) => ttc.deleted === false).length

    state.currRow = {
      id: util.guid(),
      tenderTableId: tenderTableId,
      isRequired: false,
      sortOrder: nextSortOrder,
      isDynamicRow: false,
      deleted: false
    }
    state.tenderTableRows.push(state.currRow)
  },
  [types.TENDERTABLEROW_REORDER](state, props) {
    const newTargetIndex =
      props.target <= props.rows[0] ? props.target : props.target - props.rows.length

    // only reorder if col and target are different
    if (newTargetIndex !== props.rows[0]) {
      const movedElems = state.tenderTableRows.splice(props.rows[0], props.rows.length)
      state.tenderTableRows.splice(newTargetIndex, 0, ...movedElems)
    }

    let newIndex = 0
    state.tenderTableRows.forEach((ttr) => {
      ttr.sortOrder = newIndex++
    })
  },
  [types.TENDERTABLEROW_DELETE](state, tenderTableRowId) {
    const matchedRow = state.tenderTableRows.find((ttr) => ttr.id === tenderTableRowId)
    matchedRow.deleted = true
    matchedRow.sortOrder = 9999

    // resort the array
    state.tenderTableRows.sort(function (a, b) {
      return a.sortOrder - b.sortOrder
    })

    // update the sortOrder
    let newIndex = 0
    state.tenderTableRows.forEach((ttr) => {
      if (ttr.deleted === false) {
        ttr.sortOrder = newIndex++
      }
    })
  },
  [types.TENDERTABLEROW_CLEAR](state) {
    state.tenderTableRows = []
    state.currRow = null
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
