import IntakeService from '@/services/intake/intake.service'
import * as types from '@/store/mutation-types'

import {
  baseContactDetailsModel,
  baseProjectDetailsModel,
  baseProjectValueModel,
  projectClassifications,
  sourceLabels
} from './intakeStoreConstants.js'

const state = () => ({
  projectClassifications,
  sourceLabels,
  intakeProjectData: {
    id: null,
    nodeId: null,
    contactDetails: {
      ...baseContactDetailsModel
    },
    projectDetail: {
      ...baseProjectDetailsModel
    },
    financialDetails: {
      ...baseProjectValueModel
    }
  },
  intakePendingRequests: [],
  newProjectData: null,
  requesters: [],
  intakeProjects: []
})

const getters = {
  getIntakeProjectData: (state) => state.intakeProjectData,
  getIntakeContactDetails: (state) => state.intakeProjectData.contactDetails,
  getIntakeProjectDetails: (state) => state.intakeProjectData.projectDetail,
  getIntakeFinancialDetails: (state) => state.intakeProjectData.financialDetails,
  getIntakePendingRequests: (state) => state.intakePendingRequests,
  getNewIntakeProjectData: (state) => state.newProjectData,
  getIntakeRequesters: (state) => state.requesters,
  getIntakeProjects: (state) => state.intakeProjects
}

const actions = {
  fetchIntakeData: async ({ commit }, intakeId) => {
    try {
      const response = await IntakeService().getIntakeProjectData(intakeId)
      if (response.status === 200) {
        commit(types.INTAKE_SET_PROJECT_DATA, response.data)
        return Promise.resolve(response.data)
      }
    } catch (err) {
      return Promise.reject(err)
    }
  },
  fetchIntakeDataModifier: async (_, intakeId) => {
    try {
      const response = await IntakeService().getIntakeProjectDataModifier(intakeId)
      if (response.status === 200) {
        return Promise.resolve(response.data)
      }
    } catch (err) {
      return Promise.reject(err)
    }
  },
  saveIntakeProjectData: async (
    { commit },
    { intakeId, contactDetails, projectDetail, financialDetails }
  ) => {
    // do save things
    const updateResponse = await IntakeService().updateIntakeProject(intakeId, {
      contactDetails,
      projectDetail,
      financialDetails
    })
    commit(types.INTAKE_UPDATE_PROJECT_DATA, {
      contactDetails,
      projectDetail,
      financialDetails
    })
    return Promise.resolve(updateResponse)
  },
  approveDeclineIntakeProject: (_, { intakeId, status, reason }) => {
    return IntakeService().approveDeclineIntakeProject(intakeId, status, reason)
  },
  resetIntakeData: ({ commit }) => {
    commit(types.INTAKE_SET_PROJECT_DATA, {
      id: null,
      nodeId: null,
      contactDetails: {
        ...baseContactDetailsModel
      },
      projectDetail: {
        ...baseProjectDetailsModel
      },
      financialDetails: {
        ...baseProjectValueModel
      }
    })
    return Promise.resolve(true)
  },
  // @params: {status: int/string }
  fetchIntakePendingRequests: async ({ commit, rootGetters }, params = {}) => {
    const nodeId = rootGetters.getCustomerNode?.id || null
    if (!nodeId) {
      return Promise.reject('nodeId is required')
    }

    params = { ...params, status: 1 }

    try {
      const response = await IntakeService({ nodeId }).getIntakeRequests(params)

      commit(types.INTAKE_SET_PENDING_REQUESTS, response)
      return Promise.resolve(response)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  createNewProjectFromIntake: async ({ commit, dispatch, getters }, intakeData) => {
    await dispatch('resetNewIntakeProjectData')
    commit(types.INTAKE_SET_NEW_PROJECT_DATA, intakeData)
    return Promise.resolve(getters.getNewIntakeProjectData)
  },
  resetNewIntakeProjectData: ({ commit }) => {
    commit(types.INTAKE_SET_NEW_PROJECT_DATA, null)
    return Promise.resolve(true)
  },
  downloadPdf: async (_, intakeId) => {
    return await IntakeService().downloadPdf(intakeId)
  },
  fetchIntakeRequesters: async ({ commit, rootGetters }) => {
    const nodeId = rootGetters.getCustomerNode?.id || null
    if (!nodeId) {
      return Promise.reject('nodeId is required')
    }

    try {
      const response = await IntakeService({ nodeId }).getIntakeRequesters()

      commit(types.INTAKE_SET_REQUESTERS, response)
      return Promise.resolve(response)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  fetchIntakeProjects: async ({ commit, rootGetters }, { params }) => {
    const nodeId = rootGetters.getCustomerNode?.id || null
    if (!nodeId) {
      return Promise.reject('nodeId is required')
    }

    try {
      const { data } = await IntakeService({ nodeId }).getSearchResults(params)

      commit(types.INTAKE_SET_PROJECTS_ALL, data)
      return data
    } catch (err) {
      commit(types.INTAKE_SET_PROJECTS_ALL, [])
      throw err
    }
  },
  clearAllIntakeProjects: ({ commit }) => {
    commit(types.INTAKE_SET_PROJECTS_ALL, [])
  }
}

const mutations = {
  [types.INTAKE_SET_PROJECT_DATA](state, intakeProjectData) {
    state.intakeProjectData = intakeProjectData
  },
  [types.INTAKE_UPDATE_PROJECT_DATA](state, intakeProjectData) {
    state.intakeProjectData = {
      ...state.intakeProjectData,
      ...intakeProjectData
    }
  },
  [types.INTAKE_SET_PENDING_REQUESTS](state, intakePendingRequests) {
    state.intakePendingRequests = intakePendingRequests
  },
  [types.INTAKE_SET_NEW_PROJECT_DATA](state, newProjectData) {
    state.newProjectData = newProjectData
  },
  [types.INTAKE_SET_REQUESTERS](state, requesters) {
    state.requesters = requesters
  },
  [types.INTAKE_SET_PROJECTS_ALL](state, projects) {
    state.intakeProjects = projects
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
