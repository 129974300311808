import { api, downloadApi } from '@/api/api'
import util from '@/utils/utility.js'
import * as types from './../../mutation-types.js'

export const state = {
  awardApproval: []
}

export const getters = {
  getAwardApprovalPublicPage: (state) => state.awardApproval
}

export const actions = {
  fetchAwardApprovalPublicPage: ({ commit }, params) => {
    const url = `api/v1/Awards/approvals/${params.approvalId}/approver/${params.workflowId}?uat=${params.uat}`

    return api.get(url).then((response) => {
      let result = []

      if (response.data.count !== 0) {
        result = response.data.data.awardApprovalDTO.values[0]
      }

      commit(types.AWARD_APPROVAL_PUBLIC_SET, result)

      return result
    })
  },
  awardApprovalPublicPageDocumentPreview: (_, params) => {
    const url = `api/v1/Awards/approvals/${params.awardApprovalId}/approver/${params.awardApprovalWorkflowId}/document/${params.documentId}/download?uat=${params.userAccessToken}`

    return downloadApi.get(url).then(util.getBlobUrlFomApiResponse)
  },
  awardApprovalPublicPageDocumentDownload: (_, params) => {
    const url = `api/v1/Awards/approvals/${params.awardApprovalId}/approver/${params.awardApprovalWorkflowId}/document/${params.documentId}/download?uat=${params.userAccessToken}`

    return downloadApi.get(url).then(util.forceFileDownload)
  },
  awardApprovalPublicPageStatusSet: ({ commit }, params) => {
    const url = `api/v1/Awards/approvals/${params.approvalId}/approver/${params.workflowId}/status?uat=${params.uat}&status=${params.status}`

    return api.post(url, params.comments).then((response) => {
      const result = response?.data?.data?.awardApprovalDTO?.values?.[0] || []
      commit(types.AWARD_APPROVAL_PUBLIC_SET, result)
      return result
    })
  },
  awardApprovalPublicPageDocumentDownloadAll: (_, params) => {
    const url = `api/v1/Awards/approvals/${params.awardApprovalId}/approver/${params.awardApprovalWorkflowId}/document/download/all?uat=${params.userAccessToken}`

    return downloadApi.get(url).then(util.forceFileDownload)
  }
}

export const mutations = {
  [types.AWARD_APPROVAL_PUBLIC_SET](state, awardApproval) {
    state.awardApproval = awardApproval
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
