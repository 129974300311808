import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  certificateTypes: []
}

export const getters = {
  getCertificateTypes: (state) => state.certificateTypes
}
export const actions = {
  fetchCertificateTypes: ({ commit }) => {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/CertificateTypes'
    return api.get(url).then((response) => {
      const certificateTypes = response?.data?.data?.nodeCertificateTypeDTO?.values || []
      commit(types.CERTIFICATE_TYPES_SET, certificateTypes)
      return certificateTypes
    })
  }
}
export const mutations = {
  [types.CERTIFICATE_TYPES_SET](state, certificateTypes) {
    state.certificateTypes = certificateTypes
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
