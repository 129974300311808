import { api } from '@/api/api'
import * as types from '../../../mutation-types'

export const state = {
  customerNode: null
}
export const getters = {
  getCustomerNode: (state) => state.customerNode
}
export const actions = {
  setCustomerNode: ({ commit }, customerNode) => {
    commit(types.CUSTOMERNODE_SET, customerNode)
  },
  fetchCustomerNode: ({ commit }, customerNodeId) => {
    const url = `/api/v1/customer/{customerId}/CustomerNode?customerNodeId=${customerNodeId}`
    return api.get(url).then((response) => {
      const data = response.data.data.customerNodeDTO.values[0]
      commit(types.CUSTOMERNODE_SET, response.data.data.customerNodeDTO.values[0])
      return data
    })
  }
}
export const mutations = {
  [types.CUSTOMERNODE_SET](state, customerNode) {
    state.customerNode = customerNode
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
