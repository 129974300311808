<template>
  <div class="award-approval-header has-padding-large">
    <router-link to="/" class="ogo-link">
      <img id="logo-svg" :src="logo" aria-label="Bids and tenders" alt="bids and tenders" />
    </router-link>
    <span>AWARD APPROVAL</span>
  </div>
</template>

<script>
import logo from '@/assets/images/bandt-logo-inverse.svg'
export default {
  name: 'award-approval-header',
  data() {
    return {
      logo
    }
  }
}
</script>

<style lang="scss" scoped>
.award-approval-header {
  background: $team-member-primary;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @include breakpoint('sm') {
    height: $team-member-header-height;
    flex-direction: row;
    span {
      margin-left: auto;
    }
  }

  #logo-svg {
    height: 36px;
  }
}
</style>
