import { api } from '@/api/api'
import * as types from './../../mutation-types.js'

const state = {
  widgets: {
    openProjects: null,
    totalProjects: null,
    incompleteQuestions: null,
    awardedProjects: null,
    expiredContractDocuments: null,
    calendar: null,
    allDraftProjects: null,
    allPlannedProjects: null,
    allOpenProjects: null,
    allClosedProjects: null,
    allAwardedProjects: null,
    allActiveProjects: null,
    allWarrantyProjects: null
  },
  userWidgets: null,
  userProjectLeads: [],
  teamMemberProjects: null
}

const getters = {
  getDashboardwidgets: (state) => state.widgets,
  getDashboardCalendar: (state) => state.widgets.calendar,
  getDashboardUserWidgets: (state) => state.userWidgets,
  getDashboardUserProjectLeads: (state) => state.userProjectLeads,
  getTeamMemberProjects: (state) => state.teamMemberProjects
}

const actions = {
  fetchDashboardWidget: ({ commit }, widgetType) => {
    const url = `api/v1/customer/{customerId}/node/{nodeId}/Dashboards/Widget?widgetType=${widgetType}`

    return api.get(url).then((response) => {
      if (response.data.count > 0) {
        const result = response.data.data.searchResult.values
        commit(types.DASHBOARD_WIDGET_SET, { widgetType: widgetType, values: result })
        return result
      }
    })
  },
  fetchDashboardCalendar: ({ commit }, params) => {
    const url = `api/v1/customer/{customerId}/node/{nodeId}/Dashboards/Calendar?startDate=${params.startDate}&endDate=${params.endDate}`

    return api.get(url).then((response) => {
      const result = response?.data?.data?.searchResult?.values || null
      commit(types.DASHBOARD_WIDGET_SET, { widgetType: 'calendar', values: result })
      return result
    })
  },
  saveUserWidget: ({ commit }, params) => {
    const url = `api/v1/customer/{customerId}/node/{nodeId}/Dashboards/UserBuyerWidget`

    return api.post(url, params).then((response) => {
      const result = response?.data?.data?.userBuyerDashboardWidgetsDTO?.values || []
      commit(types.DASHBOARD_USER_WIDGET_SET, result)
      return result
    })
  },
  fetchUserWidgets: ({ commit }) => {
    const url = `api/v1/customer/{customerId}/node/{nodeId}/Dashboards/UserBuyerWidget`

    return api.get(url).then((response) => {
      const result = response?.data?.data?.userBuyerDashboardWidgetsDTO?.values || []
      commit(types.DASHBOARD_USER_WIDGET_SET, result)
      return result
    })
  },
  fetchSourceProjectandLeads: ({ commit }) => {
    const url = `api/v1/customer/{customerId}/node/{nodeId}/Dashboards/SourceProjectByLeadWidget`

    return api.get(url).then((response) => {
      const result = response?.data?.data?.searchResult?.values || []
      commit(types.DASHBOARD_USER_PROJECTSLEAD_WIDGET_RESET, result)
      return result
    })
  },
  fetchSourceProjectsByTeamMember: ({ commit }) => {
    const url = `api/v1/customer/{customerId}/node/{nodeId}/Dashboards/SourceProjectsByTeamMembers`

    return api.get(url).then((response) => {
      const result = response?.data?.data?.searchResult?.values || []
      commit(types.DASHBOARD_TEAMMEMBER_PROJECT_WIDGET_RESET, result)
      return result
    })
  }
}

const mutations = {
  [types.DASHBOARD_WIDGET_SET]: (state, params) => {
    state.widgets[params.widgetType] = params.values
  },
  [types.DASHBOARD_USER_WIDGET_SET]: (state, params) => {
    state.userWidgets = params
  },
  [types.DASHBOARD_USER_PROJECTSLEAD_WIDGET_RESET]: (state, params) => {
    state.userProjectLeads = params
  },
  [types.DASHBOARD_TEAMMEMBER_PROJECT_WIDGET_RESET]: (state, params) => {
    state.teamMemberProjects = params
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
