import { api, downloadApi } from '@/api/api'
import util from '@/utils/utility.js'
import * as types from '../../mutation-types'

function commonDownload(url) {
  return downloadApi.get(url).then(util.forceFileDownload)
}

export const state = {
  requestedTenders: [],
  notifications: []
}

const mutations = {
  [types.EXPORTFILE_ADD](state, tenderStatus) {
    state.requestedTenders.push(tenderStatus)
    localStorage.setItem('requestedTenders', JSON.stringify(state.requestedTenders))
  },
  [types.EXPORTFILE_REMOVE](state, tenderId) {
    state.requestedTenders = state.requestedTenders.filter((t) => t.id !== tenderId)
    localStorage.setItem('requestedTenders', JSON.stringify(state.requestedTenders))
  },
  [types.EXPORTFILE_READY](state, tenderId) {
    const tenderStatus = state.requestedTenders.find((t) => t.id === tenderId)
    tenderStatus.isReady = true
    localStorage.setItem('requestedTenders', JSON.stringify(state.requestedTenders))
  },
  [types.EXPORTFILE_RESTORE](state) {
    const tenders = JSON.parse(localStorage.getItem('requestedTenders'))
    state.requestedTenders = tenders || []
  },
  [types.EXPORTFILE_NOTIFICATIONADD](state, notification) {
    state.notifications.push(notification)
  },
  [types.EXPORTFILE_NOTIFICATIONCLEAR](state, tenderGuid) {
    state.notifications = state.notifications.filter((n) => n.tenderGuid !== tenderGuid)
  }
}

export const getters = {
  getRequestedTenders: function (state, getters, rootState) {
    return state.requestedTenders.filter((t) => t.userId === rootState.user.user.id)
  },
  getReadyRequestedTenders: function (state, getters, rootState) {
    return state.requestedTenders.filter((t) => t.isReady && t.userId === rootState.user.user.id)
  }
}

export const actions = {
  downloadSignInSheetPDF: (_, exportParam) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Exports/GenerateSignInSheetPDF?tenderId=${exportParam.tenderId}&meetingLocationId=${exportParam.meetingLocationId}`
    return commonDownload(url)
  },
  downloadSignInSheetExcel: (_, exportParam) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Exports/GenerateSignInSheetExcel?tenderId=${exportParam.tenderId}&meetingLocationId=${exportParam.meetingLocationId}`
    return commonDownload(url)
  },
  downloadCompletedSubmission: (_, planTakerSubmissionId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Exports/CompletedSubmission?planTakerSubmissionId=${planTakerSubmissionId}`
    commonDownload(url)
  },
  requestCompletedSubmissionsAll: ({ commit }, tenderStatus) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Exports/CompletedSubmissionsAllRequest?tenderId=${tenderStatus.id}`

    return api.get(url).then(() => {
      commit(types.EXPORTFILE_ADD, tenderStatus)
    })
  },
  downloadCompletedSubmissionsAll: ({ commit }, tenderId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Exports/CompletedSubmissionsAllDownload?tenderId=${tenderId}`

    return downloadApi.get(url).then((response) => {
      util.forceFileDownload(response)
      commit(types.EXPORTFILE_REMOVE, tenderId)
    })
  },
  getCompletedSubmissionsAll: (_, tenderId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Exports/CompletedSubmissionsAll?tenderId=${tenderId}`
    return commonDownload(url)
  },
  addCompletedSubmissionsAll: ({ commit }, tenderStatus) => {
    commit(types.EXPORTFILE_ADD, tenderStatus)
  },
  removeCompletedSubmissionsAll: ({ commit }, tenderId) => {
    commit(types.EXPORTFILE_REMOVE, tenderId)
  },
  setCompletedSubmissionsAllReady: ({ commit }, tenderId) => {
    commit(types.EXPORTFILE_READY, tenderId)
  },
  restoreCompletedSubmissionsAll: ({ commit }) => {
    commit(types.EXPORTFILE_RESTORE)
  },
  addAllSubmissionsNotification: ({ commit }, notification) => {
    commit(types.EXPORTFILE_NOTIFICATIONADD, notification)
  },
  clearAllSubmissionsNotifications: ({ commit, state }) => {
    for (const notification of state.notifications) {
      notification.closeFn()
      commit(types.EXPORTFILE_NOTIFICATIONCLEAR, notification.tenderGuid)
    }
  },
  downloadBidAnalysis: (_, tenderId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Exports/BidAnalysis?tenderId=${tenderId}`
    return commonDownload(url)
  },
  downloadVendorQuestions: (_, exportParam) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Exports/VendorQuestions?tenderId=${exportParam.tenderId}`

    return downloadApi
      .post(url, exportParam.vendorQuestionsIds.split(','))
      .then(util.forceFileDownload)
  },
  generateBidDocument: (_, exportParam) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Exports/BidDocument?projectId=${exportParam.projectId}&attachFrontEndDocument=${exportParam.attachFrontEndDocument}`

    return api.post(url)
  },
  downloadProjectSummary: (_, tenderId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Exports/ProjectSummary?tenderId=${tenderId}`
    return commonDownload(url)
  },
  downloadSupplierActivity: (_, tenderId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Exports/SupplierActivity?tenderId=${tenderId}`
    return commonDownload(url)
  },
  downloadOpeningSheet: (_, tenderId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Exports/OpeningSheet?tenderId=${tenderId}`
    return commonDownload(url)
  },
  downloadNoBidReport: (_, tenderId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Exports/NoBid?tenderId=${tenderId}`
    return commonDownload(url)
  },
  downloadComplianceReport: (_, tenderId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Exports/Compliance?tenderId=${tenderId}`
    return commonDownload(url)
  },
  downloadEmailWithAttachments: (_, payload) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Exports/Email?emailId=${payload}`
    return commonDownload(url)
  },
  downloadScheduledEmailWithAttachments: (_, param) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Exports/ScheduledEmailWithAttachment?emailScheduleId=${param.id}&emailScheduleContactId=${param.emailScheduleContactId}`
    return commonDownload(url)
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
