<script setup>
import { computed, useSlots } from 'vue'
import { RouterLink } from 'vue-router'
import AppDropdown from '@/components/app-dropdown/app-dropdown.vue'
import AppButton from '@/components/app-button/app-button.vue'

const props = defineProps({
  component: {
    type: String,
    default: 'div'
  },
  icon: {
    type: String,
    default: null
  }
})

const $slots = useSlots()

const dynamicComponent = computed(() => {
  if (!props.component) return 'div'
  switch (props.component) {
    case 'dropdown':
      return AppDropdown
    case 'button':
      return AppButton
    case 'link':
      return RouterLink
    default:
      return props.component
  }
})

const additionalAttrs = computed(() => {
  const attrs = {}

  if (props.icon) {
    attrs.icon = props.icon
  }
  if (props.component === 'dropdown') {
    attrs.menuClasses = 'app-bar-item__menu'
    attrs.controlClasses = 'app-bar-item__control'
  }

  return attrs
})
</script>

<template>
  <Component
    :is="dynamicComponent"
    v-bind="{ ...$attrs, ...additionalAttrs }"
    class="app-bar-item"
    :class="{ 'is-link': props.component === 'link' }"
  >
    <AppIcon v-if="props.component === 'link' && props.icon" :icon="props.icon" />
    <slot v-for="(_, name) in $slots" :name="name" />
  </Component>
</template>

<style lang="scss">
.app-bar-item {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  height: 100% !important;
  color: $white;
  background: transparent;

  &.app-button,
  &.is-link {
    color: white !important;
    background: transparent;
    border: none;
    padding: 0 $gap;

    &:focus-visible {
      box-shadow: $focus-shadow !important;
    }
  }
  .app-icon {
    color: white !important;
  }

  &:hover,
  &:focus {
    background: $c-primary-shade;
  }

  .app-button.app-bar-item__control {
    color: $white !important;
    background: transparent !important;
    width: 100% !important;
    height: 100% !important;
    padding: 0 $gap;
    border: 0;

    &[aria-expanded='true'] {
      background: $c-primary-shade !important;
    }

    &:hover,
    &:focus {
      background: $c-primary-shade !important;
    }
    &:focus-visible {
      box-shadow: $focus-shadow !important;
    }
  }

  &.is-parent,
  &.is-parent > .app-button {
    font-family: $family-heading !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
  }

  .app-icon {
    margin-right: $gap-tiny;
    font-size: $size-5;
  }
}

.app-bar-item__menu {
  background: $c-primary-shade;
  border-radius: 0;
  min-width: 200px;
  padding: 0;

  .app-bar-item {
    &:hover,
    &:focus {
      background: $c-primary-light;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
  }
  li {
    display: flex;
  }

  a {
    width: 100%;
    padding: $gap-small $gap;
    font-weight: 500;
    color: $white !important;

    &:hover,
    &:focus {
      color: $white;
    }

    &:focus-visible {
      box-shadow: $focus-shadow !important;
    }
  }
}
</style>
