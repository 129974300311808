<script setup>
import { ref } from 'vue'
const warning = ref('ui.common.warningFieldVisible')
</script>

<template>
  <div v-bind="$attrs" class="public-visibility-warning icon-date-visible">
    <app-icon icon="eye" size="small" />
    <span>{{ $t(warning) }}</span>
  </div>
</template>

<style lang="scss">
.public-visibility-warning {
  display: flex;
  align-items: center;
  word-break: keep-all;
  white-space: nowrap;
  color: $c-text-light;
  font-size: $size-6;

  .app-icon {
    margin-right: 2px;
    color: $c-text-light;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
