<template>
  <div :class="['is-' + size, { 'is-open': isActive }]" class="app-reveal">
    <button
      :class="{ 'is-active': isActive }"
      :title="srHelp"
      class="has-noStyle app-reveal-trigger"
      @click="revealContent"
    >
      <span class="app-reveal-trigger-wrapper">
        <span v-if="icon" class="icon app-reveal-trigger-icon">
          <app-icon :icon="icon"></app-icon>
        </span>
        <span class="app-reveal-trigger-text">
          <slot name="title">{{ revealTitle }}</slot>
        </span>
        <span v-if="tag" :class="[tagClass]" class="tag is-tiny">{{ tag }}</span>
        <span v-if="hasArrowText" class="app-reveal-trigger-arrow-text">
          {{ arrowText }}
        </span>
        <span :class="{ 'is-default': hasDefaultIcon }" class="app-reveal-trigger-arrow">
          <app-icon :icon="toggleIcon" :size="iconSize"></app-icon>
        </span>
      </span>
    </button>
    <transition name="fade-in-down">
      <div v-show="isActive" class="app-reveal-content">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'app-reveal',
  props: {
    iconSize: {
      type: String,
      default: 'small'
    },
    size: {
      type: String,
      default: 'default'
    },
    icon: String,
    title: {
      type: String
    },
    closedIcon: {
      type: String,
      default: 'chevron-down'
    },
    openedIcon: String,
    tag: String,
    tagType: {
      type: String,
      default: 'danger'
    },
    srHelp: String,
    hasArrowText: Boolean,
    defaultOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: this.defaultOpen
    }
  },
  computed: {
    tagClass() {
      return `is-${this.tagType}`
    },
    arrowText() {
      return this.isActive
        ? this.$t('ui.components.app-reveal.close')
        : this.$t('ui.components.app-reveal.open')
    },
    toggleIcon() {
      if (!this.isActive || !this.openedIcon) return this.closedIcon
      return this.openedIcon
    },
    hasDefaultIcon() {
      return !this.openedIcon && this.closedIcon === 'chevron-down'
    },
    revealTitle() {
      if (this.$slots.title) return ''
      return this.title ? this.title : ''
    }
  },
  methods: {
    revealContent() {
      this.isActive = !this.isActive
      this.$el.querySelectorAll('button')[0].blur()
      if (this.isActive) {
        this.$emit('open')
      }
    },
    hide() {
      this.isActive = false
    }
  }
}
</script>

<style lang="scss" src="./app-reveal.scss"></style>
