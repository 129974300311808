/**
 * these are the newer, easier to use mixins that provide a data model
 * that can be set.
 * 
 * old table-mixins can be found in /utils
 * 
  * Implementation
  * ==================
  * -include the mixins,
  * -declare a new dataSource() computed field that provides your required data source
  * -the table you are using should then use <el-table :data="tableData">...
  * -inside the table, use the visibleColumns computed to filter columns 
  *  if the columns button is present eg: 
    <el-table-column v-for="(column, c) in visibleColumns" :key="`table-column-${c}`" ...
  *
  * NOTE
  * ===============
  * <column-dropdown :column-defs="" /> is also provided to turn the columns on and off. You can filter columnDefs 
  * to exclude any columns that shouldn't be toggled
  * 
  * if you need alternate filtering capabilities, 
  * you'll have to declare a new function, 'filterDataSource'
  * 
  * Alternately, you can provide an entirely different tableData computed field 
  * but note that it currently performs the slicing for pagination
 */

const columnDropdown = {
  name: 'table-column-dropdown',
  props: {
    columnDefs: {
      type: Array,
      default: () => []
    }
  },
  template: `
    <el-dropdown trigger="click"
      :show-timeout="0"
      :hide-timeout="0"
      :hide-on-click="false">
      <app-column-button />
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-for="(column, index) in columnDefs"
          :key="'visible-column' + index">
          <app-checkbox :label="column.columnHeading"
            :id="column.field"
            :name="column.field"
            :value="column.field"
            v-model="column.visible"
            class="has-margin-bottom-tiny">
          </app-checkbox>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>`
}

import dayjs from '@/utils/dayjs'
import cloneDeep from 'lodash/cloneDeep'

export default {
  components: {
    columnDropdown
  },
  computed: {
    gridTotalPages() {
      const totalPages = Math.ceil(this.gridTotalRows / this.gridPageSize)

      return Math.ceil(totalPages > 0 ? totalPages : 1)
    },
    gridTotalRows() {
      return this.filteredTableData.length
    },
    visibleColumns() {
      if (!this.columnDefs.length) return []
      return this.columnDefs.filter(
        (column) => !column.hasOwnProperty('visible') || column.visible === true
      )
    },
    dataSource() {
      return []
    },
    filteredTableData() {
      if (!this.dataSource?.length) return []

      return this.dataSource.filter(this.filterDataSource)
    },
    tableData() {
      const filteredData = this.filteredTableData

      const startIndex = (this.gridCurrentPage - 1) * this.gridPageSize
      const endIndex = startIndex + this.gridPageSize

      return filteredData.slice(startIndex, endIndex)
    }
  },
  data() {
    return {
      columnDefs: [],
      gridPageSize: 20,
      gridCurrentPage: 1
    }
  },
  created() {
    if (!this.columnDefs.length) {
      this.columnDefs = this.getDefaultColumnDefs()
    }
  },
  mounted() {
    // eslint-disable-next-line no-undef
    if (import.meta.env.MODE === 'development') {
      if (!this.columnDefs.length) {
        // eslint-disable-next-line
        console.warn(
          'please add columnDefs data property to your component with the following schema:'
        )
        // eslint-disable-next-line
        console.warn(`[
        {
        field: String, the name of the field on the tableData object,
        columnHeading: String, the translated column header,
        visible: Boolean, whether or not the column is visible'
        }
        ]`)
      }
    }
  },
  methods: {
    filterDataSource() {
      // add a new filter function if you're going to filter your data
      return true
    },
    handleClickPrev() {
      this.gridCurrentPage--
    },
    handleClickNext() {
      this.gridCurrentPage++
    },
    handleGridPageSizeChange(size) {
      this.gridCurrentPage = 1
      this.gridPageSize = Number(size)
    },
    toggleColumn(index) {
      this.columnDefs[index].visible = !this.columnDefs[index].visible
    },
    getDefaultColumnDefs() {
      if (!this.tableData.length) return []

      return Object.keys(this.tableData[0]).map((key) => {
        return {
          columnHeading: key,
          field: key,
          visible: true
        }
      })
    },
    standardTableSortWithDate(row, data, dateTypeCols = []) {
      return cloneDeep(data).sort((a, b) => {
        const [a1, b1] = this.setSortValues(row.prop, a, b, dateTypeCols)
        // Needed to check for null values as localeCompare doesn't like these
        // https://stackoverflow.com/a/29829370  to see an example of the code below

        // nulls sort after anything else
        if (a1 === null) return 1
        if (b1 === null) return -1

        return row.order === 'ascending'
          ? typeof a1 === 'number'
            ? a1 - b1
            : `${a1}`.localeCompare(b1)
          : typeof a1 === 'number'
          ? b1 - a1
          : `${b1}`.localeCompare(a1)
      })
    },
    setSortValues(columnName, a, b, dateTypeCols) {
      const a1 = a[columnName]
      const b1 = b[columnName]
      if (dateTypeCols.includes(columnName)) {
        return [
          !a1 || a1 === 'N/A' ? null : dayjs(a1).valueOf() || 0,
          !b1 || b1 === 'N/A' ? null : dayjs(b1).valueOf() || 0
        ]
      } else {
        return [!a1 || a1 === 'N/A' ? null : a1, !b1 || b1 === 'N/A' ? null : b1]
      }
    },
    isColumnVisible({ key, val }) {
      return this.columnDefs.find((c) => c[key] === val)
    }
  }
}
