import { ref, toRefs, watch, computed, onMounted } from 'vue'
import debounce from 'lodash/debounce'

const TooltipTypes = {
  NONE: null,
  OVERFLOW: 'OVERFLOW',
  VALUE: 'VALUE'
}

export function useStandardInput({ emit, props, overrides, el }) {
  const field = ref(el || null)
  const touched = ref(false)
  const isDirty = ref(false)
  const firstPass = ref(false)
  const isValid = ref(false)
  const tooltip = ref(null)
  const hiddenEl = ref(null)
  const contentOverflowing = ref(false)

  const { value, errorMessage } = (props && toRefs(props)) || {
    value: ref(null),
    errorMessage: ref(null)
  }

  const tooltipType = computed(() => props?.tooltipType || TooltipTypes.NONE)

  const showRequiredError = computed(() => {
    if (!props) return false
    return props.required && touched.value && !value.value
  })

  function EstablishEvents() {
    let OnBlur = () => true
    let OnInputChanged = () => true

    if (!emit)
      return {
        OnBlur,
        OnInputChanged
      }

    OnBlur = () => {
      if (touched.value === true) {
        isDirty.value = true
      }
      if (!props.required && value?.value?.length && value.value.length === 0) {
        isDirty.value = false
      }
      firstPass.value = true
    }

    OnInputChanged = (e) => {
      if (touched.value === true) {
        isDirty.value = true
      }
      touched.value = true

      if (emit) {
        emit('input', e)
      }
    }

    return { OnBlur, OnInputChanged }
  }

  const ValidateValue = debounce((val) => {
    if (!props || !emit) return
    let valid = true

    if (props.required && (!val || (val.length && val.length === 0))) {
      isValid.value = false
      emit('isValid', false)
      return
    }

    if (props && props.validate) {
      valid = props.validate(val)
    }

    isValid.value = valid
    emit('isValid', valid)
  }, 30)

  const UseAppropriateValidation = overrides?.ValidateValue || ValidateValue

  function checkOverflow(value) {
    // if inputEl is a vue wrapper, get the native element
    if (!field.value || !hiddenEl.value) return

    const input = field.value.$el || field.value
    hiddenEl.value.innerText = value

    // test hiddenEl.value width against inputEl width
    contentOverflowing.value = hiddenEl.value.offsetWidth > input.offsetWidth
  }

  const ManageTooltip = debounce((val) => {
    if (!tooltipType.value) return

    const ttype = tooltipType.value.toUpperCase()
    switch (ttype) {
      case TooltipTypes.OVERFLOW:
        if (!field.value) return
        checkOverflow(val)
        tooltip.value = (contentOverflowing.value && val) || null
        break
      case TooltipTypes.VALUE:
        tooltip.value = val
        break
      default:
        tooltip.value = null
    }
  }, 30)

  function AssignTooltipText() {
    if (!tooltipType.value || tooltipType.value.toUpperCase() !== TooltipTypes.VALUE) return
    tooltip.value = value.value
  }

  function CreateOverflowCheck() {
    if (!field.value || tooltipType.value?.toUpperCase() !== TooltipTypes.OVERFLOW) return

    hiddenEl.value = document.createElement('span')
    hiddenEl.value.style.visibility = 'hidden'
    hiddenEl.value.style.whiteSpace = 'nowrap'
    hiddenEl.value.style.position = 'absolute'
    hiddenEl.value.style.width = 'auto'
    hiddenEl.value.style.top = '0'
    hiddenEl.value.style.left = '-9999px'
    hiddenEl.value.setAttribute('aria-hidden', 'true')
    hiddenEl.value.classList.add('overflow-check')
    document.body.appendChild(hiddenEl.value)
  }

  watch(value, UseAppropriateValidation)

  watch(() => value.value, ManageTooltip, { immediate: true })

  onMounted(() => {
    UseAppropriateValidation(value.value)
    AssignTooltipText()
    CreateOverflowCheck()
  })

  const { OnBlur, OnInputChanged } = EstablishEvents()

  return {
    field,
    touched,
    isDirty,
    firstPass,
    isValid,
    value,
    tooltip,
    errorMessage,
    showRequiredError,
    OnBlur,
    OnInputChanged
  }
}

export function useValidationGroup(refs) {
  const toValidate = ref(refs)
  const validationGroup = computed(() => {
    return toValidate.value?.map((ref) => ref?.value?.isValid)
  })

  const errorIndecies = computed(() => {
    return validationGroup.value.map((v, i) => (v ? 0 : i)).filter((v) => v !== 0)
  })

  return { validationGroup, errorIndecies }
}
