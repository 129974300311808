import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  performanceEvaluationStatus: []
}
export const getters = {
  getPerformanceEvaluationStatuses: (state) => state.performanceEvaluationStatus
}
export const actions = {
  fetchPerformanceEvaluationStatuses: ({ commit, state }, force = false) => {
    // cache these where possible
    if (!force && state.performanceEvaluationStatus.length) {
      return Promise.resolve(state.performanceEvaluationStatus)
    }

    const url = `/api/v1/customer/{customerId}/node/{nodeId}/PerformanceEvaluation/Statuses`

    return api.get(url).then((response) => {
      const performanceEvaluationStatus = response.data.data.performanceEvaluationStatusDTO.values
      commit(types.PERFORMANCE_EVALUATION_STATUS_SET_ALL, performanceEvaluationStatus)
      return performanceEvaluationStatus
    })
  }
}
export const mutations = {
  [types.PERFORMANCE_EVALUATION_STATUS_SET_ALL]: (state, templates) => {
    state.performanceEvaluationStatus = templates
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
