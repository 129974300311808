import { api } from '@/api/api'
import get from 'lodash/get'
import has from 'lodash/has'
import set from 'lodash/set'
import * as types from '../../mutation-types'

export const state = {
  accountName: null,
  displayName: null,
  address1: null,
  address2: null,
  postalCode: null,
  language: null,
  timezone: null,
  country: null,
  provinceState: null,
  city: null,
  industry: null,
  sourceOfTender: null,
  allowSendToSPAOnProject: null,
  spaEndpointType: null
}

const getters = {
  // requires field eg: getAccountInfo('accountName')
  getAccountInfo: (state) => (field) => {
    // if no field, return entire state
    if (!field) return state
    if (has(state, field)) return get(state, field)
    return null
  }
}

export const actions = {
  /**
   * takes [field to update, value to assign]
   * returns [error, response]
   */
  createCustomer(_, model) {
    const url = `api/v1/customer/{customerId}`
    return api.post(url, model).then((response) => {
      const customerDTO = response.data.data.customerDTO.values[0]
      return customerDTO
    })
  },
  updatesCustomer(_, model) {
    const url = `api/v1/customer/{customerId}`
    return api.put(url, model).then((response) => {
      const customerDTO = response.data.data.customerDTO.values[0]
      return customerDTO
    })
  },
  updateAccountInfo: ({ commit, state }, params) => {
    if (!Array.isArray(params) || params.length < 2) {
      // incorrect format, structure
      return Promise.resolve([`updateAccountInfo argument must be array`, null])
    }

    const [field, value] = params

    if (!has(state, field)) {
      // incorrect format, structure
      return Promise.resolve([`no such field: [${field}]`, null])
    }

    commit(types.CONTROL_PANEL_ACCOUNT_INFO_SET_ITEM, { field, value })
    return Promise.resolve([null, [field, value]])
  }
}

export const mutations = {
  [types.CONTROL_PANEL_ACCOUNT_INFO_SET_ITEM]: (state, { field, value }) => {
    set(state, field, value)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
