import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  contractBuilderLicenseConfiguration: null, // single Agency
  contractBuilderLicenseConfigurations: [], // all Agencies
  contractBuilderLicenseConfigurationsContractSignatures: [],
  fetchRequired: false
}

export const getters = {
  getContractBuilderLicenseConfiguration: (state) => state.contractBuilderLicenseConfiguration,
  getContractBuilderLicenseConfigurations: (state) => state.contractBuilderLicenseConfigurations,
  getContractBuilderLicenseConfigurationsContractSignatures: (state) =>
    state.contractBuilderLicenseConfigurationsContractSignatures,
  contractBuilderLicenseConfigurationFetchRequired: (state) => state.fetchRequired
}

export const actions = {
  fetchContractBuilderLicenseConfiguration: ({ commit }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ContractBuilderLicenseConfiguration`

    return api.get(url).then((response) => {
      const result = response?.data?.data?.contractBuilderLicenseConfigurationDTO?.values?.[0] || []
      commit(types.CONTRACT_BUILDER_LICENSE_CONFIGURATION_SET, result)
      return result
    })
  },
  fetchContractBuilderLicenseConfigurationAll: ({ commit }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ContractBuilderLicenseConfiguration/All`
    commit(types.CONTRACT_BUILDER_LICENSE_CONFIGURATION_FETCH_REQUIRED_SET, false)

    return api.get(url).then((response) => {
      const result = response?.data?.data?.contractBuilderLicenseConfigurationDTO?.values || []
      commit(types.CONTRACT_BUILDER_LICENSE_CONFIGURATIONS_SET, result)
      return result
    })
  },
  fetchContractBuilderLicenseConfigurationsContractSignatures: ({ commit }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ContractBuilderLicenseConfiguration/ContractSignatures`

    return api.get(url).then((response) => {
      const result = response?.data?.data?.searchResult?.values || []
      commit(types.CONTRACT_BUILDER_LICENSE_CONFIGURATIONS_CONTRACT_SIGNATURES_SET, result)
      return result
    })
  },
  saveContractBuilderLicenseConfiguration: ({ commit }, contractBuilderLicenseConfigurationDTO) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ContractBuilderLicenseConfiguration`

    return api.post(url, contractBuilderLicenseConfigurationDTO).then((response) => {
      const result = response?.data?.data?.contractBuilderLicenseConfigurationDTO?.values?.[0] || []
      commit(types.CONTRACT_BUILDER_LICENSE_CONFIGURATION_SET, result)
      return result
    })
  },
  setContractBuilderLicenseConfigurationFetchRequired: ({ commit }, isRequired) => {
    commit(types.CONTRACT_BUILDER_LICENSE_CONFIGURATION_FETCH_REQUIRED_SET, isRequired)
  }
}

export const mutations = {
  [types.CONTRACT_BUILDER_LICENSE_CONFIGURATION_SET](state, contractBuilderLicenseConfiguration) {
    state.contractBuilderLicenseConfiguration = contractBuilderLicenseConfiguration
  },
  [types.CONTRACT_BUILDER_LICENSE_CONFIGURATIONS_SET](state, contractBuilderLicenseConfigurations) {
    state.contractBuilderLicenseConfigurations = contractBuilderLicenseConfigurations
  },
  [types.CONTRACT_BUILDER_LICENSE_CONFIGURATION_FETCH_REQUIRED_SET](state, isRequired) {
    state.fetchRequired = isRequired
  },
  [types.CONTRACT_BUILDER_LICENSE_CONFIGURATIONS_CONTRACT_SIGNATURES_SET](
    state,
    contractBuilderLicenseConfigurationsContractSignatures
  ) {
    state.contractBuilderLicenseConfigurationsContractSignatures =
      contractBuilderLicenseConfigurationsContractSignatures
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
