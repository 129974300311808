import { api } from '@/api/api'
import * as types from '../../mutation-types'

const state = {
  searchTenderResults: null,
  searchCriteraResults: null,
  totalNumSearchCriteriaResults: null,
  lastRotationalListUpdate: null
}

export const getters = {
  getBidInvitationSearchTenderResults: (state) => state.searchTenderResults,
  getBidInvitationSearchCriteriaResults: (state) => state.searchCriteraResults,
  getBidInvitationSearchCriteriaResultsTotal: (state) => state.totalNumSearchCriteriaResults,
  getLastRotationalListUpdate: (state) => state.lastRotationalListUpdate
}

export const actions = {
  fetchBidInvitationSearchResultsByCriteria: ({ commit }, criteria) => {
    const url = `${
      '/api/v1/customer/{customerId}/node/{nodeId}/VendorInvitations/SearchCriteria' +
      '?filter.limit='
    }${criteria.limit}&filter.start=${criteria.start}&filter.sortColumn=${
      criteria.sortColumn
    }&filter.sortDirection=${criteria.sortDirection}`

    const model = {
      companyName: criteria.companyName,
      contactFirstName: criteria.contactFirstName,
      contactLastName: criteria.contactLastName,
      email: criteria.email,
      city: criteria.city,
      localVendor: criteria.localVendor,
      emergencyVendor: criteria.emergencyVendor,
      certifiedDiverseSupplier: criteria.certifiedDiverseSupplier,
      categoryIds:
        criteria.categoryIds !== null && criteria.categoryIds.length > 0
          ? criteria.categoryIds
          : '',
      adminOnlyCategoryIds:
        criteria.adminOnlyCategoryIds !== null && criteria.adminOnlyCategoryIds.length > 0
          ? criteria.adminOnlyCategoryIds
          : '',
      CertifiedDiverseSupplierClassificationIds:
        criteria.diversityClassificationIds !== null &&
        criteria.diversityClassificationIds.length > 0
          ? criteria.diversityClassificationIds
          : ''
    }

    return api.post(url, model).then((response) => {
      if (response.data.count !== 0) {
        const searchCriteraResults = response.data.data.searchResult.values[0]

        commit(types.INVITATION_SET_SEARCH_CRITERIA_RESULT, searchCriteraResults)
        commit(
          types.INVITATION_SET_SEARCH_CRITERIA_RESULT_TOTAL_COUNT,
          response.data.data.searchResult.total
        )
        return response
      }
    })
  },
  fetchBidInvitationSearchResultsByTender: ({ commit }, criteria) => {
    const url = `${
      '/api/v1/customer/{customerId}/node/{nodeId}/VendorInvitations/SearchTender' + '?tenderId='
    }${criteria.tenderId}&plantakerOptions=${criteria.plantakerOptions}&filter.sortColumn=${
      criteria.sortColumn
    }&filter.sortDirection=${criteria.sortDirection}`

    return api.get(url).then((response) => {
      if (response.data.count !== 0) {
        const searchTenderResults = response.data.data.searchResult.values[0]

        commit(types.INVITATION_SET_SEARCH_TENDER_RESULT, searchTenderResults)

        return response
      }
    })
  },
  lookupEmailInvitee: (_, param) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/VendorInvitations/LookupEmailInvitee?tenderId=${param.tenderId}&email=${param.email}`
    return api.get(url)
  },
  setLastRotationalListUpdate: ({ commit }, date) => {
    commit(types.INVITATION_SET_LAST_ROTATIONAL_LIST_UPDATE, date)
  }
}

export const mutations = {
  [types.INVITATION_SET_SEARCH_TENDER_RESULT](state, searchTenderResults) {
    state.searchTenderResults = searchTenderResults
  },
  [types.INVITATION_SET_SEARCH_CRITERIA_RESULT](state, searchCriteraResults) {
    state.searchCriteraResults = searchCriteraResults
  },
  [types.INVITATION_SET_SEARCH_CRITERIA_RESULT_TOTAL_COUNT](state, count) {
    state.totalNumSearchCriteriaResults = count
  },
  [types.INVITATION_SET_LAST_ROTATIONAL_LIST_UPDATE](state, date) {
    state.lastRotationalListUpdate = date
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
