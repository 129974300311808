<template>
  <div class="award-approval-unavailable has-padding-xlg is-flex is-direction-column">
    <app-title heading="1" size="3">{{ $t(viewString + 'titleUnavailable') }}</app-title>
    <p>{{ $t(viewString + 'descriptionAwardUnvailable') }}</p>
  </div>
</template>

<script>
export default {
  name: 'award-approval-unavailable',
  data() {
    return {
      viewString: 'ui.awardApproval.'
    }
  }
}
</script>

<style lang="scss" scoped>
.award-approval-unavailable {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: auto;
  text-align: center;
  height: 100%;
}
</style>
