import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = import.meta.glob('../localization/locales/*.json', { eager: true })
  const messages = {}
  for (const key in locales) {
    if (locales.hasOwnProperty(key)) {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i)
      if (matched && matched.length > 1) {
        const locale = matched[1]
        messages[locale] = locales[key].default
      }
    }
  }
  return messages
}

const messages = loadLocaleMessages()

export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  silentTranslationWarn: true,
  messages
})
