export const tokens = [
  // Contract Only
  { Token: '#ContractID#', Bids: null, Contracts: 'X', Other: null },
  { Token: '#ContractName#', Bids: null, Contracts: 'X', Other: null },
  { Token: '#ContractNumber#', Bids: null, Contracts: 'X', Other: null },
  { Token: '#ContractStatus#', Bids: null, Contracts: 'X', Other: null },
  { Token: '#ContractDateClosing#', Bids: null, Contracts: 'X', Other: null },
  { Token: '#ContractDateOpen#', Bids: null, Contracts: 'X', Other: null },
  { Token: '#Document#', Bids: null, Contracts: 'X', Other: null },
  { Token: '#InsuranceType#', Bids: null, Contracts: 'X', Other: null },
  { Token: '#UploadedBy#', Bids: null, Contracts: 'X', Other: null },
  { Token: '#UploadedDate#', Bids: null, Contracts: 'X', Other: null },
  { Token: '#ReminderDescription#', Bids: null, Contracts: 'X', Other: null },
  { Token: '#ReminderDueDate#', Bids: null, Contracts: 'X', Other: null },
  { Token: '#TenderId#', Bids: 'X', Contracts: null, Other: null },
  { Token: '#TenderName#', Bids: 'X', Contracts: null, Other: null },
  { Token: '#TenderNumber#', Bids: 'X', Contracts: null, Other: null },
  { Token: '#TenderStatus#', Bids: 'X', Contracts: null, Other: null },
  { Token: '#TenderDateClosing#', Bids: 'X', Contracts: null, Other: null },
  { Token: '#TenderDateAvailable#', Bids: 'X', Contracts: null, Other: null },
  { Token: '#VendorId#', Bids: 'X', Contracts: null, Other: null },
  { Token: '#CompanyName#', Bids: 'X', Contracts: 'X', Other: null },
  { Token: '#Username#', Bids: 'X', Contracts: null, Other: null },
  { Token: '#PasswordResetUrl#', Bids: 'X', Contracts: null, Other: null },
  { Token: '#Address1#', Bids: 'X', Contracts: 'X', Other: null },
  { Token: '#Address2#', Bids: 'X', Contracts: 'X', Other: null },
  { Token: '#Province#', Bids: 'X', Contracts: 'X', Other: null },
  { Token: '#Country#', Bids: 'X', Contracts: 'X', Other: null },
  { Token: '#City#', Bids: 'X', Contracts: 'X', Other: null },
  { Token: '#PostalCode#', Bids: 'X', Contracts: 'X', Other: null },
  { Token: '#CompanyContacts#', Bids: 'X', Contracts: 'X', Other: null },
  { Token: '#CompanyContactsCompact#', Bids: 'X', Contracts: 'X', Other: null },
  { Token: '#Contracts#', Bids: null, Contracts: 'X', Other: null },
  { Token: '#ContactID#', Bids: 'X', Contracts: 'X', Other: null },
  { Token: '#FirstName#', Bids: 'X', Contracts: 'X', Other: null },
  { Token: '#LastName#', Bids: 'X', Contracts: 'X', Other: null },
  { Token: '#Email#', Bids: 'X', Contracts: 'X', Other: null },
  { Token: '#Username#', Bids: 'X', Contracts: null, Other: null },
  { Token: '#UserID#', Bids: 'X', Contracts: null, Other: null },
  { Token: '#YearMonthDay#', Bids: 'X', Contracts: null, Other: null },
  { Token: '#MonthDayYear#', Bids: 'X', Contracts: null, Other: null },
  { Token: '#DayMonthYear#', Bids: 'X', Contracts: null, Other: null },
  { Token: '#CurrentDate#', Bids: 'X', Contracts: null, Other: null },
  { Token: '#CurrentTime#', Bids: 'X', Contracts: null, Other: null },
  { Token: '#LoggedInUser#', Bids: 'X', Contracts: null, Other: null },
  { Token: '#NoBidReasons#', Bids: 'X', Contracts: null, Other: null },
  { Token: '#QuestionText#', Bids: 'X', Contracts: null, Other: null },
  { Token: '#DateSubmitted#', Bids: 'X', Contracts: null, Other: null },
  { Token: 'http://ClientPUblicUrl.com', Bids: 'X', Contracts: null, Other: null },
  { Token: '#PerformanceEvaluationUrl#', Bids: 'X', Contracts: null, Other: null },
  { Token: '#ClientDisplayName#', Bids: 'X', Contracts: null, Other: null },
  { Token: '#TenderInvitationLink#', Bids: 'X', Contracts: null, Other: null },
  {
    Token: '#SiteMeetingDate# (These are used for 24 hour site meeting email)',
    Bids: 'X',
    Contracts: null,
    Other: null
  },
  {
    Token:
      '#SiteMeetingStartTime# - #SiteMeetingCloseTime# (These are used for 24 hour site meeting email)',
    Bids: 'X',
    Contracts: null,
    Other: null
  },
  {
    Token: '#SiteMeetingLocation# (These are used for 24 hour site meeting email)',
    Bids: 'X',
    Contracts: null,
    Other: null
  },
  { Token: '#CurrentDate# - Based on local setttings', Bids: 'X', Contracts: null, Other: null },
  { Token: '#CurrentTime#', Bids: 'X', Contracts: null, Other: null },
  { Token: '#TenderQuestionDeadlineDate#', Bids: 'X', Contracts: null, Other: null },
  { Token: '#TenderQuestionDeadlineTime#', Bids: 'X', Contracts: null, Other: null },
  { Token: '#MeetingLocations#', Bids: 'X', Contracts: null, Other: null },
  { Token: '#PurchasingRep#', Bids: 'X', Contracts: 'X', Other: null }
]

export const columnDefs = [
  {
    headerName: 'TOKEN',
    field: 'Token'
  },
  {
    headerName: 'Bids',
    field: 'Bids'
  },
  {
    headerName: 'Contracts',
    field: 'Contracts'
  },
  {
    headerName: 'Other',
    field: 'Other'
  }
]
