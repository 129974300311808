<script setup>
import { ref, computed, watch, useSlots } from 'vue'
import uuid from 'uuid/v4'
import AppPermissionTooltip from '@/components/app-permission-tooltip/app-permission-tooltip.vue'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import { fontBackgroundColor, fontColor } from './colors.js'

const slots = useSlots()

const props = defineProps({
  id: String,
  value: String,
  disabled: Boolean,
  readonly: Boolean,
  toolbar: {
    type: Array,
    default: () => []
  },
  appendToolbar: {
    type: Array,
    default: () => []
  },
  height: {
    type: String,
    default: '300px'
  },
  placeholder: {
    type: String,
    default: 'Start typing...'
  }
})

const emit = defineEmits(['input', 'ready'])

const wrapperEl = ref(null)
const editorData = ref('<p>The editor data</p>')

const defaultToolbar = ref({
  items: [
    'undo',
    'redo',
    'heading',
    'bold',
    'link',
    'italic',
    'fontBackgroundColor',
    'fontColor',
    'fontFamily',
    'fontSize',
    'removeHighlight',
    'highlight',
    'blockQuote',
    'numberedList',
    'bulletedList',
    'insertImage',
    'alignment',
    'indent',
    'outdent',
    'horizontalLine',
    'insertTable'
  ]
})

const editorConfig = ref({
  placeholder: props.placeholder,
  fontColor,
  fontBackgroundColor,
  // eslint-disable-next-line
  toolbar: buildToolbar(),
  removePluginslugins: ['markdown']
})

const isDirty = ref(false)

const EditorId = ref(props.id || `editor-${uuid()}`)

const isDisabled = computed(() => {
  return props.disabled || props.readonly
})

watch(
  () => props.value,
  (value) => {
    if (editorData.value === value) return
    editorData.value = value
  },
  { immediate: true }
)

watch(
  () => editorData.value,
  (value) => {
    if (props.value === value) {
      return
    }
    emit('input', value)
    if (!isDirty.value) isDirty.value = true
  }
)

function resizeEditor() {
  const editorEl = wrapperEl.value.querySelector(
    '.ck-editor__editable_inline:not(.ck-comment__input *)'
  )

  if (editorEl) {
    editorEl.style = `height: ${props.height}px;`
  }
}

function onEditorReady() {
  emit('ready')
  resizeEditor()
}

function buildToolbar() {
  const toolbarItems = props.toolbar.length ? props.toolbar : defaultToolbar.value.items

  if (props.appendToolbar) {
    toolbarItems.push(...props.appendToolbar)
  }

  return { items: toolbarItems }
}

function getPlainText() {
  const span = document.createElement('span')
  span.innerHTML = this.value
  return span.textContent || span.innerText
}

defineExpose({
  getPlainText
})
</script>

<template>
  <div ref="wrapperEl" class="app-editor">
    <div v-if="isDisabled" class="editor-locked-icon">
      <AppPermissionTooltip class="permission-icon has-margin-left-tiny" />
    </div>

    <ckeditor
      :id="EditorId"
      v-model="editorData"
      :editor="Editor"
      :config="editorConfig"
      :disabled="isDisabled"
      :style="{ height: props.height + 'px' }"
      @ready="onEditorReady"
    >
    </ckeditor>

    <div
      v-if="slots['append'] && isDirty"
      :class="{ 'is-visible': hasAppend && isDirty }"
      class="app-editor-append"
    >
      <slot ref="append" name="append"></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.app-editor {
  position: relative;
  margin-top: $gap;

  :deep(.ck-editor__editable_inline:not(.ck-comment__input *)) {
    height: 300px;
  }

  :deep(.ck-editor__editable) {
    height: auto;
    padding: $gap-tiny;
    border: 1px solid $border;
    background: $white;
    color: $text;
    overflow: auto;
    transition: border-color 0.2s;
  }

  :deep(.ck-read-only) {
    background: $white-bis !important;
  }

  :deep(.ck.ck-powered-by) {
    display: none !important;
  }

  &-append {
    padding: 0;
    &.is-visible {
      display: block;
      padding: $gap-tiny;
      color: $c-danger;
    }
  }

  .editor-locked-icon {
    position: absolute;
    left: $gap-tiny;
    bottom: $gap-tiny;
    color: $c-text-light;
    z-index: $z-4;

    i,
    i:before {
      font-size: 16px;
    }
  }
}
</style>
<style lang="scss">
.ck.ck-powered-by {
  /* not cool, guys */
  display: none !important;

  .ck.ck-toolbar {
    background: none;
  }

  // editor styles
  .ck-editor__top {
    border-bottom: 0;
    background: $white;
    border-radius: $radius $radius 0 0;

    .cke_button {
      border-radius: 4px;
      border-color: transparent;

      &.cke_button_on {
        background: $c-border;
        color: $white;
      }
    }
  }

  .ck-content {
    border-top: 0;
  }

  .cke_bottom {
    border-radius: 0 0 $radius $radius;
    border-top: 0;
    background: $white;

    .cke_path {
      display: none;
    }
  }

  .cke_toolbox {
    display: flex;
    flex-flow: row wrap;
    max-width: 100%;
  }

  &.is-disabled {
    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      z-index: $z-5;
      width: 100%;
      height: 100%;
      background: rgba($c-border, 0.1);
    }
  }

  &.is-preview {
    .cke_top {
      display: none;
    }

    .editor-locked-icon {
      display: none;
    }
  }
}
</style>
