import * as types from '../mutation-types'

const state = {
  authorized: null,
  authCustomer: null,
  authCustomerNode: null,
  authUser: null,
  authTenderNode: null
}

export const getters = {
  isAuthorized: (state) => state.authorized,
  getAuthCustomer: (state) => state.authCustomer,
  getAuthCustomerNode: (state) => state.authCustomerNode,
  getAuthUser: (state) => state.authUser,
  getAuthTenderNode: (state) => state.authTenderNode
}

export const actions = {
  setAuthCredentials: ({ commit }, { customerId, userId, nodeId, customerNodeId }) => {
    commit(types.AUTH_SET_ISAUTH, true)
    commit(types.AUTH_SET_CUSTOMER, { id: customerId })
    commit(types.AUTH_SET_USER, { id: userId })
    commit(types.AUTH_SET_NODE, { id: nodeId })
    commit(types.AUTH_SET_CUSTOMERNODE, { id: customerNodeId })
  }
}

export const mutations = {
  [types.AUTH_SET_ISAUTH](state, isAuth) {
    state.authorized = isAuth
  },
  [types.AUTH_SET_CUSTOMER](state, authCustomer) {
    state.authCustomer = authCustomer
  },
  [types.AUTH_SET_USER](state, authUser) {
    state.authUser = authUser
  },
  [types.AUTH_SET_NODE](state, authTenderNode) {
    state.authTenderNode = authTenderNode
  },
  [types.AUTH_SET_CUSTOMERNODE](state, authCustomerNode) {
    state.authCustomerNode = authCustomerNode
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
