<template>
  <div
    :class="{
      'has-error': showError,
      'is-group': group,
      'is-row': layout === 'row',
      'is-required': required
    }"
    class="app-field"
  >
    <label
      v-if="label"
      :class="{ 'is-sr-only': srOnly, 'is-required': required }"
      :for="inputID"
      class="label"
      >{{ label }}
    </label>
    <slot></slot>
    <slot name="append"></slot>
  </div>
</template>

<script>
import uuid from 'uuid/v1'

export default {
  name: 'app-field',
  props: {
    label: String,
    srOnly: Boolean,
    required: Boolean,
    showError: Boolean,
    group: Boolean,
    layout: String
  },
  data() {
    return {
      inputID: null
    }
  },
  mounted() {
    this.inputID = this.$el.querySelectorAll('input')[0]?.getAttribute('id') || uuid()
  }
}
</script>

<style lang="scss" src="./app-field.scss"></style>
