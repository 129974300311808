import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  customer: null,
  customerAll: null,
  timezones: null,
  languages: null
}

export const getters = {
  getCustomer: (state) => state.customer,
  getCustomerAll: (state) => state.customerAll,
  getTimeZoneAll: (state) => state.timezones,
  getLanguageAll: (state) => state.languages,
  getCustomerTimeZone: () => {
    return localStorage.getItem('customerTimeZone')
  }
}
export const actions = {
  setCustomer: ({ commit }, customer) => {
    commit(types.CUSTOMER_SET, customer)
  },
  setCustomerTimeZone: ({ commit }, timeZone) => {
    commit(types.CUSTOMER_SET_TIMEZONE, timeZone)
  },
  fetchCustomer: ({ commit }, customerId) => {
    const url = `/api/v1/customer/{customerId}?customerId=${customerId}`
    return api.get(url).then((response) => {
      const data = response.data.data.customerDTO.values[0]
      commit(types.CUSTOMER_SET, data)
      return data
    })
  },
  fetchCustomerAll: ({ commit }) => {
    const url = '/api/v1/customer/{customerId}/All'

    return api.get(url).then((response) => {
      const data = response.data.data.searchResult.values
      commit(types.CUSTOMER_ALL_SET, data)
      return data
    })
  },
  fetchTimeZoneAll: ({ commit }) => {
    const url = '/api/v1/customer/{customerId}/TimeZones'

    return api.get(url).then((response) => {
      const timezones = response.data.data.timeZoneDTO.values
      commit(types.TIMEZONE_ALL_SET, timezones)
      return timezones
    })
  },
  fetchLanguageAll: ({ commit }) => {
    const url = '/api/v1/customer/{customerId}/Languages'

    return api.get(url).then((response) => {
      const languages = response.data.data.languageDTO.values
      commit(types.LANGUAGE_ALL_SET, languages)
      return languages
    })
  }
}
export const mutations = {
  [types.CUSTOMER_SET](state, customer) {
    state.customer = customer
  },
  [types.CUSTOMER_ALL_SET](state, customer) {
    state.customerAll = customer
  },
  [types.TIMEZONE_ALL_SET](state, timezones) {
    state.timezones = timezones
  },
  [types.LANGUAGE_ALL_SET](state, languages) {
    state.languages = languages
  },
  [types.CUSTOMER_SET_TIMEZONE](state, timeZone) {
    localStorage.setItem('customerTimeZone', timeZone)
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
