<template>
  <div
    ref="wrapper"
    :class="{ 'is-full-width': !nav, 'has-topbar': topbar, 'has-sidebar': hasSidebar }"
    v-bind="$attrs"
    class="project-view-wrapper"
  >
    <div v-if="hasRoutePermissionToView" class="project-view-wrapper__inner">
      <section v-if="hasTitle || hasDescription" class="project-view-wrapper__header">
        <div v-if="hasTitle" class="project-view-wrapper__title">
          <slot name="title" />
        </div>
        <div v-if="hasDescription" class="project-view-wrapper__description">
          <slot name="description" />
        </div>
      </section>
      <section v-if="hasContent" class="project-view-wrapper__content">
        <slot name="content" />
      </section>
      <slot />
    </div>
    <div
      v-else
      class="project-view-wrapper__inner access-denied is-flex is-aligned-center is-justified-center is-fullheight"
    >
      <app-empty background="white">
        <img src="@/assets/images/SVGs/access_denied.svg" alt="monitor with alert sign" />
        <app-title size="2" heading="2" centered>
          {{ $t('ui.views.projects.project-view.titleAccessDenied') }}
        </app-title>
        <p slot="subtext">
          {{ $t('ui.views.projects.project-view.descriptionNoPermission') }}
        </p>
      </app-empty>
    </div>
    <aside v-if="hasSidebar" class="project-view-wrapper__sidebar">
      <slot name="sidebar" />
    </aside>
  </div>
</template>

<script>
import PermissionsMixins from '@/security/permissions/permissions-mixins'
export default {
  name: 'project-view-wrapper',
  mixins: [PermissionsMixins],
  props: {
    nav: {
      type: Boolean,
      default: true
    },
    topbar: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      internalClasses: ['project-view-wrapper', 'is-full-width', 'has-topbar', 'has-sidebar']
    }
  },
  computed: {
    hasTitle() {
      return this.$slots.title
    },
    hasDescription() {
      return this.$slots.description
    },
    hasContent() {
      return this.$slots.content
    },
    hasSidebar() {
      return this.hasRoutePermissionToView && this.$slots.sidebar
    }
  },
  mounted() {
    // if !view permission, remove any classes bound by $attrs (to strip view-specific styling)
    if (!this.hasRoutePermissionToView) {
      this.$refs.wrapper.classList.forEach((className) => {
        if (!this.internalClasses.includes(className))
          this.$refs.wrapper.classList.remove(className)
      })
    }
  }
}
</script>

<style lang="scss">
$sidebar-width: $sidebar-width-base * 2;

.project-view-wrapper {
  height: calc(100% - #{$toolbar-height-md});
  width: 100%;
  min-width: $tablet;
  // overflow:auto;
  padding: 0;

  &.has-sidebar {
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 auto;
  }

  @media (min-width: #{$desktop}) {
    // overflow: hidden;
  }

  &__header {
    margin-bottom: $gap-large;
  }

  &__content {
    & > section,
    & > div > section {
      padding: 0;
    }
  }

  &__inner {
    min-width: $tablet;

    .has-sidebar & {
      width: 100%;
      min-width: $tablet - $sidebar-width-base;
      padding-right: $gap;
      border-right: 1px solid $c-border;
    }

    .is-full-width & {
      min-width: $desktop;
      padding: $padding-large;
    }

    .has-topbar & {
      padding-bottom: $appbar-height-lg;
    }

    &.access-denied {
      min-height: calc(100vh - 150px);
      width: 100%;
    }
  }

  &__sidebar {
    padding-left: $gap;
    width: $sidebar-width;
    min-width: $sidebar-width-base;
  }
}
</style>
