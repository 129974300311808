import store from '@/store'
import axios from 'axios'

let apiHeaders = {
  'Content-Type': 'application/json'
}

let uploadApiMultipartHeaders = {
  'Content-Type': 'multipart/form-data'
}

let accessHeaders = {}

// eslint-disable-next-line no-undef
if (import.meta.env.MODE === 'development' || import.meta.env.VITE_IS_LOCAL) {
  accessHeaders = {
    // eslint-disable-next-line no-undef
    externalaccessapikey: import.meta.env.VITE_ACCESS_API_KEY
  }

  apiHeaders = { ...apiHeaders, ...accessHeaders }
  uploadApiMultipartHeaders = { ...uploadApiMultipartHeaders, ...accessHeaders }
}

export const api = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: import.meta.env.VITE_API_BASE,
  // 5 minutes
  timeout: 300000,
  withCredentials: true,
  headers: apiHeaders
})

export const uploadApi = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: import.meta.env.VITE_API_BASE,
  // increase to 30 minutes as per request of CustomDev
  timeout: 1800000,
  contentType: false,
  withCredentials: true,
  processData: false,
  headers: accessHeaders
})

export const uploadApiMultipart = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: import.meta.env.VITE_API_BASE,
  // increase to 30 minutes as per request of CustomDev
  timeout: 1800000,
  contentType: false,
  withCredentials: true,
  processData: false,
  headers: uploadApiMultipartHeaders
})

export const downloadApi = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: import.meta.env.VITE_API_BASE,
  // 30 minutes - might still not be enough for large files over slow connections
  timeout: 1800000,
  withCredentials: true,
  contentType: false,
  responseType: 'blob',
  headers: accessHeaders
})

function replaceString(baseString, customerId, nodeId) {
  return baseString
    .replace(/\{[ ]{0,}customerId[ ]{0,}\}/, customerId)
    .replace(/\{[ ]{0,}nodeId[ ]{0,}\}/, nodeId)
}

function processConfig(config, replaceTagsInData) {
  const customerId = (store.getters.getAuthCustomer || {}).id || ''
  const nodeId = (store.getters.getAuthTenderNode || {}).id || ''

  // replace tags in url
  config.url = replaceString(config.url, customerId, nodeId)

  // replace tags in posted data
  if (replaceTagsInData && config.data) {
    config.data = JSON.parse(replaceString(JSON.stringify(config.data), customerId, nodeId))
  }

  return config
}

api.interceptors.request.use((config) => processConfig(config, true))

uploadApi.interceptors.request.use((config) => processConfig(config))

downloadApi.interceptors.request.use((config) => processConfig(config))

uploadApiMultipart.interceptors.request.use((config) => processConfig(config))

// 401 exemptions
export const allowedEndpoints = [
  ['PUT', '/teamMember']
  // ['POST', '/allowedEndpoint'],
  // ['GET', '/allowedEndpoint'],
]

export const isAllowedEndpoint = (error) =>
  allowedEndpoints.some((endpoint) => {
    return (
      error.config.method.toUpperCase() === endpoint[0] && error.config.url.includes(endpoint[1])
    )
  })

// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    localStorage.setItem('session-timeout', Date.now())
    return response
  },
  (error) => {
    if (error.status && error.status !== 401) {
      localStorage.setItem('session-timeout', Date.now())
    }
    if (error.response?.status && error.response.status === 401 && isAllowedEndpoint(error)) {
      // Certain 401 errors are allowed
      localStorage.setItem('session-timeout', Date.now())
    }

    if (error?.response?.data?.length === 2 && error.response.data[0] === 'agencyMismatch') {
      error.message = error.response.data[1]
    }
    return Promise.reject(error)
  }
)

export default {
  api
}
