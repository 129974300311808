import { uploadApi } from '@/api/api'

export const actions = {
  createProjectJournalDocument: (_, model) => {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/ProjectJournalDocuments'
    return uploadApi
      .post(url, model)
      .then((response) => response.data.data.projectJournalDocumentDTO.values[0])
  }
}

export default {
  namespaced: false,
  actions
}
