<template>
  <div class="app-toolbar-group">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'app-toolbar-group',
  data() {
    return {}
  }
}
</script>
