<template>
  <div>
    <div class="app-loading"></div>
  </div>
</template>

<script>
export default {
  name: 'app-loading'
}
</script>

<style lang="scss" scoped>
.app-loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 3px solid rgba($c-primary, 0.3);
  border-radius: 50%;
  border-top-color: $c-primary;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
