import AppAvatar from '@/components/app-avatar/app-avatar.vue'
import AppButton from '@/components/app-button/app-button.vue'
import AppColumnButton from '@/components/app-button/app-column-button.vue'
import AppCheckbox from '@/components/app-checkbox/app-checkbox.vue'
import AppDialogBox from '@/components/app-dialog/app-dialogbox.vue'
import AppEditor from '@/components/app-editor/app-editor.vue'
import AppEmpty from '@/components/app-empty/app-empty.vue'
import AppField from '@/components/app-field/app-field.vue'
import AppGrid from '@/components/app-grid/app-grid.vue'
import AppGridActions from '@/components/app-grid/app-grid-actions/app-grid-actions.vue'
import AppGridPagination from '@/components/app-grid/app-grid-pagination/app-grid-pagination.vue'
import AppIcon from '@/components/app-icon/app-icon.vue'
import AppInputFile from '@/components/app-input-file/app-input-file.vue'
import AppInput from '@/components/app-input/app-input.vue'
import { AppListGroup, AppListGroupItem } from '@/components/app-listgroup/index.js'
import { AppPanel, AppPanelContent, AppPanelHeader } from '@/components/app-panel/index.js'
import AppPermissionTooltip from '@/components/app-permission-tooltip/app-permission-tooltip.vue'
import AppPrivateFieldLabel from '@/components/app-private-field-warning/app-private-field-label.vue'
import AppPrivateFieldWarning from '@/components/app-private-field-warning/app-private-field-warning.vue'
import AppPublicVisibilityLabel from '@/components/app-public-visibility-warning/app-public-visibility-label.vue'
import AppPublicVisibilityWarning from '@/components/app-public-visibility-warning/app-public-visibility-warning.vue'
import AppRadio from '@/components/app-radio/app-radio.vue'
import AppReveal from '@/components/app-reveal/app-reveal.vue'
import AppSelect from '@/components/app-select/app-select.vue'
import { AppSidebar } from '@/components/app-sidebar/index.js'
import AppSwitch from '@/components/app-switch/app-switch.vue'
import AppTitle from '@/components/app-title/app-title.vue'
import { AppToolbar, AppToolbarGroup } from '@/components/app-toolbar/index.js'
import AppVerifiedCheckmark from '@/components/app-verified-checkmark/app-verified-checkmark.vue'
import AppWidget from '@/components/app-widget/app-widget.vue'
import CapitalizeFilter from '@/filters/capitalize.js'
import CurrencyCommaFilter from '@/filters/currencyComma.js'
import DateStringFilter from '@/filters/dateString.js'
import DateStringFilterShort from '@/filters/dateStringShort.js'
import i18n from '@/plugins/i18n.js'
import EmailWizard from '@/views/email-wizard/email-wizard-view.vue'
import ProjectViewWrapper from '@/views/projects/project-view/project-components/project-view-wrapper.vue'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale'
import lang from 'element-ui/lib/locale/lang/en'
import Vue from 'vue'
import VueDraggable from 'vue-draggable'
import Trap from 'vue-focus-lock'
import App from './App.vue'
import router from './router/index.js'
import store from './store/index.js'
import CKEditor from '@ckeditor/ckeditor5-vue2'

import { extendElementUIComponents } from './utils/accessibilityPatches.js'

// configure language for element.ui
locale.use(lang)

// use elemnt framework
Vue.use(ElementUI)

Vue.use(VueDraggable)
Vue.use(CKEditor)

Vue.component('app-grid', AppGrid)
Vue.component('app-empty', AppEmpty) //*
Vue.component('app-grid-pagination', AppGridPagination)
Vue.component('app-grid-actions', AppGridActions)
Vue.component('app-sidebar', AppSidebar)
Vue.component('app-panel', AppPanel)
Vue.component('app-panel-header', AppPanelHeader)
Vue.component('app-panel-content', AppPanelContent)
Vue.component('app-title', AppTitle)
Vue.component('app-reveal', AppReveal) // *
Vue.component('app-toolbar', AppToolbar)
Vue.component('app-toolbar-group', AppToolbarGroup) // *
Vue.component('app-listgroup', AppListGroup)
Vue.component('app-listgroup-item', AppListGroupItem)
Vue.component('app-avatar', AppAvatar)
Vue.component('app-input', AppInput)
Vue.component('app-field', AppField)
Vue.component('app-checkbox', AppCheckbox)
Vue.component('app-dialogbox', AppDialogBox)
Vue.component('app-radio', AppRadio)
Vue.component('app-icon', AppIcon)
Vue.component('app-button', AppButton)
Vue.component('app-input-file', AppInputFile)
Vue.component('app-editor', AppEditor)
Vue.component('app-column-button', AppColumnButton)
Vue.component('app-select', AppSelect)
Vue.component('email-wizard', EmailWizard)
Vue.component('project-view-wrapper', ProjectViewWrapper)
Vue.component('app-switch', AppSwitch)
Vue.component('app-permission-tooltip', AppPermissionTooltip)
Vue.component('app-public-visibility-warning', AppPublicVisibilityWarning)
Vue.component('app-public-visibility-label', AppPublicVisibilityLabel)
Vue.component('app-private-field-warning', AppPrivateFieldWarning)
Vue.component('app-private-field-label', AppPrivateFieldLabel)
Vue.component('app-widget', AppWidget) // *
Vue.component('app-verified-checkmark', AppVerifiedCheckmark) // *
Vue.component('app-focus-trap', Trap)
Vue.filter('dateString', DateStringFilter)
Vue.filter('dateStringShort', DateStringFilterShort)
Vue.filter('currencyComma', CurrencyCommaFilter)
Vue.filter('capitalize', CapitalizeFilter)

// Accessibility patches
const { extendedDropdown, extendedTable } = extendElementUIComponents()
Vue.component('ElDropdown', extendedDropdown)
Vue.component('ElTable', extendedTable)

Vue.config.productionTip = false

/* eslint-disable no-new */
/* eslint-disable no-unused-vars */
const vm = new Vue({
  el: '#app',
  components: {
    App
  },
  render: (h) => h(App),
  store,
  router,
  i18n
})
