import EvaluationService from '@/services/evaluation/evaluation.service.js'
import * as types from '@/store/mutation-types.js'

const state = () => ({
  projectEvaluation: null,
  evaluations: [],
  evaluationGuideTemplates: [],
  nonDisclosureTemplates: [],
  conflictOfInterestTemplates: [],
  scoreCardTemplates: []
})

const getters = {
  getProjectEvaluation: (state) => state.projectEvaluation,
  getEvaluations: (state) => state.evaluations,
  getEvaluationGuideTemplates: (state) => state.evaluationGuideTemplates,
  getNonDisclosureTemplates: (state) => state.nonDisclosureTemplates,
  getConflictOfInterestTemplates: (state) => state.conflictOfInterestTemplates,
  getScoreCardTemplates: (state) => state.scoreCardTemplates
}

const actions = {
  fetchProjectEvaluation: async ({ commit }, config) => {
    try {
      commit(types.EVALUATION_SET_PROJECT_EVALUATION, null)
      const response = await EvaluationService(config).getProjectEvaluation()
      if (response?.status === 200) {
        commit(types.EVALUATION_SET_PROJECT_EVALUATION, response.data.projectEvaluation)
        return Promise.resolve(response)
      }
    } catch (err) {
      return Promise.reject(err)
    }
  },
  createProjectEvaluation: async ({ commit }, config) => {
    try {
      const response = await EvaluationService(config).createProjectEvaluation()
      if (response) {
        commit(types.EVALUATION_SET_PROJECT_EVALUATION, response.data.projectEvaluation)
        return Promise.resolve(response)
      }
    } catch (err) {
      return Promise.reject(err)
    }
  },
  fetchAllEvaluations: async ({ commit }, config) => {
    try {
      const response = await EvaluationService(config).getAllEvaluations()
      if (response) {
        commit(types.EVALUATION_SET_EVALUATIONS, response?.data?.evaluationDTO?.values || [])
        return Promise.resolve(response)
      }
    } catch (err) {
      return Promise.reject(err)
    }
  },
  fetchAllEvaluationGuideTemplates: async ({ commit }, config) => {
    try {
      const response = await EvaluationService(config).getAllTemplates('EvaluationGuide')
      if (response) {
        commit(
          types.EVALUATION_SET_EVALTAION_GUIDE_TEMPLATES,
          response?.data?.onlineSubmissionTemplateDTO?.values || []
        )
        return Promise.resolve(response)
      }
    } catch (err) {
      return Promise.reject(err)
    }
  },
  fetchAllNonDisclosureTemplates: async ({ commit }, config) => {
    try {
      const response = await EvaluationService(config).getAllTemplates(
        'EvaluationNonDisclosureAgreement'
      )
      if (response) {
        commit(
          types.EVALUATION_SET_NON_DISCLOSURE_TEMPLATES,
          response?.data?.onlineSubmissionTemplateDTO?.values || []
        )
        return Promise.resolve(response)
      }
    } catch (err) {
      return Promise.reject(err)
    }
  },
  fetchAllConflictOfInterestTemplates: async ({ commit }, config) => {
    try {
      const response = await EvaluationService(config).getAllTemplates(
        'EvaluationConflictOfInterest'
      )
      if (response) {
        commit(
          types.EVALUATION_SET_CONFLICT_OF_INTEREST_TEMPLATES,
          response?.data?.onlineSubmissionTemplateDTO?.values || []
        )
        return Promise.resolve(response)
      }
    } catch (err) {
      return Promise.reject(err)
    }
  },
  fetchAllScoreCardTemplates: async ({ commit }, config) => {
    try {
      const response = await EvaluationService(config).getAllTemplates('EvaluationScoreCard')
      if (response) {
        commit(
          types.EVALUATION_SET_SCORE_CARD_TEMPLATES,
          response?.data?.onlineSubmissionTemplateDTO?.values || []
        )
        return Promise.resolve(response)
      }
    } catch (err) {
      return Promise.reject(err)
    }
  },
  submitProjectEvaluation: async ({ commit }, config) => {
    try {
      let response = null
      if (config.evaluationId) {
        response = await EvaluationService(config).putProjectEvaluation(
          config.model,
          config.evaluationId
        )
      } else {
        response = await EvaluationService(config).postProjectEvaluation(config.model)
      }
      if (response) {
        commit(types.EVALUATION_SET_PROJECT_EVALUATION, response)
        return Promise.resolve(response)
      }
    } catch (err) {
      return Promise.reject(err)
    }
  },
  deleteProjectEvaluation: async (_, config) => {
    try {
      await EvaluationService(config).deleteProjectEvaluation(config.evaluationId)
    } catch (err) {
      return Promise.reject(err)
    }
  },
  clearProjectEvaluation: ({ commit }) => {
    commit(types.EVALUATION_SET_PROJECT_EVALUATION, null)
  }
}

const mutations = {
  [types.EVALUATION_SET_PROJECT_EVALUATION]: (state, payload) => {
    state.projectEvaluation = payload
  },
  [types.EVALUATION_SET_EVALTAION_GUIDE_TEMPLATES]: (state, payload) => {
    state.evaluationGuideTemplates = payload
  },
  [types.EVALUATION_SET_NON_DISCLOSURE_TEMPLATES]: (state, payload) => {
    state.nonDisclosureTemplates = payload
  },
  [types.EVALUATION_SET_CONFLICT_OF_INTEREST_TEMPLATES]: (state, payload) => {
    state.conflictOfInterestTemplates = payload
  },
  [types.EVALUATION_SET_SCORE_CARD_TEMPLATES]: (state, payload) => {
    state.scoreCardTemplates = payload
  },
  [types.EVALUATION_SET_EVALUATIONS]: (state, payload) => {
    state.evaluations = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
