import { api, uploadApi } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  customerSsoSaml: {}
}

export const getters = {
  getCustomerSsoSaml: (state) => state.customerSsoSaml
}
export const actions = {
  fetchCustomerSsoSaml: async ({ commit }) => {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/CustomerSsoSaml'
    try {
      const response = await api.get(url)
      if (response.data.count > 0) {
        const data = response.data.data.customerSsoSamlDTO.values[0]
        commit(types.SSO_ACTIVE_SET, data)
      }
    } catch (err) {
      throw err
    }
  },
  updateCustomerSsoSaml: async ({ commit }, model) => {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/CustomerSsoSaml'
    try {
      const response = await uploadApi.put(url, model)
      if (response.data.count > 0) {
        const customerSsoSaml = response.data.data.customerSsoSamlDTO.values[0]
        commit(types.SSO_ACTIVE_SET, customerSsoSaml)
      }
    } catch (err) {
      throw err
    }
  }
}
export const mutations = {
  [types.SSO_ACTIVE_SET](state, customerSsoSaml) {
    state.customerSsoSaml = customerSsoSaml
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
