import { api } from '@/api/api.js'

function EvaluationService(config) {
  const nodeId = config.nodeId
  const projectId = config.projectId
  const evaluationApi = config.api || api

  function validateCredentials() {
    if (!nodeId || !projectId) {
      throw new Error('Node and project ID are required')
    }
    return true
  }

  async function getProjectEvaluation() {
    validateCredentials()

    const response = await evaluationApi.get(
      `/api/nodes/${nodeId}/projects/evaluations/${projectId}`
    )
    // (response.data: projectEvaluation || 204?)
    return response.data
  }

  async function createProjectEvaluation() {
    validateCredentials()

    const response = await evaluationApi.post(
      `/api/nodes/${nodeId}/projects/evaluations/${projectId}`
    )
    return response.data
  }

  async function getAllTemplates(groupName) {
    const response = await evaluationApi.get(
      `/api/v1/customer/{customerId}/node/{nodeId}/OnlineSubmissionTemplate/Search?groupName=${groupName}`
    )
    return response.data
  }

  async function getAllEvaluations() {
    const response = await evaluationApi.get(
      `/api/nodes/${nodeId}/projects/evaluations/${projectId}/evaluations`
    )
    return response.data
  }

  async function postProjectEvaluation(model) {
    const response = await evaluationApi.post(
      `/api/nodes/${nodeId}/projects/evaluations/${projectId}/evaluations`,
      model
    )
    return response.data
  }

  async function putProjectEvaluation(model, evaluationId) {
    const response = await evaluationApi.put(
      `/api/nodes/${nodeId}/projects/evaluations/${projectId}/evaluations/${evaluationId}`,
      model
    )
    return response.data
  }

  async function deleteProjectEvaluation(evaluationId) {
    const response = await evaluationApi.delete(
      `/api/nodes/${nodeId}/projects/evaluations/${projectId}/evaluations/${evaluationId}`
    )
    return response.data
  }

  // ----- CRITERIA -----
  async function postCreateEvaluationCriteria(evaluationId, model) {
    /**
     * @param {
     * evaluationStageId: guid,
     * evaluationSectionId: guid,
     * title: string,
     * minRatingScale: int,
     * maxRatingScale: int,
     * score: decimal,
     * formulaType: CriteriaFormulaType,
     * order: int,
     * evaluators: EvaluationCriteriaEvaluator[]
     * requirements: EvaluationRequirement[]
     * } model
     * @returns EvaluationCriteria
     * */

    const response = await evaluationApi.post(
      `/api/nodes/${nodeId}/projects/evaluations/${projectId}/evaluation/${evaluationId}/criteria`,
      model
    )
    return response.data
  }

  async function putEvaluationCriteria(evaluationId, criteriaId, model) {
    const response = await evaluationApi.put(
      `/api/nodes/${nodeId}/projects/evaluations/${projectId}/evaluation/${evaluationId}/criteria/${criteriaId}`,
      model
    )
    return response.data
  }

  async function getEvaluationCriteria(evaluationId, criteriaId) {
    const response = await evaluationApi.get(
      `/api/nodes/${nodeId}/projects/evaluations/${projectId}/evaluation/${evaluationId}/criteria/${criteriaId}`
    )
    return response.data
  }

  async function deleteEvaluationCriteria(evaluationId, criteriaId) {
    const response = await evaluationApi.delete(
      `/api/nodes/${nodeId}/projects/evaluations/${projectId}/evaluation/${evaluationId}/criteria/${criteriaId}`
    )
    return response.data
  }

  async function reorderEvaluationCriteria(evaluationId, model) {
    /**
     * @param {
     * evaluationCriteriaId: guid,
     * newOrder: int
     * } model
     */
    const response = await evaluationApi.put(
      `/api/nodes/${nodeId}/projects/evaluations/${projectId}/evaluation/${evaluationId}/criteria/reorder`,
      model
    )
    return response.data
  }

  // ---- SECTIONS ----

  async function getEvaluationSection(evaluationId, sectionId) {
    const response = await evaluationApi.get(
      `/api/nodes/${nodeId}/projects/evaluations/${projectId}/evaluation/${evaluationId}/section/${sectionId}`
    )
    return response.data
  }

  async function postCreateEvaluationSection(evaluationId, model) {
    /**
     * @param {
     * evaluationStageId: guid,
     * title: string,
     *  score: decimal,
     * order: int
     * criteria: EvaluationCriteria[]
     *  } model
     * @returns EvaluationSection
     */
    const response = await evaluationApi.post(
      `/api/nodes/${nodeId}/projects/evaluations/${projectId}/evaluation/${evaluationId}/section`,
      model
    )
    return response.data
  }

  async function putEvaluationSection(evaluationId, sectionId, model) {
    const response = await evaluationApi.put(
      `/api/nodes/${nodeId}/projects/evaluations/${projectId}/evaluation/${evaluationId}/section/${sectionId}`,
      model
    )
    return response.data
  }

  async function deleteEvaluationSection(evaluationId, sectionId) {
    const response = await evaluationApi.delete(
      `/api/nodes/${nodeId}/projects/evaluations/${projectId}/evaluation/${evaluationId}/section/${sectionId}`
    )
    return response.data
  }

  async function reorderEvaluationSections(evaluationId, model) {
    /**
     * @param {
     * evaluationSectionId: guid,
     * newOrder: int
     * } model
     */
    const response = await evaluationApi.put(
      `/api/nodes/${nodeId}/projects/evaluations/${projectId}/evaluation/${evaluationId}/section/reorder`,
      model
    )
    return response
  }

  return {
    validateCredentials,
    getProjectEvaluation,
    createProjectEvaluation,
    getAllTemplates,
    postProjectEvaluation,
    getAllEvaluations,
    putProjectEvaluation,
    deleteProjectEvaluation,
    postCreateEvaluationCriteria,
    putEvaluationCriteria,
    getEvaluationCriteria,
    deleteEvaluationCriteria,
    reorderEvaluationCriteria,
    getEvaluationSection,
    postCreateEvaluationSection,
    putEvaluationSection,
    deleteEvaluationSection,
    reorderEvaluationSections
  }
}

export default EvaluationService
