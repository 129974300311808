<template>
  <div class="is-flex is-aligned-center">
    <app-grid-pagination
      :page-size="gridPageSize"
      :current-page="gridCurrentPage"
      :total-pages="gridTotalPages"
      :total-rows="gridTotalRows"
      @clickPrev="$emit('handleClickPrev')"
      @clickNext="$emit('handleClickNext')"
      @pageSizeChange="(size) => $emit('handleGridPageSizeChange', size)"
    >
    </app-grid-pagination>
    <el-dropdown :show-timeout="0" :hide-timeout="0" :hide-on-click="false" trigger="click">
      <app-column-button />
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-for="(column, index) in columnDefs" :key="'visible-column' + index">
          <app-checkbox
            :id="`column-${column.field}-${Math.random() * 1000}`"
            v-model="column.visible"
            :label="column.columnHeading"
            :name="column.field"
            :value="column.field"
            class="has-margin-bottom-tiny"
          >
          </app-checkbox>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <app-button
      v-if="hasExport"
      type="secondary"
      minwidth
      :disabled="exportDisabled"
      @click="$emit('handleExport')"
    >
      {{ $t('ui.common.export') }}
    </app-button>
  </div>
</template>

<script>
export default {
  name: 'app-grid-actions',
  props: {
    gridPageSize: { type: Number },
    gridCurrentPage: { type: Number },
    gridTotalPages: { type: Number },
    gridTotalRows: { type: Number },
    columnDefs: { type: Array },
    hasExport: { type: Boolean, default: false },
    exportDisabled: { type: Boolean, default: false }
  }
}
</script>
