import { Dropdown, Table } from 'element-ui'

export function extendElementUIComponents() {
  const extendedDropdown = {
    extends: Dropdown,
    methods: {
      ...Dropdown.methods,
      async initDomOperation() {
        Dropdown.methods.initDomOperation.call(this)
        await this.$nextTick()
        this.triggerElm.setAttribute('aria-haspopup', 'listbox')
      }
    }
  }

  const extendedTable = {
    extends: Table,
    updated() {
      if (Table.updated) Table.updated.call(this)
      this.$nextTick(() => {
        this.$refs.bodyWrapper
          .querySelectorAll('.el-table-column--selection .el-checkbox__original')
          .forEach((el) => {
            // set the aria label to be the value of nearest .el-tree-node__label innerText or ('Select node')
            const labelText = 'Select Row'
            el.setAttribute('aria-label', labelText)
          })

        this.$refs.tableHeader.$el
          .querySelectorAll('.el-table-column--selection .el-checkbox__original')
          .forEach((el) => {
            // set the aria label to be the value of nearest .el-tree-node__label innerText or ('Select node')
            const labelText = 'Select All'
            el.setAttribute('aria-label', labelText)
          })
      })
    }
  }

  return {
    extendedDropdown,
    extendedTable
  }
}
