import { usePermissionLevels } from '@/composables/permissions/permissionLevels'
import { routes } from '@/security/permissions/permissions-definitions'
import { mapGetters } from 'vuex'

const { getPermissionLevels } = usePermissionLevels()
const permissionLevels = getPermissionLevels()

export default {
  data() {
    return {
      permissionLevels
    }
  },
  computed: {
    ...mapGetters([
      'getRoutePermission',
      'getLoggedInUser',
      'getIsSiteAdmin',
      'getRoutePermissionUser'
    ]),
    routePermissions() {
      return this.getRoutePermission || []
    },
    user() {
      return this.getLoggedInUser
    },
    currentRoute() {
      return this.$route
    },
    hasRoutePermissionToView() {
      // if route store is empty, it means no permissions configured, then give access
      if (!this.getRoutePermissionUser) return true

      return this.getRoutePermissionUser.hasRoutePermissionToView
    },
    hasRoutePermissionToAction() {
      // if route store is empty, it means no permissions configured, then give access
      if (!this.getRoutePermissionUser) return true

      return this.getRoutePermissionUser.hasRoutePermissionToAction
    }
  },
  methods: {
    filterPermissions(config, targetRoute) {
      return config.filter((r) => r.routeName.toLowerCase() === targetRoute.name?.toLowerCase())
    },
    checkRoutePermission(context, targetRoute) {
      if (!context) return
      const user = context.getters.getLoggedInUser
      const routePermissionConfig = context.getters.getRoutePermission
      let routePermissionFiltered = []
      let routePermissionSectionFiltered = []
      let hasSubSectionPermission = false
      let hasSubSectionPermissionToAction = false

      // Site Admin has full access
      if (context.getters.getIsSiteAdmin === true) {
        context.dispatch('setRoutePermissionUser', {
          hasRoutePermissionToView: true,
          hasRoutePermissionToAction: true
        })
        return this.permissionLevels?.ALL || 0
      }

      if (
        !routePermissionConfig ||
        routePermissionConfig === null ||
        // eslint-disable-next-line no-undefined
        routePermissionConfig === undefined ||
        routePermissionConfig === 'undefined' ||
        routePermissionConfig.length === 0
      ) {
        // if route store is empty, it means no permissions configured, then give access
        context.dispatch('setRoutePermissionUser', {
          hasRoutePermissionToView: true,
          hasRoutePermissionToAction: true
        })
        return this.permissionLevels?.ALL || 0
      }

      if (targetRoute.matched.filter((r) => r.name === targetRoute.name)[0]?.parent) {
        routePermissionFiltered = this.filterPermissions(
          routePermissionConfig,
          targetRoute.matched.filter((r) => r.name === targetRoute.name)[0]?.parent
        )

        routePermissionSectionFiltered = this.filterPermissions(routePermissionConfig, targetRoute)
      } else {
        routePermissionFiltered = this.filterPermissions(routePermissionConfig, targetRoute)
      }

      // remove permissions for control, it will be handled by checkRouteControlPermission()
      routePermissionFiltered = routePermissionFiltered.filter(
        // eslint-disable-next-line no-undefined
        (r) => r.controlId === null || r.controlId === undefined || r.controlId === 'undefined'
      )

      // remove permissions for control, it will be handled by checkRouteControlPermission()
      routePermissionSectionFiltered = routePermissionSectionFiltered.filter(
        // eslint-disable-next-line no-undefined
        (r) => r.controlId === null || r.controlId === undefined || r.controlId === 'undefined'
      )

      if (routePermissionFiltered.length === 0 && routePermissionSectionFiltered.length === 0) {
        // if the route has no permissions configured, then give access
        context.dispatch('setRoutePermissionUser', {
          hasRoutePermissionToView: true,
          hasRoutePermissionToAction: true
        })
        return this.permissionLevels?.ALL || 0
      }

      // promote section permissions to parent route permissions if no parent route permissions
      if (routePermissionFiltered.length === 0 && routePermissionSectionFiltered.length !== 0) {
        routePermissionFiltered = routePermissionSectionFiltered
        routePermissionSectionFiltered = []
      }

      // check if the user has any permissions set for the parent route then give View access as a minimum
      if (
        user.proPermissions &&
        user.proPermissions.length !== 0 &&
        routePermissionFiltered[0].permissions.some(
          (r) =>
            user.proPermissions.filter((e) => e.id.toLowerCase() === r.id.toLowerCase()).length > 0
        )
      ) {
        // when a user has any of the permissions set for the parent route then give View access as a minimum
        context.dispatch('setRoutePermissionUser', {
          hasRoutePermissionToView: true,
          hasRoutePermissionToAction: false
        })

        // when user has permission based on parent route, then check if there are permissions set on the sub area/section
        if (routePermissionSectionFiltered.length !== 0) {
          // this block means there are permissions set for sub area/section
          hasSubSectionPermission = true

          // check if the user has any permissions set for the sub area/section then give View access as a minimum
          if (
            user.proPermissions &&
            user.proPermissions.length !== 0 &&
            routePermissionSectionFiltered.some((e) =>
              e.permissions.some(
                (r) =>
                  user.proPermissions.filter((e) => e.id.toLowerCase() === r.id.toLowerCase())
                    .length > 0
              )
            )
          ) {
            // when a user has any of the permissions set for the sub area/serction then give View access as a minimum
            context.dispatch('setRoutePermissionUser', {
              hasRoutePermissionToView: true,
              hasRoutePermissionToAction: false
            })
          } else {
            // when user has no access to sub area/section
            context.dispatch('setRoutePermissionUser', {
              hasRoutePermissionToView: false,
              hasRoutePermissionToAction: false
            })

            return this.permissionLevels?.NONE || 0
          }

          // check for actions permissions, such as ability to Edit, canPerformAction=tue
          if (
            routePermissionSectionFiltered[0].permissions.some(
              (r) =>
                r.canPerformAction &&
                user.proPermissions.filter((e) => e.id.toLowerCase() === r.id.toLowerCase())
                  .length > 0
            )
          ) {
            hasSubSectionPermissionToAction = true

            context.dispatch('setRoutePermissionUser', {
              hasRoutePermissionToView: true,
              hasRoutePermissionToAction: hasSubSectionPermissionToAction
            })
          }
        }

        // parent route check for actions permissions, such as ability to Edit, canPerformAction=tue
        // this also overrides the permission granted on sub area/section, parent permission should take over
        if (
          routePermissionFiltered[0].permissions.some(
            (r) =>
              r.canPerformAction &&
              user.proPermissions.filter((e) => e.id.toLowerCase() === r.id.toLowerCase()).length >
                0
          )
        ) {
          context.dispatch('setRoutePermissionUser', {
            hasRoutePermissionToView: true,
            hasRoutePermissionToAction:
              !hasSubSectionPermission ||
              (hasSubSectionPermission && hasSubSectionPermissionToAction)
          })
        } else {
          // give View access if no edit permission
          context.dispatch('setRoutePermissionUser', {
            hasRoutePermissionToView: true,
            hasRoutePermissionToAction: false
          })
        }

        // check current routePermissionUser and return the correct admin level
        if (context.getters.getRoutePermissionUser.hasRoutePermissionToAction === true) {
          return this.permissionLevels?.ALL || 0
        } else if (context.getters.getRoutePermissionUser.hasRoutePermissionToView === true) {
          return this.permissionLevels?.VIEW || 0
        } else {
          return this.permissionLevels?.NONE || 0
        }
      }

      // end check permissions

      // when it reach this point, it means that there are permissions set for the route and the user does not have it
      context.dispatch('setRoutePermissionUser', {
        hasRoutePermissionToView: false,
        hasRoutePermissionToAction: false
      })

      return this.permissionLevels?.NONE || 0
    },
    checkRouteControlPermission(context, targetRoute, control) {
      const user = context.getters.getLoggedInUser
      let routePermissionConfig = context.getters.getRoutePermission

      // Site Admin has full access
      if (context.getters.getIsSiteAdmin === true) {
        return true
      }

      if (
        !routePermissionConfig ||
        routePermissionConfig === null ||
        // eslint-disable-next-line no-undefined
        routePermissionConfig === undefined ||
        routePermissionConfig === 'undefined' ||
        routePermissionConfig.length === 0
      ) {
        // if route store is empty, it means no permissions configured, then give access
        return true
      }

      if (
        !control ||
        control.id === null ||
        // eslint-disable-next-line no-undefined
        control.id === undefined ||
        control.id === 'undefined'
      ) {
        // if control has no ID, by default give everyone permission
        return true
      }

      routePermissionConfig = this.filterPermissions(routePermissionConfig, targetRoute)

      if (routePermissionConfig.length === 0) {
        // if the route has no permissions configured, then give access
        return true
      }

      if (
        routePermissionConfig.filter(
          // eslint-disable-next-line no-undefined
          (r) => r.controlId !== null && r.controlId !== undefined && r.controlId !== 'undefined'
        ).length === 0
      ) {
        // if control has no permissions configured, then give access
        return true
      } else {
        // get permission set for control
        routePermissionConfig = routePermissionConfig.filter(
          (r) =>
            r.controlId !== null &&
            // eslint-disable-next-line no-undefined
            r.controlId !== undefined &&
            r.controlId !== 'undefined' &&
            r.controlId.toLowerCase() === control.id.toLowerCase()
        )
      }

      if (routePermissionConfig.length === 0) {
        // if no control permissions configured, then give access
        return true
      }

      if (
        user.proPermissions &&
        user.proPermissions.length !== 0 &&
        routePermissionConfig[0].permissions.some(
          (r) =>
            r.canPerformAction &&
            user.proPermissions.filter((e) => e.id.toLowerCase() === r.id.toLowerCase()).length > 0
        )
      ) {
        // if the user has permission over the Control and canPerformAction, canPerformAction=tue
        return true
      }

      // when it reach this point, it means that there are permissions set for the route and the user does not have it
      return false
    },
    checkTemplatePermissions(status, routePermissions, userPermissions, userEmail) {
      if (status === 'template' && routePermissions.length > 0) {
        routePermissions = routePermissions.filter(
          (p) => p.id === routes.PROJECT_TOP_LEVEL_ROUTE.id.toLowerCase()
        )
        userPermissions = userPermissions.filter((up) =>
          routePermissions[0].permissions.some((p) => p.id === up.id)
        )

        if (userPermissions && userPermissions.length > 0) {
          // if they can edit templates they have access. if they only have create, and this isn't their template: they can only view
          if (
            userPermissions.some(
              (up) =>
                up.name === 'titleEditTemplates' ||
                (up.name === 'titleCreateTemplates' && this.project.createdBy === userEmail)
            )
          ) {
            this.$store.dispatch('setRoutePermissionUser', {
              hasRoutePermissionToView: true,
              hasRoutePermissionToAction: true
            })
            return this.permissionLevels?.All || 0
          }
          // if they have any permissions at all, they can view
          this.$store.dispatch('setRoutePermissionUser', {
            hasRoutePermissionToView: true,
            hasRoutePermissionToAction: false
          })

          return this.permissionLevels?.VIEW || 0
        } else {
          // the user doesn't have any permission, so they can't view or edit
          this.$store.dispatch('setRoutePermissionUser', {
            hasRoutePermissionToView: false,
            hasRoutePermissionToAction: false
          })
          return this.permissionLevels?.NONE || 0
        }
      }
    }
  }
}
