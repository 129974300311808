export const permissions = {
  PROJECT_PUBLIC_APPROVER: {
    permissionId: 'DA31A4DB-3A4B-4EDB-BFE2-F8FCE0240A35',
    permissionGroupId: '09F57C52-A96E-428C-AD9F-1AED9D59A0FC'
  },
  PROJECT_PREQUAL_APPROVER: {
    permissionId: 'AFCBA1C1-EA58-46FF-8765-7FAFCB42AF18',
    permissionGroupId: '09F57C52-A96E-428C-AD9F-1AED9D59A0FC'
  },
  PROJECT_INVITATIONAL_APPROVER: {
    permissionId: '9D967882-91CD-494D-9DC6-14D59C0C191C',
    permissionGroupId: '09F57C52-A96E-428C-AD9F-1AED9D59A0FC'
  },
  PROJECT_LIMITED_APPROVER: {
    permissionId: '4D8388DF-6EDB-4F7F-A16D-C5D436752099',
    permissionGroupId: '09F57C52-A96E-428C-AD9F-1AED9D59A0FC'
  },
  PROJECT_PUBLIC_CREATE: {
    permissionId: '9680826B-8133-4190-956C-B78CBE0398B5',
    permissionGroupId: '09F57C52-A96E-428C-AD9F-1AED9D59A0FC'
  },
  PROJECT_PREQUAL_CREATE: {
    permissionId: '49E64021-B51B-4F9F-A2F4-4D69B573C18A',
    permissionGroupId: '09F57C52-A96E-428C-AD9F-1AED9D59A0FC'
  },
  PROJECT_INVITATIONAL_CREATE: {
    permissionId: 'F1545AF2-C1F0-4C78-AF99-CB0FA540CEB3',
    permissionGroupId: '09F57C52-A96E-428C-AD9F-1AED9D59A0FC'
  },
  PROJECT_LIMITED_CREATE: {
    permissionId: 'A64C34DF-B543-4B1F-B26D-B16C2F9A80FF',
    permissionGroupId: '09F57C52-A96E-428C-AD9F-1AED9D59A0FC'
  },
  PROJECT_CONTRACT_BUILDER_EDIT: {
    permissionId: '65B2EDA4-5032-425D-A1B0-517683D2087D',
    permissionGroupId: 'ED4F1D59-BACF-422C-AD84-F18F263681F2'
  },
  PROJECT_INFORMAL_CREATE: {
    permissionId: 'D1D0650A-D913-46AE-B55D-53F5A7C19630',
    permissionGroupId: '09F57C52-A96E-428C-AD9F-1AED9D59A0FC'
  }
}

export const routes = {
  PROJECT_TOP_LEVEL_ROUTE: { id: '3b06c651-5b06-49db-ab93-de79a0299dfd' }
}
