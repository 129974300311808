<script setup>
import { ref, computed, onBeforeMount, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { useStore } from '@/store'
import AppBarItem from '../app-bar/app-bar-item.vue'

const $route = useRoute()
const $router = useRouter()
const $store = useStore()

const navItems = ref([
  {
    name: 'Dashboard',
    target: '/',
    icon: 'bar-chart',
    translateName: 'ui.components.app-mainnav.adminNavDashboardLabel',
    children: null
  },
  {
    name: 'Projects',
    target: '/projects',
    icon: 'edit-3',
    translateName: 'ui.components.app-mainnav.adminNavProjectsLabel',
    children: [
      {
        name: 'Search a project',
        target: '/projects',
        icon: 'search',
        // eslint-disable-next-line no-use-before-define
        clickEvent: handleClickProjectSearch,
        translateName: 'ui.components.app-mainnav.adminNavProjectsOpen',
        subtitle: 'Search for a specific project.'
      },
      {
        name: 'Create a project',
        target: '/projects/new',
        icon: 'file-plus',
        translateName: 'ui.components.app-mainnav.adminNavProjectsNew',
        subtitle: 'Start a new project from scratch.'
      }
    ]
  },
  {
    name: 'Suppliers',
    target: '/suppliers',
    icon: 'user',
    translateName: 'ui.components.app-mainnav.adminNavSuppliersLabel'
  },
  {
    name: 'Reports',
    target: '/reports',
    icon: 'file',
    translateName: 'ui.components.app-mainnav.adminNavReportsLabel',
    children: [
      {
        name: 'ReportsCentre',
        target: '/reports/center',
        icon: 'bar-chart-2',
        translateName: 'ui.components.app-mainnav.adminNavReportsCenter',
        subtitle: 'View Reports Center'
      },
      {
        name: 'ReportsLibrary',
        target: '/reports/library',
        icon: 'bar-chart-2',
        translateName: 'ui.components.app-mainnav.adminNavReportsLibrary',
        subtitle: 'View Reports library'
      }
    ]
  }
])

const hasSupplierDashboard = ref(true)

const hasContractorPerformance = computed(() => {
  return $store.getters.getTenderNode?.contractorPerformance
})

function checkIsCurrentRoute(route) {
  if (route === '/') return $route.path === route
  return $route.path.length > 2 && $route.path.match(route)
}

function handleClickProjectSearch() {
  $router.push('/projects')
}

function configureDropdownNavChildren() {
  if (!hasSupplierDashboard.value || !hasContractorPerformance.value) return
  navItems.value[0].children = [
    {
      name: 'Dashboard',
      target: '/',
      translateName: 'ui.components.app-mainnav.adminNavDashboardLabel'
    },
    {
      name: 'Supplier Dashboard',
      target: '/supplier-dashboard',
      translateName: 'ui.components.app-mainnav.adminNavSupplierDashboardLabel'
    }
  ]
}

onBeforeMount(async () => {
  await nextTick()
  configureDropdownNavChildren()
})
</script>

<template>
  <!-- accessible main nav -->
  <nav class="app-main-nav" aria-label="Main Navigation">
    <ul class="app-main-nav__menu">
      <li
        v-for="item in navItems"
        :key="item.name"
        :class="{ 'is-active': checkIsCurrentRoute(item.target) }"
      >
        <AppBarItem
          v-if="item.children"
          :id="item.name"
          :icon="item.icon"
          component="dropdown"
          class="is-parent"
          :control-text="$t(item.translateName)"
        >
          <ul>
            <li v-for="child in item.children" :key="child.name">
              <AppBarItem
                v-if="child.target"
                component="link"
                :to="child.target"
                class="has-padding has-padding-left-large is-flex is-justified-start"
              >
                <span>{{ $t(child.translateName) }}</span>
              </AppBarItem>
              <a
                v-else
                role="button"
                class="has-padding has-padding-left-large is-flex is-justified-start"
                @click="child.clickEvent"
              >
                {{ $t(child.translateName) }}
              </a>
            </li>
          </ul>
        </AppBarItem>
        <AppBarItem v-else component="link" :to="item.target" class="is-parent">
          <app-icon v-if="item.icon" :icon="item.icon" />
          <span>{{ $t(item.translateName) }}</span>
        </AppBarItem>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
nav {
  height: 100%;
}
ul {
  display: flex;
  height: 100%;
}

li {
  display: flex;
  height: 100%;
  position: relative;

  &.is-active {
    &::after {
      display: block;
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: $white;
    }
  }
}
</style>
