export const projectClassifications = [
  'Goods',
  'Services',
  'Construction',
  'Services related to goods'
]

export const sourceLabels = {
  EMERGENCY: 'Emergency',
  SINGLE_SOURCE: 'Single Source',
  SOLE_SOURCE: 'Sole Source',
  NONE: 'Not Applicable'
}
export const bidTypes = [
  {
    id: 1,
    label: 'Request for proposal (RFP)'
  },
  {
    id: 2,
    label: 'Request for quotation (RFQ)'
  },
  {
    id: 3,
    label: 'Request for information (RFI)'
  },
  {
    id: 4,
    label: 'Tender'
  }
]

export const baseProjectDetailsModel = {
  projectName: '',
  classification: '',
  dateCreatedUtc: null,
  modifiedByUserId: null,
  dateModifiedUtc: null,
  declineReason: null,
  description: '',
  bidType: null,
  applyToProject: null,
  categories: [],
  isSiteVisit: null,
  locations: [],
  estimatedPostingDate: null,
  deliveryConstructionStartDate: null,
  supplierEmails: '',
  documents: [],
  status: 1
}
export const baseContactDetailsModel = {
  firstName: '',
  lastName: '',
  email: '',
  departmentId: null,
  divisionId: null,
  procurementLeadId: null,
  consultant: {
    FirstName: null,
    LastName: null,
    Email: null
  }
}
export const baseProjectValueModel = {
  projectBudget: null,
  estimatedTotalProjectValue: null
}
