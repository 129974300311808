<template>
  <div
    ref="wrapper"
    :class="[
      type === 'person' ? 'has-target-aligned-center' : 'has-target-aligned-' + aligned,
      { 'is-type-person': type === 'person' }
    ]"
    class="app-radio"
  >
    <label
      :aria-label="label ? label : `${value}`"
      :class="{ 'is-disabled': disabled }"
      class="app-radio-label"
      tabindex="0"
      role="radio"
      :aria-checked="shouldBeChecked ? 'true' : 'false'"
      :data-value="value"
      @focus="focus = true"
      @blur="focus = false"
      @keyup.enter="handleChange"
      @keyup.space="handleChange"
    >
      <input
        :id="id"
        ref="radio"
        :value="value"
        :disabled="disabled || !hasPermissionToEdit || !hasControlPermission"
        :checked="shouldBeChecked"
        type="radio"
        class="app-radio-input"
        role="presentation"
        tabindex="-1"
        @change="handleChange"
      />
      <span v-if="hasSlotContent" class="app-radio-content">
        <slot name="content"></slot>
      </span>
      <span v-else class="app-radio-content">{{ label ? label : value }}</span>
      <span class="app-radio-mark"></span>
    </label>
  </div>
</template>
<script>
import InputPermissionMixins from '@/mixins/input-permission-mixins'

export default {
  name: 'app-radio',
  mixins: [InputPermissionMixins],
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  props: {
    name: String,
    id: {
      type: String,
      default: `controlId-${Math.random().toString(36).substr(2, 10)}`
    },
    value: {
      type: [String, Number, Object, Boolean],
      required: true
    },
    type: String,
    aligned: {
      type: String,
      default: 'top'
    },
    // eslint-disable-next-line vue/require-prop-types
    modelValue: {
      default: ''
    },
    label: {
      type: String
    },
    disabled: false
  },
  data() {
    return {}
  },
  computed: {
    shouldBeChecked() {
      return this.modelValue === this.value
    },
    hasSlotContent() {
      return this.$slots.content
    }
  },
  methods: {
    handleChange(event) {
      if (this.disabled) return false
      if (event.type === 'keyup') {
        this.$refs.radio.checked = !this.$refs.radio.checked
      }
      this.$emit('change', this.value)
    }
  }
}
</script>

<style lang="scss" src="./app-radio.scss"></style>
