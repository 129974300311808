import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  term: [],
  terms: []
}

export const getters = {
  getTerm: (state) => state.term,
  getTerms: (state) => state.terms
}

export const actions = {
  fetchTerm: ({ commit }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Term/Search`

    return api.get(url).then((response) => {
      const term = response.data.data.termDTO.values

      commit(types.TERM_SET_ALL, term)
      return term
    })
  },
  fetchTerms: ({ commit }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Term/All`

    return api.get(url).then((response) => {
      if (response.data.count > 0) {
        const terms = response.data.data.termDTO.values
        commit(types.TERMS_SET_ALL, terms)
        return terms
      }
    })
  },
  saveTerm: ({ commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Term`
    return api.post(url, model).then((response) => {
      const term = response.data.data.termDTO.values[0]
      commit(types.TERM_SET_ALL, term)
      return term
    })
  },
  updateTerm: ({ commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Term`
    return api.put(url, model).then((response) => {
      const term = response.data.data.termDTO.values[0]
      commit(types.TERM_SET_ALL, term)
      return new Promise((resolve) => {
        resolve(term)
      })
    })
  },
  deleteTerm: (_, id) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Term?termId=${id}`
    return api.delete(url)
  }
}

export const mutations = {
  [types.TERM_SET_ALL](state, term) {
    state.term = term
  },
  [types.TERMS_SET_ALL](state, terms) {
    state.terms = terms
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
