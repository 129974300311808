import cloneDeep from 'lodash/cloneDeep'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    step: {
      type: [Number, String],
      default: 0
    }
  },
  inject: ['WizardBus', 'totalSteps'],
  computed: {
    ...mapGetters([
      'getNewEmail',
      'getNewEmailProjectUsers',
      'getNewEmailContacts',
      'getNewEmailInvitees'
    ]),
    currentStep() {
      return this.step
    },
    email() {
      return this.getNewEmail
    },
    emailProjectUsers() {
      let emailProjUsers = this.getNewEmailProjectUsers

      if (emailProjUsers && emailProjUsers.length !== 0) {
        emailProjUsers = emailProjUsers.filter((r) => r && r.user && r.user.inActive === false)
      }

      return emailProjUsers
    },
    emailContacts() {
      const registeredCompanies = this.getNewEmailContacts
      const invitees = this.getNewEmailInvitees

      // filter out any invitees already in the registered company list
      const filteredInvitees = invitees.filter(
        (i) => !registeredCompanies.some((rc) => rc.contact.id === i.contact.id)
      )

      return registeredCompanies
        .concat(filteredInvitees)
        .sort((a, b) => a.name.localeCompare(b.name))
    }
  },
  data() {
    return {
      viewString: 'ui.views.email-wizard.email-wizard-view.',
      sendOptions: {
        NOW: 1,
        LATER: 2
      }
    }
  },
  methods: {
    ...mapActions(['updateNewEmail']),
    updateEmail(property, value) {
      const clonedEmail = cloneDeep(this.email)
      clonedEmail[property] = value
      this.updateNewEmail(clonedEmail)
    }
  }
}
