import { api, downloadApi } from '@/api/api'
import util from '@/utils/utility'
import * as types from '../../mutation-types'
import axios from 'axios'

let cancelTokenSource = axios.CancelToken.source()
const state = {
  vendor: null,
  vendorSearch: [],
  vendorSearchTotal: null,
  vendorHistory: [],
  vendorDiversityClassificationType: []
}

export const getters = {
  getVendor: (state) => state.vendor,
  getVendorSearch: (state) => state.vendorSearch,
  getVendorSearchTotal: (state) => state.vendorSearchTotal,
  getVendorHistory: (state) => state.vendorHistory,
  getVendorDiversityClassificationType: (state) => state.vendorDiversityClassificationType
}
export const actions = {
  fetchVendor: ({ commit, rootState }, vendorId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Vendors?vendorId=${vendorId}&filter.fields=Tender`
    return api.get(url).then((response) => {
      const vendor = response.data.data.vendorDTO.values[0]
      commit(types.VENDOR_SET, { vendor, rootState })
      return vendor
    })
  },
  fetchVendorSubmissions: ({ commit }, vendorId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Vendors/SubmissionHistory?vendorId=${vendorId}`

    return api
      .get(url)
      .then((response) => {
        const result = response.data.data.searchResult
          ? response.data.data.searchResult.values
          : null
        commit(types.SUPPLIER_SUBMISSION_SUBMISSION_RESULTS_SET, result)
        return result
      })
      .catch((err) => {
        commit(types.SUPPLIER_SUBMISSION_SUBMISSION_RESULTS_SET, null)
        throw err
      })
  },
  fetchVendorSearch: ({ commit }, params) => {
    let url = '/api/v1/customer/{customerId}/node/{nodeId}/Vendors/Search?'
    let connectAmpersand = false

    if (params.limit) {
      url += `filter.limit=${params.limit}`
      connectAmpersand = true
    }

    if (params.start) {
      url += `${connectAmpersand ? '&' : ''}filter.start=${params.start}`
      connectAmpersand = true
    }

    if (params.dateFrom && params.dateFrom.length > 0) {
      url += `${connectAmpersand ? '&' : ''}filter.dateFrom=${
        params.dateFrom ? new Date(params.dateFrom).toISOString() : ''
      }`
      connectAmpersand = true
    }

    if (params.dateTo && params.dateTo.length > 0) {
      url += `${connectAmpersand ? '&' : ''}filter.dateTo=${
        params.dateTo ? new Date(params.dateTo).toISOString() : ''
      }`
      connectAmpersand = true
    }

    if (params.status && params.status.length > 0) {
      url += `${connectAmpersand ? '&' : ''}filter.status=${params.status}`
      connectAmpersand = true
    }

    if (params.searchText && params.searchText.length > 0) {
      url += `${connectAmpersand ? '&' : ''}filter.searchText=${params.searchText}`
    }
    const config = { cancelToken: cancelTokenSource.token }
    return api
      .get(url, config)
      .then((response) => {
        const result = response.data.data.searchResult
          ? response.data.data.searchResult.values
          : null
        commit(types.VENDOR_SET_SEARCH, result)
        return result
      })
      .catch((err) => {
        commit(types.VENDOR_SET_SEARCH, null)
        throw err
      })
  },

  cancelFetchVendorSearch: ({ commit }) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Request canceled by user')
    }
    cancelTokenSource = axios.CancelToken.source()
    commit(types.VENDOR_SET_SEARCH, null)
  },

  fetchVendorSearchWithPerformanceEval: ({ commit }, params) => {
    let url = '/api/v1/customer/{customerId}/node/{nodeId}/Vendors/SearchWithPerformaceEvaluation?'
    let connectAmpersand = false

    if (params.dateFrom && params.dateFrom.length > 0) {
      url += `${connectAmpersand ? '&' : ''}filter.dateFrom=${
        params.dateFrom ? new Date(params.dateFrom).toISOString() : ''
      }`
      connectAmpersand = true
    }

    if (params.dateTo && params.dateTo.length > 0) {
      url += `${connectAmpersand ? '&' : ''}filter.dateTo=${
        params.dateTo ? new Date(params.dateTo).toISOString() : ''
      }`
      connectAmpersand = true
    }

    if (params.status && params.status.length > 0) {
      url += `${connectAmpersand ? '&' : ''}filter.status=${params.status}`
      connectAmpersand = true
    }
    url += `${connectAmpersand ? '&' : ''}filter.searchText=${params.searchText}`
    return api
      .get(url)
      .then((response) => {
        const result = response.data.data.searchResult
          ? response.data.data.searchResult.values
          : null
        commit(types.VENDOR_SET_SEARCH, result)
        return result
      })
      .catch((err) => {
        commit(types.VENDOR_SET_SEARCH, null)
        throw err
      })
  },
  fetchVendorDiversityClassificationType: ({ commit }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Vendors/DiversityClassificationType/All`

    return api.get(url).then((response) => {
      const result = response?.data?.data?.vendorDiversityClassificationTypeDTO?.values || []

      commit(types.VENDOR_DIVERSITY_CLASSIFICATION_TYPE_SET, result)
      return result
    })
  },
  fetchVendorAdvancedSearch: ({ commit }, model) => {
    let url = '/api/v1/customer/{customerId}/node/{nodeId}/Vendors/AdvancedSearch?'
    let connectAmpersand = false

    if (model.limit) {
      url += `filter.limit=${model.limit}`
      connectAmpersand = true
    }

    if (model.start) {
      url += `${connectAmpersand ? '&' : ''}filter.start=${model.start}`
      connectAmpersand = true
    }

    if (model.sortColumn) {
      url += `${connectAmpersand ? '&' : ''}filter.sortColumn=${model.sortColumn}`
      connectAmpersand = true
    }

    if (model.sortDirection) {
      url += `${connectAmpersand ? '&' : ''}filter.sortDirection=${model.sortDirection}`
      connectAmpersand = true
    }

    if (model.dateFrom && model.dateFrom.length > 0) {
      url += `${connectAmpersand ? '&' : ''}filter.dateFrom=${
        model.dateFrom ? new Date(model.dateFrom).toISOString() : ''
      }`
      connectAmpersand = true
    }

    if (model.dateTo && model.dateTo.length > 0) {
      url += `${connectAmpersand ? '&' : ''}filter.dateTo=${
        model.dateTo ? new Date(model.dateTo).toISOString() : ''
      }`
      connectAmpersand = true
    }

    if (model.status && model.status.length > 0) {
      url += `${connectAmpersand ? '&' : ''}filter.status=${model.status}`
      connectAmpersand = true
    }

    if (model.keywords && model.keywords.length > 0) {
      url += `${connectAmpersand ? '&' : ''}filter.searchText=${model.keywords}`
    }

    return api
      .post(url, model)
      .then((response) => {
        const result = response.data.data.searchResult
          ? response.data.data.searchResult.values
          : null

        commit(types.VENDOR_SET_SEARCH, result)

        if (response.data.count !== 0) {
          commit(types.VENDOR_SET_SEARCH_TOTAL, response.data.data.searchResult.total)
        } else {
          commit(types.VENDOR_SET_SEARCH_TOTAL, null)
        }

        return new Promise(function (resolve) {
          resolve(result)
        })
      })
      .catch((err) => {
        commit(types.VENDOR_SET_SEARCH, null)
        throw err
      })
  },
  exportVendorAdvancedSearch: (_, model) => {
    let url = '/api/v1/customer/{customerId}/node/{nodeId}/Vendors/AdvancedSearchResultExport?'
    let connectAmpersand = false

    if (model.limit) {
      url += `filter.limit=${model.limit}`
      connectAmpersand = true
    }

    if (model.start) {
      url += `${connectAmpersand ? '&' : ''}filter.start=${model.start}`
      connectAmpersand = true
    }

    if (model.sortColumn) {
      url += `${connectAmpersand ? '&' : ''}filter.sortColumn=${model.sortColumn}`
      connectAmpersand = true
    }

    if (model.sortDirection) {
      url += `${connectAmpersand ? '&' : ''}filter.sortDirection=${model.sortDirection}`
      connectAmpersand = true
    }

    if (model.dateFrom && model.dateFrom.length > 0) {
      url += `${connectAmpersand ? '&' : ''}filter.dateFrom=${
        model.dateFrom ? new Date(model.dateFrom).toISOString() : ''
      }`
      connectAmpersand = true
    }

    if (model.dateTo && model.dateTo.length > 0) {
      url += `${connectAmpersand ? '&' : ''}filter.dateTo=${
        model.dateTo ? new Date(model.dateTo).toISOString() : ''
      }`
      connectAmpersand = true
    }

    if (model.status && model.status.length > 0) {
      url += `${connectAmpersand ? '&' : ''}filter.status=${model.status}`
      connectAmpersand = true
    }

    if (model.searchText && model.searchText.length > 0) {
      url += `${connectAmpersand ? '&' : ''}filter.searchText=${model.searchText}`
    }

    return downloadApi.post(url, model).then(util.forceFileDownload)
  },
  updateVendor: (_, vendor) => {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/Vendors/'
    return api.put(url, vendor).then((response) => response.data.data.vendorDTO.values[0])
  },
  resetVendorSearch: ({ commit }) => {
    commit(types.VENDOR_SET_SEARCH, null)
  }
}
export const mutations = {
  [types.VENDOR_SET](state, { vendor, rootState }) {
    // cover classificaiton type
    let vendorDiversityClassificationTypeDescription = null
    if (vendor.vendorDiversityClassificationTypeDescription) {
      vendorDiversityClassificationTypeDescription =
        vendor.vendorDiversityClassificationTypeDescription
    } else if (vendor.vendorDiversityClassificationType) {
      vendorDiversityClassificationTypeDescription =
        vendor.vendorDiversityClassificationType.description
    }

    state.vendor = {
      id: vendor ? vendor.id : util.guid(),
      companyName: vendor ? vendor.companyName : '',
      relevantName: vendor ? vendor.relevantName : '',
      address1: vendor ? vendor.address1 : '',
      address2: vendor ? vendor.address2 : '',
      city: vendor ? vendor.city : '',
      provinceState: vendor ? vendor.provinceState : '',
      country: vendor ? vendor.country : '',
      postalCode: vendor ? vendor.postalCode : '',
      faxAreaCode: vendor ? vendor.faxAreaCode : '',
      faxPrefix: vendor ? vendor.faxPrefix : '',
      faxSuffix: vendor ? vendor.faxSuffix : '',
      primaryContact: vendor ? vendor.primaryContact : null,
      contacts: vendor ? vendor.contacts : null,
      accountStatus: vendor ? vendor.isActive : null,
      website: vendor ? vendor.website : null,
      localVendorAgencies: vendor && vendor.localVendorAgencies ? vendor.localVendorAgencies : [],
      isLocalVendor: vendor.isLocalVendor ? vendor.isLocalVendor : false,
      isEmergencyVendor: vendor ? vendor.emergencyVendor : null,
      subscription: vendor ? vendor.vendorPaySubscription : null,
      hstNumber: vendor ? vendor.hstNumber : null,
      agencyNotifications: vendor ? vendor.agencyNotifications : null,
      categories: vendor ? vendor.categories : null,
      previousCompanyName: vendor ? vendor.previousCompanyName : null,
      customerNode: (rootState.authCredential.authCustomerNode || {}).id || '', // localStorage.getItem('customerNodeId'),
      certifiedDiverseSupplier: vendor ? vendor.certifiedDiverseSupplier : null,
      vendorDiversityClassificationTypeDescription,
      vendorDiversityVerified:
        vendor && vendor.vendorDiversityVerified ? vendor.vendorDiversityVerified : [],
      isvendorDiversityVerified:
        vendor && vendor.isvendorDiversityVerified ? vendor.isvendorDiversityVerified : false,
      isActive: vendor && vendor.isActive ? vendor.isActive : false,
      isDeleted: vendor && vendor.isDeleted ? vendor.isDeleted : false,
      testVendor: vendor && vendor.testVendor ? vendor.testVendor : false,
      belongsToNodeId: vendor && vendor.belongsToNodeId ? vendor.belongsToNodeId : false
    }
  },
  [types.VENDOR_SET_SEARCH](state, vendors) {
    state.vendorSearch = vendors
  },
  [types.VENDOR_SET_SEARCH_TOTAL](state, rowCount) {
    state.vendorSearchTotal = rowCount
  },
  [types.SUPPLIER_SUBMISSION_SUBMISSION_RESULTS_SET](state, history) {
    state.vendorHistory = history
  },
  [types.VENDOR_DIVERSITY_CLASSIFICATION_TYPE_SET](state, vendorDiversityClassificationType) {
    state.vendorDiversityClassificationType = vendorDiversityClassificationType
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
