<template>
  <div class="dashboard-stat">
    <div v-if="hasSlotLeft" class="dashboard-stat__left">
      <slot name="left" />
    </div>
    <div v-if="hasSlotRight" class="dashboard-stat__right">
      <slot name="right" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'dashboard-stat',
  computed: {
    hasSlotLeft() {
      return this.$slots['left']
    },
    hasSlotRight() {
      return this.$slots['right']
    }
  }
}
</script>
<style lang="scss">
.dashboard-stat {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &__left {
    font-size: $size-1;
    color: $black;
    font-weight: bold;
    padding-right: $gap;
    overflow: hidden;
  }
  &__right {
    color: $c-text-light;
  }
}
</style>
