import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  performanceEvaluationTypes: []
}
export const getters = {
  getPerformanceEvaluationTypes: (state) => state.performanceEvaluationTypes
}
export const actions = {
  fetchPerformanceEvaluationTypes: ({ commit, state }, force = false) => {
    // cache these where possible
    if (!force && state.performanceEvaluationTypes.length) {
      return Promise.resolve(state.performanceEvaluationTypes)
    }

    const url = `/api/v1/customer/{customerId}/node/{nodeId}/PerformanceEvaluationType`

    return api.get(url).then((response) => {
      const performanceEvaluationTypes = response.data.data.performanceEvaluationTypeDTO.values
      commit(types.PERFORMANCE_EVALUATION_TYPE_SET_ALL, performanceEvaluationTypes)
      return performanceEvaluationTypes
    })
  }
}
export const mutations = {
  [types.PERFORMANCE_EVALUATION_TYPE_SET_ALL]: (state, templates) => {
    state.performanceEvaluationTypes = templates
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
