import { api } from '@/api/api'
import * as types from '@/store/mutation-types'

const state = {
  permission: []
}

const getters = {
  getPermission: (state) => state.permission
}

const actions = {
  fetchPermission: ({ commit }) => {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/Permissions/All'

    return api.get(url).then(function (response) {
      commit(types.PERMISSION_SET, response?.data?.data?.proPermissionGroupDTO?.values || [])
      return response.data
    })
  }
}

const mutations = {
  [types.PERMISSION_SET](state, permission) {
    state.permission = permission
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
