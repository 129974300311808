<template>
  <span :class="[svgType, 'is-' + size, { 'has-badge': badgeValue }]" class="icon app-icon">
    <svg
      :width="svgSize"
      :height="svgSize"
      fill="none"
      :stroke="color || 'currentColor'"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <use :href="iconPath" />
    </svg>
    <span v-if="badgeValue" class="app-icon-badge">{{ badgeValue }}</span>
  </span>
</template>

<script>
export default {
  name: 'app-icon',
  props: {
    type: String,
    badgeValue: String,
    icon: { type: String, required: true },
    size: { type: String, default: 'base' },
    color: String,
    customSize: String
  },
  computed: {
    iconPath() {
      return `${import.meta.env.BASE_URL}feather-icons/feather-sprite.svg#${this.icon}`
    },
    svgType() {
      return this.type ? `has-text-${this.type}` : ''
    },
    svgSize() {
      if (this.customSize) {
        return `${this.customSize}px`
      }
      switch (this.size) {
        case 'tiny':
          return '16px'
        case 'small':
          return '18px'
        case 'medium':
          return '30px'
        case 'large':
          return '36px'
        default:
          return '24px'
      }
    }
  }
}
</script>

<style lang="scss" src="./app-icon.scss"></style>
