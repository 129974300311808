import { api } from '@/api/api'
import util from '@/utils/utility'
import * as types from '../../mutation-types'

const state = {
  tenderTableRowColumns: [],
  currRowColumn: null
}

export const getters = {
  getTenderTableRowColumns: (state) => state.tenderTableRowColumns,
  getCurrRowColumn: (state) => state.currRowColumn
}
export const actions = {
  fetchTenderTableRowColumns: ({ commit }, tenderTableId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/TenderTableRowColumns/${tenderTableId}?filter.fields=TenderTableRow,TenderTableColumn`
    return api.get(url).then((response) => {
      commit(types.TENDERTABLEROWCOLUMN_SET_FROMDTO, {
        tenderTableRowColumns: response.data.data.tenderTableRowColumnDTO.values,
        tenderTableId
      })
      return response
    })
  },
  createTenderTableRowColumn: ({ commit }, props) => {
    commit(types.TENDERTABLEROWCOLUMN_CREATE, props)
  },
  setTenderTableRowColumns: ({ commit }, props) => {
    commit(types.TENDERTABLEROWCOLUMN_SET_FROMDTO, props)
  },
  setTenderTableRowColumn: ({ commit }, props) => {
    commit(types.TENDERTABLEROWCOLUMN_SET, props)
  },
  deleteTenderTableRowColumn: ({ commit }, props) => {
    commit(types.TENDERTABLEROWCOLUMN_DELETE, props)
  },
  clearTenderTableRowColumns: ({ commit }) => {
    commit(types.TENDERTABLEROWCOLUMN_CLEAR)
  }
}
export const mutations = {
  [types.TENDERTABLEROWCOLUMN_SET_FROMDTO](state, vals) {
    state.tenderTableRowColumns = vals.tenderTableRowColumns.map(function (ttrc) {
      return {
        id: ttrc.id,
        tenderTableId: vals.tenderTableId,
        tenderTableRow: {
          id: ttrc.tenderTableRow.id
        },
        tenderTableColumn: {
          id: ttrc.tenderTableColumn.id
        },
        value: ttrc.value,
        deleted: ttrc.deleted
      }
    })
  },
  [types.TENDERTABLEROWCOLUMN_CREATE](state, props) {
    state.currRowColumn = {
      id: util.guid(),
      tenderTableId: props.tenderTableId,
      tenderTableRow: {
        id: props.tenderTableRowId
      },
      tenderTableColumn: {
        id: props.tenderTableColumnId
      },
      value: null,
      deleted: false
    }
    state.tenderTableRowColumns.push(state.currRowColumn)
  },
  [types.TENDERTABLEROWCOLUMN_SET](state, props) {
    state.currRowColumn = state.tenderTableRowColumns.find(
      (ttrc) => ttrc.id === props.tenderTableRowColumnId
    )

    if (!state.currRowColumn) return false

    state.currRowColumn.value = props.newValue
  },
  [types.TENDERTABLEROWCOLUMN_DELETE](state, props) {
    state.tenderTableRowColumns.forEach((ttrc) => {
      if (
        (props.tenderTableRowId != null && props.tenderTableRowId === ttrc.tenderTableRow.id) ||
        (props.tenderTableColumnId != null &&
          props.tenderTableColumnId === ttrc.tenderTableColumn.id)
      ) {
        ttrc.deleted = true
      }
    })
  },
  [types.TENDERTABLEROWCOLUMN_CLEAR](state) {
    state.tenderTableRowColumns = []
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
