import emailUtil from '@/utils/email'
import uiHandler from '@/utils/ui'
import emailWizardMixins from '@/views/email-wizard/email-wizard-mixins.js'
import { mapActions, mapGetters } from 'vuex'
import { columnDefs, tokens } from './tokens.js'

export default {
  name: 'email-wizard-step-compose',
  mixins: [emailWizardMixins],
  watch: {
    selectedTemplate(val) {
      if (val && val !== this.selectedTemplate.id) {
        const newTemplate = this.getEmailTemplates.find((et) => et.id === val)

        this.senderList = newTemplate.sender
        this.ccList = newTemplate.cc
        this.bccList = newTemplate.bcc
        if (newTemplate.groupName === 'RejectDocument') {
          const doc = this.getEmailDocument
          this.subject = `${doc.category.name} - ${newTemplate.subject}: ${doc.displayName}`
        } else {
          this.subject = newTemplate.subject
        }
        this.content = newTemplate.body
        this.editorKey++
      }
    }
  },
  computed: {
    ...mapGetters([
      'getEmailTemplates',
      'getNewEmail',
      'getEmailTenderId',
      'getEmailContractId',
      'getEmailDocument'
    ]),
    templateList() {
      let templates = this.getEmailTemplates.filter((et) => et.active)
      templates = templates.sort((a, b) => {
        return a.name != null && a.name.localeCompare(b.name)
      })

      return templates
    },
    senderList: {
      get() {
        return this.replyTo
      },
      set(val) {
        this.replyTo = val
        this.updateEmail('sender', val)
      }
    },
    ccList: {
      get() {
        return this.ccEmail
      },
      set(val) {
        this.ccEmail = val

        const list = (val && val.replace(/\s+/g, ' ').trim().split(/[;, ]/)) || ''
        this.updateEmail('cc', list)
      }
    },
    bccList: {
      get() {
        return this.bccEmail
      },
      set(val) {
        this.bccEmail = val

        const list = (val && val.replace(/\s+/g, ' ').trim().split(/[;, ]/)) || ''
        this.updateEmail('bcc', list)
      }
    },
    subject: {
      get() {
        return this.email.subject
      },
      set(val) {
        this.updateEmail('subject', val !== null && val ? val : '')
      }
    },
    content: {
      get() {
        return this.email.body
      },
      set(val) {
        this.updateEmail('body', val !== null && val ? val : '')
      }
    },
    parsedFileList() {},
    senderListValid() {
      return this.testEmail(this.senderList, false, true)
    },
    senderListFormatValid() {
      return this.testEmail(this.senderList, false, true)
    },
    subjectValid() {
      return this.isNotNullOrEmpty(this.subject)
    },
    contentValid() {
      return this.isNotNullOrEmpty(this.content)
    },
    emailIsValid() {
      const valid =
        this.senderListValid &&
        this.senderListFormatValid &&
        this.subjectValid &&
        this.contentValid &&
        this.bccValid &&
        this.ccValid

      return valid
    },
    bccValid() {
      return this.testEmail(this.bccList, true, false)
    },
    ccValid() {
      return this.testEmail(this.ccList, true, false)
    }
  },
  data() {
    return {
      templates: [],
      fileList: [],
      selectedTemplate: null,
      fileUploaded: false,
      showTokens: false,
      tokens,
      columnDefs,
      editorKey: 0,
      ccEmail: '',
      bccEmail: '',
      replyTo: ''
    }
  },
  mounted() {},
  methods: {
    ...mapActions(['updateNewEmail', 'uploadEmailAttachment', 'deleteDocument']),
    handleClickPreview() {
      // if there's a file selected but not uploaded notify
      if (this.fileList && this.fileList.length > 0 && !this.fileUploaded) {
        this.$notify({
          message: this.$t(`${this.viewString}warnFileNotUploaded`),
          type: 'warning'
        })
        return
      }

      // button is disabled, but in case they manage this anyway
      if (!this.emailIsValid) return

      this.WizardBus.$emit('next')
    },
    handleClickBack() {
      this.WizardBus.$emit('previous')
    },
    cleanEmailList(item) {
      return item.replace(',,', ',')
    },
    handleClickViewTokens() {
      this.showTokens = true
    },
    handleCloseTokens() {
      this.showTokens = false
    },
    handleExceed() {},
    handleFileUpload(files) {
      if (files && files.length > 0) {
        // if we're replacing an existing file delete the previous file
        if (this.email.attachments && this.email.attachments.length > 0) {
          this.deleteDocument(this.email.attachments[0].id)
          this.updateEmail('attachments', [])
        }

        if (files[0].size > 3145728) {
          this.$notify.error({
            message: this.$t('ui.errors.global.emailAttachFailTooLarge')
          })
          return
        }

        const formData = new FormData()
        formData.append('file', files[0])
        formData.append('DisplayName', files[0].name)

        if (this.getEmailTenderId !== null && this.getEmailTenderId.length > 0) {
          formData.append('ReferenceId', this.getEmailTenderId)
        } else if (this.getEmailContractId !== null && this.getEmailContractId.length > 0) {
          formData.append('ReferenceId', this.getEmailContractId)
        }
        uiHandler.showLoading(this)
        this.uploadEmailAttachment(formData)
          .then((response) => {
            this.fileUploaded = true
            uiHandler.hideLoading(this)
            this.updateEmail('attachments', [response])
            this.$notify({
              message: this.$t(`${this.viewString}notifAttachSuccessful`),
              type: 'success'
            })
          })
          .catch(() => {
            this.$refs.emailUpload.clear()
            this.updateEmail('attachments', [])
            uiHandler.hideLoading(this)
            this.$notify.error({
              message: this.$t(`${this.viewString}notifAttachFail`)
            })
          })
      }
    },
    handleFileUploadClear() {
      this.fileUploaded = false
      this.fileList = []
      if (this.email.attachments && this.email.attachments.length > 0) {
        this.deleteDocument(this.email.attachments[0].id)
        this.updateEmail('attachments', [])
      }
    },
    isNotNullOrEmpty(prop) {
      if (Array.isArray(prop)) return prop !== null && prop.length > 0
      return prop !== null && prop !== '' && prop !== '<p>&nbsp;</p>'
    },
    isNullOrEmpty(val) {
      return val === '' || val === null
    },
    testEmail(val, multi = true, mand = false) {
      return emailUtil.validEmail(val, { asMulti: multi, mandatory: mand })
    }
  }
}
