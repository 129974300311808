<template>
  <div v-bind="$attrs" :style="getStyle()" class="app-widget">
    <div v-if="hasHeader" class="app-widget__header is-flex">
      <app-icon v-if="icon" :icon="icon" size="small" class="has-margin-right-tiny" />
      <slot name="header">
        <app-title size="3" heading="3">
          {{ title }}
        </app-title>
      </slot>
      <div class="app-widget__header-right">
        <slot name="header-right" />
      </div>
    </div>
    <div class="app-widget__body">
      <slot />
    </div>
    <div v-if="hasFooter" class="app-widget__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-widget',
  props: {
    title: String,
    icon: String,
    height: [Number, String]
  },
  computed: {
    hasHeader() {
      return this.$slots.header || this.hasTitle
    },
    hasFooter() {
      return this.$slots.footer
    },
    hasTitle() {
      return !!this.title
    },
    getHeight() {
      if (!this.height) return false
      return typeof this.height === 'number' ? `${this.height}px` : this.height
    }
  },
  methods: {
    getStyle() {
      const style = {}
      if (this.getHeight) style.height = this.getHeight

      return style
    }
  }
}
</script>

<style lang="scss">
.app-widget {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: $gap-small;
  border: 1px solid $c-border;
  margin: $gap-tiny $gap-small;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  &__header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: $gap-tiny;
    padding-bottom: $gap-small;
    border-bottom: 1px solid $c-border;
    width: 100%;

    &-right {
      margin-left: auto;
    }

    .app-title {
      margin-bottom: 0;
    }
  }

  &__body {
    display: flex;
    flex-flow: column;
  }

  &__footer {
    display: flex;
    height: 40px;
    margin-top: auto;
  }
}
</style>
