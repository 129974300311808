import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  contractBondDocumentTypes: [],
  contractBondDocumentType: null
}

export const getters = {
  getContractBondDocumentTypes: (state) => state.contractBondDocumentTypes,
  getContractBondDocumentType: (state) => (id) => {
    return state.contractBondDocumentTypes.find((c) => c.id === id)
  },
  getContractBondDocumentTypeNew: (state) => state.contractBondDocumentType
}
export const actions = {
  fetchContractBondDocumentTypes: ({ commit }) => {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/ContractBondsDocumentTypes'
    return api.get(url).then((response) => {
      const contractBondDocumentTypes =
        response.data?.data?.contractBondsDocumentTypeDTO?.values || []
      commit(types.CONTRACT_BOND_DOCUMENT_TYPES_SET_ALL, contractBondDocumentTypes)
      return contractBondDocumentTypes
    })
  },
  saveContractBondDocumentType: ({ commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ContractBondsDocumentTypes`
    return api.post(url, model).then((response) => {
      const contractBondDocumentType = response.data.data.contractBondsDocumentTypeDTO.values[0]
      commit(types.CONTRACT_BOND_DOCUMENT_TYPE_SET, contractBondDocumentType)
      return contractBondDocumentType
    })
  },
  updateContractBondDocumentType: ({ commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ContractBondsDocumentTypes`
    return api.put(url, model).then((response) => {
      const contractBondDocumentType = response.data.data.contractBondsDocumentTypeDTO.values[0]
      commit(types.CONTRACT_BOND_DOCUMENT_TYPE_SET, contractBondDocumentType)
      return contractBondDocumentType
    })
  },
  deleteContractBondDocumentType: (_, id) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ContractBondsDocumentTypes?contractBondsDocumentTypeId=${id}`
    return api.delete(url)
  }
}
export const mutations = {
  [types.CONTRACT_BOND_DOCUMENT_TYPES_SET_ALL](state, contractBondDocumentTypes) {
    state.contractBondDocumentTypes = contractBondDocumentTypes
  },
  [types.CONTRACT_BOND_DOCUMENT_TYPE_SET](state, contractBondDocumentType) {
    state.contractBondDocumentType = contractBondDocumentType
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
