<template>
  <div
    ref="wrapper"
    :class="{
      'is-textarea': type === 'textarea',
      'has-label': hasInsetLabel,
      'is-active': hasInsetLabel && active,
      'is-date': type === 'date'
    }"
    class="app-input"
  >
    <slot name="prepend"></slot>

    <label v-if="label" :class="[labelSize ? 'is-size' + labelSize : '']" :for="id" class="label">
      <span
        >{{ label }}
        <span v-if="required && hasPermissionToEdit" class="is-required has-text-danger"
          >*</span
        ></span
      >
      <span v-if="hasLabelAppend" class="label-append">
        <slot name="label-append" />
      </span>
      <app-permission-tooltip
        v-if="(!hasPermissionToEdit || !hasControlPermission) && inputDisabled"
        class="permission-icon has-margin-left-tiny"
      />
    </label>
    <div v-if="hasSlotInput" class="control">
      <slot name="input"></slot>
    </div>

    <div
      v-if="!hasSlotInput"
      :class="{
        'has-icons-left': iconLeft,
        'has-icons-right': iconRight || clearable,
        'is-disabled': disabled || !hasPermissionToEdit || !hasControlPermission,
        'has-no-margin-bottom': !marginBottom
      }"
      class="app-input__wrapper control"
      @mouseenter="hovering = true"
      @mouseleave="hovering = false"
    >
      <app-icon icon="alert-circle" class="app-input-error-icon"></app-icon>
      <div
        v-if="isCurrency"
        :class="{ 'is-visible': hasInsetLabel && active }"
        class="currency-sign"
      >
        $
      </div>
      <div
        v-if="isCurrency && !hasInsetLabel"
        :class="{ 'is-visible': active }"
        class="currency-sign currency-sign-no-inset-label"
      >
        $
      </div>
      <input
        v-if="type !== 'textarea' && type !== 'date' && type !== 'time'"
        :id="id"
        ref="input"
        :type="getType"
        :step="type === 'number' && getStepValue"
        :class="setInputClasses"
        :name="name"
        :value="currentValue"
        :placeholder="label ? '' : placeholder"
        :maxlength="maxlength"
        :minlength="minlength"
        :disabled="inputDisabled"
        :aria-label="ariaLabel"
        :autocomplete="autoComplete"
        v-bind="$attrs"
        :max="max"
        :min="min"
        :readonly="readonly"
        class="input"
        @input="handleInput"
        @keypress="handleKeypress"
        @keyup.enter="handleKeyup"
        @focus="handleFocus"
        @blur="handleBlur"
        @change="handleChange"
      />

      <span v-if="iconLeft && type !== 'textarea'" :class="'is-' + size" class="icon is-left">
        <app-icon :icon="iconLeft" :size="setIconSize()"></app-icon>
      </span>

      <span
        v-if="type === 'text' && clearable && currentValue !== '' && (hovering || focused)"
        :class="'is-' + size"
        class="icon is-right is-small app-input-clear-btn"
        aria-label="Clear Input"
        tabindex="0"
        role="button"
        @click="clear"
        @focus="focused = true"
        @keyup.enter="clear"
      >
        <app-icon icon="x-circle" :size="setIconSize()"></app-icon>
      </span>

      <span
        v-else-if="iconRight && type !== 'textarea'"
        :class="'is-' + size"
        class="icon is-right"
      >
        <app-icon :icon="iconRight" :size="setIconSize()"></app-icon>
      </span>

      <span v-if="hasIconSlotRight && type !== 'textarea'" class="icon-slot-right is-right">
        <slot name="iconSlotRight" />
      </span>
      <span v-if="type === 'textarea'" class="label-mask" />
      <textarea
        v-if="type === 'textarea'"
        :id="id"
        ref="textarea"
        :type="type"
        :class="setInputClasses"
        :name="name"
        :value="currentValue"
        :placeholder="label ? '' : placeholder"
        :rows="rows"
        :maxlength="maxlength"
        :minlength="minlength"
        :disabled="disabled || !hasPermissionToEdit || !hasControlPermission"
        :aria-label="ariaLabel"
        :autocomplete="autoComplete"
        :max="max"
        :min="min"
        :readonly="readonly"
        :step="step"
        class="textarea"
        @input="handleInput"
        @keyup.enter="handleKeyup"
        @focus="handleFocus"
        @blur="handleBlur"
        @change="handleChange"
      >
      </textarea>
      <el-date-picker
        v-if="type === 'date' && !time"
        :id="id"
        ref="datepicker"
        :class="setInputClasses"
        :name="name"
        :picker-options="pickerOptions"
        :value="currentValue"
        :placeholder="label ? '' : placeholder"
        :disabled="disabled || !hasPermissionToEdit || !hasControlPermission"
        :aria-label="ariaLabel"
        :autocomplete="autoComplete"
        :readonly="readonly"
        :step="step"
        v-bind="$attrs"
        v-on="$listeners"
        @keyup.enter="handleKeyup"
        @focus="handleFocus"
        @blur="handleBlur"
      />

      <el-date-picker
        v-if="type === 'date' && time"
        :id="id"
        ref="datepicker"
        :class="setInputClasses"
        :name="name"
        :value="currentValue"
        :placeholder="label ? '' : placeholder"
        :disabled="disabled || !hasPermissionToEdit || !hasControlPermission"
        :aria-label="ariaLabel"
        :autocomplete="autoComplete"
        :readonly="readonly"
        :picker-options="pickerOptions"
        :step="step"
        align="center"
        v-bind="$attrs"
        type="datetime"
        v-on="$listeners"
        @keyup.enter="handleKeyup"
        @focus="handleFocus"
        @blur="handleBlur"
      />

      <el-time-select
        v-if="type === 'time'"
        :id="id"
        ref="timeselect"
        :class="setInputClasses"
        :name="name"
        :value="currentValue"
        :placeholder="label ? '' : placeholder"
        :disabled="disabled || !hasPermissionToEdit || !hasControlPermission"
        :aria-label="ariaLabel"
        align="center"
        :autocomplete="autoComplete"
        :readonly="readonly"
        :step="step"
        v-bind="$attrs"
        type="datetime"
        v-on="$listeners"
        @keyup.enter="handleKeyup"
        @focus="handleFocus"
        @blur="handleBlur"
      />
    </div>

    <div
      v-if="$slots['append'] && isDirty"
      :class="{ 'is-visible': hasAppend && isDirty }"
      class="app-input__append"
      @click="handleClickAppend"
    >
      <slot ref="append" name="append"></slot>
    </div>
  </div>
</template>

<script>
import InputPermissionMixins from '@/mixins/input-permission-mixins'

export default {
  name: 'app-input',
  mixins: [InputPermissionMixins],
  props: {
    validate: [String, Number, Array, Object, Boolean],
    time: Boolean,
    type: {
      type: String,
      default: 'text'
    },
    field: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    },
    isCurrency: Boolean,
    allowNegativeCurrency: Boolean,
    isAlphaNumeric: Boolean,
    label: String,
    labelSize: Number,
    iconLeft: String,
    iconRight: String,
    marginBottom: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: false
    },
    value: [String, Number, Date],
    size: String,
    staticInput: {
      type: Boolean,
      default: false
    },
    decimals: [Number, String],
    for: String,
    placeholder: String,
    maxlength: Number,
    minlength: Number,
    disabled: {
      type: Boolean,
      default: false
    },
    name: String,
    id: String,
    ariaLabel: String,
    rows: {
      type: Number,
      default: 2
    },
    autoComplete: {
      type: String,
      default: 'off'
    },
    // eslint-disable-next-line vue/require-prop-types
    max: {},
    // eslint-disable-next-line vue/require-prop-types
    min: {},
    step: [Number, String],
    readonly: {
      type: Boolean,
      default: false
    },
    pastDateDisabled: { type: Boolean, default: false },
    overwriteDisabled: { type: Boolean, default: false }
  },
  data() {
    return {
      currentValue: this.value,
      hovering: false,
      focused: false,
      childId: null,
      childIdIndex: 0,
      valChanged: false,
      isDirty: false,
      focusTimeout: null
    }
  },
  computed: {
    inputDisabled() {
      if (this.overwriteDisabled) {
        return false
      } else {
        return this.disabled || !this.hasPermissionToEdit || !this.hasControlPermission
      }
    },
    hasSlotInput() {
      return this.$slots.input
    },
    hasInsetLabel() {
      return this.label
    },
    hasFocus() {
      return this.focused || !!this.currentValue || this.currentValue !== ''
    },
    active() {
      return this.focused || (this.value !== '' && this.value !== null)
    },
    setInputClasses() {
      const sizeClass = this.size ? `is-${this.size}` : ''
      const staticClass = this.staticInput ? 'is-static' : ''
      const currencyClass = this.isCurrency ? 'is-currency' : ''
      return `${sizeClass} ${staticClass} ${currencyClass}`
    },
    AppendText() {
      return this.hasAppend && this.hasAppend.innerHTML
    },
    hasAppend() {
      return !!this.$slots.append
    },
    hasLabelAppend() {
      return this.$slots['label-append']
    },
    hasIconSlotRight() {
      return this.$slots.iconSlotRight
    },
    getStepValue() {
      if (this.isCurrency) return 2
      if (this.step) return this.step
      if (!this.decimals) return 1
      const d = parseInt(this.decimals)
      return Math.pow(10, -d)
    },
    getDecimalPlaces() {
      if (this.decimals) return parseInt(this.decimals)
      if (this.step) {
        if (this.step % 1 !== 0) return this.step.toString().split('.')[1].length
        return 0
      }
      return 0
    },
    getType() {
      if (this.isCurrency) return 'text'
      return this.type
    },
    pickerOptions() {
      if (!this.pastDateDisabled) return {}
      return {
        disabledDate(time) {
          const date = new Date()
          return time.getTime() < date.setDate(date.getDate() - 1)
        }
      }
    }
  },
  watch: {
    value(val) {
      this.valChanged = true
      this.setCurrentValue(val)
      if (!this.hasFocus) this.blinkFocus()
    },
    currentValue(val) {
      if (val !== this.value && (this.value === null || this.value === '')) this.$emit('input', val)
      if (!this.hasFocus) this.blinkFocus()
    }
  },
  mounted() {
    this.setLabel()
    this.handleBlur()
    if (this.isCurrency) {
      this.parseCurrency()
    }
  },
  beforeDestroy() {
    this.focusTimeout = null
  },
  methods: {
    setIconSize() {
      return this.size === 'large' ? 'base' : 'small'
    },
    focus() {
      const el = this.$refs.input || this.$refs.textarea
      el?.focus()
    },
    checkNumber(val) {
      return this.checkMinAndMax(this.stripZeros(val))
    },
    stripZeros(val) {
      if (isNaN(val) || val === null || val === '') return null
      if (!this.isDecimal) return parseInt(`${val}`)
      return val
    },
    checkMinAndMax(val) {
      if (this.max && val && val > this.max) return this.max
      if (this.min && val && val < this.min) return this.min
      return val
    },
    handleInput(event) {
      let { value } = event.target
      if (this.type === 'number') value = this.checkNumber(value)
      this.$emit('input', value)
      this.setCurrentValue(value)
    },
    handleKeyup(event) {
      let { value } = event.target
      if (this.type === 'number') value = this.checkNumber(value)
      this.$emit('keyup_enter', value)
      this.setCurrentValue(value)
    },
    handleChange(event) {
      this.$emit('change', event.target.value)
    },
    handleKeypress(evt) {
      const { code } = evt
      const currentLength = evt.target.value !== null ? evt.target.value.toString().length : 0
      const charPressed = String.fromCharCode(evt.keyCode)
      const isDash = charPressed === '-'
      const isDot = charPressed === '.'

      if (this.type === 'number') {
        if (this.min >= 0 && isDash) {
          evt.preventDefault()
        }

        if (isDot && this.decimals === 0) {
          evt.preventDefault()
        }

        if (currentLength === 0 && charPressed === '0' && this.min > 0) {
          evt.preventDefault()
        }
      } else if (this.isCurrency) {
        if (!this.allowNegativeCurrency) {
          if (
            !code.toLowerCase().match('digit') &&
            !code.toLowerCase().match('numpad') &&
            !code.toLowerCase().match('period')
          ) {
            evt.preventDefault()
          }
        } else {
          if (
            !isDash &&
            !code.toLowerCase().match('digit') &&
            !code.toLowerCase().match('numpad') &&
            !code.toLowerCase().match('period')
          ) {
            evt.preventDefault()
          } else if (isDash && evt.target.selectionStart > 0) {
            // only allow dash on first character
            evt.preventDefault()
          }
        }

        const amountString = evt.target.value === null ? null : evt.target.value.toString()
        const keyCode = evt.keyCode

        // only allow number and one dot
        if (
          !(this.allowNegativeCurrency && isDash) &&
          (keyCode < 48 || keyCode > 57) &&
          (keyCode !== 46 || amountString.indexOf('.') !== -1)
        ) {
          // 46 is dot
          evt.preventDefault()
        }

        /** below code is making it so that you can't add any numbers if the amount already has
         * 2 decimal places.
         */
        // restrict to 2 decimal places
        // if (amountString != null && amountString.indexOf(".") > -1 && amountString.split(".")[1].length > 1) {
        //   evt.preventDefault();
        // }

        const limit = 999999999999.99
        if (amountString > limit) {
          evt.preventDefault()
        }
      } else if (this.isAlphaNumeric) {
        const charCode = evt.keyCode || evt.which
        const charStr = String.fromCharCode(charCode)

        const isValid = charStr.match(/^[a-z0-9]+$/i)
        if (!isValid) {
          evt.preventDefault()
        }
      }

      this.checkMinAndMax(event)
    },
    handleClickAppend() {
      this.handleFocus()
    },
    setCurrentValue(value) {
      if (value === this.currentValue) return
      this.currentValue = value
    },
    manuallySetDirty(val = true) {
      this.isDirty = val
    },
    handleFocus(event) {
      this.focused = true

      if (this.isCurrency) {
        this.unparseCurrency()
      }
      this.$emit('focus', event)
    },
    handleBlur(event) {
      if (!this.isDirty && this.valChanged) this.isDirty = true
      if (this.type === 'number' && this.getDecimalPlaces) {
        this.setCurrentValue(this.clampDecimal(this.value))
      }
      this.focused = false

      if (this.isCurrency) {
        this.parseCurrency()
      }
      // click events don't pass through so we need a delay
      window.setTimeout(() => {
        this.$emit('blur', event)
      }, 300)
    },
    setLabel() {
      if (this.type === 'select') {
        const input = this.$el.querySelectorAll('input')[0]
        if (!input.hasAttribute('aria-label')) {
          const ariaLabel = this.ariaLabel || `Input ${this.id}`

          input.setAttribute('aria-label', ariaLabel)
        }
      }
    },
    clear() {
      this.$emit('input', '')
      this.$emit('change', '')
      this.setCurrentValue('')
      this.$emit('cleared')
      this.focus()
    },
    clampDecimal(val) {
      return parseFloat(val).toFixed(this.getDecimalPlaces)
    },
    parseCurrency() {
      if (!this.value) return false
      if (this.value - Math.floor(this.value) !== 0) {
        this.val = parseFloat(this.value.toString().replace(/,/gi, '')).toFixed(2).toString()
      } else {
        this.val = this.value
      }
      const [before, after] = this.val ? this.val.toString().split('.') : [0, 0]
      let tail = after ? after.replace(/\D/, '').substring(0, 2) : '00'
      if (tail.length < 2) tail += '0'
      let result = ''
      if (this.allowNegativeCurrency) {
        result = before.toString().replace(/(?!-)[^0-9.]/g, '')
      } else {
        result = before.toString().replace(/\D/g, '')
      }

      result = result.substring(0, 13).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      const rebuilt = tail ? `${result}.${tail}` : result

      this.$nextTick(() => {
        this.setCurrentValue(rebuilt)
        this.$emit('input', rebuilt)
      })
    },
    unparseCurrency() {
      if (!this.currentValue) return false
      const [before, after] = this.currentValue ? this.currentValue.toString().split('.') : [0, 0]
      const isNegative = before.match(/-/)
      const tail = after ? after.replace(/\D/, '').substring(0, 2) : ''
      let result = before ? before.replace(/\D/g, '') : ''

      if (isNegative) result = `-${result}`

      const rebuilt = tail ? `${result}.${tail}` : result
      this.$nextTick(() => this.setCurrentValue(rebuilt))
    },
    blinkFocus() {
      this.handleFocus()
      this.focusTimeout = setTimeout(() => {
        this.handleBlur()
      }, 30)
    }
  }
}
</script>

<style lang="scss" src="./app-input.scss"></style>
