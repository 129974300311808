import { api } from '@/api/api'
import * as types from '../../mutation-types'

const state = {
  performanceEvaluationDocuments: []
}

const getters = {
  getPerformanceEvaluationDocuments: (state) => {
    return state.performanceEvaluationDocuments
  },
  getPerformanceEvaluationDocument: (state) => (id) => {
    return state.performanceEvaluationDocuments.find((i) => i.id === id)
  }
}

const actions = {
  fetchPerformanceEvaluationDocuments({ commit }, projectId) {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ProjectDocuments/project/${projectId}`
    return api.get(url).then((response) => {
      const documents = response?.data?.data?.projectDocumentDTO?.values || []
      commit(types.PERFORMANCE_EVALUATION_DOCUMENTS_SET, documents)
      return documents
    })
  },
  updatePerformanceEvaluationDocument: ({ commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ProjectDocuments/`
    return api.put(url, model.model).then((response) => {
      let projectDocument = {}
      if (response.data.count > 0) {
        projectDocument = response.data.data.projectDocumentDTO.values[0]
        commit(types.PERFORMANCE_EVALUATION_DOCUMENT_UPDATE, projectDocument)
      }
      return projectDocument
    })
  }
}

const mutations = {
  [types.PERFORMANCE_EVALUATION_DOCUMENTS_SET](state, documents) {
    state.performanceEvaluationDocuments = documents
  },
  [types.PERFORMANCE_EVALUATION_DOCUMENT_UPDATE](state, performanceDoc) {
    const matchedIndex = state.performanceEvaluationDocuments.findIndex(
      (c) => c.id === performanceDoc.id
    )
    if (matchedIndex !== -1) {
      // pop it off the array and push a new one so that it triggers a recalc on the ui
      state.performanceEvaluationDocuments.splice(matchedIndex, 1)
    }
    state.performanceEvaluationDocuments.push(performanceDoc)
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
