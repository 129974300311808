<template>
  <div>
    <div v-if="!projectTasks.length" class="dashboard-tasks-feed__empty">
      <br />
      <p>
        {{ $t(viewString + 'labelNoTask') }}
      </p>
      <img
        class="no-tasks-image"
        src="@/assets/images/SVGs/NoTask.svg"
        alt="a screen with empty columns"
      />
    </div>
    <dashboard-feed
      v-if="projectTasks.length"
      :data="projectTasks"
      class="dashboard-tasks-feed"
      type="custom"
    >
      <template slot-scope="props">
        <div class="task-feed-item">
          <strong class="has-margin-right"
            ><router-link :to="getProjectLink(props.item.project)">{{
              props.item.projectname
            }}</router-link></strong
          >
          <p class="task-feed-item__subject">
            {{ props.item.subject }}
          </p>
          <p class="task-feed-item__description">
            {{ props.item.description.substring(0, 50) + '..' }}
          </p>
          <p class="task-feed-item__date">
            {{ $t(viewString + 'labelDueDate') }}: {{ format_date(props.item.duedate) }}
          </p>
        </div>
      </template>
    </dashboard-feed>
  </div>
</template>

<script>
import DashboardFeed from '@/views/dashboard/components/dashboard-feed-component/dashboard-feed-component.vue'
import dayjs from '@/utils/dayjs'

export default {
  name: 'dashboard-tasks-feed',
  components: {
    DashboardFeed
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      defaultLinks: {
        build: 'settings',
        contract: 'dates',
        source: 'companies'
      },
      viewString: 'ui.views.projects.messages.message-center-view.'
    }
  },
  computed: {
    projectTasks() {
      return (this.items || []).map((item) => {
        return {
          description: item.description,
          projectname: item.project.name,
          duedate: item.dueDate,
          subject: item.subject,
          project: item.project
        }
      })
    }
  },
  methods: {
    getProjectLink(project) {
      const phase = this.$t(project.phase.localization).toLowerCase()
      const { id } = project
      const projectLink = `/projects/${id}/${phase}/${this.defaultLinks[phase]}`
      return projectLink
    },
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format('YYYY-MM-DD')
      }
    }
  }
}
</script>

<style lang="scss">
.dashboard-tasks-feed__empty {
  text-align: center;
  padding-top: $gap;

  img {
    max-width: 250px;
  }
}
.dashboard-tasks-feed {
  .task-feed-item {
    &__description,
    &__subject,
    &__date {
      color: $c-text-light;
    }
  }

  &__empty {
    color: $c-text-light;
  }
}
</style>
