<template>
  <app-button
    class="has-padding-left-large has-padding-right-large copy-intake-button"
    @click="copyIntakeUrl"
  >
    <app-icon icon="link"></app-icon>
    {{ $t(viewString + 'urlButtonText') }}
  </app-button>
</template>

<script>
export default {
  name: 'dashboard-intake-essentials-button',
  data() {
    return {
      viewString: 'ui.views.control-panel.intake-settings-view.'
    }
  },
  computed: {
    customerId() {
      return this.$store.getters.getAuthCustomer?.id || ''
    },
    nodeId() {
      return this.$store.getters.getAuthTenderNode?.id || ''
    }
  },
  methods: {
    copyIntakeUrl() {
      const url = `${window.location.origin}/#/intake-request?cid=${this.customerId}&nid=${this.nodeId}`

      navigator.clipboard.writeText(url).then(() =>
        this.$notify({
          title: 'Success',
          message: this.$t(`${this.viewString}successfulCopyMessage`),
          type: 'success'
        })
      )
    }
  }
}
</script>

<style lang="scss">
.copy-intake-button {
  height: 40px !important;
}
</style>
