import dayjs from '@/utils/dayjs'
import isEqual from 'lodash/isEqual'
import isObject from 'lodash/isObject'
import transform from 'lodash/transform'
import { useDataSanitizing } from './dataSanitizing.js'

const { sanitizeData } = useDataSanitizing()

function guid() {
  // check crypto for compatibility with IE 11
  const crypto = window.crypto || window.msCrypto
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    // eslint-disable-next-line no-bitwise
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  )
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

// Takes a date meant to represent a UTC date and returns it as a proper utc date
export const getDateAsUtc = (date) => {
  const temp = new Date(date)
  return Date.UTC(
    temp.getFullYear(),
    temp.getMonth(),
    temp.getDate(),
    temp.getHours(),
    temp.getMinutes(),
    temp.getSeconds()
  )
}

export const getDateParts = (date) => {
  const temp = new Date(date)
  return [
    temp.getFullYear(),
    temp.getMonth(),
    temp.getDate(),
    temp.getHours(),
    temp.getMinutes(),
    temp.getSeconds()
  ]
}

export const compareObjects = (object, base) => {
  return transform(object, (result, value, key) => {
    if (base && !isEqual(value, base[key])) {
      result[key] =
        isObject(value) && isObject(base[key]) ? compareObjects(value, base[key]) : value
    }
  })
}

export const getTimeString = (date, time = '00:00 PM') => {
  // make ie and ff behave
  const [pTime, period] = time.split(' ')
  // eslint-disable-next-line prefer-const
  let [hours, minutes] = pTime.split(':')

  if (period && period.toLowerCase() === 'pm' && parseInt(hours) < 12)
    hours = (parseInt(hours) + 12).toString()
  if (period && period.toLowerCase() === 'am' && hours === '12') hours = '0'

  // eslint-disable-next-line prefer-const
  let [year, month, day] = date.split('-')
  month = parseInt(month) - 1
  return [year, month, day, hours, minutes]
}

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

export const fullMonthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export const getMonthName = (month, full = false) => {
  if (full) return fullMonthNames[month]
  return monthNames[month]
}

export const hasValue = (element) => {
  return (
    // eslint-disable-next-line no-undefined
    element !== undefined &&
    element !== null &&
    ((typeof element === 'string' && element.length > 0) || typeof element !== 'string')
  )
}

export const stringToDate = (val) => {
  // eslint-disable-next-line prefer-const
  let [year, month, day] = val.split('-')
  day = day.substring(0, 2)

  return new Date(year, month - 1, day)
}

export const getDateFromDelta = (val, days) => {
  const currDate = new Date(val.getFullYear(), val.getMonth(), val.getDate())

  const result = new Date(currDate)
  result.setDate(result.getDate() + days)

  return result
}

export const getMergedDateFromInput = (inputDateComponent, inputTimeComponent, timezone) => {
  const currYear = new Date(Date.now()).getFullYear()

  if (inputDateComponent == null) {
    return null
  }
  const time = inputTimeComponent != null ? inputTimeComponent : new Date(currYear, 0, 1, 0, 0, 0)
  return dayjs([
    inputDateComponent.getFullYear(),
    inputDateComponent.getMonth(),
    inputDateComponent.getDate(),
    time.getHours(),
    time.getMinutes(),
    0
  ]).tz(timezone, true)
}

export const createAuthGuidIfNeed = () => {
  let btAuthGuid = localStorage.getItem('btAuthGuid')
  if (btAuthGuid === null) {
    btAuthGuid = guid()
    localStorage.setItem('btAuthGuid', btAuthGuid)
  }
  return sanitizeData(btAuthGuid)
}

// Prop validation
export const onlyValues = (values) => (str) => values.indexOf(str.toLowerCase()) !== -1

// check nulls
export const itemsAreNotNull = (arr) => {
  return !arr.some((item) => item === null)
}

export const valueOrNA = (dataField) => {
  return (!!dataField && dataField) || 'N/A'
}

export const forceFileDownload = (response, blobInput, name) => {
  const blob = blobInput || new Blob([response.data], { type: response.data.type })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', name || response?.headers?.filename || 'download')
  document.body.appendChild(link)
  link.click()
  link.remove()
  window.URL.revokeObjectURL(url)
  return response
}

export const getBlobUrlFomApiResponse = (response) => {
  const blob = new Blob([response.data], { type: response.data.type })
  return window.URL.createObjectURL(blob)
}

export const currencyStringToFloat = (value) => {
  const currencyString = value.replace(/[^0-9.-]+/g, '')
  return parseFloat(currencyString)
}

export default {
  guid,
  sleep,
  getDateAsUtc,
  createAuthGuidIfNeed,
  getMergedDateFromInput,
  forceFileDownload,
  getBlobUrlFomApiResponse,
  currencyStringToFloat
}
