import { useDataSanitizing } from '@/utils/dataSanitizing.js'
import dayjs from '@/utils/dayjs'
import uiHandler from '@/utils/ui'
import emailWizardMixins from '@/views/email-wizard/email-wizard-mixins.js'
import { mapActions, mapGetters } from 'vuex'
const { sanitizeHTMLData } = useDataSanitizing()

export default {
  name: 'email-wizard-step-preview',
  mixins: [emailWizardMixins],
  props: {
    groupName: String
  },
  data() {
    return {}
  },
  computed: {
    emailPreview: {
      get() {
        const preview = `${`<p><strong>${this.email.subject}</strong></p>` + '<br />'}${
          this.email.body
        }`
        return sanitizeHTMLData(preview)
      },
      set() {
        // nothing, really. We don't want to set.
        return false
      }
    },
    isSendLater() {
      return this.sendWhen === this.sendOptions.LATER
    },
    formValid() {
      return this.sendWhen === this.sendOptions.NOW || (this.isSendLater && this.sendDate !== null)
    },
    sendWhen: {
      get() {
        return this.email.sendWhen
      },
      set(val) {
        this.updateEmail('sendWhen', val)
      }
    },
    sendDate: {
      get() {
        return this.email.sendDate
      },
      set(val) {
        this.updateEmail('sendDate', val)
      }
    },
    ...mapGetters([
      'getCustomerTimeZone',
      'getEmailTenderId',
      'getEmailContractId',
      'getEmailDocument'
    ])
  },
  mounted() {},
  methods: {
    handleClickSend() {
      let sendDateUtc
      if (this.sendWhen === this.sendOptions.NOW) {
        sendDateUtc = null
      } else {
        sendDateUtc = dayjs([
          this.sendDate.getFullYear(),
          this.sendDate.getMonth(),
          this.sendDate.getDate(),
          this.sendDate.getHours(),
          this.sendDate.getMinutes(),
          this.sendDate.getSeconds()
        ])
          .tz(this.getCustomerTimeZone, true)
          .utc()
          .toISOString()
      }
      const newMailRequest = {
        projectUserIds: this.email.projectUsers.map((tm) => tm.id),
        contactIds: this.email.contacts
          .filter((c) => c.contact.id !== null)
          .map((c) => c.contact.id),
        invitationIds: this.email.contacts
          .filter((c) => c.contact.id === null)
          .map((c) => c.invitationId),
        ccs: this.email.cc,
        bccs: this.email.bcc,
        attachmentIds: this.email.attachments.map((a) => a.id),
        sender: this.email.sender,
        replyTo: this.email.reply,
        subject: this.email.subject,
        body: this.email.body,
        sendDateUtc: sendDateUtc,
        contractId: this.getEmailContractId,
        tenderId: this.getEmailTenderId,
        groupName: this.groupName,
        document: this.getEmailDocument
      }

      uiHandler.showLoading(this)
      this.scheduleNewEmail(newMailRequest)
        .then(() => {
          uiHandler.hideLoading(this)
          this.WizardBus.$emit('next')
        })
        .catch((err) => {
          uiHandler.hideLoading(this)
          if (err.response && err.response.data && err.response.data.length > 0) {
            let msg = '<ul>'
            err.response.data.forEach((e) => {
              msg = `${msg}<li>${e}</li>`
            })
            msg = `${msg}</ul>`

            this.$notify.error({
              dangerouslyUseHTMLString: true,
              message: msg,
              duration: 0
            })
          }
        })
    },
    handleClickBack() {
      this.WizardBus.$emit('previous')
    },
    ...mapActions(['scheduleNewEmail'])
  }
}
