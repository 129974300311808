import {
  filterDictionary,
  filterDictionaryMapper
} from '@/views/projects/project-search-view/project-search-definitions'
import cloneDeep from 'lodash/cloneDeep'

export function projectSearchApi() {
  function buildSearchFilters(params) {
    const filter = {}
    if (params) {
      filter.limit = params.limit || null
      filter.start = params.start || null
      filter.searchText = params.keywords || null
      filter.dateFrom = params.dateFrom ? new Date(params.dateFrom).toISOString() : null
      filter.dateTo = params.dateTo ? new Date(params.dateTo).toISOString() : null
      filter.status = params.status || null

      if (params.criteria) {
        filter.searchCriteria = {}

        for (const key in params.criteria) {
          if (params.criteria[key] != null && params.criteria[key].length !== 0) {
            filter.searchCriteria[`${key}`] = params.criteria[key]
          }
        }
      }
    }
    return filter
  }

  function getModifiedFiltersForApi(filters) {
    const clonedFilters = cloneDeep(filters)
    const intakeFilterKeys = Object.entries(filterDictionary)
      .filter((entry) => entry[1].type === 'intake')
      .map((entry) => entry[0])

    const intakeObj = {}
    Object.keys(clonedFilters).forEach((filter) => {
      if (filter === 'requesterIntake') {
        intakeObj.firstName = clonedFilters[filter].split('|')[0]
        intakeObj.lastName = clonedFilters[filter].split('|')[1]
        delete clonedFilters[filter]
      } else if (filter === 'procurementTypesIntake') {
        intakeObj.procurementTypeId = clonedFilters[filter].split('|')[0]
        intakeObj.procurementType = clonedFilters[filter].split('|')[1]
        delete clonedFilters[filter]
      } else if (intakeFilterKeys.includes(filter)) {
        intakeObj[filterDictionaryMapper[filter]] = clonedFilters[filter]
        delete clonedFilters[filter]
      }
    })
    if (Object.keys(intakeObj).length) {
      clonedFilters.intake = intakeObj
    }

    return clonedFilters
  }

  return {
    getModifiedFiltersForApi,
    buildSearchFilters
  }
}
