// Colors for ckeditor
export const fontBackgroundColor = {
  colors: [
    {
      color: '#e64c4c',
      label: 'Red'
    },
    {
      color: '#e6994c',
      label: 'Orange'
    },
    {
      color: '#e6e64c',
      label: 'Yellow'
    },
    {
      color: '#99e64c',
      label: 'Light green'
    },
    {
      color: '#4ce64c',
      label: 'Green'
    }
  ]
}

export const fontColor = {
  colors: [
    {
      color: '#000000',
      label: 'black'
    },
    {
      color: '#4d4d4d',
      label: 'Dark Grey'
    },
    {
      color: '#999999',
      label: 'Grey'
    },
    {
      color: '#e6e6e6',
      label: 'Light Grey'
    },
    {
      color: '#FFFFFF',
      label: 'White'
    },
    {
      color: '#e64c4c',
      label: 'Red'
    },
    {
      color: '#e6994c',
      label: 'Orange'
    },
    {
      color: '#e6e64c',
      label: 'Yellow'
    },
    {
      color: '#99e64c',
      label: 'Light Green'
    },
    {
      color: '#4ce64c',
      label: 'Green'
    },
    {
      color: '#4ce699',
      label: 'Aquamarine'
    },
    {
      color: '#4ce6e6',
      label: 'Turquoise'
    },
    {
      color: '#4c99e6',
      label: 'Light Blue'
    },
    {
      color: '#4c4ce6',
      label: 'Blue'
    },
    {
      color: '#994ce6',
      label: 'Purple'
    }
  ]
}
