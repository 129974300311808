<template>
  <div
    :class="[
      gutterSize ? 'has-gutters-' + gutterSize : '',
      alignContent ? 'has-content-aligned-' + alignContent : ''
    ]"
    class="app-panel-content"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'app-panel-content',
  props: {
    gutterSize: String, // small, medium
    alignContent: String
  },
  data() {
    return {}
  }
}
</script>
