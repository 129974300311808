export default [
  {
    name: 'ProjectContractDates',
    path: 'dates',
    meta: { area: 'contract' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/contract/contract-dates-view/contract-dates-view.vue'
      )
  },
  {
    name: 'ProjectContractSuppliers',
    path: 'suppliers',
    meta: { area: 'contract' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/contract/contract-suppliers-view/contract-suppliers-view.vue'
      )
  },
  {
    name: 'ProjectContractBuilder',
    path: 'contract-builder',
    meta: { area: 'contract' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/contract/contract-builder-view/contract-builder-view.vue'
      )
  },
  {
    name: 'ProjectContractDocuments',
    path: 'documents',
    meta: { area: 'contract' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/contract/contract-documents-view/contract-documents-view.vue'
      )
  },
  {
    name: 'ProjectContractPurchaseOrders',
    path: 'purchase-orders',
    meta: { area: 'contract' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/contract/contract-purchaseorders-view/contract-purchaseorders-view.vue'
      )
  },
  {
    name: 'ProjectContractPerformance',
    path: 'performance',
    meta: { area: 'contract' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/contract/contract-performance-view/contract-performance-view.vue'
      )
  }
]
