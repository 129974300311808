import tableMixins from '@/mixins/table-mixins.js'
import dayjs from '@/utils/dayjs'
import errorHandler from '@/utils/error'
import { exportTableToXLS } from '@/utils/exportTable'
import uiHandler from '@/utils/ui'
import cloneDeep from 'lodash/cloneDeep'
import sortBy from 'lodash/sortBy'
import uuid from 'uuid/v1'
import { mapActions, mapGetters } from 'vuex'
import AgencyTransactionsModal from './agency-transactions-modal.vue'

export default {
  name: 'ContractBuilderDashboardView',
  mixins: [tableMixins],
  components: {
    AgencyTransactionsModal
  },
  data() {
    return {
      viewString: 'ui.views.dashboard.contract-builder-dashboard-view.',
      gridCurrentPage: 1,
      gridPageSize: 20,
      isAgencyTransactionsModalVisible: false,
      isManageMode: false,
      agencyDetails: {},
      addNewAgencyDefault: {
        id: null,
        allowContractBuilder: false,
        agencyId: null,
        contactEmail: '',
        activityData: [],
        reminder: [
          { id: 25, label: '25 Envelopes Left', val: false },
          { id: 5, label: '5 Envelopes Left', val: false },
          { id: 0, label: 'No Envelopes Left', val: false }
        ],
        billingPeriods: [
          {
            id: uuid(),
            startDate: '',
            endDate: '',
            purchased: 100,
            price: '',
            transactionUsed: 0,
            error: null,
            remaining: null,
            status: ''
          }
        ]
      },
      columnDefs: [
        {
          columnHeading: this.$t(`ui.views.dashboard.contract-builder-dashboard-view.agencyName`),
          field: 'agencyDisplay',
          visible: true,
          sortable: true
        },
        {
          columnHeading: this.$t(
            'ui.views.dashboard.contract-builder-dashboard-view.currentBillingPeriod'
          ),
          field: 'currentBillingPeriod',
          visible: true,
          sortable: false
        },
        {
          columnHeading: this.$t(
            'ui.views.dashboard.contract-builder-dashboard-view.purchasedTransactions'
          ),
          field: 'currentPurchasedTransactions',
          visible: true,
          sortable: true
        },
        {
          columnHeading: this.$t(
            'ui.views.dashboard.contract-builder-dashboard-view.completedTransactions'
          ),
          field: 'currentCompletedTransactions',
          visible: true,
          sortable: true
        },
        {
          columnHeading: this.$t(
            'ui.views.dashboard.contract-builder-dashboard-view.remainingTransactions'
          ),
          field: 'currentRemainingTransactions',
          visible: true,
          sortable: true
        },
        {
          columnHeading: this.$t('ui.views.dashboard.contract-builder-dashboard-view.manage'),
          field: 'manage',
          visible: true,
          sortable: false
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'getContractBuilderLicenseConfigurations',
      'contractBuilderLicenseConfigurationFetchRequired'
    ]),
    gridTotalRows() {
      return this.transactionsList.length
    },
    gridTotalPages() {
      return Math.ceil(this.transactionsList.length / this.gridPageSize)
    },
    transactionsByPage() {
      const start = (this.gridCurrentPage - 1) * this.gridPageSize
      return this.transactionsList.slice(start, start + this.gridPageSize)
    },
    filteredColumnDefs() {
      return this.columnDefs.filter((col) => col.visible)
    },
    contractBuilderLicenseConfigurationsAll() {
      if (this.contractBuilderLicenseConfigurationFetchRequired) {
        this.loadContractBuilderLicenseConfigurationAll()
      }

      return cloneDeep(this.getContractBuilderLicenseConfigurations)
    },
    eSolutionsLicense() {
      if (!this.contractBuilderLicenseConfigurationsAll) return null

      const license = this.transactionsList.filter(
        (r) => r.agencyId.toUpperCase() === '0C2CBE28-1B56-4A37-BFAE-D1F49CF3CC36'
      )[0]

      return license
    },
    esolCurrentPurchasedTransactions() {
      if (!this.eSolutionsLicense) return ''

      return this.eSolutionsLicense.currentPurchasedTransactions
    },
    esolCurrentCompletedTransactions() {
      if (!this.eSolutionsLicense || this.eSolutionsLicense.currentCompletedTransactions === '')
        return ''

      const completedTransaction = this.transactionsList
        .map((r) => r.billingPeriods)
        .concat.apply(
          [],
          this.transactionsList.map((r) => r.billingPeriods)
        )
        .filter(
          (bPeriod) =>
            (new Date(this.eSolutionsLicense.currentBillingPeriodStartDate) <=
              new Date(bPeriod.startDate) &&
              new Date(this.eSolutionsLicense.currentBillingPeriodEndDate) >=
                new Date(bPeriod.startDate)) ||
            (new Date(this.eSolutionsLicense.currentBillingPeriodStartDate) <=
              new Date(bPeriod.endDate) &&
              new Date(this.eSolutionsLicense.currentBillingPeriodEndDate) >=
                new Date(bPeriod.endDate))
        )
        .map((r) => r.transactionUsed)
        .reduce((a, b) => a + b)

      return completedTransaction
    },
    esolCurrentRemainingTransactions() {
      if (!this.eSolutionsLicense || this.eSolutionsLicense.currentRemainingTransactions === '')
        return ''

      return this.esolCurrentPurchasedTransactions - this.esolCurrentCompletedTransactions
    },
    esolCurrentBillingPeriod() {
      if (!this.eSolutionsLicense) return ''

      return this.eSolutionsLicense.currentBillingPeriod
    },
    transactionsList() {
      const configList = []
      const today = dayjs(new Date()).format('YYYY-MM-DD')

      if (!this.contractBuilderLicenseConfigurationsAll) return []

      this.contractBuilderLicenseConfigurationsAll.forEach((r) => {
        const periods = []
        const activityList = []
        let currentPeriod = null

        r.billingPeriods.forEach((bPeriod) => {
          const period = {
            id: bPeriod.id,
            startDate: dayjs(bPeriod.dateStartUTC).format('ddd, DD MMM YYYY'),
            endDate: dayjs(bPeriod.dateEndUTC).format('ddd, DD MMM YYYY'),
            purchased: bPeriod.transactionPurchased,
            price: bPeriod.pricePerTransaction,
            transactionUsed: bPeriod.transactionUsed,
            error: null,
            remaining: bPeriod.transactionPurchased - bPeriod.transactionUsed
          }

          if (dayjs(new Date(bPeriod.dateEndUTC)).format('YYYY-MM-DD') < today) {
            period.status = 'Expired'
          } else if (
            !currentPeriod &&
            dayjs(new Date(bPeriod.dateStartUTC)).format('YYYY-MM-DD') <= today &&
            dayjs(new Date(bPeriod.dateEndUTC)).format('YYYY-MM-DD') >= today
          ) {
            period.status = 'Current'
            currentPeriod = period
          } else if (dayjs(new Date(bPeriod.dateStartUTC)).format('YYYY-MM-DD') > today) {
            period.status = 'Future'
          }

          periods.push(period)
        })

        if (r.auditEvent) {
          r.auditEvent.forEach((auditEvent) => {
            const audit = {
              date: dayjs(auditEvent.created).format('ddd, DD MMM YYYY hh:mm a'),
              type: auditEvent.userFriendlyMessage,
              activity: auditEvent.description
            }

            activityList.push(audit)
          })
        }

        configList.push({
          id: r.id,
          allowContractBuilder: r.allowContractBuilder,
          agencyDisplay: r.node.customer.name,
          agencyId: r.node.id,
          contactEmail: r.contactEmail,
          activityData: activityList,
          reminder: [
            {
              id: 25,
              label: '25 Envelopes Left',
              val: r.reminders.some((r) => r.remainingTransactionCount === 25)
            },
            {
              id: 5,
              label: '5 Envelopes Left',
              val: r.reminders.some((r) => r.remainingTransactionCount === 5)
            },
            {
              id: 0,
              label: 'No Envelopes Left',
              val: r.reminders.some((r) => r.remainingTransactionCount === 0)
            }
          ],
          billingPeriods: periods,
          currentBillingPeriodStartDate: currentPeriod ? dayjs(currentPeriod.startDate) : '',
          currentBillingPeriodEndDate: currentPeriod ? dayjs(currentPeriod.endDate) : '',
          currentBillingPeriod: currentPeriod
            ? `${dayjs(currentPeriod.startDate).format('ddd, DD MMM YYYY')} - ${dayjs(
                currentPeriod.endDate
              ).format('ddd, DD MMM YYYY')}`
            : '',
          currentPurchasedTransactions: currentPeriod ? currentPeriod.purchased : '',
          currentCompletedTransactions: currentPeriod ? currentPeriod.transactionUsed : '',
          currentRemainingTransactions: currentPeriod
            ? currentPeriod.purchased - currentPeriod.transactionUsed
            : ''
        })
      })

      return sortBy(configList, 'agencyDisplay')
    }
  },
  mounted() {
    this.loadContractBuilderLicenseConfigurationAll()
  },
  methods: {
    ...mapActions(['fetchContractBuilderLicenseConfigurationAll']),
    handleExport() {
      exportTableToXLS(
        'Contract Builder Agencies Report',
        this.transactionsList,
        this.visibleColumns.filter((r) => r.field !== 'manage')
      )
    },
    openAgencyModal(row) {
      this.isAgencyTransactionsModalVisible = true
      this.isManageMode = !!row
      this.agencyDetails = cloneDeep(row) || cloneDeep(this.addNewAgencyDefault)
    },
    closeAgencyModal() {
      this.isAgencyTransactionsModalVisible = false
    },
    loadContractBuilderLicenseConfigurationAll() {
      uiHandler.showLoading(this)

      this.fetchContractBuilderLicenseConfigurationAll()
        .catch((e) => errorHandler.handleApiError(this, e))
        .finally(() => uiHandler.hideLoading(this))
    }
  }
}
