<script setup>
import { toRefs, computed } from 'vue'

const props = defineProps({
  heading: String,
  dataPct: String,
  marker: String,
  icon: String,
  data1Text: String,
  data2Text: String,
  data1Value: String,
  data2Value: String
})

const { marker } = toRefs(props)

const markerClass = computed(() => {
  if (!marker.value) return ''
  return `has-marker-${marker.value}`
})
</script>

<template>
  <div class="overview-data-card">
    <div class="overview-data-card-heading">
      <app-title v-if="props.heading" heading="3" size="4" class="has-margin-bottom-tiny">{{
        props.heading
      }}</app-title>
    </div>
    <div class="overview-data-card-content" :class="markerClass">
      <div class="overview-data-card-summary">
        <app-icon :icon="props.icon" />
        <p class="display-value">
          {{ props.dataPct }}
        </p>
      </div>
      <dl class="overview-data-card-data">
        <dt>{{ props.data1Text }}</dt>
        <dd>{{ props.data1Value }}</dd>
        <dt>{{ props.data2Text }}</dt>
        <dd>{{ props.data2Value }}</dd>
      </dl>
    </div>
  </div>
</template>

<style lang="scss">
// colored markers
$markerHeight: 12px;

@mixin CardMarker($gradient) {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: $markerHeight;
}
.has-marker {
  $card-colors: green, blue, pink;
  @each $c in $card-colors {
    &-#{'' + $c} {
      padding-top: $markerHeight;
      position: relative;
      &::before {
        @include CardMarker(#{'' + $c});
        background: map-get($markerGradients, $c);
      }
    }
  }
}
// end colored markers

.overview-data-card {
  display: flex;
  flex-flow: column;
  font-size: $size-6;
  color: map-get($dashboard-text-color, 'light');
}

.overview-data-card-heading {
  width: 100%;
  color: map-get($dashboard-text-color, 'normal');
}

.overview-data-card-content {
  display: flex;
  padding: calc(15px + #{$markerHeight}) 0 15px;
  border: 1px solid $c-border;
  flex: 1;
}

.overview-data-card-summary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70px;
  border-right: 1px solid $c-border;
  padding: $gap-small;
  font-weight: bold;

  .app-icon {
    margin-bottom: $gap-small;
    svg {
      width: 60px !important;
      height: 60px !important;
    }
  }
}

.overview-data-card-data {
  display: grid;
  grid-template-columns: auto 50px;
  max-width: calc(80% - #{$gap-small});
  height: 100%;
  padding: $gap-tiny $gap-small;
  row-gap: $gap-tiny;

  dd {
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;

    &:not(:last-child) {
      margin-bottom: $gap-small;
    }
  }
}
</style>
