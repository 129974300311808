<template>
  <ul
    :id="id"
    :class="{
      'is-horizontal': layout === 'horizontal',
      'has-margin-top-small': layout === 'horizontal'
    }"
    class="dashboard-list has-margin-bottom has-marrgin-top"
  >
    <li
      v-for="(item, i) in data"
      :key="`${id}-${i}`"
      class="dashboard-list__item has-padding-top-small has-padding-bottom-small"
    >
      <div v-if="item.name" class="dashboard-list__item__left">
        {{ item.name | capitalize }}
      </div>
      <div class="dashboard-list__item__right">
        {{ item.amount || 0 }}
      </div>
    </li>
  </ul>
</template>
<script>
import uuid from 'uuid/v1'
export default {
  name: 'dashboard-list',
  props: {
    id: {
      type: String,
      default: `dashboard-list-${uuid()}`
    },
    data: {
      type: Array,
      default: () => []
    },
    layout: String
  }
}
</script>
<style lang="scss">
.dashboard-list {
  &.is-horizontal {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    border-top: 1px solid $c-border;
    -top: 0;
  }
  &__item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    border-top: 1px solid $c-border;

    &__left {
      margin-right: $gap;
      white-space: normal;
    }
    &__right {
      font-size: $size-2;
      color: $c-primary;
      font-weight: bold;
      margin-left: auto;
    }

    .is-horizontal & {
      width: 100%;
      margin-top: $gap;
      flex-flow: column-reverse;
      align-items: center;
      border-left: 1px solid $c-border;
      border-top: 0;

      &:first-child {
        border-left: 0;
      }

      &__left {
        margin: 0;
        font-weight: bold;
      }

      &__right {
        margin: 0;
      }
    }
  }
}
</style>
