<script setup>
const emit = defineEmits(['close', 'approve'])

const viewString = 'ui.views.projectIntakeView.approveModal.'
function closeDialog() {
  emit('close')
}
function approveDialog() {
  emit('approve', { status: 2, details: 'approve' })
}
</script>

<template>
  <el-dialog
    :visible="true"
    :close-on-click-modal="false"
    append-to-body
    class="intake-approve-modal"
    @close="closeDialog"
  >
    <div class="is-flex-column is-aligned-center">
      <app-icon icon="alert-circle"></app-icon>
      <h1>{{ $t(viewString + 'title') }}</h1>
      <span class="has-margin-top-tiny">{{ $t(viewString + 'sub-heading') }} </span>
      <span class="has-margin-top-tiny">{{ $t(viewString + 'bodyText') }}</span>
      <app-button type="primary" class="has-margin-top-small" min-width @click="approveDialog">
        {{ $t('ui.common.confirm') }}
      </app-button>
    </div>
  </el-dialog>
</template>

<style lang="scss">
.intake-approve-modal {
  .app-icon {
    &,
    svg {
      width: 56px;
      height: 56px;
    }

    svg {
      color: $white;
      fill: $c-primary;
    }
  }
}
</style>
