import { api, downloadApi } from '@/api/api'
import { projectSearchApi } from '@/composables/api/projectSearchApi'
import { useStore } from '@/store'
import dayjs from '@/utils/dayjs'
import util from '@/utils/utility.js'
import axios from 'axios'

function IntakeService(config) {
  const store = useStore()

  const accessHeaders = {
    'Content-Type': 'application/json'
  }
  if (import.meta.env.MODE === 'development' || import.meta.env.VITE_IS_LOCAL) {
    accessHeaders.externalaccessapikey = import.meta.env.VITE_ACCESS_API_KEY
  }
  const uploadApiMultipartHeaders = {
    'Content-Type': 'multipart/form-data'
  }

  const accessApi = axios.create({
    baseURL: store.getters.apiRoot,
    // 5 minutes
    timeout: 300000,
    withCredentials: true,
    headers: accessHeaders
  })

  const uploadApi = axios.create({
    baseURL: store.getters.apiRoot,
    timeout: 1800000,
    contentType: false,
    withCredentials: true,
    processData: false,
    headers: uploadApiMultipartHeaders
  })

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })
  function IsCustomerNodeAssigned() {
    const customerId = config?.customerId
    const nodeId = config?.nodeId

    return customerId && nodeId
  }

  function mapIntakePutData(data) {
    const { contactDetails, projectDetail, financialDetails } = data
    const mappedData = {
      contactDetails: {
        ...contactDetails
      },
      projectDetail: {
        ...projectDetail,
        categories: projectDetail.categoryIds,
        supplierIds: projectDetail.supplierEmails
      },
      financialDetails: {
        ...financialDetails
      }
    }

    return mappedData
  }

  function mapIntakeFetchData(data) {
    return {
      contactDetails: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        departmentId: data.departmentId,
        divisionId: data.divisionId,
        procurementLeadId: data.procurementLeadId,
        consultant: {
          FirstName: data.consultantFirstName,
          LastName: data.consultantLastName,
          Email: data.consultantEmail
        }
      },
      projectDetail: {
        projectName: data.projectName,
        classification: data.classification,
        description: data.description,
        bidType: data.bidType,
        applyToProject: data.applyToProject,
        categoryIds: data.intakeRequestCategoryIds,
        isSiteVisit: data.isSiteVisit,
        locations: data.intakeRequestLocations,
        estimatedPostingDate: data.estimatedPostingDate,
        deliveryConstructionStartDate: data.deliveryConstructionStartDate,
        supplierEmails: data.supplierEmails,
        documents: data.documents,
        status: data.status,
        declineReason: data.declinedReason,
        dateCreatedUtc: data.dateCreatedUtc,
        modifiedByUserId: data.modifiedByUserId,
        dateModifiedUtc: data.dateModifiedUtc
      },
      financialDetails: {
        projectBudget: formatter.format(data.projectBudget),
        estimatedTotalProjectValue: formatter.format(data.estimatedTotalProjectValue)
      }
    }
  }

  function mapIntakePostData(data) {
    const baseIntakeRequest = {
      contactDetails: {
        firstName: null,
        lastName: null,
        email: null,
        departmentId: null,
        divisionId: null,
        procurementLeadId: null,
        consultant: null
      },
      projectDetail: {
        projectName: null,
        classification: null,
        description: null,
        bidType: null,
        applyToProject: null,
        categories: [],
        isSiteVisit: false,
        locations: [],
        estimatedPostingDate: null,
        deliveryConstructionStartDate: null,
        supplierEmails: null,
        documentIds: [],
        status: 1
      },
      financialDetails: {
        projectBudget: 0,
        estimatedTotalProjectValue: 0
      }
    }

    delete data.projectDetail.documents

    const mappedData = {
      ...baseIntakeRequest,
      ...data
    }

    // correction
    if (mappedData.contactDetails.consultant === false) {
      mappedData.contactDetails.consultant = null
    }
    mappedData.projectDetail.documentIds.forEach((doc) => {
      doc.displayName = decodeURIComponent(doc.displayName)
    })

    return mappedData
  }

  function MapIntakePendingRequests(data) {
    const fields = [
      'id',
      'dateCreatedUtc',
      'projectName',
      'firstName',
      'lastName',
      'procurementLead',
      'department',
      'bidType',
      'classification',
      'estimatedPostingDate',
      'deliveryConstructionStartDate',
      'projectBudget',
      'estimatedTotalProjectValue',
      'status',
      'dateModifiedUtc'
    ]

    const mappedData = data.map((item) => ({
      ...fields.reduce((acc, field) => {
        acc[field] =
          typeof item[field] === 'object' && item[field]?.name ? item[field].name : item[field]
        return acc
      }, {}),
      requester: `${item.firstName} ${item.lastName}`,
      dateCreatedUtc:
        item.dateCreatedUtc !== null ? dayjs(item.dateCreatedUtc).format('MMMM DD, YYYY') : 'N/A',
      estimatedPostingDate:
        item.estimatedPostingDate !== null
          ? dayjs(item.estimatedPostingDate).format('MMMM DD, YYYY')
          : 'N/A',
      deliveryConstructionStartDate:
        item.deliveryConstructionStartDate !== null
          ? dayjs(item.deliveryConstructionStartDate).format('MMMM DD, YYYY')
          : 'N/A',
      estimatedTotalProjectValue:
        item.estimatedTotalProjectValue !== null
          ? `$${item.estimatedTotalProjectValue.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}`
          : 'N/A',
      projectBudget:
        item.projectBudget !== null
          ? `$${item.projectBudget.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}`
          : 'N/A'
    }))

    return mappedData
  }

  async function getIntakeGuide() {
    if (!IsCustomerNodeAssigned()) {
      return Promise.reject('customerId and nodeId are required')
    }

    const intakeGuideUrl = `api/intake/guide?customerId=${config.customerId}&nodeId=${config.nodeId}`

    try {
      const response = await accessApi.get(intakeGuideUrl)

      return response.data
    } catch (err) {
      throw err
    }
  }

  async function getIntakeContextData() {
    if (!IsCustomerNodeAssigned()) {
      return Promise.reject('customerId and nodeId are required')
    }

    const intakeContextUrl = `api/intake/context?customerId=${config.customerId}&nodeId=${config.nodeId}`

    try {
      const response = await accessApi.get(intakeContextUrl) // return {categories, departments, divisions, procurementLeads}

      return { ...response.data }
    } catch (err) {
      throw err
    }
  }

  async function getIntakeProjectData(intakeId) {
    const intakeProjectUrl = `api/intake/${intakeId}`
    const { data, status } = await api.get(intakeProjectUrl)
    return { data: mapIntakeFetchData(data), status }
  }

  async function getIntakeProjectDataModifier(intakeId) {
    const intakeProjectUrl = `api/intake/getIntakeModifiedUser/${intakeId}`
    const { data, status } = await api.get(intakeProjectUrl)
    return { data: data, status }
  }
  async function postIntakeData(data) {
    if (!IsCustomerNodeAssigned()) {
      return Promise.reject('customerId and nodeId are required')
    }
    const mappedData = mapIntakePostData(data)

    const intakePostUrl = `api/intake?customerId=${config.customerId}&nodeId=${config.nodeId}`
    mappedData.projectDetail.estimatedPostingDate = dayjs(
      mappedData.projectDetail.estimatedPostingDate
    ).format('YYYY-MM-DD')
    mappedData.projectDetail.deliveryConstructionStartDate = dayjs(
      mappedData.projectDetail.deliveryConstructionStartDate
    ).format('YYYY-MM-DD')
    try {
      const response = await accessApi.post(intakePostUrl, mappedData)
      return response.data
    } catch (err) {
      throw err
    }
  }

  async function approveDeclineIntakeProject(intakeId, status, reason) {
    const intakeProjectUrl = `api/intake/approvedeclineintake`
    try {
      const mappedData = {
        IntakeRequestId: intakeId,
        Status: status,
        Reason: reason
      }
      const response = await accessApi.put(intakeProjectUrl, mappedData)
      return response
    } catch (err) {
      // handle error from api
      throw err
    }
  }

  async function updateIntakeProject(intakeId, updatedIntakeProject) {
    const intakeProjectUrl = `api/intake/?intakeRequestId=${intakeId}&nodeId={nodeId}&customerId={customerId}`
    const mappedData = mapIntakePutData(updatedIntakeProject)
    const response = await api.put(intakeProjectUrl, mappedData)
    return response
  }

  async function uploadIntakeDocuments(documents) {
    const response = await uploadApi.post('/intake/documents', documents)

    return response
  }

  async function getIntakeRequests({ status }) {
    const intakePendingRequestsUrl = `api/intake/intakeRequests?nodeId=${config.nodeId}&status=${status}`

    // endpoint is returning a LOT of info.
    // Limit to what's needed (or remove these and get everything)

    try {
      const { data } = await accessApi.get(intakePendingRequestsUrl)
      if (status === 1) {
        // status 1 is pending
        return MapIntakePendingRequests(data)
      }
      return data
    } catch (err) {
      throw err
    }
  }

  async function downloadPdf(intakeId) {
    const url = `api/intake/${intakeId}/download`

    return await downloadApi.get(url).then(util.forceFileDownload)
  }

  async function getIntakeRequesters() {
    try {
      const { data } = await accessApi.get(`api/intake/requesters?nodeId=${config.nodeId}`)
      return data
    } catch (err) {
      throw err
    }
  }

  async function getSearchResults(params = {}) {
    const url = `api/intake/search?nodeId=${config.nodeId}`

    const filter = projectSearchApi().buildSearchFilters(params)

    const modifiedCriteriaForApi = projectSearchApi().getModifiedFiltersForApi(
      filter.searchCriteria
    )

    const response = await accessApi.post(url, {
      ...filter,
      searchCriteria: modifiedCriteriaForApi
    })

    return { data: response?.data?.data?.searchResult?.values || [] }
  }

  async function getIntakeToken({ fileName, intakeId }) {
    const url = `api/intake/token?intakeId=${intakeId}&fileName=${fileName}`

    const response = await accessApi.get(url)
    return response.data
  }

  return {
    accessApi,
    getIntakeContextData,
    getIntakeGuide,
    getIntakeToken,
    postIntakeData,
    uploadIntakeDocuments,
    getIntakeProjectData,
    getIntakeProjectDataModifier,
    updateIntakeProject,
    approveDeclineIntakeProject,
    getIntakeRequests,
    downloadPdf,
    getIntakeRequesters,
    getSearchResults
  }
}

export default IntakeService
