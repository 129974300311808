<script setup>
import { ref } from 'vue'
const privateLabel = ref('ui.common.labelPrivate')
</script>

<template>
  <div v-bind="$attrs" class="private-field-warning icon-date-visible">
    <app-icon icon="lock" size="small" />
    <span>{{ $t(privateLabel) }}</span>
  </div>
</template>

<style lang="scss">
.private-field-warning {
  // display: flex;
  display: none; /* TODO figure out why these weren't visible before*/
  align-items: center;
  color: $c-text-light;
  font-size: $size-6;
  word-break: keep-all;
  white-space: nowrap;

  .app-icon {
    margin-right: 2px;
    color: $c-text-light;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
