import { api } from '@/api/api'
import { NotAllowedError, NotFoundError } from '@/utils/error'
import cloneDeep from 'lodash/cloneDeep'
import * as types from '../../mutation-types'

const state = {
  savedSearchFilters: null,
  projectSearchFilters: null,
  projectSearchColumns: null,
  searchSaved: false,
  savedProjectSearches: [],
  currentSavedSearchId: null,
  currentSavedSearchFilters: [],
  currentActiveSearch: null,
  searchFilterEnabled: false
}

const getters = {
  getSavedSearchFilters: (state) => state.savedSearchFilters,
  getProjectSearchFilters: (state) => state.projectSearchFilters,
  getProjectSearchColumns: (state) => state.projectSearchColumns,
  getProjectSearchSaved: (state) => state.searchSaved,
  getSavedProjectSearches: (state) => state.savedProjectSearches,
  getCurrentActiveSearch: (state) => state.currentActiveSearch,
  getSearchFilterEnabled: (state) => state.searchFilterEnabled
}

const actions = {
  updateSavedSearchFilters: ({ commit, dispatch }, filters) => {
    const clonedFilters = cloneDeep(filters)
    commit(types.SAVED_SEARCH_FILTERS_SET, clonedFilters)
    dispatch('updateProjectSearchSaved', true)
    return Promise.resolve('')
  },
  updateProjectSearchFilters: ({ commit }, filters) => {
    const clonedFilters = cloneDeep(filters)
    commit(types.PROJECT_SEARCH_FILTERS_SET, clonedFilters)
    return Promise.resolve('')
  },
  updateProjectSearchColumns: ({ commit }, columns) => {
    const clonedColumns = cloneDeep(columns)
    commit(types.PROJECT_SEARCH_COLUMNS_SET, clonedColumns)
    return Promise.resolve('')
  },
  updateProjectSearchSaved: ({ commit }, saved) => {
    commit(types.PROJECT_SEARCH_SAVED_SET, saved)
  },
  fetchSavedProjectSearches: ({ dispatch }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/SavedSearchCriteria?type=Project`
    return api.get(url).then((response) => {
      if (response.data.count > 0) {
        const result = response.data.data.savedSearchCriteriaDTO.values
        const parsedSearches = result
        return dispatch('setSavedProjectSearches', parsedSearches).then((result) => [null, result])
      }
    })
  },
  /**
   * fetch searches: sets store searches
   * return [error, searchresults]
   */

  // clear saved searches from memory
  clearSavedProjectSearches: ({ commit }) => {
    commit(types.SAVED_PROJECT_SEARCH_SET_ALL, [])
  },

  setSavedProjectSearches: ({ commit }, searches) => {
    commit(types.SAVED_PROJECT_SEARCH_SET_ALL, searches)
    return new Promise((resolve) => {
      resolve(searches)
    })
  },
  saveCurrentSearch: ({ getters }, model) => {
    const idtl = model.name.toLowerCase()
    const searches = cloneDeep(getters.getSavedProjectSearches)
    if (model.id === null && searches.find((x) => x.name.toLowerCase() === idtl)) {
      return new Promise((resolve) => {
        resolve([new NotAllowedError(`Saved search "${idtl}" already exists`), null])
      })
    }
    if (
      model.id !== null &&
      searches.find((x) => x.name.toLowerCase() === idtl && x.id !== model.id)
    ) {
      return new Promise((resolve) => {
        resolve([new NotAllowedError(`Saved search "${idtl}" already exists`), null])
      })
    }
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/SavedSearchCriteria`
    return api.post(url, model).then((response) => {
      const savedsearchCriteria = response.data
      return [null, savedsearchCriteria]
    })
  },
  setCurrentActiveSearch({ commit, getters }, search) {
    const idtl = search
    // get searches, clone
    const searches = cloneDeep(getters.getSavedProjectSearches)
    if (searches.find((x) => x === search)) {
      return [new NotFoundError(), null]
    }

    commit(types.SAVED_SEARCH_SET_ACTIVE, idtl)
    return [null, idtl]
  },

  clearCurrentActiveSearch({ commit }) {
    commit(types.SAVED_SEARCH_SET_ACTIVE, null)
    return [null, null]
  }
}

const mutations = {
  [types.SAVED_SEARCH_FILTERS_SET](state, filters) {
    state.savedSearchFilters = filters
  },
  [types.PROJECT_SEARCH_FILTERS_SET](state, filters) {
    state.projectSearchFilters = filters
  },
  [types.PROJECT_SEARCH_COLUMNS_SET](state, columns) {
    state.projectSearchColumns = columns
  },
  [types.PROJECT_SEARCH_FILTERS_ACTIVE_SET](state, isActive) {
    state.searchFilterEnabled = isActive
  },
  [types.PROJECT_SEARCH_SAVED_SET](state, saved) {
    state.searchSaved = saved
  },

  [types.SAVED_PROJECT_SEARCH_SET_ALL](state, searches) {
    state.savedProjectSearches = searches
  },
  [types.SAVED_SEARCH_SET_ACTIVE](state, search) {
    state.currentActiveSearch = search
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
