import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  projectTasks: [],
  projectTasksbyUser: []
}

export const getters = {
  getProjectTasks: (state) => state.projectTasks,
  getProjectTasksbyUser: (state) => state.projectTasksbyUser
}

export const actions = {
  fetchProjectTasks: ({ commit }, projectId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ProjectTask?projectId=${projectId}`
    return api
      .get(url)
      .then((response) => {
        if (response.data.count > 0) {
          const result = response.data.data.projectTaskDTO.values
          commit(types.PROJECT_TASK_SET_ALL, result)
          return new Promise(function (resolve) {
            resolve(result)
          })
        }
      })
      .catch((err) => {
        commit(types.PROJECT_TASK_SET_ALL, null)
        throw err
      })
  },
  saveProjectTask: (_, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ProjectTask`
    return api.post(url, model).then((response) => response.data.data.projectTaskDTO.values[0])
  },
  fetchProjectTasksByUser: ({ commit }, userId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ProjectTask/GetByUser?userId=${userId}&dueDays=15`
    return api
      .get(url)
      .then((response) => {
        if (response.data.count > 0) {
          const result = response.data.data.projectTaskDTO.values
          commit(types.PROJECT_TASK_SET_ALL_BY_USER, result)
          return result
        }
      })
      .catch((err) => {
        commit(types.PROJECT_TASK_SET_ALL_BY_USER, null)
        throw err
      })
  }
}
export const mutations = {
  [types.PROJECT_TASK_SET_ALL](state, projectTasks) {
    state.projectTasks = projectTasks
  },

  [types.PROJECT_TASK_SET_ALL_BY_USER](state, projectTasksbyUser) {
    state.projectTasksbyUser = projectTasksbyUser
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
