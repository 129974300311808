import { api, downloadApi, uploadApiMultipart } from '@/api/api'
import util from '@/utils/utility.js'
import * as types from '../../mutation-types'

export const state = {
  documents: [],
  documentToEdit: null,
  pendingDocuments: [],
  expiredDocuments: [],
  emailDocuments: []
}

export const getters = {
  getDocuments: (state) => state.documents,
  getDocumentToEdit: (state) => state.documentToEdit,
  getExpiredDocuments: (state) => state.expiredDocuments,
  getPendingDocuments: (state) => state.pendingDocuments,
  getEmailDocuments: (state) => state.emailDocuments
}

export const actions = {
  fetchDocumentsByTender: ({ commit }, id) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Documents/SearchByTender?tenderId=${id}`

    return api.get(url).then((response) => {
      const documents = response.data.data.searchResult.values
      commit(types.DOCUMENT_SET_ALL, documents)
      return documents
    })
  },

  fetchExpiredDocumentsByNode: ({ commit }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Documents/Contract/Expired`

    return api.get(url).then((response) => {
      let documents = []
      if (response.data.data.searchResult) documents = response.data.data.searchResult.values
      commit(types.EXPIRED_DOCS_ALL, documents)
      return documents
    })
  },

  fetchPendingDocumentsByNode: ({ commit }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Documents/Contract/Pending`

    return api.get(url).then((response) => {
      let documents = []
      if (response.data.data.searchResult) documents = response.data.data.searchResult.values
      commit(types.PENDING_DOCS_ALL, documents)
      return documents
    })
  },

  uploadDocument: (_, payload) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Documents`
    const { formData } = payload

    return uploadApiMultipart.post(url, formData).then((response) => {
      const document = response.data.data.documentDTO.values[0]
      return document
    })
  },
  downloadDocument: (_, documentToDownload) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Documents/Download?documentId=${documentToDownload.documentId}`

    return downloadApi.get(url).then(util.forceFileDownload)
  },
  saveDocument: (_, payload) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Documents`
    const { formData } = payload

    return uploadApiMultipart.put(url, formData).then((response) => {
      const document = response.data.data.documentDTO.values[0]
      return document
    })
  },
  deleteDocument: (_, documentId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Documents?documentId=${documentId}`
    return api.delete(url)
  },
  deleteBidDocument: (_, tenderId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Documents/BidDocument?tenderId=${tenderId}`
    return api.delete(url)
  },
  deleteSubmissionNoteDocument: (_, tenderId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Documents/SubmissionNoteDocument?tenderId=${tenderId}`
    return api.delete(url)
  },
  sortOrderDocument: (_, documentList) => {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/Documents/SortOrder/'

    return api.put(url, documentList).then((response) => {
      if (response.data.count !== 0) {
        const documents = response.data.data.documentDTO.values
        return documents
      }
    })
  },
  fetchEmailDocuments: ({ commit }, emailId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Documents/EmailDocuments?emailId=${emailId}`

    return api.get(url).then((response) => {
      let documents = []
      if (response.data.data.documentDTO) documents = response.data.data.documentDTO.values
      commit(types.EMAIL_DOCS_ALL, documents)
      return documents
    })
  },

  updateDocument: ({ commit }, document) => {
    commit(types.DOCUMENT_EDIT, document)
  }
}

export const mutations = {
  [types.DOCUMENT_SET_ALL](state, documents) {
    state.documents = documents
  },
  [types.DOCUMENT_EDIT]: (state, document) => {
    state.documentToEdit = document
  },
  [types.EXPIRED_DOCS_ALL](state, documents) {
    state.expiredDocuments = documents
  },
  [types.PENDING_DOCS_ALL](state, documents) {
    state.pendingDocuments = documents
  },
  [types.EMAIL_DOCS_ALL](state, documents) {
    state.emailDocuments = documents
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
