<template>
  <div class="chart-wrapper">
    <Pie
      :data="chartData"
      :options="mergedOptions"
      v-bind="$attrs"
      :style="{ height: size, width: size }"
    />
    <div v-if="$slots['percentage']" class="chart-percentage" :style="{ color: textColor }">
      <slot name="percentage" />
    </div>
  </div>
</template>

<script>
import { Pie } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from 'chart.js'
import merge from 'lodash/merge'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  name: 'PieChart',
  components: { Pie },
  props: {
    chartData: {
      type: Object,
      required: true
    },
    chartOptions: {
      type: Object,
      default: () => ({})
    },
    size: {
      type: String,
      default: '200px'
    },
    textColor: {
      type: String,
      default: '#FFFFFF'
    }
  },
  data() {
    return {
      defaultOptions: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateRotate: true
        },
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          }
        }
      }
    }
  },
  computed: {
    mergedOptions() {
      return merge(this.defaultOptions, this.chartOptions)
    }
  }
}
</script>

<style lang="scss">
.chart-wrapper {
  position: relative;
}
.chart-percentage {
  position: absolute;
  bottom: 21%;
  color: white;
  font-weight: bold;
  left: 50%;
  transform: translateX(-50%);
}
</style>
