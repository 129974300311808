import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  vendorList: [],
  vendorLists: []
}

export const getters = {
  getVendorList: (state) => state.vendorList,
  getVendorLists: (state) => state.vendorLists
}
export const actions = {
  fetchVendorLists: ({ commit }) => {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/VendorLists'
    return api.get(url).then((response) => {
      const lists = response.data.data.vendorListDTO.values
      commit(types.VENDOR_LISTS_SET, lists)
      return lists
    })
  },
  fetchVendorList: ({ commit }, vendorListId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/VendorLists/${vendorListId}/`
    return api.get(url).then((response) => {
      const vendorlist = response.data.data.vendorListDTO.values
      commit(types.VENDOR_LIST_SET, vendorlist)
      return vendorlist
    })
  },
  saveVendorList: (_, model) => {
    const url = `api/v1/customer/{customerId}/node/{nodeId}/VendorLists`
    return api.post(url, model).then((response) => response.data)
  },
  addVendorToList: (_, model) => {
    const url = `api/v1/customer/{customerId}/node/{nodeId}/VendorLists/AddVendorToList`
    return api.post(url, model).then((response) => {
      const savedVendorList = response.data
      return [null, savedVendorList]
    })
  }
}
export const mutations = {
  [types.VENDOR_LISTS_SET](state, lists) {
    state.vendorLists = lists
  },
  [types.VENDOR_LIST_SET](state, list) {
    state.vendorList = list
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
