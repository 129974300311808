<template>
  <div
    :class="['is-size-' + size, headingClass ? headingClass : '', { 'has-breadcrumb': breadcrumb }]"
    class="app-title"
  >
    <div v-if="hasPrepend && !count" class="app-title-prepend">
      <slot name="prepend"></slot>
    </div>
    <!--if count, prepend display number -->
    <div v-if="count" class="app-title-prepend">
      <span :class="count ? 'is-size-' + countSize : ''" class="app-title-count">
        {{ count }}
      </span>
    </div>
    <!--better readability than render function-->
    <div class="app-title-content">
      <component
        :is="component"
        :class="['is-size-' + size, { 'is-centered': centered }]"
        class="app-title-heading"
      >
        <slot></slot>
      </component>
      <div v-if="hasSubtitle" class="app-title-subtitle">
        <slot name="subtitle"></slot>
      </div>
    </div>
    <div v-if="hasAppend" class="app-title-append">
      <slot name="append"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-title',
  props: {
    heading: {
      type: String,
      default: '2'
    },
    count: String,
    countSize: {
      type: String,
      default: '4'
    },
    size: {
      type: String,
      default: '3'
    },
    breadcrumb: false,
    centered: Boolean,
    headingClass: String
  },
  computed: {
    hasPrepend() {
      return this.$slots.prepend
    },
    hasAppend() {
      return this.$slots.append
    },
    hasSubtitle() {
      return this.$slots.subtitle
    },
    component() {
      if (parseInt(this.heading) >= 6) return 'h6'
      return `h${this.heading}`
    }
  },
  methods: {
    setHeadingClasses() {
      return `is-size-${this.size ? this.size : '3'}${
        this.headingClass ? ` ${this.headingClass}` : ''
      }`
    }
  }
}
</script>

<style lang="scss" src="./app-title.scss"></style>
