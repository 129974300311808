import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  contractVendorDocumentTypes: [],
  contractVendorDocumentType: null
}

export const getters = {
  getContractVendorDocumentTypes: (state) => state.contractVendorDocumentTypes,
  getContractVendorDocumentType: (state) => (id) => {
    return state.contractVendorDocumentTypes.find((c) => c.id === id)
  },
  getContractVendorDocumentTypeNew: (state) => state.contractVendorDocumentType
}

export const actions = {
  fetchContractVendorDocumentTypes: ({ commit }) => {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/ContractVendorDocumentTypes'
    return api.get(url).then((response) => {
      const vendorDocumentTypes = response?.data?.data?.contractVendorDocumentTypeDTO?.values
      commit(types.CONTRACT_VENDOR_DOCUMENT_TYPES_SET, vendorDocumentTypes)
      return vendorDocumentTypes
    })
  },
  saveContractVendorDocumentType: ({ commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ContractVendorDocumentTypes`
    return api.post(url, model).then((response) => {
      const vendorDocumentType = response.data.data.contractVendorDocumentTypeDTO.values[0]
      commit(types.CONTRACT_VENDOR_DOCUMENT_TYPE_SET, vendorDocumentType)
      return vendorDocumentType
    })
  },
  updateContractVendorDocumentType: ({ commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ContractVendorDocumentTypes`
    return api.put(url, model).then((response) => {
      const vendorDocumentType = response.data.data.contractVendorDocumentTypeDTO.values[0]
      commit(types.CONTRACT_VENDOR_DOCUMENT_TYPE_SET, vendorDocumentType)
      return vendorDocumentType
    })
  },
  deleteContractVendorDocumentType: (_, id) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ContractVendorDocumentTypes?contractVendorDocumentTypeId=${id}`
    return api.delete(url)
  }
}
export const mutations = {
  [types.CONTRACT_VENDOR_DOCUMENT_TYPES_SET](state, vendorDocumentTypes) {
    state.contractVendorDocumentTypes = vendorDocumentTypes
  },
  [types.CONTRACT_VENDOR_DOCUMENT_TYPE_SET](state, vendorDocumentType) {
    state.contractVendorDocumentType = vendorDocumentType
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
