<template>
  <div class="award-approval-footer has-padding-large">
    <span><img :src="logo" class="has-margin-right-small" /> &copy;2021 Bids&tenders</span>
  </div>
</template>
<script>
import logo from '@/assets/images/logo-small-inverse.svg'
export default {
  name: 'award-approval-footer',
  data() {
    return {
      logo
    }
  }
}
</script>

<style lang="scss" scoped>
.award-approval-footer {
  background: $team-member-primary;
  display: flex;
  align-items: center;
  width: 100%;
  height: $team-member-footer-height;
}

svg {
  fill: $c-text;
}
</style>
