import { api } from '@/api/api'
import * as types from '@/store/mutation-types'

const state = {
  routePermission: [],
  routePermissionUser: {
    hasRoutePermissionToView: false,
    hasRoutePermissionToAction: false
  }
}

const getters = {
  getRoutePermission: (state) => state.routePermission,
  getRoutePermissionUser: (state) => state.routePermissionUser
}

const actions = {
  fetchRoutePermission: ({ commit }) => {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/RoutePermissions/All'

    return api.get(url).then(function (response) {
      commit(types.ROUTEPERMISSION_SET, response.data.data.routeDTO.values)
      return response.data
    })
  },
  setRoutePermission: ({ commit }, routePermission) => {
    commit(types.ROUTEPERMISSION_SET, routePermission)
  },
  setRoutePermissionUser: ({ commit }, routePermissionUser) => {
    commit(types.ROUTEPERMISSION_USER_SET, routePermissionUser)
  }
}

const mutations = {
  [types.ROUTEPERMISSION_SET](state, routePermission) {
    state.routePermission = routePermission
  },
  [types.ROUTEPERMISSION_USER_SET](state, routePermissionUser) {
    state.routePermissionUser = routePermissionUser
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
