import { useStore } from '@/store'
const store = useStore()
import { ref } from 'vue'

export function useFeatureHelpers(givenStore = store) {
  const storeToUse = ref(givenStore || store)

  const navBlockedError = new Error('Gated  navigation aborted')
  navBlockedError.name = 'GateNavigationAborted'

  function isFeatureEnabled(featureId) {
    return storeToUse.value.getters.getFeatureEnabled(featureId).enabled
  }

  function isModuleEnabled(featureId, moduleId) {
    return storeToUse.value.getters.getModuleEnabled(featureId, moduleId).enabled
  }

  function featureGateRoute(featureId) {
    return (to, from, next) => {
      const { enabled } = storeToUse.value.getters.getFeatureEnabled(featureId)
      if (enabled) {
        next()
      } else {
        const nextPath = from.path === '/login' ? navBlockedError : '/login'
        next(nextPath)
      }
    }
  }

  function moduleGateRoute(featureId, moduleId) {
    return (to, from, next) => {
      const { enabled } = storeToUse.value.getters.getModuleEnabled(featureId, moduleId)
      if (enabled) {
        next()
      } else {
        const nextPath = from.path === '/login' ? navBlockedError : '/login'
        next(nextPath)
      }
    }
  }

  return {
    featureGateRoute,
    moduleGateRoute,
    navBlockedError,
    isFeatureEnabled,
    isModuleEnabled
  }
}
