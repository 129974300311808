import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  performanceTemplates: []
}
export const getters = {
  getPerformanceTemplates: (state) => state.performanceTemplates
}
export const actions = {
  fetchPerformanceTemplates: ({ commit }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/PerformanceTemplate`

    return api.get(url).then((response) => {
      const performanceTemplates = response.data.data.performanceTemplateDTO.values
      commit(types.PERFORMANCE_TEMPLATE_SET_ALL, performanceTemplates)
      return performanceTemplates
    })
  }
}
export const mutations = {
  [types.PERFORMANCE_TEMPLATE_SET_ALL]: (state, templates) => {
    state.performanceTemplates = templates
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
