<script setup>
import debounce from 'lodash/debounce'
const props = defineProps({
  controls: {
    type: String,
    required: true
  },
  expanded: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['toggle'])

const handleClick = debounce(function () {
  emit('toggle', !props.expanded)
}, 60)
</script>

<template>
  <button
    class="app-hamburger-button"
    v-bind="$attrs"
    :aria-controls="props.controls"
    :aria-expanded="props.expanded"
    @click="handleClick"
    @keydown.enter="handleClick"
  >
    <span class="app-hamburger-button__line" aria-hidden="true" focusable="false"></span>
    <span class="app-hamburger-button__line" aria-hidden="true" focusable="false"></span>
    <span class="app-hamburger-button__line" aria-hidden="true" focusable="false"></span>
  </button>
</template>

<style lang="scss" scoped>
$button-size: 48px;
.app-hamburger-button {
  display: flex;
  position: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: $button-size;
  height: $button-size;
  background: none;
  border: 0;
  cursor: pointer;

  &__line {
    display: block;
    content: '';
    width: calc(#{$button-size} - #{$gap-small});
    height: 3px;
    background: $white;
    margin: 4px 0;
    transition: all 0.2s ease-in-out;
  }
}
</style>
