export default {
  path: '/control-panel',
  name: 'ControlPanelView',
  component: () => import('@/views/control-panel/control-panel-view/control-panel-view.vue'),
  meta: { Auth: true, isLanding: true },
  props: true,
  children: [
    {
      path: 'account-information',
      name: 'CPAccountInfo',
      component: () =>
        import(
          '@/views/control-panel/control-panel-sections/cpanel-account-info-view/cpanel-account-info-view.vue'
        )
    },
    {
      path: 'project-settings',
      name: 'CPProjectSettings',
      component: () =>
        import(
          '@/views/control-panel/control-panel-sections/cpanel-project-settings-view/cpanel-project-settings-view.vue'
        )
    },
    {
      path: 'intake-settings',
      name: 'CPIntakeSettings',
      component: () =>
        import(
          '@/views/control-panel/control-panel-sections/cpanel-intake-settings-view/cpanel-intake-settings-view.vue'
        )
    },
    {
      path: 'source-settings',
      name: 'CPSourceSettings',
      component: () =>
        import(
          '@/views/control-panel/control-panel-sections/cpanel-source-settings-view/cpanel-source-settings-view.vue'
        )
    },
    {
      path: 'evaluation-settings',
      name: 'CPEvaluationSettings',
      component: () =>
        import(
          '@/views/control-panel/control-panel-sections/cpanel-evaluation-settings-view/cpanel-evaluation-settings-view.vue'
        )
    },
    {
      path: 'contract-settings',
      name: 'CPContractSettings',
      component: () =>
        import(
          '@/views/control-panel/control-panel-sections/cpanel-contract-settings-view/cpanel-contract-settings-view.vue'
        )
    },
    {
      path: 'dropdown-settings',
      name: 'CPDropDownSettings',
      component: () =>
        import(
          '@/views/control-panel/control-panel-sections/cpanel-dropdown-settings-view/cpanel-dropdown-settings-view.vue'
        )
    },
    {
      path: 'email-notifications',
      name: 'CPanelEmailNotifications',
      component: () =>
        import(
          '@/views/control-panel/control-panel-sections/cpanel-email-notifications-view/cpanel-email-notifications-view.vue'
        )
    },
    {
      path: 'system-content',
      name: 'CPanelSystemContent',
      component: () =>
        import(
          '@/views/control-panel/control-panel-sections/cpanel-system-content-view/cpanel-system-content-view.vue'
        )
    },
    {
      path: 'library-content',
      name: 'CPanelLibraryContent',
      component: () =>
        import(
          '@/views/control-panel/control-panel-sections/cpanel-library-content-view/cpanel-library-content-view.vue'
        )
    },
    {
      path: 'sso-settings',
      name: 'CPSsoSettings',
      component: () =>
        import(
          '@/views/control-panel/control-panel-sections/cpanel-sso-settings-view/cpanel-sso-settings-view.vue'
        )
    },
    {
      path: 'contract-builder',
      name: 'CPContractBuilder',
      component: () =>
        import(
          '@/views/control-panel/control-panel-sections/cpanel-contract-builder-view/cpanel-contract-builder-view.vue'
        )
    }
  ]
}
