<template>
  <component :is="tag" class="dashboard-activity has-padding-top-small has-padding-bottom-small">
    <router-link v-if="href" :to="link" class="dashboard-activity__wrapper">
      <app-avatar
        v-if="hasAvatar"
        :src="avatarUrl"
        :class="{ 'is-loading': !avatarUrl }"
        class="dashboard-activity__icon"
      />
      <div class="dashboard-activity__content">
        <span v-if="hasTitle" class="dashboard-activity__title has-margin-bottom-tiny"
          ><slot name="title"
        /></span>
        <span v-if="hasInfo" class="dashboard-activity__info has-margin-bottom-tiny"
          ><slot name="info"
        /></span>
        <span v-if="hasUser || hasDate" class="dashboard-activity__date"
          ><slot name="user" /> <slot name="date"
        /></span>
      </div>
    </router-link>
    <div v-else class="dashboard-activity__wrapper">
      <app-avatar
        v-if="hasAvatar"
        :src="avatarUrl"
        :class="{ 'is-loading': !avatarUrl }"
        class="dashboard-activity__icon"
      />
      <div class="dashboard-activity__content">
        <span v-if="hasTitle" class="dashboard-activity__title has-margin-bottom-tiny"
          ><slot name="title"
        /></span>
        <span v-if="hasInfo" class="dashboard-activity__info has-margin-bottom-tiny"
          ><slot name="info"
        /></span>
        <span v-if="hasUser || hasDate" class="dashboard-activity__date"
          ><slot name="user" /> <slot name="date"
        /></span>
      </div>
    </div>
  </component>
</template>

<script>
export default {
  name: 'dashboard-activity',
  props: {
    tag: {
      type: String,
      default: 'li'
    },
    href: String,
    avatar: {
      type: Boolean,
      default: false
    },
    avatarUrl: String
  },
  computed: {
    hasDate() {
      return this.$slots.date
    },
    hasTitle() {
      return this.$slots.title
    },
    hasInfo() {
      return this.$slots.info
    },
    hasUser() {
      return this.$slots.user
    },
    hasAvatar() {
      return this.avatarUrl
    }
  }
}
</script>

<style lang="scss">
.dashboard-activity {
  display: flex;
  flex-flow: row;

  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row;
    align-items: flex-start;
  }

  &__icon {
    margin-right: $padding-base;
  }

  &__content {
    display: flex;
    flex-flow: column;
    width: calc(100% - (38px + #{$padding-base}));
  }
  &__title {
    @include wrapper-body-medium;
    font-weight: 500;
    color: $c-primary;
  }
  &__date {
    @include wrapper-body;
    font-weight: 400;

    color: $c-text-light;
  }
  &__info {
    @include wrapper-body;
    font-size: $size-4;
    font-weight: 400;
    color: $c-text-light;
  }

  .app-avatar {
    width: 36px;
    height: 36px;
  }
}
</style>
