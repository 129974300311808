export default {
  path: '/reports',
  name: 'reports-view',
  meta: { Auth: true },
  component: () => import('@/views/reports/reports-view/reports-view.vue'),
  children: [
    {
      path: 'library',
      component: () => import('@/views/reports/reports-library/reports-library-view.vue'),
      name: 'reportsLibrary'
    },
    {
      path: 'center',
      component: () => import('@/views/reports/reports-center/reports-center-view.vue'),
      name: 'reportsCentre'
    }
  ]
}
