import { api, uploadApi } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  insuranceDocuments: []
}

export const getters = {
  getInsuranceDocuments: (state) => state.insuranceDocuments,
  getInsuranceDocument: (state) => (id) => {
    return state.insuranceDocuments.find((i) => i.id === id)
  }
}
export const actions = {
  fetchInsuranceDocuments: ({ commit }, contractId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Insurance?contractId=${contractId}&filter.fields=Vendor,InsuranceType,Contract`
    return api.get(url).then((response) => {
      const documents = response?.data?.data?.insuranceDTO?.values || []
      commit(types.INSURANCE_DOCUMENTS_SET, documents)
      return documents
    })
  },
  fetchInsuranceDocument: ({ commit }, documentId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Insurance/InsuranceDocument?documentId=${documentId}`
    return api.get(url).then((response) => {
      const documents = response?.data?.data?.insuranceDTO?.values
      commit(types.INSURANCE_DOCUMENTS_SET, documents)
      return documents
    })
  },
  createInsuranceDocument: ({ commit }, formData) => {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/Insurance'

    return uploadApi.post(url, formData).then((response) => {
      const insurance = response?.data?.data?.insuranceDTO?.values?.[0] || []
      commit(types.INSURANCE_UPDATE_OR_ADD, insurance)
      return insurance
    })
  },
  updateInsuranceDocument: ({ commit }, model) => {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/Insurance'
    return api.put(url, model.model).then((response) => {
      const insurance = response?.data?.data?.insuranceDTO?.values?.[0]
      commit(types.INSURANCE_UPDATE_OR_ADD, insurance)
      return insurance
    })
  },

  deleteInsurance: ({ commit }, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Insurance/${params.documentId}`
    return api.delete(url).then(() => {
      commit(types.INSURANCE_DOCUMENT_DELETE, params.documentId)
      return ''
    })
  }
}
export const mutations = {
  [types.INSURANCE_DOCUMENTS_SET](state, documents) {
    state.insuranceDocuments = documents
  },
  [types.INSURANCE_UPDATE_OR_ADD](state, insuranceDocument) {
    const matchedIndex = state.insuranceDocuments.findIndex((i) => i.id === insuranceDocument.id)
    if (matchedIndex !== -1) {
      // pop it off the array and push a new one so that it triggers a recalc on the ui
      state.insuranceDocuments.splice(matchedIndex, 1)
    }
    state.insuranceDocuments.push(insuranceDocument)
  },
  [types.INSURANCE_DOCUMENT_DELETE](state, insuranceId) {
    state.insuranceDocuments = state.insuranceDocuments.filter((c) => c.id !== insuranceId)
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
