import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  commodityCategories: [],
  fetchingCommodityCategories: false
}

export const getters = {
  getCommodityCategories: (state) => state.commodityCategories,
  getCommodityCategoriesFetchStatus: (state) => state.fetchingCommodityCategories
}

export const actions = {
  fetchCommodityCategories: ({ commit, state }, forceUpate = false) => {
    if (forceUpate) {
      commit(types.COMMODITY_CATEGORIES_SET, [])
    }

    // For caching, exit early if already fetched
    if (state.commodityCategories.length) {
      commit(types.COMMODITY_CATEGORIES_SET_STATUS, false)
      return Promise.resolve(state.commodityCategories)
    }
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/CommodityCategories`

    // check fetch status
    if (state.fetchingCommodityCategories === true) {
      return Promise.resolve('')
    }

    // update the fetch status
    commit(types.COMMODITY_CATEGORIES_SET_STATUS, true)

    // fallback cancel fetching status
    setTimeout(() => {
      if (state.fetchingCommodityCategories === true) {
        commit(types.COMMODITY_CATEGORIES_SET_STATUS, false)
      }
    }, 60000)

    return api
      .get(url)
      .then((response) => {
        const commodityCategories = response.data.data.commodityCategoriesDTO.values
        commit(types.COMMODITY_CATEGORIES_SET, commodityCategories)
        return commodityCategories
      })
      .finally(() => commit(types.COMMODITY_CATEGORIES_SET_STATUS, false))
  }
}

export const mutations = {
  [types.COMMODITY_CATEGORIES_SET](state, commodityCategories) {
    state.commodityCategories = commodityCategories
  },
  [types.COMMODITY_CATEGORIES_SET_STATUS](state, status) {
    state.fetchingCommodityCategories = status
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
