<script setup>
import { ref, toRefs } from 'vue'
import PieChart from '@/components/pie-chart/pie-chart.vue'

const props = defineProps({
  satisfactory: Number,
  nonSatisfactory: Number
})

const chartColors = ['#007a8d', '#66afbb']

const { satisfactory, nonSatisfactory } = toRefs(props)

const totalEvaluations = satisfactory.value + nonSatisfactory.value
const satisfactoryPct = ((satisfactory.value / totalEvaluations) * 100).toFixed(2)
const nonSatisfactoryPct = ((nonSatisfactory.value / totalEvaluations) * 100).toFixed(2)

const datasets = [
  {
    backgroundColor: chartColors,
    data: [satisfactoryPct, nonSatisfactoryPct]
  }
]

const chartData = ref({
  datasets,
  labels: ['Satisfactory', 'Unatisfactory']
})

const labelSatisfactory = ref('ui.views.dashboard.supplier-dashboard-view.labelSatisfactory')
const labelUnsatisfactory = ref('ui.views.dashboard.supplier-dashboard-view.labelUnsatisfactory')
</script>

<template>
  <figure class="pct-satisfactory-chart">
    <ul class="legend">
      <li>
        <span class="marker" :style="{ backgroundColor: chartColors[0] }" />
        {{ $t(labelSatisfactory) }} <span class="percentage-number">{{ satisfactoryPct }}%</span>
      </li>
      <li>
        <span class="marker" :style="{ backgroundColor: chartColors[1] }" />
        {{ $t(labelUnsatisfactory) }}
        <span class="percentage-number">{{ nonSatisfactoryPct }}%</span>
      </li>
    </ul>
    <PieChart
      :chart-data="chartData"
      aria-label="Percentage satisfatory pie chart"
      class="pct-chart-data-card"
      size="140px"
      role="img"
    />
  </figure>
</template>

<style lang="scss">
$legendColor: #f0f3f5;
$dashboard-wide-width: 1380px;
$random-aesthetic-breakpoint: 1649px;

.pct-satisfactory-chart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;

  @media (min-width: $dashboard-wide-width) {
    justify-content: space-between;
  }
}

.legend {
  margin-right: $gap-large;
  @media (min-width: $dashboard-wide-width) and (max-width: $random-aesthetic-breakpoint) {
    margin-right: $gap-small;
  }

  li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: $gap-small $gap;
    font-weight: bold;
    background: #f0f3f5;
    width: 280px;

    @media (min-width: $dashboard-wide-width) and (max-width: $random-aesthetic-breakpoint) {
      width: 235px;
    }

    .percentage-number {
      margin-left: auto;
    }

    &:first-child {
      margin-bottom: $gap-small;
    }
  }

  .marker {
    display: block;
    content: '';
    width: 14px;
    height: 14px;
    margin-right: $gap-tiny;
  }
}
</style>
