import UseIntakeNewProject from '@/composables/project/newIntakeProject'
import tableMixins from '@/mixins/table-mixins'
import i18n from '@/plugins/i18n'
import dayjs from '@/utils/dayjs'
import errorHandler from '@/utils/error'
import { exportTableToXLS } from '@/utils/exportTable'
import ui from '@/utils/ui'
import ApproveModal from '@/views/projects/project-view/project-sub-views/intake/modals/intake-view-approve-modal'
import DeclineModal from '@/views/projects/project-view/project-sub-views/intake/modals/intake-view-decline-modal'
import { Notification } from 'element-ui'
import cloneDeep from 'lodash/cloneDeep'
import uniqBy from 'lodash/uniqBy'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'intake-essentials-widget',
  mixins: [tableMixins],
  components: { ApproveModal, DeclineModal },
  computed: {
    ...mapGetters(['getDepartments', 'getUsers', 'getIntakePendingRequests']),
    dataSource() {
      let projects = []

      if (this.mappedData?.length) {
        projects = this.mappedData.filter((val) => {
          let showVal = true
          if (this.selectedRequester && this.selectedRequester != 'ALL') {
            showVal = val.requester?.includes(this.selectedRequester)
          }
          if (showVal && this.selectedDepartment && this.selectedDepartment != 'ALL') {
            showVal = val.department?.includes(
              this.departmentList.find((dept) => dept.id === this.selectedDepartment).name
            )
          }
          if (showVal && this.selectedTeamMember && this.selectedTeamMember !== 'ALL') {
            if (this.selectedTeamMember === 'NULL') {
              showVal = !val.procurementLead
            } else {
              showVal = val.procurementLead?.includes(this.selectedTeamMember)
            }
          }
          if (showVal && this.keywordFilter) {
            showVal = val.projectName?.toLowerCase().includes(this.keywordFilter.toLowerCase())
          }
          return showVal
        })
      }

      return projects
    },
    storedIntakeRequests() {
      return this.getIntakePendingRequests
    },
    departmentList() {
      return this.getDepartments
    }
  },
  data() {
    return {
      viewString: 'ui.views.dashboard.intake-widget.',
      mappedData: [],
      fields: [
        'id',
        'dateCreatedUtc',
        'projectName',
        'firstName',
        'lastName',
        'procurementLead',
        'department',
        'bidType',
        'classification',
        'estimatedPostingDate',
        'deliveryConstructionStartDate',
        'projectBudget',
        'estimatedTotalProjectValue'
      ],
      columnDefs: [
        {
          columnHeading: 'Request date',
          field: 'dateCreatedUtc',
          visible: true,
          isDate: true
        },
        {
          columnHeading: 'Request name',
          field: 'projectName',
          visible: true
        },
        {
          columnHeading: 'Requester',
          field: 'requester',
          visible: true
        },
        {
          columnHeading: 'Procurement lead',
          field: 'procurementLead',
          visible: true
        },
        {
          columnHeading: 'Department',
          field: 'department',
          visible: true
        },
        {
          columnHeading: 'Type',
          field: 'bidType',
          visible: true
        },
        {
          columnHeading: 'Classification',
          field: 'classification',
          visible: true
        },
        {
          columnHeading: 'Estimated posting date',
          field: 'estimatedPostingDate',
          visible: true,
          isDate: true
        },
        {
          columnHeading: 'Delivery start date',
          field: 'deliveryConstructionStartDate',
          visible: true,
          isDate: true
        },
        {
          columnHeading: 'Estimated annual project value',
          field: 'projectBudget',
          visible: true
        },
        {
          columnHeading: 'Estimated total project value',
          field: 'estimatedTotalProjectValue',
          visible: true
        },
        {
          columnHeading: 'Action',
          field: 'manage',
          align: 'center',
          visible: true
        }
      ],
      selectedRequester: '',
      selectedTeamMember: '',
      selectedDepartment: '',
      keywordFilter: '',
      sortedTeamMemberList: [],
      sortedRequesterList: [],
      projectDepartments: [],
      showAcceptModal: false,
      showDeclineModal: false,
      declineComment: '',
      selectedIntake: null
    }
  },
  watch: {
    mappedData: {
      handler: function () {
        this.sortRequesterList()
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.sortTeamMemberList()
    this.getMappedRequests()
  },
  methods: {
    ...mapActions([
      'fetchDepartments',
      'fetchIntakePendingRequests',
      'approveDeclineIntakeProject'
    ]),
    getMappedRequests() {
      this.$emit('loading', true)
      this.fetchIntakePendingRequests()
        .then(() => {
          this.mappedData = this.getIntakePendingRequests
          this.$emit('error', false)
        })
        .catch((e) => {
          this.$emit('error', true)
          errorHandler.handleApiError(this, e)
        })
        .finally(() => this.$emit('loading', false))
    },
    sortRequesterList() {
      const members = [...new Set(this.mappedData.map((data) => data.requester))].sort((a, b) =>
        a.localeCompare(b)
      )
      this.sortedRequesterList = members
    },
    sortTeamMemberList() {
      const members = uniqBy([...this.getUsers], 'id').sort((a, b) => {
        const aFN = (a.firstName || '') + (a.lastName || '')
        const bFN = (b.firstName || '') + (b.lastName || '')
        return aFN.localeCompare(bFN)
      })
      this.sortedTeamMemberList = members
    },
    handleColumnReorder(row) {
      switch (row.prop) {
        case 'projectBudget':
        case 'estimatedTotalProjectValue':
          this.mappedData = this.mappedData.sort((a, b) => {
            const a1 = a[row.prop]
            const a2 = b[row.prop]
            const c1 = parseFloat(a1.replace(/[^0-9.]/g, '')) || null
            const c2 = parseFloat(a2.replace(/[^0-9.]/g, '')) || null
            if (c1 === null) return 1
            if (c2 === null) return -1
            return row.order === 'ascending' ? c1 - c2 : c2 - c1
          })
          break
        default:
          this.mappedData = this.standardTableSortWithDate(
            row,
            this.mappedData,
            this.columnDefs
              .filter((columnDef) => columnDef.isDate)
              .map((columnDef) => columnDef.field)
          )
      }
    },
    async approveSelectedIntakeRequest() {
      this.$emit('loading', true)
      const intakeData = cloneDeep(this.selectedIntake)

      try {
        // These composable functions will set the intake data to the store @ intake.module fyi
        const { createProjectFromIntakeData, getIntakeDataById } = UseIntakeNewProject({
          store: this.$store
        })
        const fullIntakeData = await getIntakeDataById(intakeData.id)
        await createProjectFromIntakeData(fullIntakeData)

        ui.showLoading(this)

        this.$router.push({ name: 'ProjectNewView' })
      } catch (e) {
        this.$emit('error', true)
        errorHandler.handleApiError(this, e)
      } finally {
        this.showAcceptModal = false
        this.$emit('loading', false)
        ui.hideLoading(this)
      }
    },
    async statusIntakeResult(result) {
      try {
        await this.approveDeclineIntakeProject({
          intakeId: this.selectedIntake.id,
          status: result.status,
          reason: result.details
        })
        Notification.success({
          title: 'Success',
          message:
            result.status === 2
              ? i18n.t('ui.views.projectIntakeView.approveModal.modalApproved')
              : i18n.t('ui.views.projectIntakeView.declineModal.modalDeclined'),
          type: 'success'
        })
        this.getMappedRequests()
      } catch (err) {
        const statusCode = err.response?.status || '500'
        const message = `${i18n.t(
          'ui.views.projectIntakeView.declineModal.modalDeclinedError'
        )}\n[${statusCode}]`
        Notification.error({
          title: 'Error',
          message,
          type: 'error',
          customClass: 'is-white-space-pre-line'
        })
      } finally {
        this.showAcceptModal = false
        this.showDeclineModal = false
      }
    },
    viewIntakeProject(intakeId) {
      this.$router.push({
        path: `/projects/${intakeId}/intake`
      })
    },
    handleManage([intakeId, action]) {
      this.selectedIntake = this.mappedData.find((item) => item.id === intakeId)
      switch (action) {
        case 'view':
          return this.viewIntakeProject(intakeId)
        case 'approve':
          this.showAcceptModal = true
          break
        case 'decline':
          this.showDeclineModal = true
          break
      }
    },
    handleExportData(exportAll) {
      const name = exportAll ? 'All Data' : 'Filtered'
      const data = exportAll
        ? this.mapExportTable(this.mappedData)
        : this.mapExportTable(this.dataSource)

      exportTableToXLS(
        `Intake_ Pending Requests - ${name} - ${dayjs().format('MM.DD.YYYY')}`,
        data,
        exportAll ? this.columnDefs : this.visibleColumns
      )
    },
    mapExportTable(data) {
      return data.map((item) => {
        return Object.assign({}, item, { procurementLead: item.procurementLead || 'Not sure' })
      })
    },
    renderColumnContents(columnName, data) {
      switch (columnName) {
        case 'procurementLead':
          return data ? data : 'Not sure'
        default:
          return data ? data : 'N/A'
      }
    }
  }
}
