<template>
  <li :class="[{ 'has-link': clickable, 'has-chevron': chevron }]" class="app-listgroup-item">
    <a
      v-if="clickable"
      :class="[linkClass]"
      class="app-listgroup-item-wrapper"
      @click.prevent="handleItemClick()"
    >
      <div v-if="hasSlotPrepend" class="app-listgroup-item-prepend">
        <slot name="prepend"></slot>
      </div>
      <div class="app-listgroup-item-content">
        <span class="app-listgroup-item-title">
          <slot></slot>
        </span>
        <span v-if="hasSlotSubtitle" class="app-listgroup-item-subtitle">
          <slot name="subtitle"></slot>
        </span>
      </div>
      <div v-if="hasSlotAppend" class="app-listgroup-item-append">
        <slot name="append"></slot>
      </div>
      <app-icon v-if="chevron" icon="chevron-right" class="app-listgroup-item-chevron"></app-icon>
    </a>
    <div v-else class="app-listgroup-item-wrapper">
      <div v-if="hasSlotPrepend" class="app-listgroup-item-prepend">
        <slot name="prepend"></slot>
      </div>
      <span class="app-listgroup-item-content" @click.prevent="handleItemClick()">
        <span class="app-listgroup-item-title">
          <slot></slot>
        </span>
        <span v-if="hasSlotSubtitle" class="app-listgroup-item-subtitle">
          <slot name="subtitle"></slot>
        </span>
      </span>
      <div v-if="hasSlotAppend" class="app-listgroup-item-append">
        <slot name="append"></slot>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: 'app-listgroup-item',
  props: {
    chevron: {
      type: Boolean,
      default: false
    },
    clickable: {
      type: Boolean,
      default: true
    },
    linkClass: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {}
  },
  computed: {
    hasSlotPrepend() {
      return this.$slots.prepend
    },
    hasSlotAppend() {
      return this.$slots.append
    },
    hasSlotSubtitle() {
      return this.$slots.subtitle
    }
  },
  methods: {
    handleItemClick() {
      this.$emit('click')
    }
  }
}
</script>
