import * as types from '../../mutation-types'

export const state = {
  sections: {
    insuranceTypes: [],
    terms: [],
    contractCategory: [],
    contractVendorType: [],
    bidTypes: [],
    departments: [],
    irrevocablePeriods: [],
    vendorDocTypes: [],
    securityTypes: [],
    approvalExceptions: [],
    awardExceptions: [],
    leadAgencyNames: [],
    methodLimitedTypes: [],
    tradeAgreement: [],
    description: []
  }
}

export const getters = {
  getSectionNames: (state) => Object.keys(state.sections),
  getDropdownElements: (state) => (sectionName) => state.sections[sectionName]
}

export const actions = {
  updateDropdownElement: ({ commit, state }, { section, items }) => {
    // don't set items that aren't an array
    if (!Array.isArray(items)) {
      return false
    }
    commit(types.CONTROL_PANEL_DROPDOWNS_SET, { section, items })
    return state.sections[section]
  }
}

export const mutations = {
  [types.CONTROL_PANEL_DROPDOWNS_SET](state, { section, items }) {
    state.sections[section] = items
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
