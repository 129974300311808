import WizardMixins from '@/mixins/wizard-mixins'
import errorHandler from '@/utils/error'
import EmailWizardStepCompose from '@/views/email-wizard/email-wizard-steps/email-wizard-step-compose/email-wizard-step-compose.vue'
import EmailWizardStepContacts from '@/views/email-wizard/email-wizard-steps/email-wizard-step-contacts/email-wizard-step-contacts.vue'
import EmailWizardStepPreview from '@/views/email-wizard/email-wizard-steps/email-wizard-step-preview/email-wizard-step-preview.vue'
import EmailWizardStepSent from '@/views/email-wizard/email-wizard-steps/email-wizard-step-sent/email-wizard-step-sent.vue'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

const WizardBus = new Vue()

export default {
  name: 'email-wizard',
  mixins: [WizardMixins],
  components: {
    EmailWizardStepContacts,
    EmailWizardStepCompose,
    EmailWizardStepPreview,
    EmailWizardStepSent
  },
  props: {
    visible: Boolean,
    plantakers: Array,
    projectUsers: Array,
    invitees: Array,
    groupName: String,
    tenderId: String,
    contractId: String,
    document: Object
  },
  provide() {
    return {
      WizardBus,
      totalSteps: this.totalStepCount - 1
    }
  },
  watch: {
    plantakers(pts) {
      this.setNewEmailContacts(this.mapContacts(pts))
    },
    projectUsers(val) {
      this.setNewEmailProjectUsers(val)
    },
    invitees(invs) {
      this.setNewEmailInvitees(this.mapInvitees(invs))
    },
    tenderId(val) {
      this.setNewEmailTenderId(val)
    },
    contractId(val) {
      this.setNewEmailContractId(val)
    },
    document(val) {
      this.setNewEmailDocument(val)
    }
  },
  computed: {
    totalStepCount() {
      return 4
    },
    ...mapGetters(['getEmailTemplates', 'getNewEmail'])
  },
  data() {
    return {
      viewString: 'ui.views.email-wizard.email-wizard-view.',
      test: 'florp'
    }
  },
  created() {
    if (this.groupName && this.groupName.length > 0) {
      this.fetchEmailTemplates(this.groupName).catch((err) =>
        errorHandler.handleApiError(this, err)
      )
    }

    if (this.plantakers) {
      this.setNewEmailContacts(this.mapContacts(this.plantakers))
    } else {
      this.setNewEmailContacts([])
    }

    if (this.invitees) {
      this.setNewEmailInvitees(this.mapInvitees(this.invitees))
    } else {
      this.setNewEmailInvitees([])
    }

    this.setNewEmailProjectUsers(this.projectUsers ? this.projectUsers : [])
    this.setNewEmailTenderId(this.tenderId ? this.tenderId : '')
    this.setNewEmailContractId(this.contractId ? this.contractId : '')
    this.setNewEmailDocument(this.document ? this.document : '')
  },
  mounted() {
    WizardBus.$on('next', this.handleClickNext)
    WizardBus.$on('previous', this.handleClickPrevious)
    this.setNewEmail()
  },
  methods: {
    ...mapActions([
      'updateNewEmail',
      'setNewEmailContacts',
      'setNewEmailProjectUsers',
      'setNewEmailInvitees',
      'setNewEmailTenderId',
      'setNewEmailContractId',
      'deleteDocument',
      'setNewEmailDocument'
    ]),
    reset() {
      this.currentStepNumber = 1
      this.setNewEmail()
    },
    mapContacts(pts) {
      const plantakers = []

      if (pts !== null) {
        pts.forEach((pt) => {
          if (pt.vendor !== null && pt.vendor.contacts !== null) {
            pt.vendor.contacts.forEach((c) => {
              plantakers.push({
                id: pt.vendor.id,
                name: pt.vendor.companyName,
                contact: {
                  id: c.id,
                  name: `${c.firstName} ${c.lastName}`,
                  email: c.email
                }
              })
            })
          }
        })
      }

      return plantakers
    },
    mapInvitees(invs) {
      const invitees = []
      invs.forEach((inv) => {
        invitees.push({
          id: inv.vendor.id,
          invitationId: inv.id,
          name: inv.vendor.companyName,
          contact: {
            id: inv.contactId,
            name: inv.contactName,
            email: inv.email
          }
        })
      })
      return invitees
    },
    createNewEmail() {
      return {
        includeProjectUsers: false,
        projectUsers: [],
        contacts: [],
        template: null,
        reply: null,
        sender: null,
        cc: [],
        bcc: [],
        attachments: [],
        subject: null,
        body: '',
        sendWhen: null,
        sendDate: null
      }
    },
    setNewEmail() {
      const email = this.createNewEmail()
      this.updateNewEmail(email)
    },
    ...mapActions(['fetchEmailTemplates'])
  }
}
