<template>
  <div ref="wrapper" class="app-checkbox" @click="handleClick">
    <ElCheckbox
      v-bind="$attrs"
      :id="id"
      ref="element-checkbox"
      :value="internalValue"
      :disabled="isDisabled"
      :label="label"
      @change="handleChange"
    >
      <slot name="content"></slot>
      <slot></slot>
    </ElCheckbox>
  </div>
</template>
<script>
import InputPermissionMixins from '@/mixins/input-permission-mixins'
import ElCheckbox from 'element-ui/packages/checkbox/index.js'

export default {
  name: 'app-checkbox',
  components: {
    ElCheckbox
  },
  mixins: [InputPermissionMixins],
  model: {
    prop: 'modelValue',
    event: 'input'
  },
  props: {
    id: {
      type: String,
      default: `app-checkbox-${Math.random().toString(36).substr(2, 9)}`
    },
    value: {
      type: [String, Number, Object, Boolean, Array],
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    },
    modelValue: {
      type: [Boolean, Array],
      default: false
    }
  },
  data() {
    return {
      checked: null
    }
  },
  computed: {
    isDisabled() {
      return this.disabled || !this.hasPermissionToEdit
    },
    internalValue() {
      if (Array.isArray(this.modelValue)) {
        return this.modelValue.includes(this.value)
      }
      return this.modelValue
    }
  },
  methods: {
    handleChange(isChecked) {
      let newValue

      if (Array.isArray(this.modelValue)) {
        newValue = [...this.modelValue]
        if (isChecked) {
          newValue.push(this.value)
        } else {
          const index = newValue.indexOf(this.value)
          if (index !== -1) {
            newValue.splice(index, 1)
          }
        }
      } else {
        newValue = isChecked
      }

      this.$emit('input', newValue)
      this.$emit('change', newValue)
    },
    handleClick() {
      const mockEvent = {
        target: this.$refs['element-checkbox']?.$el?.querySelector('input'),
        preventDefault: () => {}
      }
      this.$emit('click', mockEvent)
    }
  }
}
</script>

<style lang="scss">
.app-checkbox {
  .is-focus {
    .el-checkbox__inner {
      box-shadow: $focus-shadow;
    }
  }

  .label,
  .el-checkbox__label {
    font-size: 1rem;
    font-weight: 400;
  }
}
</style>
