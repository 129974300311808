<template>
  <app-grid class="buyer-dashboard-overview__expired-docs has-margin-top">
    <div slot="actionsRight">
      <app-grid-pagination
        :page-size="gridPageSize"
        :current-page="gridCurrentPage"
        :total-pages="gridTotalPages"
        :total-rows="gridTotalRows"
        @clickPrev="handleClickPrev"
        @clickNext="handleClickNext"
        @pageSizeChange="handleGridPageSizeChange"
      >
      </app-grid-pagination>
      <el-dropdown :show-timeout="0" :hide-timeout="0" :hide-on-click="false" trigger="click">
        <app-column-button />
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(column, index) in columnDefs" :key="'visible-column' + index">
            <app-checkbox
              :id="column.field"
              v-model="column.visible"
              :label="column.columnHeading"
              :name="column.field"
              :value="column.field"
              class="has-margin-bottom-tiny"
            >
            </app-checkbox>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-table
      ref="bidTable"
      :data="tableData"
      class="table-bids"
      width="100%"
      stripe
      @sort-change="handleColumnReorder"
    >
      <span slot="empty">{{ $t('ui.views.dashboard.dashboard-view.overviewExpiredEmpty') }}</span>
      <el-table-column
        v-for="(column, c) in visibleColumns"
        :key="`column-${c}`"
        :label="column.columnHeading"
        :align="getAlign(column.field)"
        :min-width="column.width || '150'"
        :property="column.field"
        sortable="custom"
      >
        <template slot-scope="props">
          <router-link
            v-if="column.field === 'projectName'"
            :to="`/projects/${props.row.projectId}/contract/documents`"
          >
            {{ props.row[column.field] }}
          </router-link>
          <span
            v-if="
              column.field === 'type' ||
              column.field === 'category' ||
              column.field === 'supplier' ||
              column.field === 'projectNumber'
            "
            >{{ props.row[column.field] }}</span
          >
          <div
            v-if="column.field === 'procurementLead' || column.field === 'contractAdmin'"
            class="is-flex is-aligned-center"
          >
            <span
              v-if="props.row[column.field] && props.row[column.field] !== 'N/A'"
              class="is-flex is-aligned-center"
            >
              <app-avatar :src="props.row[column.field].avatarUrl" size="medium" />
              {{ props.row[column.field].firstName + ' ' + props.row[column.field].lastName }}
              <span v-if="props.row[column.field].inActive" class="is-danger">{{
                $t('ui.common.deactivated')
              }}</span>
            </span>
            <span v-else class="is-flex is-aligned-center">
              {{ props.row[column.field] }}
            </span>
          </div>
          <span v-if="column.field === 'expiredDate'">{{
            props.row[column.field] | dateString
          }}</span>
        </template>
      </el-table-column>
    </el-table>
  </app-grid>
</template>

<script>
import TableMixins from '@/mixins/table-mixins.js'
import cloneDeep from 'lodash/cloneDeep'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'dashboard-buyer-overview-uploads',
  mixins: [TableMixins],
  data() {
    return {
      viewString: 'ui.views.dashboard.buyer-dashboard-view.',
      columnDefs: [
        {
          field: 'projectNumber',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingProjectNumber'),
          visible: true
        },
        {
          field: 'projectName',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingProjectName'),
          visible: true
        },
        {
          field: 'category',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingCategory'),
          visible: true
        },
        {
          field: 'type',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingType'),
          visible: true
        },
        {
          field: 'supplier',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingSupplier'),
          visible: true
        },
        {
          field: 'procurementLead',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingProcurementLead'),
          visible: true
        },
        {
          field: 'contractAdmin',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingContractAdmin'),
          visible: true
        },
        {
          field: 'expiredDate',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingExpiredDate'),
          visible: true
        }
      ],
      data: []
    }
  },
  computed: {
    ...mapGetters([
      'getUsers',
      'getInsuranceTypes',
      'getContractBondDocumentTypes',
      'getContractVendorDocumentTypes',
      'getExpiredDocuments'
    ]),
    dataSource() {
      return this.data
    }
  },
  mounted() {
    this.$emit('loading', true)

    const promises = [this.fetchExpiredDocumentsByNode()]
    if (!this.getUsers.length) {
      promises.push(this.fetchUsers())
    }
    if (!this.getContractVendorDocumentTypes.length) {
      promises.push(this.fetchContractVendorDocumentTypes())
    }
    if (!this.getContractBondDocumentTypes.length) {
      promises.push(this.fetchContractBondDocumentTypes())
    }
    if (!this.getInsuranceTypes.length) {
      promises.push(this.fetchInsuranceTypes())
    }

    Promise.all(promises)
      .then(() => {
        this.buildData()
        this.$emit('error', false)
      })
      .catch(() => this.$emit('error', false))
      .finally(() => this.$emit('loading', false))
  },
  methods: {
    ...mapActions([
      'fetchExpiredDocumentsByNode',
      'fetchUsers',
      'fetchContractVendorDocumentTypes',
      'fetchContractBondDocumentTypes',
      'fetchInsuranceTypes'
    ]),
    buildData() {
      const currentDate = new Date()
      const docsWithExpiry = cloneDeep(this.getExpiredDocuments)
      const users = cloneDeep(this.getUsers)
      const insuranceTypes = cloneDeep(this.getInsuranceTypes)
      const bondTypes = cloneDeep(this.getContractBondDocumentTypes)
      const vendorDocTypes = cloneDeep(this.getContractVendorDocumentTypes)
      // throw all the types into one array
      const docTypes = insuranceTypes.concat(bondTypes, vendorDocTypes)
      // put array in descending order by expire date
      const feedItems = []
      if (docsWithExpiry)
        docsWithExpiry.forEach((doc) => {
          let procLead = []
          if (doc.projectName) procLead = users.filter((user) => user.id === doc.procurementLeadId)
          let docType = null
          let docCategory = null
          let isNew = true
          let noReplacement = true

          if (doc.category.toLowerCase() === 'certificate') {
            docType = 'Certificate'
            isNew = !feedItems.some(
              (item) =>
                item.category === docType &&
                item.supplier === doc.companyName &&
                item.projectId === doc.projectId // each instance of this certificate should show so that they know each project it touches
            )
          } else if (doc.category.toLowerCase() === 'other') {
            docType = 'Other'
          } else if (doc.category.toLowerCase() === 'bond') {
            docType = 'Bonds and Security'
            docCategory = docTypes.find((type) => type.id === doc.type)
            isNew = !feedItems.some(
              (item) =>
                item.category === docType &&
                item.type === docCategory.text &&
                item.supplier === doc.companyName &&
                item.projectId === doc.projectId
            )
          } else if (doc.category.toLowerCase() === 'vendordocument') {
            docType = 'Supplier Document'
            docCategory = docTypes.find((type) => type.id === doc.type)
            isNew = !feedItems.some(
              (item) =>
                item.category === docType &&
                item.type === docCategory.text &&
                item.supplier === doc.companyName &&
                item.projectId === doc.projectId
            )
          } else if (doc.category.toLowerCase() === 'insurance') {
            docType = 'Insurance'
            docCategory = docTypes.find((type) => type.id === doc.type)
            isNew = !feedItems.some(
              (item) =>
                item.category === docType &&
                item.type === docCategory.text &&
                item.supplier === doc.companyName &&
                item.projectId === doc.projectId
            )
            noReplacement = !docsWithExpiry.some(
              (item) =>
                item.category === docType &&
                item.type === docCategory.text &&
                item.supplier === doc.companyName &&
                item.projectId === doc.projectId &&
                Date.parse(item.expireDate) > Date.parse(currentDate)
            )
          }

          let docContractAdmin = []
          if (docType) {
            if (doc.contractAdminId) {
              const docContractAdmins = doc.contractAdminId.split(',')
              const admin = docContractAdmins[0]
              docContractAdmin = users.filter((user) => user.id === admin.toLowerCase())
            }

            if (isNew && noReplacement && Date.parse(currentDate) > Date.parse(doc.expireDate)) {
              feedItems.push({
                projectName: doc.projectName || 'N/A',
                projectId: doc.projectId || 'N/A',
                projectNumber: doc.projectNumber || 'N/A',
                category: docType,
                type: docCategory?.text || 'N/A',
                supplier: doc.companyName || 'N/A',
                procurementLead: procLead?.[0] || 'N/A',
                contractAdmin: docContractAdmin?.[0] || 'N/A',
                expiredDate: doc.expireDate,
                documentId: doc.documentId
              })
            }
          }
        })
      this.data = feedItems
    },
    getAlign(field) {
      switch (field) {
        case 'view':
        case 'approved':
        case 'download':
          return 'center'
        default:
          return 'left'
      }
    },
    handleColumnReorder(row) {
      switch (row.prop) {
        case 'procurementLead':
        case 'contractAdmin':
          this.data = this.data.sort((a, b) => {
            const a1 = a[row.prop]?.firstName || a[row.prop]
            const b1 = b[row.prop]?.firstName || b[row.prop]
            return row.order === 'ascending' ? a1.localeCompare(b1) : b1.localeCompare(a1)
          })
          break
        default:
          this.data = this.standardTableSortWithDate(row, this.data, ['expiredDate'])
      }
    }
  }
}
</script>

<style lang="scss">
.buyer-dashboard-overview__expired-docs {
  .el-checkbox__label {
    margin: 0 auto;
  }

  .app-grid-actions.app-grid-actions-top {
    margin-bottom: 0 !important;
  }
}
</style>
