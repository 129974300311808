<template>
  <div v-bind="$attrs" class="document-preview">
    <div v-if="fileLocation" id="pdfvuer">
      <vue-pdf-embed :source="file" />
    </div>
    <app-empty v-else icon="slash"> You have not specified a file </app-empty>
  </div>
</template>

<script>
// import VuePdfEmbed from 'vue-pdf-embed'

// OR THE FOLLOWING IMPORT FOR VUE 2
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  components: {
    VuePdfEmbed
  },
  props: {
    file: String
  },
  data() {
    return {}
  },
  computed: {
    fileLocation() {
      return !!this.file
    }
  }
}
</script>

<style lang="scss" scoped>
.document-preview {
  width: 830px;
  height: 500px;
  overflow: auto;
  border: 1px solid #dedede;
}

#buttons {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
/* Page content */
.content {
  padding: 16px;
}
</style>
