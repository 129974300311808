import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  procurementTypes: [],
  procurementType: null
}

export const getters = {
  getProcurementTypes: (state) => state.procurementTypes,
  getProcurementType: (state) => state.procurementType
}

export const actions = {
  fetchProcurementTypes: ({ commit }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/TenderType`

    return api.get(url).then((response) => {
      if (response.data.count > 0) {
        const procurementTypes = response.data.data.tenderTypeDTO.values
        commit(types.PROCUREMENT_TYPE_SET_ALL, procurementTypes)
        return procurementTypes
      }
    })
  },
  saveProcurementType: ({ commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/TenderType`
    return api.post(url, model).then((response) => {
      const procurementType = response.data.data.tenderTypeDTO.values[0]
      commit(types.PROCUREMENT_TYPE_SET, procurementType)
      return procurementType
    })
  },
  updateProcurementType: ({ commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/TenderType`
    return api.put(url, model).then((response) => {
      const procurementType = response.data.data.tenderTypeDTO.values[0]
      commit(types.PROCUREMENT_TYPE_SET, procurementType)
      return procurementType
    })
  },
  deleteProcurementType: (_, id) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/TenderType?tenderTypeId=${id}`
    return api.delete(url)
  }
}

export const mutations = {
  [types.PROCUREMENT_TYPE_SET_ALL](state, procurementTypes) {
    state.procurementTypes = procurementTypes
  },
  [types.PROCUREMENT_TYPE_SET](state, procurementType) {
    state.procurementType = procurementType
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
