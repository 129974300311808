import { api, uploadApi } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  contractVendorDocuments: []
}

export const getters = {
  getContractVendorDocuments: (state) => state.contractVendorDocuments,
  getContractVendorDocument: (state) => (id) => {
    return state.contractVendorDocuments.find((i) => i.id === id)
  }
}
export const actions = {
  fetchContractVendorDocuments: ({ commit }, contractId) => {
    const url =
      `/api/v1/customer/{customerId}/node/{nodeId}/contract/${contractId}/ContractVendorDocuments` +
      `?filter.fields=Vendor,ContractVendorDocumentType&filter.limit=5000`
    return api.get(url).then((response) => {
      const contractVendorDocuments = response?.data?.data?.contractVendorDocumentDTO?.values || []
      commit(types.CONTRACT_VENDOR_DOCUMENTS_SET, contractVendorDocuments)
      return contractVendorDocuments
    })
  },

  fetchContractVendorDocument: ({ commit }, id, contractId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/contract/${contractId}/ContractVendorDocuments/${id}`
    return api.get(url).then((response) => {
      const contractVendorDocuments = response?.data?.data?.contractVendorDocumentDTO?.values || []
      commit(types.CONTRACT_VENDOR_DOCUMENTS_SET, contractVendorDocuments)
      return contractVendorDocuments
    })
  },

  createContractVendorDocument: ({ commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/contract/${model.contractId}/ContractVendorDocuments`
    return uploadApi.post(url, model.formData).then((response) => {
      const contractVendorDocument =
        response?.data?.data?.contractVendorDocumentDTO?.values?.[0] || []
      commit(types.CONTRACT_VENDOR_DOCUMENT_UPDATE_OR_ADD, contractVendorDocument)
      return contractVendorDocument
    })
  },
  updateContractVendorDocument: ({ commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/contract/${model.contractId}/ContractVendorDocuments`
    return api.put(url, model.model).then((response) => {
      const contractVendorDocument =
        response?.data?.data?.contractVendorDocumentDTO?.values?.[0] || []
      commit(types.CONTRACT_VENDOR_DOCUMENT_UPDATE_OR_ADD, contractVendorDocument)
      return contractVendorDocument
    })
  },
  deleteContractVendorDocument: ({ commit }, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/contract/${params.contractId}/ContractVendorDocuments/${params.documentId}`
    return api.delete(url).then(() => {
      commit(types.CONTRACT_VENDOR_DOCUMENTS_DELETE, params.documentId)
      return ''
    })
  }
}
export const mutations = {
  [types.CONTRACT_VENDOR_DOCUMENTS_SET](state, contractVendorDocuments) {
    state.contractVendorDocuments = contractVendorDocuments
  },
  [types.CONTRACT_VENDOR_DOCUMENTS_DELETE](state, contractVendorDocumentId) {
    state.contractVendorDocuments = state.contractVendorDocuments.filter(
      (c) => c.id !== contractVendorDocumentId
    )
  },
  [types.CONTRACT_VENDOR_DOCUMENT_UPDATE_OR_ADD](state, contractVendorDocument) {
    const matchedIndex = state.contractVendorDocuments.findIndex(
      (i) => i.id === contractVendorDocument.id
    )
    if (matchedIndex !== -1) {
      // pop it off the array and push a new one so that it triggers a recalc on the ui
      state.contractVendorDocuments.splice(matchedIndex, 1)
    }
    state.contractVendorDocuments.push(contractVendorDocument)
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
