import { useVersionedApi } from '@/composables/api/useVersionedApi'

function CustomerMapper(version) {
  function mapUsersFromVersionedEndpoint(userData) {
    const users = version === 1 ? userData.data.userDTO.values : userData.items

    if (version === 1) return users

    return users
  }

  function mapGroupsFromVersionedEndpoint(groupsData) {
    const groups = version === 1 ? groupsData.data.userGroupDTO.values : groupsData

    if (version === 1) return groups

    return groups.map((group) => {
      const { userIds, ...rest } = group
      return {
        ...rest,
        users: userIds
      }
    })
  }

  return {
    mapUsersFromVersionedEndpoint,
    mapGroupsFromVersionedEndpoint
  }
}

function CustomerService(config) {
  const { api, getEndpointVersion } = useVersionedApi(config)

  async function getUsers(reqParams = {}) {
    const version = getEndpointVersion('api2Customer', 'getUsers')

    const defaultParams = {
      filters: '',
      offset: 0,
      limit: 10000
    }
    const params = { ...defaultParams, ...reqParams }

    let url = `/api/v${version}/customer/{customerId}/Users?filter.fields=permissions`
    if (params.filters?.length) url += `,${params.filters}`
    if (version !== 1) {
      url += `&offset=${params.offset}&limit=${params.limit}`
    }

    const response = await api.get(url)

    return CustomerMapper(version).mapUsersFromVersionedEndpoint(response.data)
  }

  async function getUserGroups() {
    const version = getEndpointVersion('api2Customer', 'getUserGroups')
    const url = `/api/v${version}/customer/{customerId}/node/{nodeId}/UserGroups?filter.fields=Users,ProPermissions`

    const response = await api.get(url)

    return CustomerMapper(version).mapGroupsFromVersionedEndpoint(response.data)
  }

  return {
    getUsers,
    getUserGroups
  }
}

export default CustomerService
