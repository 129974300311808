export const validEmail = (val, options) => {
  // eslint-disable-next-line no-undefined
  if (options === undefined || options === null) {
    options = {
      asMulti: false,
      mandatory: false
    }
  }

  // Null value is valid if not mandatory
  if (val === null && !options.mandatory) return true
  else if (val === null && options.mandatory) val = ''

  // allow blank strings
  if (!options.mandatory && (val === null || val.trim().length === 0)) return true

  const emailRegexPattern = new RegExp(
    /^[a-zA-Z0-9']+([._-]?[a-zA-Z0-9']+)*@[a-zA-Z0-9']+([._-][a-zA-Z0-9]+)*(\.[a-zA-Z]{2,})+$/
  )
  let isValid =
    !val.includes(';,') && !val.includes(',;') && !val.includes(',,') && !val.includes(';;')

  if (!isValid) return false

  if (options.asMulti) {
    const splitVal = val.split(/[\s,;]+/)

    for (let ii = 0; ii < splitVal.length; ii++) {
      const innerVal = splitVal[ii]
      const cleanVal = innerVal.replace(/\s/g, '')

      isValid = (!cleanVal || emailRegexPattern.test(cleanVal)) && cleanVal.length > 0

      if (!isValid) {
        break
      }
    }
  } else {
    isValid = emailRegexPattern.test(val)
  }

  return isValid
}

export default {
  validEmail
}
