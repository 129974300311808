import { api as defaultApi } from '@/api/api'
import { useStore } from '@/store'

export function useVersionedApi({ api = defaultApi, store = null } = {}) {
  const $store = store || useStore()

  function getEndpointVersion(feature, module = null) {
    // Check the feature flag for the given feature.
    // If the feature flag is true, use version 2 of the endpoint.
    // Otherwise, use version 1.

    if (!module) {
      return $store.getters.getFeatureEnabled(feature).enabled ? 2 : 1
    }

    return $store.getters.getModuleEnabled(feature, module).enabled ? 2 : 1
  }

  // Some returns are for testing purposes only.
  // consider checking against import.meta.env.MODE before returning
  return {
    api,
    store: $store,
    getEndpointVersion
  }
}
