<template>
  <el-dialog
    :visible="visible"
    :width="dialogWidth"
    append-to-body
    :show-close="true"
    @close="confirmClose"
  >
    <div class="icon-container">
      <i class="el-icon-warning"></i>
    </div>
    <div class="title-container">
      <app-title heading="2" size="3">
        {{ title }}
      </app-title>
    </div>
    <label class="center-label">
      {{ description }}
    </label>
    <div slot="footer" class="footer-container">
      <app-button type="primary" class="center-button" minwidth @click="confirmCloseAndSave">
        {{ $t('ui.common.confirm') }}
      </app-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'app-dialogbox',
  props: ['visible', 'title', 'description', 'close', 'save'],
  data() {
    return {
      dialogWidth: '40%'
    }
  },
  methods: {
    confirmCloseAndSave() {
      this.$emit('update:visible', false) // Emit event to update the prop
      this.$emit('save') // Emit a 'save' event
    },
    confirmClose() {
      this.$emit('close') // in case it's needed
      this.$emit('update:visible', false) // Emit event to update the prop
    }
  }
}
</script>
<style scoped>
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  color: rgb(0, 113, 131);
  margin-bottom: 10px;
}

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-label {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-container {
  display: flex;
  justify-content: center;
}

.center-button {
  margin-left: auto;
  margin-right: auto;
}
</style>
