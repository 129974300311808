<template>
  <ul
    :class="[
      'is-' + size,
      {
        'has-no-border': !border,
        'has-hover-highlight': highlight,
        'has-no-first-border': !borderFirst,
        'has-no-last-border': !borderLast
      }
    ]"
    class="app-listgroup"
  >
    <slot></slot>
  </ul>
</template>

<script>
export default {
  name: 'listGroup',
  props: {
    size: {
      type: String,
      default: 'medium'
    },
    border: {
      type: Boolean,
      default: true
    },
    borderFirst: {
      type: Boolean,
      default: true
    },
    highlight: {
      type: Boolean,
      default: true
    },
    borderLast: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" src="./app-listgroup.scss"></style>
