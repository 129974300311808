<template>
  <div class="app-grid-pagination">
    <slot name="contentBefore"></slot>
    <span class="is-size-6 has-margin-right-medium">{{
      $t('ui.components.app-grid-pagination.totalRows', { totalRows: totalRows })
    }}</span>
    <span class="is-size-6 has-margin-right-small">{{
      $t('ui.components.app-grid-pagination.pageOf', {
        currentPage: currentPage,
        totalPages: totalPages
      })
    }}</span>
    <app-button
      type="secondary"
      :disabled="currentPage === 1"
      :aria-label="$t('ui.components.app-grid-pagination.goToPreviousPage')"
      class="has-no-minWidth app-grid-pagination-arrow"
      @click.native="handleClickPrev"
    >
      <app-icon icon="chevron-left"></app-icon>
    </app-button>
    <app-button
      type="secondary"
      :aria-label="$t('ui.components.app-grid-pagination.goToNextPage')"
      :disabled="currentPage === totalPages"
      class="has-no-minWidth app-grid-pagination-arrow"
      @click.native="handleClickNext"
    >
      <app-icon icon="chevron-right"></app-icon>
    </app-button>
    <el-dropdown :show-timeout="0" :hide-timeout="0" trigger="click" @command="gridPageSizeChange">
      <app-button type="secondary" class="button" aria-haspopup="listbox">
        <span>{{ pageSize }} / {{ $t('ui.components.app-grid-pagination.page') }}</span>
        <app-icon icon="chevron-down"> </app-icon>
      </app-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item v-for="(size, index) in sizes" :key="size + index" :command="size">
          {{ size }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <slot name="contentAfter"></slot>
  </div>
</template>

<script>
export default {
  name: 'app-grid-pagination',
  props: {
    // eslint-disable-next-line vue/require-prop-types
    pageSize: {
      required: true
    },
    // eslint-disable-next-line vue/require-prop-types
    totalPages: {
      required: true
    },
    // eslint-disable-next-line vue/require-prop-types
    currentPage: {
      required: true
    },
    // eslint-disable-next-line vue/require-prop-types
    totalRows: {
      required: true
    }
  },
  data() {
    return {
      sizes: []
    }
  },
  mounted() {
    this.getSizes()
  },
  methods: {
    gridPageSizeChange(size) {
      this.currentSize = size
      this.$emit('pageSizeChange', size)
    },
    handleClickPrev(event) {
      event.currentTarget.blur()
      this.$emit('clickPrev')
    },
    handleClickNext(event) {
      event.currentTarget.blur()
      this.$emit('clickNext')
    },
    getSizes() {
      for (let i = 0; i < 4; i++) {
        this.sizes.push((i + 1) * this.pageSize)
      }
    }
  }
}
</script>

<style lang="scss" src="./app-grid-pagination.scss"></style>
