import dayjs from '@/utils/dayjs'
import uiHandler from '@/utils/ui'
import { calendarViews, eventTypes } from '@/views/dashboard/dashboard-defs'
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'
import { mapActions, mapGetters } from 'vuex'
import CalendarEvent from './dashboard-important-dates-event.vue'

export default {
  name: 'dashboard-important-dates',
  components: {
    VueCal,
    CalendarEvent
  },
  computed: {
    ...mapGetters(['getDashboardCalendar']),
    eventsList() {
      return this.getDashboardCalendar || []
    },
    filteredEventsCalendarList() {
      return this.eventsList
        .filter((event) => this.selectedProcurementLeads.includes(event.procurementLead))
        .map((event) => {
          return {
            event,
            start: dayjs(event.calendarDate).format('YYYY-MM-DD HH:00'),
            end: dayjs(event.calendarDate).add(1, 'hour').format('YYYY-MM-DD HH:00'),
            class: event.type.includes(this.selectedEventType) ? event.type : 'is-disabled',
            content: `${event.projectNumber} ${event.projectName}`
          }
        })
    },
    currentProcurementLeads() {
      return [...new Set(this.eventsList.map((event) => event.procurementLead))]
    },
    calendarEventsMapByDate() {
      const eventMap = new Map('', [])
      this.filteredEventsCalendarList.forEach((e) => {
        const date = dayjs(e.event.calendarDate).format('MMMM D, YYYY')
        eventMap.set(date, [...(eventMap.get(date) || []), e])
      })

      return eventMap
    },
    eventsToDisplay() {
      if (this.activeView === calendarViews.DAY) {
        return this.filteredEventsCalendarList
      } else {
        const eventsByDate = [...this.calendarEventsMapByDate.values()]
          .map((val) => {
            if (val.length > 3) {
              const newArr = val.slice(0, 3)
              newArr.push({
                start: dayjs(val[0].event.calendarDate).format('YYYY-MM-DD HH:00'),
                end: dayjs(val[0].event.calendarDate).add(1, 'hour').format('YYYY-MM-DD HH:00'),
                class: this.customEventClass,
                content: `<a class="more-element-link">+${val.length - 3} more</a>`
              })
              return newArr
            }
            return val
          })
          .flat()
        return eventsByDate || []
      }
    }
  },
  props: {
    size: String
  },
  watch: {
    currentProcurementLeads(val) {
      this.selectedProcurementLeads = val
    },
    calendarEventsMapByDate(val) {
      this.activeNames = [...Array(val.size).keys()]
    },
    activeView(val) {
      if (val === calendarViews.DAY) {
        setTimeout(() => {
          // scroll to hours * height of each cell
          document
            .querySelector('#vuecal .vuecal__bg')
            .scrollTo({ top: 23 * 40, behavior: 'smooth' })
        }, 500)
      }
    }
  },
  data() {
    return {
      viewString: 'ui.views.dashboard.dashboard-view.',
      selectedProcurementLeads: [],
      selectedEventType: '',
      allProcurementLeadsSelected: true,
      eventTypes,
      activeView: calendarViews.MONTH,
      activeNames: [],
      selectedDate: null,
      customEventClass: 'more-elements'
    }
  },
  methods: {
    ...mapActions(['fetchDashboardCalendar']),
    toggleAllProcurementLeads() {
      this.allProcurementLeadsSelected = !this.allProcurementLeadsSelected
      this.selectedProcurementLeads = []
      if (this.allProcurementLeadsSelected) {
        this.selectedProcurementLeads = [...this.currentProcurementLeads]
      }
    },
    handleViewChange(event) {
      this.$emit('loading', true)

      if (event.startDate && event.endDate) {
        const startDate = dayjs(event.startDate.valueOf()).format('YYYY-MM-DD')
        const endDate = dayjs(event.endDate.valueOf()).format('YYYY-MM-DD')

        this.fetchDashboardCalendar({ startDate: startDate, endDate: endDate })
          .then(() => this.$emit('error', false))
          .catch(() => this.$emit('error', true))
          .finally(() => this.$emit('loading', false))
      }
    },
    onEventClick(event) {
      if (event.class === this.customEventClass) {
        this.activeView = calendarViews.DAY
        this.selectedDate = event.start
      } else if (event.class !== 'is-disabled') {
        this.handleEventClick(event.event)
      }
    },
    handleEventClick(event) {
      uiHandler.showLoading(this)
      this.$router.push(`/projects/${event.projectId}/build/settings`)
    },
    setActiveType(eventType) {
      this.selectedEventType = eventType
    }
  }
}
