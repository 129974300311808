import { api } from '@/api/api'
import * as types from './../../mutation-types.js'

export const state = {
  externalSuppliers: [],
  contractDateList: []
}

export const getters = {
  getExternalSuppliers: (state) => state.externalSuppliers,
  getContractDateList: (state) => state.contractDateList
}

export const actions = {
  addSupplier: (_, params) => {
    const uri = `/api/v1/customer/{customerId}/node/{nodeId}/Contracts/${params.contractId}/Supplier/${params.vendorId}`
    return api.post(uri).then((response) => response.data.data.vendorDTO.values[0])
  },
  deleteSupplier: (_, params) => {
    const uri = `/api/v1/customer/{customerId}/node/{nodeId}/Contracts/${params.contractId}/Supplier/${params.vendorId}`
    return api.delete(uri)
  },
  inviteExternalSupplier: (_, params) => {
    const uri = `/api/v1/customer/{customerId}/node/{nodeId}/ContractVendorInvitations`
    return api.post(uri, params)
  },
  fetchExternalSuppliers: ({ commit }, contractId) => {
    const uri = `/api/v1/customer/{customerId}/node/{nodeId}/ContractVendorInvitations?contractId=${contractId}`
    return api.get(uri).then((response) => {
      const suppliers = response.data.data.contractVendorInvitationDTO.values
      commit(types.CONTRACT_EXTERNAL_SUPPLIERS_SET_ALL, suppliers)
      return suppliers
    })
  },
  deleteExternalSupplier: (_, id) => {
    const uri = `/api/v1/customer/{customerId}/node/{nodeId}/ContractVendorInvitations?contractVendorInvitationId=${id}`
    return api.delete(uri)
  },
  activateSupplier: (_, params) => {
    const uri = `/api/v1/customer/{customerId}/node/{nodeId}/Contracts/${params.contractId}/Supplier/${params.vendorId}/Activate`
    return api.put(uri)
  },
  deactivateSupplier: (_, params) => {
    const uri = `/api/v1/customer/{customerId}/node/{nodeId}/Contracts/${params.contractId}/Supplier/${params.vendorId}/Deactivate`
    return api.put(uri)
  },
  fetchContractDateList: ({ commit }, contractId) => {
    const uri = `/api/v1/customer/{customerId}/node/{nodeId}/Contracts/${contractId}/DateList/`
    return api.get(uri).then((response) => {
      const contractDateList = response.data.data.contractDateDTO.values
      commit(types.CONTRACT_DATELIST_SET_ALL, contractDateList)
      return contractDateList
    })
  }
}

export const mutations = {
  [types.CONTRACT_EXTERNAL_SUPPLIERS_SET_ALL]: (state, suppliers) => {
    state.externalSuppliers = suppliers
  },
  [types.CONTRACT_DATELIST_SET_ALL]: (state, contractDateList) => {
    state.contractDateList = contractDateList
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
