import { useFeatureHelpers } from '@/utils/featureHelpers'
const { featureGateRoute } = useFeatureHelpers()

const docsRoutes = {
  path: '/docs',
  name: 'docs',
  beforeEnter: featureGateRoute('documentation'),
  meta: {
    Auth: true
  },
  component: () => import('@/views/docs/docs-view.vue'),
  children: [
    {
      path: 'styles-intro',
      name: 'styles-intro',
      component: () => import('@/views/docs/app-components/docs-styles-intro')
    },
    {
      path: 'styles-bulma',
      name: 'styles-bulma',
      component: () => import('@/views/docs/app-components/docs-styles-bulma')
    },
    {
      path: 'styles-helpers',
      name: 'styles-helpers',
      component: () => import('@/views/docs/app-components/docs-styles-helpers')
    },
    {
      path: 'styles-animatecss',
      name: 'styles-animatecss',
      component: () => import('@/views/docs/app-components/docs-vue-animate')
    },
    {
      path: 'app-avatar',
      name: 'app-avatar',
      component: () => import('@/views/docs/app-components/docs-avatar')
    },
    {
      path: 'app-panel',
      name: 'app-panel',
      component: () => import('@/views/docs/app-components/docs-panel')
    },
    {
      path: 'app-button',
      name: 'app-button',
      component: () => import('@/views/docs/app-components/docs-button')
    },
    {
      path: 'app-checkbox',
      name: 'app-checkbox',
      component: () => import('@/views/docs/app-components/docs-checkbox')
    },
    {
      path: 'document-preview',
      name: 'document-preview',
      component: () => import('@/views/docs/app-components/docs-document-preview.vue')
    },
    {
      path: 'app-grid',
      name: 'app-grid',
      component: () => import('@/views/docs/app-components/docs-grid')
    },
    {
      path: 'app-icon',
      name: 'app-icon',
      component: () => import('@/views/docs/app-components/docs-icon')
    },
    {
      path: 'app-sidebar',
      name: 'app-sidebar',
      component: () => import('@/views/docs/app-components/docs-sidebar')
    },
    {
      path: 'app-svg',
      name: 'app-svg',
      component: () => import('@/views/docs/app-components/docs-app-svg')
    },
    {
      path: 'app-toolbar',
      name: 'app-toolbar',
      component: () => import('@/views/docs/app-components/docs-toolbar')
    },
    {
      path: 'app-title',
      name: 'app-title',
      component: () => import('@/views/docs/app-components/docs-title')
    },
    {
      path: 'app-subnav',
      name: 'app-subnav',
      component: () => import('@/views/docs/app-components/docs-subnav')
    },
    {
      path: 'app-listgroup',
      name: 'app-listgroup',
      component: () => import('@/views/docs/app-components/docs-listgroup')
    },
    {
      path: 'app-logo',
      name: 'app-logo',
      component: () => import('@/views/docs/app-components/docs-app-logo')
    },
    {
      path: 'app-empty',
      name: 'app-empty',
      component: () => import('@/views/docs/app-components/docs-empty')
    },
    {
      path: 'app-editor',
      name: 'app-editor',
      component: () => import('@/views/docs/app-components/docs-editor')
    },
    {
      path: 'app-reveal',
      name: 'app-reveal',
      component: () => import('@/views/docs/app-components/docs-reveal')
    },
    {
      path: 'azure-blob',
      name: 'azure-blob',
      component: () => import('@/views/docs/features/docs-azure-blob')
    },
    {
      path: 'table-mixins',
      name: 'table-mixins',
      component: () => import('@/views/docs/app-components/docs-table-mixins.vue')
    },
    {
      path: 'el-dropdown',
      name: 'el-dropdown',
      component: () => import('@/views/docs/app-components/docs-el-dropdown')
    },
    {
      path: 'el-tabs',
      name: 'el-tabs',
      component: () => import('@/views/docs/app-components/docs-el-tabs')
    },
    {
      path: 'el-autocomplete',
      name: 'el-autocomplete',
      component: () => import('@/views/docs/app-components/docs-el-autocomplete')
    },
    {
      path: 'el-date-picker',
      name: 'el-date-picker',
      component: () => import('@/views/docs/app-components/docs-el-date-picker')
    },
    {
      path: 'el-time-select',
      name: 'el-time-select',
      component: () => import('@/views/docs/app-components/docs-el-time-select')
    },
    {
      path: 'el-popover',
      name: 'el-popover',
      component: () => import('@/views/docs/app-components/docs-el-popover')
    },
    {
      path: 'el-select',
      name: 'el-select',
      component: () => import('@/views/docs/app-components/docs-el-select')
    },
    {
      path: 'el-switch',
      name: 'el-switch',
      component: () => import('@/views/docs/app-components/docs-el-switch')
    },
    {
      path: 'el-dialog',
      name: 'el-dialog',
      component: () => import('@/views/docs/app-components/docs-el-dialog')
    },
    {
      path: 'el-table',
      name: 'el-table',
      component: () => import('@/views/docs/app-components/docs-el-table')
    },
    {
      path: 'el-intro',
      name: 'el-intro',
      component: () => import('@/views/docs/app-components/docs-el-intro')
    },
    {
      path: 'el-cascader',
      name: 'el-cascader',
      component: () => import('@/views/docs/app-components/docs-el-cascader')
    },
    {
      path: 'wizard',
      name: 'wizard',
      component: () => import('@/views/docs/app-components/docs-app-wizard')
    },
    {
      path: 'wizard-components',
      name: 'wizard-components',
      component: () => import('@/views/docs/app-components/docs-app-wizard-components')
    },
    {
      path: 'wizard-form',
      name: 'wizard-form',
      component: () => import('@/views/docs/app-components/docs-app-wizard-form')
    },
    {
      path: 'app-tree-filter',
      name: 'app-tree-filter',
      component: () => import('@/views/docs/app-components/docs-app-tree-filter')
    },
    {
      path: 'email-wizard',
      name: 'email-wizard',
      component: () => import('@/views/docs/app-components/docs-email-wizard.vue')
    },
    {
      path: 'app-input-currency',
      name: 'app-input-currency',
      component: () => import('@/views/docs/app-components/docs-app-input-currency.vue')
    },
    {
      path: 'app-input-date',
      name: 'app-input-date',
      component: () => import('@/views/docs/app-components/docs-app-input-date.vue')
    },
    {
      path: 'app-input-mask',
      name: 'app-input-mask',
      component: () => import('@/views/docs/app-components/docs-app-input-mask.vue')
    },
    {
      path: 'app-input-file',
      name: 'app-input-file',
      component: () => import('@/views/docs/app-components/docs-app-input-file.vue')
    },
    {
      path: 'app-input-text',
      name: 'app-input-text',
      component: () => import('@/views/docs/app-components/docs-app-input-text.vue')
    },
    {
      path: 'app-input-time',
      name: 'app-input-time',
      component: () => import('@/views/docs/app-components/docs-app-input-time.vue')
    },
    {
      path: 'app-ellipsis',
      name: 'app-ellipsis',
      component: () => import('@/views/docs/app-components/docs-app-ellipsis.vue')
    },
    {
      path: 'app-question-field',
      name: 'app-question-field',
      component: () => import('@/views/docs/app-components/docs-app-question-field')
    },
    {
      path: 'app-select',
      name: 'app-select',
      component: () => import('@/views/docs/app-components/docs-app-select.vue')
    },
    {
      path: 'data-sanitizing',
      name: 'dataSanitizing',
      component: () => import('@/views/docs/utils/docs-data-sanitizing.vue')
    },
    {
      path: 'feature-flagging',
      name: 'featureFlagging',
      component: () => import('@/views/docs/features/docs-feature-flagging.vue')
    },
    {
      path: 'input-permissions',
      name: 'inputPermissions',
      component: () => import('@/views/docs/composables/docs-input-permissions.vue')
    },
    {
      path: 'vue-2-7',
      name: 'vue-2-7',
      component: () => import('@/views/docs/docs-vue-2-7.vue')
    },
    {
      path: 'evaluation-tree',
      name: 'evaluation-tree',
      component: () => import('@/views/docs/docs-evaluation-tree.vue')
    },
    {
      path: 'hot',
      name: 'hot',
      component: () => import('@/views/docs/vendor/docs-hot-view.vue')
    },
    {
      path: '*',
      component: () => import('@/views/docs/app-components/docs-el-intro')
    }
  ]
}

docsRoutes.children.forEach((route) => {
  route.beforeEnter = featureGateRoute('documentation')
})

export default docsRoutes
