<template>
  <div class="email-step__sent email-step has-text-centered">
    <figure>
      <img :src="emailSentImage" />
    </figure>
    <section v-if="hasSendDate" class="has-margin-bottom">
      <app-title centered size="3" heading="3" class="has-text-centered">
        {{ $t(viewString + 'titleSentDate') }}
      </app-title>
      <strong>{{ sendDate | dateString }}</strong>
    </section>
    <section v-else class="has-margin-bottom">
      <app-title centered size="3" heading="3">
        {{ $t(viewString + 'titleSent') }}
      </app-title>
    </section>
    <section class="has-margin-bottom">
      <p class="has-margin-bottom">
        {{ $t(viewString + 'introTextSent') }}
      </p>
      <app-field>
        <app-button class="has-min-width" @click="handleClickClose">
          {{ $t('ui.common.close') }}
        </app-button>
      </app-field>
    </section>
  </div>
</template>

<script>
import emailWizardMixins from '@/views/email-wizard/email-wizard-mixins.js'
import UseImages from '@/composables/useImages.js'

const { emailSentImage } = UseImages()

export default {
  name: 'email-wizard-step-sent',
  mixins: [emailWizardMixins],
  data() {
    return {
      emailSentImage
    }
  },
  computed: {
    sendDate() {
      // return new Date().getTime()
      return this.email.sendDate
    },
    hasSendDate() {
      return this.email.sendWhen !== this.sendOptions.NOW
    }
  },
  methods: {
    handleClickClose() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.email-step__sent {
  figure {
    width: 200px;
    margin: 0 auto $gap;
  }
}
</style>
