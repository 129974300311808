/* eslint-disable no-useless-escape */
import DOMPurify from 'dompurify'

// default implicitly blocked tags
export const FORBID_TAGS = [
  'base',
  'basefont',
  'command',
  'embed',
  'frame',
  'frameset',
  'iframe',
  'keygen',
  'link',
  'meta',
  'noframes',
  'noscript',
  'object',
  'param',
  'script',
  'title'
]
// default implicitly blocked attributes
export const FORBID_ATTR = [
  'accept-charset',
  'accesskey',
  'allow',
  'async',
  'autocapitalize',
  'autofocus',
  'autoplay',
  'buffered',
  'challenge',
  'charset',
  'code',
  'codebase',
  'content',
  'contenteditable',
  'contextmenu',
  'codebase',
  'content',
  'contenteditable',
  'contextmenu',
  'controls',
  'data',
  'decoding',
  'defer',
  'dirname',
  'draggable',
  'dropzone',
  'form',
  'formaction',
  'http-equiv',
  'icon',
  'importance',
  'itemprop',
  'keytype',
  'kind',
  'language',
  'lazyload',
  'manifest',
  'minlength',
  'muted',
  'ping',
  'sandbox',
  'scoped',
  'slot',
  'spellcheck',
  'srcdoc',
  'srclang',
  'start',
  'target',
  'translate',
  'wrap'
]

const nukeConfig = {
  ALLOWED_TAGS: [],
  ALLOWED_ATTR: [],
  ALLOW_DATA_ATTR: false,
  ALLOWED_URI_REGEXP:
    /^(?:(?:(?:f|ht)tps?|mailto|tel|callto|cid|xmpp|feed|git|svn|ssh|telnet|data):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))/i,
  ALLOWED_SOURCES: [],
  ALLOWED_SVG_ATTR: false,
  ALLOWED_TAGS_REGEXP: /^$/,
  FORBID_ATTR: [],
  FORBID_TAGS: ['all'],
  RETURN_DOM: false,
  RETURN_DOM_FRAGMENT: false,
  RETURN_DOM_IMPORT: false,
  RETURN_TRUSTED_TYPE: false,
  SANITIZE_DOM: false,
  WHOLE_DOCUMENT: false
}

export function useDataSanitizing() {
  function sanitizeData(data, options = {}) {
    const optionsToUse = { ...nukeConfig, ...options }
    // default to no tags or attrs allowed
    return DOMPurify.sanitize(data, optionsToUse)
  }

  function sanitizeHTMLData(data, options = {}) {
    const optionsToUse = {
      ALLOWED_URI_REGEXP:
        /^(?:(?:(?:f|ht)tps?|mailto|tel|callto|cid|xmpp|feed|git|svn|ssh|telnet):|data:image\/[^;]+;base64)/i,
      // FORBID_TAGS: [],
      // FORBID_ATTR: [],
      // WHOLE_DOCUMENT: true,
      // SANITIZE_DOM: false,
      FORCE_BODY: true,
      ADD_TAGS: ['head', 'meta', 'title'],
      ADD_ATTR: [
        'content',
        'Content',
        'http-equiv',
        'property',
        'name',
        'charset',
        'initial-scale',
        'viewport'
      ],
      ALLOW_DATA_ATTR: true,
      ALLOW_UNKNOWN_PROTOCOLS: true,
      ...options
    }

    return DOMPurify.sanitize(data, optionsToUse)
  }

  return { sanitizeData, sanitizeHTMLData }
}
