<template>
  <div class="view-project-bus"></div>
</template>

<script>
import uiHandler from '@/utils/ui'
export default {
  mounted() {
    uiHandler.showLoading(this)
  },
  beforeDestroy() {
    uiHandler.hideLoading(this)
  }
}
</script>

<style lang="scss">
.view-project-bus {
  min-height: 100vh;
  width: 100%;
}
</style>
