<template>
  <div class="app-panel-header">
    <app-button
      v-if="showBack"
      class="is-large is-clear has-margin-right-large"
      @click.native="handleClickBack"
    >
      <app-icon size="medium" icon="chevron-left"></app-icon>
      <span class="is-size-6">{{ backText }}</span>
    </app-button>
    <div v-if="hasTitle" class="app-panel-title">
      <slot name="title"></slot>
      <div v-if="hasSuffix" class="app-panel-title-append">
        <slot name="titleAppend"></slot>
      </div>
    </div>
    <div v-if="hasActions" class="app-panel-actions">
      <div class="app-panel-actions-left">
        <slot name="actions"></slot>
      </div>
      <div v-if="hasActionsRight" class="app-panel-actions-right">
        <slot name="actionsRight"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-panel-actions',
  props: {
    showBack: {
      type: Boolean,
      default: false
    },
    backText: {
      type: String,
      default: 'Back'
    }
  },
  data() {
    return {}
  },
  computed: {
    hasTitle() {
      return this.$slots.title
    },
    hasActions() {
      return this.$slots.actions || this.$slots.actionsRight
    },
    hasActionsRight() {
      return this.$slots.actionsRight
    },
    hasSuffix() {
      return this.$slots.titleAppend
    }
  },
  methods: {
    handleClickBack() {
      this.$router.go(-1)
    }
  }
}
</script>
