import { api, uploadApi } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  contractDocuments: []
}

export const getters = {
  getContractDocuments: (state) => state.contractDocuments,
  getContractDocument: (state) => (id) => {
    return state.contractDocuments.find((c) => c.id === id)
  }
}
export const actions = {
  fetchContractDocuments: ({ commit }, contractId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Contract/${contractId}/ContractDocuments`
    return api.get(url).then((response) => {
      let documents = []
      if (response.data.count > 0) {
        documents = response.data.data.contractDocumentDTO.values
        commit(types.CONTRACT_DOCUMENTS_SET, documents)
      }
      return documents
    })
  },
  createContractDocument: ({ commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Contract/${model.contractId}/ContractDocuments`
    return uploadApi.post(url, model.formData).then((response) => {
      let contractDocument = {}
      if (response.data.count > 0) {
        contractDocument = response.data.data.contractDocumentDTO.values[0]
        commit(types.CONTRACT_DOCUMENT_UPDATE_OR_ADD, contractDocument)
      }
      return contractDocument
    })
  },
  updateContractDocument: ({ commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Contract/${model.contractId}/ContractDocuments`
    return api.put(url, model.model).then((response) => {
      let contractDocument = {}
      if (response.data.count > 0) {
        contractDocument = response.data.data.contractDocumentDTO.values[0]
        commit(types.CONTRACT_DOCUMENT_UPDATE_OR_ADD, contractDocument)
      }
      return contractDocument
    })
  },
  deleteContractDocument: ({ commit }, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Contract/${params.contractId}/ContractDocuments/${params.documentId}`
    return api.delete(url).then(() => {
      commit(types.CONTRACT_DOCUMENTS_DELETE, params.documentId)
      return ''
    })
  }
}
export const mutations = {
  [types.CONTRACT_DOCUMENTS_SET](state, documents) {
    state.contractDocuments = documents
  },
  [types.CONTRACT_DOCUMENTS_DELETE](state, contractDocumentId) {
    state.contractDocuments = state.contractDocuments.filter((cd) => cd.id !== contractDocumentId)
  },
  [types.CONTRACT_DOCUMENT_UPDATE_OR_ADD](state, contractDocument) {
    const matchedIndex = state.contractDocuments.findIndex((c) => c.id === contractDocument.id)
    if (matchedIndex !== -1) {
      // pop it off the array and push a new one so that it triggers a recalc on the ui
      state.contractDocuments.splice(matchedIndex, 1)
    }
    state.contractDocuments.push(contractDocument)
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
