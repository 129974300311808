import { api, uploadApi } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  certificates: []
}

export const getters = {
  getCertificates: (state) => state.certificates,
  getCertificate: (state) => (id) => {
    return state.certificates.find((c) => c.id === id)
  }
}
export const actions = {
  fetchCertificates: ({ commit }, contractId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Certificates?contractId=${contractId}&filter.fields=Vendor`
    return api.get(url).then((response) => {
      let certificates = []
      if (response.data.count > 0) {
        certificates = response.data.data.certificateDTO.values
      }
      commit(types.CERTIFICATES_SET, certificates)
      return certificates
    })
  },
  fetchCertificate: ({ commit }, id) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Certificates/${id}`
    return api.get(url).then((response) => {
      let certificates = []
      if (response.data.count > 0) {
        certificates = response.data.data.certificateDTO.values
      }
      commit(types.CERTIFICATES_SET, certificates)
      return certificates
    })
  },
  createCertificate: ({ commit }, formData) => {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/Certificates/'
    return uploadApi.post(url, formData).then((response) => {
      let certificate = {}
      if (response.data.count > 0) {
        certificate = response.data.data.certificateDTO.values[0]
        commit(types.CERTIFICATE_UPDATE_OR_ADD, certificate)
      }
      return certificate
    })
  },
  updateCertificate: ({ commit }, model) => {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/Certificates/'
    return api.put(url, model.model).then((response) => {
      let certificate = {}
      if (response.data.count > 0) {
        certificate = response.data.data.certificateDTO.values[0]
        commit(types.CERTIFICATE_UPDATE_OR_ADD, certificate)
      }
      return certificate
    })
  },
  deleteCertificate: ({ commit }, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Certificates/${params.documentId}`
    return api.delete(url).then(() => {
      commit(types.CERTIFICATE_DELETE, params.documentId)
      return ''
    })
  },
  setCertificate: ({ commit }, model) => {
    commit(types.CERTIFICATES_SET, model)
  }
}
export const mutations = {
  [types.CERTIFICATES_SET](state, certificates) {
    state.certificates = certificates
  },
  [types.CERTIFICATE_UPDATE_OR_ADD](state, certificate) {
    const matchedIndex = state.certificates.findIndex((c) => c.id === certificate.id)
    if (matchedIndex !== -1) {
      // pop it off the array and push a new one so that it triggers a recalc on the ui
      state.certificates.splice(matchedIndex, 1)
      state.certificates.push(certificate)
    } else {
      state.certificates.push(certificate)
    }
  },
  [types.CERTIFICATE_DELETE](state, certificateId) {
    const matchedIndex = state.certificates.findIndex((c) => c.id === certificateId)
    if (matchedIndex !== -1) {
      state.certificates.splice(matchedIndex, 1)
    } else {
      const err = 'Mismatch certificate id'
      throw err
    }
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
