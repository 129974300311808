import dayjs from 'dayjs'

// Add any plugins you want to use with Day.js
import 'dayjs/locale/en'
import arraySupport from 'dayjs/plugin/arraySupport'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(arraySupport)
dayjs.locale('en')

export default dayjs
