<template>
  <div class="agency-purchase-history-container">
    <app-title size="5" heading="2" class="has-margin-left-tiny has-no-margin-bottom">
      {{ $t(viewString + 'annualBilling') }}
    </app-title>

    <app-grid class="has-border has-padding-small">
      <div slot="actionsRight" class="is-flex">
        <app-grid-actions
          :grid-page-size="gridPageSize"
          :grid-current-page="gridCurrentPage"
          :grid-total-pages="gridTotalPages"
          :grid-total-rows="gridTotalRows"
          :column-defs="columnDefs"
          :has-export="true"
          @handleClickPrev="handleClickPrev"
          @handleClickNext="handleClickNext"
          @handleGridPageSizeChange="handleGridPageSizeChange"
          @handleExport="handleExport"
        >
        </app-grid-actions>
      </div>
      <el-table
        ref="transactionTable"
        :data="activitiesByPage"
        stripe
        fit
        width="100%"
        @sort-change="handleSortChange"
      >
        <el-table-column
          v-for="column in filteredColumnDefs"
          :key="column.field"
          :prop="column.field"
          :label="column.columnHeading"
          sortable
        >
          <template slot-scope="props">
            <span v-if="column.field === 'billingStartDate' || column.field === 'billingEndDate'">{{
              props.row[column.field] | dateStringShort
            }}</span>
            <span v-else>{{ props.row[column.field] }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div slot="actionsBottomRight" class="is-flex">
        <app-grid-actions
          :grid-page-size="gridPageSize"
          :grid-current-page="gridCurrentPage"
          :grid-total-pages="gridTotalPages"
          :grid-total-rows="gridTotalRows"
          :column-defs="columnDefs"
          :has-export="true"
          @handleClickPrev="handleClickPrev"
          @handleClickNext="handleClickNext"
          @handleGridPageSizeChange="handleGridPageSizeChange"
          @handleExport="handleExport"
        >
        </app-grid-actions>
      </div>
    </app-grid>
  </div>
</template>

<script>
import { exportTableToXLS } from '@/utils/exportTable'
import tableMixins from '@/mixins/table-mixins.js'

export default {
  name: 'agency-purchase-history',
  mixins: [tableMixins],
  props: {
    viewString: String,
    annualBillingData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      annualBillingList: this.annualBillingData,
      gridCurrentPage: 1,
      gridPageSize: 20,
      columnDefs: [
        {
          columnHeading: this.$t(`ui.views.dashboard.contract-builder-dashboard-view.status`),
          field: 'status',
          visible: true
        },
        {
          columnHeading: this.$t(
            `ui.views.dashboard.contract-builder-dashboard-view.billingStartDate`
          ),
          field: 'startDate',
          visible: true
        },
        {
          columnHeading: this.$t(
            `ui.views.dashboard.contract-builder-dashboard-view.billingEndDate`
          ),
          field: 'endDate',
          visible: true
        },
        {
          columnHeading: this.$t(`ui.views.dashboard.contract-builder-dashboard-view.purchased`),
          field: 'purchased',
          visible: true
        },
        {
          columnHeading: this.$t(
            `ui.views.dashboard.contract-builder-dashboard-view.completedTransactions`
          ),
          field: 'transactionUsed',
          visible: true
        },
        {
          columnHeading: this.$t(`ui.views.dashboard.contract-builder-dashboard-view.remaining`),
          field: 'remaining',
          visible: true
        }
      ]
    }
  },
  computed: {
    gridTotalRows() {
      return this.annualBillingList.length
    },
    gridTotalPages() {
      return Math.ceil(this.annualBillingList.length / this.gridPageSize)
    },
    activitiesByPage() {
      const start = (this.gridCurrentPage - 1) * this.gridPageSize
      return this.annualBillingList.slice(start, start + this.gridPageSize)
    },
    filteredColumnDefs() {
      return this.columnDefs.filter((col) => col.visible)
    }
  },
  methods: {
    handleSortChange(row) {
      this.annualBillingList = this.standardTableSortWithDate(row, this.annualBillingList, [
        'startDate',
        'endDate'
      ])
    },
    handleExport() {
      exportTableToXLS('Annual Billing', this.annualBillingList, this.visibleColumns)
    }
  }
}
</script>
