<script setup>
const emit = defineEmits(['click'])

const props = defineProps({
  reverse: {
    type: Boolean,
    default: false
  }
})

function handleClick() {
  emit('click')
}
</script>

<template>
  <!-- accessible close button -->
  <a
    class="app-close-button"
    role="button"
    v-bind="$attrs"
    tabindex="0"
    :class="{ 'is-color-reverse': props.reverse }"
    @click="handleClick"
    @keydown.enter="handleClick"
  >
    <span>{{ $t('ui.common.close') }}</span>
    <app-icon icon="x" aria-hidden="true" />
  </a>
</template>

<style lang="scss" scoped>
.app-close-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  font-size: 1rem;
  background: none;
  padding: $gap-tiny;
  cursor: pointer;

  &:focus,
  &:hover {
    span {
      text-decoration: underline;
    }
  }
}

.app-close-button.is-color-reverse {
  color: white;

  &:hover,
  &:focus {
    border-color: white;
  }
}
</style>
