import { api, downloadApi, uploadApiMultipart } from '@/api/api'
import util from '@/utils/utility.js'
import * as types from '../../mutation-types'

export const state = {
  contractSignature: null, // sigle Contract Signature
  contractSignatures: [], // all Contract Signature
  fetchRequired: false
}

export const getters = {
  getContractSignature: (state) => state.contractSignature,
  getContractSignatures: (state) => state.contractSignatures,
  contractSignaturesFetchRequired: (state) => state.fetchRequired
}

export const actions = {
  lookupEmailRecipient: (_, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Contracts/${params.contractId}/ContractSignatures/LookupEmailRecipient?email=${params.email}`
    return api.get(url)
  },
  fetchContractSignature: ({ commit }, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Contracts/${params.contractId}/ContractSignatures/${params.contractSignatureId}`

    return api.get(url).then((response) => {
      const result = response?.data?.data?.searchResult?.values?.[0] || []
      commit(types.CONTRACT_SIGNATURE_SET, result)
      return result
    })
  },
  fetchContractSignatures: ({ commit }, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Contracts/${params.contractId}/ContractSignatures/All`

    commit(types.CONTRACT_SIGNATURES_FETCH_REQUIRED_SET, false)

    return api.get(url).then((response) => {
      const result = response?.data?.data?.searchResult?.values || []
      commit(types.CONTRACT_SIGNATURES_SET, result)
      return result
    })
  },
  fetchContractSignatureDesigner: (_, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Contracts/${params.contractId}/ContractSignatures/${params.contractSignatureId}/Designer`

    return api.get(url).then((response) => {
      const contractSignature = response?.data?.data?.searchResult?.values?.[0] || ''
      return contractSignature
    })
  },
  saveContractSignature: ({ commit }, payload) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Contracts/${payload.contractId}/ContractSignatures`
    const { formData } = payload

    return uploadApiMultipart.post(url, formData).then((response) => {
      const result = response?.data?.data?.searchResult?.values?.[0] || []
      commit(types.CONTRACT_SIGNATURE_SET, result)
      return result
    })
  },
  contractSignatureDetailsUpdate: ({ commit }, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Contracts/${params.contractId}/ContractSignatures/${params.contractSignatureId}/Details`
    const { details } = params

    return api.put(url, details).then((response) => {
      const result = response?.data?.data?.searchResult?.values?.[0] || []
      commit(types.CONTRACT_SIGNATURE_SET, result)
      return result
    })
  },
  contractSignatureSignerAdd: ({ commit }, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Contracts/${params.contractId}/ContractSignatures/${params.contractSignatureId}/Signers`
    const { signer } = params

    return api.post(url, signer).then((response) => {
      const result = response?.data?.data?.searchResult?.values?.[0] || []
      commit(types.CONTRACT_SIGNATURE_SET, result)
      return result
    })
  },
  contractSignatureSignerUpdate: ({ commit }, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Contracts/${params.contractId}/ContractSignatures/${params.contractSignatureId}/Signers`
    const { signer } = params

    return api.put(url, signer).then((response) => {
      const result = response?.data?.data?.searchResult?.values?.[0] || []
      commit(types.CONTRACT_SIGNATURE_SET, result)
      return result
    })
  },
  contractSignatureSignerDelete: ({ commit }, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Contracts/${params.contractId}/ContractSignatures/${params.contractSignatureId}/Signers/${params.contractSignatureSignerId}`

    return api.delete(url).then((response) => {
      const result = response?.data?.data?.searchResult?.values?.[0] || []
      commit(types.CONTRACT_SIGNATURE_SET, result)
      return result
    })
  },
  contractSignatureSignerSortOrder: ({ commit }, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Contracts/${params.contractId}/ContractSignatures/${params.contractSignatureId}/Signers/SortOrder`
    const { signers } = params

    return api.put(url, signers).then((response) => {
      const result = response?.data?.data?.searchResult?.values?.[0] || []
      commit(types.CONTRACT_SIGNATURE_SET, result)
      return result
    })
  },
  contractSignatureSignerEmailResend: (_, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Contracts/${params.contractId}/ContractSignatures/${params.contractSignatureId}/Signers/${params.contractSignatureSignerId}/Email/Resend`

    return api.post(url).then((response) => {
      const result = response?.data?.data?.searchResult?.values?.[0] || []
      return result
    })
  },
  contractSignatureDocumentAdd: ({ commit }, payload) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Contracts/${payload.contractId}/ContractSignatures/${payload.contractSignatureId}/Documents`
    const { formData } = payload

    return uploadApiMultipart.post(url, formData).then((response) => {
      const result = response?.data?.data?.searchResult?.values?.[0] || []
      commit(types.CONTRACT_SIGNATURE_SET, result)
      return result
    })
  },
  contractSignatureDocumentDelete: ({ commit }, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Contracts/${params.contractId}/ContractSignatures/${params.contractSignatureId}/Documents/${params.contractSignatureDocumentId}`

    return api.delete(url).then((response) => {
      const result = response?.data?.data?.searchResult?.values?.[0] || []
      commit(types.CONTRACT_SIGNATURE_SET, result)
      return result
    })
  },
  contractSignatureDocumentSortOrder: ({ commit }, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Contracts/${params.contractId}/ContractSignatures/${params.contractSignatureId}/Documents/SortOrder`
    const { documents } = params

    return api.put(url, documents).then((response) => {
      const result = response?.data?.data?.searchResult?.values?.[0] || []
      commit(types.CONTRACT_SIGNATURE_SET, result)
      return result
    })
  },
  deleteContractSignature: (_, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Contracts/${params.contractId}/ContractSignatures/${params.contractSignatureId}`

    return api.delete(url)
  },
  contractSignatureDocumentDownloadAll: (_, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Contracts/${params.contractId}/ContractSignatures/${params.contractSignatureId}/Documents/Download/All`

    return downloadApi.get(url).then(util.forceFileDownload)
  },
  contractSignatureDocumentPreview: (_, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Contracts/${params.contractId}/ContractSignatures/${params.contractSignatureId}/Documents/${params.documentId}/Preview`

    return downloadApi.get(url).then(util.getBlobUrlFomApiResponse)
  },
  contractSignatureDocumentDownloadSummary: (_, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Contracts/${params.contractId}/ContractSignatures/${params.contractSignatureId}/Documents/Download/Summary`

    return downloadApi.get(url).then(util.forceFileDownload)
  },
  contractSignatureStatusUpdate: ({ commit }, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Contracts/${params.contractId}/ContractSignatures/${params.contractSignatureId}/Status/${params.status}`

    return api.put(url).then((response) => {
      const result = response?.data?.data?.searchResult?.values?.[0] || []
      commit(types.CONTRACT_SIGNATURE_SET, result)
      return result
    })
  },
  resetContractSignatures: ({ commit }) => {
    commit(types.CONTRACT_SIGNATURES_SET, [])
  },
  setContractSignature: ({ commit }, contractSignature) => {
    commit(types.CONTRACT_SIGNATURE_SET, contractSignature)
  },
  setContractSignaturesFetchRequired: ({ commit }, isRequired) => {
    commit(types.CONTRACT_SIGNATURES_FETCH_REQUIRED_SET, isRequired)
  }
}

export const mutations = {
  [types.CONTRACT_SIGNATURE_SET](state, contractSignature) {
    state.contractSignature = contractSignature
  },
  [types.CONTRACT_SIGNATURES_SET](state, contractSignatures) {
    state.contractSignatures = contractSignatures
  },
  [types.CONTRACT_SIGNATURES_FETCH_REQUIRED_SET](state, isRequired) {
    state.fetchRequired = isRequired
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
