import { projectSearchApi } from '@/composables/api/projectSearchApi'
import { useVersionedApi } from '@/composables/api/useVersionedApi'

function mapSearchResults(version, returnedData) {
  const data = returnedData?.count > 0 ? returnedData?.data?.searchResult?.values : []
  const { errors, status } = returnedData

  if (version === 1) {
    return {
      data,
      count: returnedData?.data?.searchResult?.count,
      errors,
      status
    }
  } else {
    return {
      data,
      count: returnedData?.data?.searchResult?.count,
      errors,
      status
    }
  }
}

function ProjectService(config) {
  const { api, getEndpointVersion } = useVersionedApi(config)

  async function searchProjects(params = {}) {
    const version = getEndpointVersion('api2Project', 'projectSearch')

    const url =
      version === 1
        ? '/api/v1/customer/{customerId}/node/{nodeId}/Projects/Search'
        : '/api/v2/customer/{customerId}/node/{nodeId}/Projects/Search'

    const filter = projectSearchApi().buildSearchFilters(params)

    const modifiedCriteriaForApi = projectSearchApi().getModifiedFiltersForApi(
      filter.searchCriteria || {}
    )

    const response = await api.post(url, { ...filter, searchCriteria: modifiedCriteriaForApi })

    const mappedResults = mapSearchResults(version, response.data)
    return { ...mappedResults, filter }
  }

  return {
    searchProjects
  }
}

export default ProjectService
