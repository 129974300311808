<template>
  <div v-if="appInitialized" id="app">
    <app-bar v-if="showAppBar"></app-bar>
    <router-view />
  </div>
</template>

<script>
import AppBar from '@/components/app-bar/app-bar.vue'
import PermissionsMixins from '@/security/permissions/permissions-mixins.js'
import errorHandler from '@/utils/error.js'
import { createCompletedSubmissionsAllNotification } from '@/utils/notification.js'
import uiHandler from '@/utils/ui.js'
import { mapActions, mapGetters } from 'vuex'

// store the context for this component
// signalr needs it to make calls from inside callbacks
let thisComponent

export default {
  name: 'app',
  components: { AppBar },
  mixins: [PermissionsMixins],
  data() {
    return {
      appData: {},
      redirectPath: null,
      appInitialized: false,
      loginInstance: null,
      scrollTimer: null
    }
  },
  computed: {
    ...mapGetters([
      'getLoggedInUser',
      'getRequestedTenders',
      'getReadyRequestedTenders',
      'getCustomer',
      'getIsSiteAdmin'
    ]),
    requestedTenders() {
      return this.getRequestedTenders
    },
    readyRequestedTenders() {
      return this.getReadyRequestedTenders
    },
    showAppBar() {
      return this.$store.getters.getShowAppBar
    }
  },
  watch: {
    $route(newPath) {
      // clear the current project if it isn't required
      let path = null
      let clearProject = false
      if (newPath.matched && newPath.matched.length) {
        path = newPath.matched[0].path
        clearProject = !path.match('/projects')
      }

      if (clearProject) {
        this.resetProject()
      }
      if (this.getCustomer === null) {
        this.ensureCustomerIsStored()
      }
    }
  },
  mounted() {
    thisComponent = this
    this.redirectPath = this.$route.path

    this.handleAlerts()

    // Add vendor scripts
    // eslint-disable-next-line no-undef
    if (import.meta.env.MODE !== 'development' && !import.meta.env.VITE_IS_LOCAL) {
      this.signalrInit()
      this.addWalkmeScripts()
      this.addJiraScripts()
    }

    this.init()
  },
  methods: {
    // global window resize event with debounce function
    ...mapActions([
      'resetProject',
      'fetchFeedback',
      'fetchCustomerAll',
      'fetchCustomer',
      'setCustomerTimeZone',
      'fetchHosts',
      'downloadCompletedSubmissionsAll',
      'setCompletedSubmissionsAllReady',
      'addAllSubmissionsNotification',
      'addCompletedSubmissionsAll',
      'removeCompletedSubmissionsAll',
      'restoreCompletedSubmissionsAll',
      'setContractSignaturesFetchRequired'
    ]),
    handleExpiredSession(context) {
      context.$store
        .dispatch('setAuthStatus', false)
        .then(() => {
          uiHandler.hideLoading(this)
          context.$router.push({ path: '/login' })
        })
        .finally(() => {
          // prevent sticking
          window.location.reload()
        })
    },
    handleScroll(e) {
      if (e.target.classList.contains('on-scrollbar') === false) {
        e.target.classList.add('on-scrollbar')
      }
      if (this.scrollTimer) {
        clearTimeout(this.scrollTimer)
        this.scrollTimer = null
      }
      this.scrollTimer = setTimeout(() => {
        e.target.classList.add('on-remove-scrollbar')
        setTimeout(() => {
          e.target.classList.remove('on-scrollbar', 'on-remove-scrollbar')
        }, 400)
      }, 300)
    },
    init() {
      // check if the session is flagged as authorized
      if (this.$store.getters.isAuthorized === true) {
        uiHandler.showLoading(this)
        // check local storage for userID and customerID
        const userId = (this.$store.getters.getAuthUser || {}).id || '' // localStorage.getItem('userId')
        const customerId = (this.$store.getters.getAuthCustomer || {}).id || '' // localStorage.getItem('customerId')
        const tenderNodeId = (this.$store.getters.getAuthTenderNode || {}).id || '' // localStorage.getItem('nodeId')
        const customerNodeId = (this.$store.getters.getAuthCustomerNode || {}).id || '' // localStorage.getItem('customerNodeId')
        // session is marked as authenticated and userId and customerID are present
        this.$store
          .dispatch('validateSession')
          .then(() => {
            if (userId && customerId) {
              // valid session
              Promise.all([
                this.$store.dispatch('fetchRoutePermission'),
                this.$store.dispatch('fetchUser', userId),
                this.$store.dispatch('fetchGroups'),
                this.$store.dispatch('fetchCustomer', customerId),
                this.$store.dispatch('fetchTenderNode', tenderNodeId),
                this.$store.dispatch('fetchCustomerNode', customerNodeId)
              ])
                .then(() => {
                  this.fetchHosts()
                  if (this.getIsSiteAdmin) {
                    this.fetchCustomerAll()
                  }
                  const customer = this.getCustomer
                  this.setCustomerTimeZone(customer.timeZoneDetail.code)
                  // check permission when user refresh the browser
                  this.checkRoutePermission(this.$store, this.$route)
                  uiHandler.hideLoading(this)
                })
                .catch((e) => {
                  uiHandler.hideLoading(this)
                  errorHandler.handleApiError(this, e)
                })
                .finally(() => {
                  this.appInitialized = true
                })
            } else {
              this.handleExpiredSession(this)
            }
          })
          .catch((e) => {
            errorHandler.handleApiError(this, e)
            this.handleExpiredSession(this)
          })
      } else {
        this.appInitialized = true
      }
    },
    signalrInit() {
      // establish a signalr connection to our server
      // eslint-disable-next-line no-undef
      const connection = $.hubConnection()
      connection.serverTimeoutInMilliseconds = 10000000
      connection.url = `https://service.stage.bidsandtenders.org` + `/signalr`

      connection.error(function (error) {
        // eslint-disable-next-line no-console
        console.log(`SignalR error: ${error}`)
      })

      connection.disconnected(function () {
        // eslint-disable-next-line no-console
        console.log('Signalr disconnected')
        connection
          .start({ transport: ['webSockets', 'longPolling'] })
          .done(function () {
            // eslint-disable-next-line no-console
            console.log(`SignalR now connected, connection ID=${connection.id}`)
            // invoke the hello endpoint on the server - testing only
            // eslint-disable-next-line no-use-before-define
            signalrHubProxy.invoke('hello')
          })
          .fail(function () {
            // eslint-disable-next-line no-console
            console.log('SignalR could not connect')
          })
      })

      const signalrHubProxy = connection.createHubProxy('signalrHub')

      // define an endpoint the server may call
      // in this case, when export is done, it will call exportReady()
      signalrHubProxy.on('exportReady', function (guid, name) {
        if (thisComponent.requestedTenders.some((t) => t.id === guid)) {
          thisComponent.setCompletedSubmissionsAllReady(guid)
          // react to the notification from the server by displaying a ui notification
          createCompletedSubmissionsAllNotification(thisComponent, name, guid)
        }
      })

      // whenever a custom report is starting to be refreshed, this endpoint will be invoked
      signalrHubProxy.on('customReportsUpdating', async function (nodeId, customerId) {
        const localStorageCustomerId = (thisComponent.$store.getters.getAuthCustomer || {}).id || '' // localStorage.getItem('customerId')
        const localStorageNodeId = (thisComponent.$store.getters.getAuthTenderNode || {}).id || '' // localStorage.getItem('nodeId')

        // if the custom report was for the currently logged in user's node/customer,
        // then update our list of pending reports
        if (localStorageCustomerId === customerId && localStorageNodeId === nodeId) {
          try {
            await thisComponent.$store.dispatch('fetchPendingCustomReports')
          } catch (e) {
            errorHandler.handleApiError(this, e)
          }
        }
      })

      // whenever a custom report is refreshed, this endpoint will be invoked
      signalrHubProxy.on('customReportsUpdated', async function (nodeId, customerId) {
        const localStorageCustomerId = (thisComponent.$store.getters.getAuthCustomer || {}).id || '' // localStorage.getItem('customerId')
        const localStorageNodeId = (thisComponent.$store.getters.getAuthTenderNode || {}).id || '' // localStorage.getItem('nodeId')

        // if the custom report was for the currently logged in user's node/customer, then update our report list
        // and the list of pending reports as they would have updated as well
        if (localStorageCustomerId === customerId && localStorageNodeId === nodeId) {
          try {
            await thisComponent.$store.dispatch('fetchCustomReports')
          } catch (e) {
            errorHandler.handleApiError(this, e)
          }
        }
      })

      // monitor for UserGroup update for the Agency, if so refetch user/group/permissions
      signalrHubProxy.on('userGroupUpdated', function (customerId) {
        const localStorageCustomerId = (thisComponent.$store.getters.getAuthCustomer || {}).id || '' // localStorage.getItem('customerId')
        const userId = (thisComponent.$store.getters.getAuthUser || {}).id || '' // localStorage.getItem('userId')

        // if the change is for the LoggedIn User's Agency, otherwise just ignore
        if (localStorageCustomerId === customerId) {
          uiHandler.showLoading(this)

          Promise.all([
            thisComponent.$store.dispatch('fetchGroups'),
            thisComponent.$store.dispatch('fetchUser', userId)
          ])
            .then(() => {
              // check permission when user refresh the browser
              thisComponent.checkRoutePermission(thisComponent.$store, thisComponent.$route)
            })
            .catch((e) => errorHandler.handleApiError(this, e))
            .finally(() => uiHandler.hideLoading(this))
        }
      })

      // monitor oneSpan package update
      signalrHubProxy.on('contractSignatureUpdate', function (customerId) {
        const localStorageCustomerId = (thisComponent.$store.getters.getAuthCustomer || {}).id || '' // localStorage.getItem('customerId')

        // if the change is for the Agency, otherwise just ignore
        if (localStorageCustomerId === customerId) {
          thisComponent.setContractSignaturesFetchRequired(true)
        }
      })

      // start the signalr connection with the server
      connection
        .start()
        .done(function () {
          // eslint-disable-next-line no-console
          console.log(`SignalR now connected, connection ID=${connection.id}`)
          // invoke the hello endpoint on the server - testing only
          signalrHubProxy.invoke('hello')
        })
        .fail(function () {
          // eslint-disable-next-line no-console
          console.log('SignalR could not connect')
        })

      // only if authorized
      if (this.$store.getters.isAuthorized === true) {
        // get all the pending submissions from session storage
        this.restoreCompletedSubmissionsAll()
        if (this.readyRequestedTenders) {
          // create a notification for those that are ready to be downloaded
          for (const tenderStatus of this.readyRequestedTenders) {
            createCompletedSubmissionsAllNotification(
              thisComponent,
              tenderStatus.name,
              tenderStatus.id
            )
          }
        }
      }
    },
    addWalkmeScripts() {
      /**
       * Insert Walkme scripts appropriate to import.meta.env.MODE
       */
      let walkmeCode
      let walkmeAdded = false

      // eslint-disable-next-line no-undef
      let currentEnvironment = import.meta.env.MODE
      const devURL = /next\.qa\.bidsandtenders/
      const stageURL = /next\.stage\.bidsandtenders/

      if (devURL.test(window.location.href) || stageURL.test(window.location.href))
        currentEnvironment = 'development'

      if (currentEnvironment === 'development') {
        if (!walkmeAdded) {
          walkmeCode = document.createTextNode(
            `(function() {var walkme = document.createElement('script'); walkme.type = 'text/javascript'; walkme.async = true; walkme.src = 'https://cdn.walkme.com/users/59a00124f7004e869ae4fee867f813b6/test/walkme_59a00124f7004e869ae4fee867f813b6_https.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(walkme, s); window._walkmeConfig = {smartLoad:true}; })();`
          )
        }
      } else {
        if (!walkmeAdded) {
          walkmeCode = document.createTextNode(
            `(function() {var walkme = document.createElement('script'); walkme.type = 'text/javascript'; walkme.async = true; walkme.src = 'https://cdn.walkme.com/users/59a00124f7004e869ae4fee867f813b6/walkme_59a00124f7004e869ae4fee867f813b6_https.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(walkme, s); window._walkmeConfig = {smartLoad:true}; })();`
          )
        }
      }
      const walkmeScript = document.createElement('script')
      walkmeScript.appendChild(walkmeCode)

      document.querySelector('body').appendChild(walkmeScript)
      walkmeAdded = true
    },
    addJiraScripts() {
      // jira feedback manager
      const jiraScript = document.createElement('script')
      jiraScript.src =
        'https://esolutionsgroup.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-w863gw/b/11/a44af77267a987a660377e5c46e0fb64/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-UK&collectorId=4bff042f'
      const s = document.getElementsByTagName('script')[0]
      s.parentNode.insertBefore(jiraScript, s)
    },
    addZenvaScripts() {
      // zenva feedback
      const zenvaScript = document.createElement('script')
      zenvaScript.setAttribute('id', 'ze-snippet')
      zenvaScript.src =
        'https://static.zdassets.com/ekr/snippet.js?key=8c64c1c9-c1c7-4940-921e-60186f22972a'
      document.querySelector('body').appendChild(zenvaScript)
    },
    handleAlerts() {
      /**
       * Walkme is firing strange alerts on export with the message '1'
       * this checks for that message and suppresses the alert
       * but overwrites the window.alert function.
       * This is only an issue with walkme's dev version rn.
       * If in the future, this overwrite needs to be more specific,
       * check the caller
       **/
      const proxied = window.alert

      window.alert = function () {
        if (arguments[0] && arguments[0] === 1) {
          // eslint-disable-next-line
          console.log('walkme error suppressed')
        } else {
          proxied.apply(window, arguments)
        }
      }
    },
    ensureCustomerIsStored() {
      // Sometimes there isn't a Customer attached when there should be.
      // This is a fallback.
      if (this.getCustomer !== null) return

      const customerId = (this.$store.getters.getAuthCustomer || {}).id || ''
      if (customerId) this.fetchCustomer(customerId)
    }
  }
}
</script>

<style lang="scss">
@import 'styles/app';
</style>
