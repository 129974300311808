<template>
  <div
    ref="wrapper"
    :class="{
      'has-label': hasLabel,
      'has-no-label': !hasLabel,
      'is-multiple': multiple,
      'has-dropdown-visible': dropdownVisible
    }"
    class="app-select"
  >
    <label
      v-if="label"
      :for="componentId"
      :class="[
        {
          'is-required': required,
          'is-empty': isEmpty,
          'is-disabled': disabled || !hasPermissionToEdit
        }
      ]"
    >
      <span>{{ label }}</span>
      <app-permission-tooltip
        v-if="!hasPermissionToEdit"
        class="permission-icon has-margin-left-tiny"
      />
    </label>
    <el-select
      :id="componentId"
      ref="select"
      :value="value"
      :collapse-tags="multiple && collapseTags"
      v-bind="$attrs"
      :disabled="disabled || !hasPermissionToEdit"
      :multiple="multiple"
      :placeholder="getPlaceholder"
      :class="componentId + '-wrapper'"
      :popper-class="popperClass"
      :aria-label="hasLabel ? '' : `${id} select`"
      @change="handleChange"
      @focus="handleFocus"
      @blur="handleBlur"
      @visible-change="handleVisibleChange"
      v-on="$listeners"
    >
      <slot />
    </el-select>
    <div
      v-if="$slots['append'] && isDirty"
      :class="{ 'is-visible': hasAppend && isDirty }"
      class="app-select__append"
    >
      <slot ref="append" name="append"></slot>
    </div>
  </div>
</template>

<script>
import InputPermissionMixins from '@/mixins/input-permission-mixins'
import uuid from 'uuid/v1'

export default {
  name: 'app-select',
  mixins: [InputPermissionMixins],
  props: {
    id: String,
    selectAll: Boolean,
    label: String,
    value: [String, Number, Object, Array, Boolean],
    required: Boolean,
    multiple: Boolean,
    placeholder: String,
    disabled: Boolean,
    collapseTags: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      focused: false,
      isDirty: false,
      createdId: null,
      dropdownVisible: false,
      popper: null
    }
  },
  computed: {
    popperClass() {
      return `${this.componentId}-list`
    },
    hasLabel() {
      return this.label !== null && this.label !== '' && !!this.label
    },
    isEmpty() {
      return (
        !this.focused &&
        (this.value === null ||
          this.value === '' ||
          (Array.isArray(this.value) && !this.value.length))
      )
    },
    getPlaceholder() {
      if (this.label) return ''
      return this.placeholder
    },
    hasAppend() {
      return this.$slots['append']
    },
    componentId() {
      return this.id ? this.id : this.createdId
    },
    isActive() {
      return this.dropdownVisible
    },
    includeSelectAll() {
      return this.multiple && this.selectAll
    }
  },
  watch: {
    componentId() {
      this.assignParentRef()
    }
  },
  mounted() {
    // if there isn't an id, create one and assign it
    let name
    if (this.$route) {
      name = this.$route.name
    } else {
      const location = window.location.hash.split('#/')
      if (location.length > 1) {
        name = location[1].replace('/', '-')
      } else {
        name = 'root'
      }
    }
    if (!this.id) {
      this.assignId({ selector: '.el-select', ref: 'select', key: `${name}-select` })
    }
    document.addEventListener('scroll', this.handleScroll, true)
    this.assignParentRef()
  },
  methods: {
    assignId({ ref, key }) {
      const selects = [...document.querySelectorAll('.el-select')]
      let createdId = `${key}-${uuid()}`
      selects.forEach((s, i) => {
        if (s === this.$refs[ref].$el) {
          createdId = `${key}-${i}`
        }
      })

      this.createdId = createdId
    },
    assignParentRef() {
      const htmlList = document.getElementById(`${this.componentId}-list`)
      if (htmlList) {
        htmlList.setAttribute('data-parent', `#${this.componentId}`)
      }
    },
    handleChange(e) {
      if (!this.isDirty) this.isDirty = true
      this.$emit('input', e)
    },
    handleFocus(event) {
      this.focused = true
      this.$emit('focus', event)
    },
    handleBlur(event) {
      this.focused = false
      this.$emit('blur', event)
    },
    handleVisibleChange(visible) {
      this.dropdownVisible = visible
    },
    closeIfOpen() {
      if (this.isActive) {
        if (this.$refs.select) this.$refs.select.blur()
        if (this.$refs.wrapper) this.$refs.wrapper.blur()
      }
    },
    handleScroll(e) {
      const contained = e.target.classList.contains('el-select-dropdown__wrap')
      if (!contained) {
        this.closeIfOpen()
      }
    }
  }
}
</script>

<style lang="scss">
$input-height: 48px;

.app-select {
  margin-bottom: $gap-small;
  z-index: 1;

  .label,
  label {
    .app-icon {
      transform: scale(0.8);
    }
  }

  input {
    &:disabled,
    &[disabled] {
      color: $c-grey;
      background: $c-background;
    }
  }

  &.has-label {
    .el-select {
      input,
      .el-input__inner {
        padding-top: $gap;
      }
    }
  }

  &.has-no-label {
    .el-select {
      input,
      .el-input__inner {
        padding-top: 0;
      }
    }
  }

  .el-select {
    input,
    .el-input__inner {
      max-height: 42px;

      .is-disabled &,
      &:disabled,
      &[disabled='disabled'] {
        color: $c-grey !important;
        background: $c-background !important;
      }

      &:focus-visible {
        border-color: $c-primary;
      }
    }
  }

  label.is-required {
    position: absolute;
  }
  .label.is-empty,
  label.is-empty {
    top: calc(#{$input-height}/ 2);
    transform: translate(0, -50%);
    font-size: $size-4;

    .app-icon {
      transform: scale(1);
    }
  }

  &.is-multiple {
    .label.is-empty,
    label.is-empty {
      margin-top: 5px;
    }

    .el-input {
      height: 60px;
    }
    .el-select__tags {
      top: calc(50% + 7px);
      left: 5px;
    }
  }
}
.el-select-dropdown {
  max-width: 2px;
}

.el-select__tags .el-select__tags-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100px;
  display: inline-block;
  vertical-align: middle;
}
</style>
