import { Notification } from 'element-ui'
import uiHandler from '@/utils/ui'
import errorHandler from '@/utils/error'

// NOTE: any component calling this function must add
//  downloadCompletedSubmissionsAll
//  addAllSubmissionsNotification
// to its mapActions list
export function createCompletedSubmissionsAllNotification(thisComponent, name, guid) {
  const notify = Notification.success({
    title: thisComponent.$t('ui.components.notifications.titles.exportAll'),
    message: `${thisComponent.$t(
      'ui.components.notifications.bodies.exportAllLead'
    )} ${name} ${thisComponent.$t('ui.components.notifications.bodies.exportAllTrail')}`,
    duration: 0,
    showClose: false,
    position: 'top-left',
    onClick: async function () {
      // when this notification is clicked, download the submission
      uiHandler.showLoading(thisComponent)
      try {
        await thisComponent.downloadCompletedSubmissionsAll(this.tenderGuid)
      } catch (e) {
        errorHandler.handleApiError(thisComponent, e)
      }
      uiHandler.hideLoading(thisComponent)
      // close the notification
      this.close()
    }
  })
  // attach the guid to the notification object
  notify.tenderGuid = guid
  // remember it so we can close it later, if user logs out
  thisComponent.addAllSubmissionsNotification({ closeFn: notify.close, tenderGuid: guid })
}
