<template>
  <div
    class="award-approval-docs-list has-border has-padding-xlg has-margin-left-auto has-margin-right-auto"
  >
    <template v-if="count === 0">
      <app-empty key="docsempty" icon="file">
        No documents are associated with this award.
      </app-empty>
    </template>
    <template v-else>
      <div class="is-flex docs-list__header has-border-bottom">
        <app-title size="5" heading="4">Documents</app-title>
      </div>
      <div class="docs-list__list has-margin-bottom">
        <slot />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'award-approval-docs-list',
  props: {
    count: Number
  }
}
</script>

<style lang="scss" scoped>
.award-approval-docs-list {
  background: white;
}
.app-title {
  font-weight: normal;
}
</style>
