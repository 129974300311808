<template>
  <div
    :style="getCustomHeight()"
    :class="['is-size-' + size, getBackgroundClass()]"
    class="app-empty"
  >
    <div class="app-empty-inner">
      <div class="app-empty-content">
        <div v-if="icon" class="app-empty-icon">
          <app-icon :icon="icon"></app-icon>
        </div>
        <div class="app-empty-text">
          <slot></slot>
        </div>
        <div v-if="hasSubtext" class="app-empty-subText">
          <slot name="subtext"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-empty',
  props: {
    icon: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'medium'
    },
    background: String
  },
  data() {
    return {}
  },
  computed: {
    hasSubtext() {
      return this.$slots.subtext
    }
  },
  methods: {
    getCustomHeight() {
      if (this.height) {
        return `min-height: none; height:${this.height}`
      }
    },
    getBackgroundClass() {
      if (!this.background) return ''
      return `is-background-${this.background}`
    }
  }
}
</script>

<style lang="scss" src="./app-empty.scss"></style>
