export const AUDIT_AGGREGATE_TYPES_SET = 'AUDIT_AGGREGATE_TYPES_SET'
export const AUDIT_AGGREGATE_SORTED_TYPES_SET = 'AUDIT_AGGREGATE_SORTED_TYPES_SET'
export const AUDIT_AGGREGATE_VENDORS_SET = 'AUDIT_AGGREGATE_VENDORS_SET'
export const AUDIT_AGGREGATE_USERS_SET = 'AUDIT_AGGREGATE_USERS_SET'
export const AUDIT_SEARCH_RESULTS_SET = 'AUDIT_SEARCH_RESULTS_SET'
export const AUDIT_SEARCH_RESULTS_TOTAL_SET = 'AUDIT_SEARCH_RESULTS_TOTAL_SET'
export const AUDIT_VENDOR_SEARCH_RESULTS_SET = 'AUDIT_VENDOR_SEARCH_RESULTS_SET'
export const AUDIT_VENDOR_SEARCH_RESULTS_TOTAL_SET = 'AUDIT_VENDOR_SEARCH_RESULTS_TOTAL_SET'
export const AUDIT_VENDOR_AGGREGATE_TYPES_SET = 'AUDIT_VENDOR_AGGREGATE_TYPES_SET'

export const AUTH_SET_TYPE = 'AUTH_SET_TYPE'
export const AUTH_SET_OPTION = 'AUTH_SET_OPTION'
export const AUTH_SET_ISAUTH = 'AUTH_SET_ISAUTH'
export const AUTH_SET_CUSTOMER = 'AUTH_SET_CUSTOMER'
export const AUTH_SET_CUSTOMERNODE = 'AUTH_SET_CUSTOMERNODE'
export const AUTH_SET_HOST = 'AUTH_SET_HOST'
export const AUTH_SET_PERMISSION = 'AUTH_SET_PERMISSION'
export const AUTH_SET_USER = 'AUTH_SET_USER'
export const AUTH_SET_NODE = 'AUTH_SET_NODE'

export const CERTIFICATES_SET = 'CERTIFICATES_SET'
export const CERTIFICATE_DELETE = 'CERTIFICATE_DELETE'
export const CERTIFICATE_UPDATE_OR_ADD = 'CERTIFICATE_UPDATE_OR_ADD'

export const CERTIFICATE_TYPES_SET = 'CERTIFICATE_TYPES_SET'
export const CONTRACTPOTYPES_TYPES_SET = 'CONTRACTPOTYPES_TYPES_SET'

export const CONTRACT_DOCUMENTS_SET = 'CONTRACT_DOCUMENTS_SET'
export const CONTRACT_VENDOR_DOCUMENT_TYPES_SET = 'CONTRACT_VENDOR_DOCUMENT_TYPES_SET'
export const CONTRACT_VENDOR_DOCUMENT_TYPE_SET = 'CONTRACT_VENDOR_DOCUMENT_TYPE_SET'

export const CONTRACT_DOCUMENTS_DELETE = 'CONTRACT_DOCUMENTS_DELETE'
export const CONTRACT_DOCUMENT_UPDATE_OR_ADD = 'CONTRACT_DOCUMENT_UPDATE_OR_ADD'

export const CONTRACT_VENDOR_DOCUMENTS_SET = 'CONTRACT_VENDOR_DOCUMENTS_SET'
export const CONTRACT_VENDOR_DOCUMENTS_DELETE = 'CONTRACT_VENDOR_DOCUMENTS_DELETE'
export const CONTRACT_VENDOR_DOCUMENT_UPDATE_OR_ADD = 'CONTRACT_VENDOR_DOCUMENT_UPDATE_OR_ADD'

export const CONTRACT_BOND_DOCUMENT_TYPE_SET = 'CONTRACT_BOND_DOCUMENT_TYPE_SET'
export const CONTRACT_BOND_DOCUMENT_TYPES_SET_ALL = 'CONTRACT_BOND_DOCUMENT_TYPES_SET_ALL'
export const CONTRACT_BOND_DOCUMENTS_SET = 'CONTRACT_BOND_DOCUMENTS_SET'
export const CONTRACT_BONDS_DOCUMENT_UPDATE_OR_ADD = 'CONTRACT_BONDS_DOCUMENT_UPDATE_OR_ADD'
export const CONTRACT_BOND_DOCUMENTS_DELETE = 'CONTRACT_BOND_DOCUMENTS_DELETE'

export const CONTRACT_PERFORMANCE_SET = 'CONTRACT_PERFORMANCE_SET'
export const CONTRACT_PERFORMANCE_SET_ALL = 'CONTRACT_PERFORMANCE_SET_ALL'
export const CONTRACT_PERFORMANCE_DELETE = 'CONTRACT_PERFORMANCE_DELETE'
export const CONTRACT_PERFORMANCE_STATISTIC_SET = 'CONTRACT_PERFORMANCE_STATISTIC_SET'

export const CONTRACT_PERFORMANCE_RATING_SET = 'CONTRACT_PERFORMANCE_RATING_SET'
export const CONTRACT_PERFORMANCE_ADD = 'CONTRACT_PERFORMANCE_ADD'
export const CONTRACT_PERFORMANCE_REMOVE = 'CONTRACT_PERFORMANCE_REMOVE'
export const CONTRACT_PERFORMANCE_EVALUATION = 'CONTRACT_PERFORMANCE_EVALUATION'
export const CONTRACT_PERFORMANCE_FINAL_RATING_SET = 'CONTRACT_PERFORMANCE_FINAL_RATING_SET'
export const CONTRACT_PERFORMANCE_EVALUATION_PREVIEW_LINKS_SET =
  'CONTRACT_PERFORMANCE_EVALUATION_PREVIEW_LINKS_SET'

export const CONTRACT_SIGNATURE_SET = 'CONTRACT_SIGNATURE_SET'
export const CONTRACT_SIGNATURES_SET = 'CONTRACT_SIGNATURES_SET'
export const CONTRACT_SIGNATURES_FETCH_REQUIRED_SET = 'CONTRACT_SIGNATURES_FETCH_REQUIRED_SET'

export const CONTRACT_BUILDER_LICENSE_CONFIGURATION_SET =
  'CONTRACT_BUILDER_LICENSE_CONFIGURATION_SET'
export const CONTRACT_BUILDER_LICENSE_CONFIGURATIONS_SET =
  'CONTRACT_BUILDER_LICENSE_CONFIGURATIONS_SET'
export const CONTRACT_BUILDER_LICENSE_CONFIGURATION_FETCH_REQUIRED_SET =
  'CONTRACT_BUILDER_LICENSE_CONFIGURATION_FETCH_REQUIRED_SET'
export const CONTRACT_BUILDER_LICENSE_CONFIGURATIONS_CONTRACT_SIGNATURES_SET =
  'CONTRACT_BUILDER_LICENSE_CONFIGURATIONS_CONTRACT_SIGNATURES_SET'

export const CUSTOMER_SET = 'CUSTOMER_SET'
export const CUSTOMERNODE_SET = 'CUSTOMERNODE_SET'
export const CUSTOMER_SET_TIMEZONE = 'CUSTOMER_SET_TIMEZONE'
export const CUSTOMER_ALL_SET = 'CUSTOMER_ALL_SET'

export const EMAIL_PROJECT_SET = 'EMAIL_PROJECT_SET'
export const SCHEDULED_EMAIL_PROJECT_SET = 'SCHEDULED_EMAIL_PROJECT_SET'
export const EMAIL_VENDOR_SET = 'EMAIL_VENDOR_SET'
export const EMAIL_AGGREGATES_SET = 'EMAIL_AGGREGATES_SET'
export const EMAIL_SCHEDULED_AGGREGATES_SET = 'EMAIL_SCHEDULED_AGGREGATES_SET'
export const EMAIL_UPDATE_NEW_EMAIL = 'EMAIL_UPDATE_NEW_EMAIL'
export const EMAIL_SET_NEW_EMAIL_TEAM_CONTACTS = 'EMAIL_SET_NEW_EMAIL_TEAM_CONTACTS'
export const EMAIL_SET_NEW_EMAIL_PROJECT_USERS = 'EMAIL_SET_NEW_EMAIL_PROJECT_USERS'
export const EMAIL_SET_NEW_EMAIL_INVITEES = 'EMAIL_SET_NEW_EMAIL_INVITEES'
export const EMAIL_SET_EMAIL_TEMPLATES = 'EMAIL_SET_EMAIL_TEMPLATES'
export const EMAIL_UPDATE_EMAIL_TEMPLATES = 'EMAIL_UPDATE_EMAIL_TEMPLATES'
export const EMAIL_SET_NEW_EMAIL_CONTRACT_ID = 'EMAIL_SET_NEW_EMAIL_CONTRACT_ID'
export const EMAIL_SET_NEW_EMAIL_TENDER_ID = 'EMAIL_SET_NEW_EMAIL_TENDER_ID'
export const EMAIL_SET_EMAIL_RESTRICTED_DOMAINS = 'EMAIL_SET_EMAIL_RESTRICTED_DOMAINS'
export const EMAIL_UPDATE_EMAIL_RESTRICTED_DOMAINS = 'EMAIL_UPDATE_EMAIL_RESTRICTED_DOMAINS'
export const EMAIL_SET_NEW_EMAIL_DOCUMENT = 'EMAIL_SET_NEW_EMAIL_DOCUMENT'
export const EMAIL_RESEND = 'EMAIL_RESEND'

export const CONTROL_PANEL_SET_ITEM = 'CONTROL_PANEL_SET_ITEM'
export const CONTROL_PANEL_SAVE_REQUIRED = 'CONTROL_PANEL_SAVE_REQUIRED'
export const CONTROL_PANEL_ACCOUNT_INFO_SET_ITEM = 'CONTROL_PANEL_ACCOUNT_INFO_SET_ITEM'
export const CONTROL_PANEL_EMAIL_NOTIFICATIONS_SET_ITEM =
  'CONTROL_PANEL_EMAIL_NOTIFICATIONS_SET_ITEM'
export const CONTROL_PANEL_EMAIL_NOTIFICATIONS_SET_DOMAINS =
  'CONTROL_PANEL_EMAIL_NOTIFICATIONS_SET_DOMAINS'
export const CONTROL_PANEL_SYSTEM_CONTENT_SET_ITEM = 'CONTROL_PANEL_SYSTEM_CONTENT_SET_ITEM'
export const CONTROL_PANEL_LIBRARY_CONTENT_SET_ITEM = 'CONTROL_PANEL_LIBRARY_CONTENT_SET_ITEM'
export const CONTROL_PANEL_DROPDOWNS_SET = 'CONTROL_PANEL_DROPDOWNS_SET'
export const CONTROL_PANEL_ERROR_SET = 'CONTROL_PANEL_ERROR_SET'
export const CONTROL_PANEL_ERROR_UNSET = 'CONTROL_PANEL_ERROR_UNSET'
export const CONTROL_PANEL_ERROR_UNSET_ALL = 'CONTROL_PANEL_ERROR_UNSET_ALL'
export const TIMEZONE_ALL_SET = 'TIMEZONE_ALL_SET'
export const LANGUAGE_ALL_SET = 'LANGUAGE_ALL_SET'
export const RESOURCE_MESSAGE_ALL_SET = 'RESOURCE_MESSAGE_ALL_SET'

export const GROUP_SET_GROUPS = 'GROUP_SET_GROUPS'
export const USER_REMOVE_GROUPS = 'USER_REMOVE_GROUPS'
export const GROUP_RENAME_USER_GROUPS = 'GROUP_RENAME_USER_GROUPS'
export const GROUP_SET_SIDEBAR_VISIBILITY = 'GROUP_SET_SIDEBAR_VISIBILITY'
export const GROUP_TOGGLE_SIDEBAR_VISIBILITY = 'GROUP_TOGGLE_SIDEBAR_VISIBILITY'
export const GROUP_SET_ACTIVE_USERS_IN_GROUP = 'GROUP_SET_ACTIVE_USERS_IN_GROUP'
export const GROUP_SET_ACTIVE_USERS_NOT_IN_GROUP = 'GROUP_SET_ACTIVE_USERS_NOT_IN_GROUP'
export const GROUP_SET_PERMISSIONS_OVERLAY_VISIBILITY = 'GROUP_SET_PERMISSIONS_OVERLAY_VISIBILITY'

export const INVITATION_SET_SEARCH_TENDER_RESULT = 'INVITATION_SET_SEARCH_TENDER_RESULT'
export const INVITATION_SET_SEARCH_CRITERIA_RESULT = 'INVITATION_SET_SEARCH_CRITERIA_RESULT'
export const INVITATION_SET_SEARCH_CRITERIA_RESULT_TOTAL_COUNT =
  'INVITATION_SET_SEARCH_CRITERIA_RESULT_TOTAL_COUNT'
export const INVITATION_SET_LAST_ROTATIONAL_LIST_UPDATE =
  'INVITATION_SET_LAST_ROTATIONAL_LIST_UPDATE'

export const INSURANCE_DOCUMENTS_SET = 'INSURANCE_DOCUMENTS_SET'
export const INSURANCE_DOCUMENT_DELETE = 'INSURANCE_DOCUMENT_DELETE'
export const INSURANCE_UPDATE_OR_ADD = 'INSURANCE_UPDATE_OR_ADD'
export const INSURANCE_TYPES_SET = 'INSURANCE_TYPES_SET'

export const PERFORMANCE_EVALUATION_DOCUMENTS_SET = 'PERFORMANCE_EVALUATION_DOCUMENTS_SET'
export const PERFORMANCE_EVALUATION_DOCUMENT_UPDATE = 'PERFORMANCE_EVALUATION_DOCUMENT_UPDATE'

export const PLANTAKER_SET = 'PLANTAKER_SET'

export const EXPORTFILE_ADD = 'EXPORTFILE_ADD'
export const EXPORTFILE_REMOVE = 'EXPORTFILE_REMOVE'
export const EXPORTFILE_READY = 'EXPORTFILE_READY'
export const EXPORTFILE_RESTORE = 'EXPORTFILE_RESTORE'
export const EXPORTFILE_NOTIFICATIONADD = 'EXPORTFILE_NOTIFICATIONADD'
export const EXPORTFILE_NOTIFICATIONCLEAR = 'EXPORTFILE_NOTIFICATIONCLEAR'

export const REPORTS_ADDTHUMBNAIL = 'REPORTS_ADDTHUMBNAIL'
export const REPORTS_THUMBNAILNAMES = 'REPORTS_THUMBNAILNAMES'
export const REPORTS_REPORTCATEGORIES = 'REPORTS_REPORTCATEGORIES'
export const REPORTS_THUMBNAILCATEGORY = 'REPORTS_THUMBNAILCATEGORY'
export const REPORTS_SUBSCRIBED = 'REPORTS_SUBSCRIBED'
export const REPORTS_REMOVE_RECIPIENT = 'REPORTS_REMOVE_RECIPIENT'
export const REPORTS_SCHEDULED = 'REPORTS_SCHEDULED'
export const REPORTS_REMOVE_SCHEDULED = 'REPORTS_REMOVE_SCHEDULED'
export const REPORTS_UPDATE_SCHEDULED = 'REPORTS_UPDATE_SCHEDULED'
export const REPORTS_DATA = 'REPORTS_DATA'
export const REPORTS_CUSTOM = 'REPORTS_CUSTOM'
export const REPORTS_CUSTOM_PENDING = 'REPORTS_CUSTOM_PENDING'
export const REPORTS_REMOVE_CUSTOM = 'REPORTS_REMOVE_CUSTOM'
export const REPORTS_UPDATE_CUSTOM = 'REPORTS_UPDATE_CUSTOM'

export const PROJECT_SET_ALL = 'PROJECT_SET_ALL'
export const PROJECT_ENDING_SET_ALL = 'PROJECT_ENDING_SET_ALL'
export const MY_PROJECTS_SET_ALL = 'MY_PROJECTS_SET_ALL'
export const PROJECT_SET_NEW = 'PROJECT_SET_NEW'
export const PROJECT_SET = 'PROJECT_SET'
export const PROJECT_UPDATE_NEW = 'PROJECT_UPDATE_NEW'
export const PROJECT_RESET_NEW = 'PROJECT_RESET_NEW'
export const PROJECT_UPDATE = 'PROJECT_UPDATE'
export const PROJECT_SET_FETCH_STATUS = 'PROJECT_SET_FETCH_STATUS'
export const PROJECTS_SET_PROJECTSPROPERTIES = 'PROJECTS_SET_PROJECTSPROPERTIES'
export const PROJECT_UPDATE_AWARD_PHASE_STATUS = 'PROJECT_UPDATE_AWARD_PHASE_STATUS'
export const GLOBAL_PROJECT_SAVE_SUCCESS = 'GLOBAL_PROJECT_SAVE_SUCCESS'
export const PROJECT_UPDATE_CLOSING_DATE_CHANGED = 'PROJECT_UPDATE_CLOSING_DATE_CHANGED'

export const DIVISION_SET_ALL = 'DIVISION_SET_ALL'
export const DEPARTMENT_SET = 'DEPARTMENT_SET'
export const DEPARTMENT_SET_ALL = 'DEPARTMENT_SET_ALL'
export const DEPARTMENTS_SET = 'DEPARTMENTS_SET'
export const DOCUMENT_SET_ALL = 'DOCUMENT_SET_ALL'
export const EXPIRED_DOCS_ALL = 'EXPIRED_DOCS_ALL'
export const PENDING_DOCS_ALL = 'PENDING_DOCS_ALL'
export const EMAIL_DOCS_ALL = 'EMAIL_DOCS_ALL'
export const DOCUMENT_EDIT = 'DOCUMENT_EDIT'
export const LEAD_AGENCY_NAME_SET_ALL = 'LEAD_AGENCY_NAME_SET_ALL'
export const LEAD_AGENCY_NAME_SET = 'LEAD_AGENCY_NAME_SET'
export const IRREVOCABLE_PERIOD_SET_ALL = 'IRREVOCABLE_PERIOD_SET_ALL'
export const IRREVOCABLE_PERIOD_SET = 'IRREVOCABLE_PERIOD_SET'
export const TERM_SET_ALL = 'TERM_SET_ALL'
export const CONTRACT_CATEGORY_SET_ALL = 'CONTRACT_CATEGORY_SET_ALL'
export const CONTRACT_CATEGORIES_SET = 'CONTRACT_CATEGORIES_SET'
export const SOURCE_CATEGORY_SET_ALL = 'SOURCE_CATEGORY_SET_ALL'
export const TRADE_AGREEMENT_SET_ALL = 'TRADE_AGREEMENT_SET_ALL'
export const TRADE_AGREEMENT_SET = 'TRADE_AGREEMENT_SET'
export const TRADE_AGREEMENT_SPA_SET_ALL = 'TRADE_AGREEMENT_SPA_SET_ALL'
export const CONTRACT_CATEGORY_SET_STATUS = 'CONTRACT_CATEGORY_SET_STATUS'
export const SOURCE_CATEGORY_SET_STATUS = 'SOURCE_CATEGORY_SET_STATUS'
export const COMMODITY_CATEGORIES_SET_STATUS = 'COMMODITY_CATEGORIES_SET_STATUS'
export const COMMODITY_CATEGORIES_SET = 'COMMODITY_CATEGORIES_SET'
export const INSTRUCTION_TEMPLATES_SET_ALL = 'INSTRUCTION_TEMPLATES_SET_ALL'
export const TERMS_AND_CONDITIONS_TEMPLATES_SET_ALL = 'TERMS_AND_CONDITIONS_TEMPLATES_SET_ALL'
export const QUESTION_TEMPLATES_SET_ALL = 'QUESTION_TEMPLATES_SET_ALL'
export const SECUITY_TEMPLATES_SET_ALL = 'SECUITY_TEMPLATES_SET_ALL'
export const DOCUMENT_TEMPLATES_SET_ALL = 'DOCUMENT_TEMPLATES_SET_ALL'
export const TAX_SET_ALL = 'TAX_SET_ALL'
export const TAXES_SET_ALL = 'TAXES_SET_ALL'
export const SUBMISSION_SET_ALL = 'SUBMISSION_SET_ALL'
export const PROJECT_DOCUMENTS_SET_ALL = 'PROJECT_DOCUMENTS_SET_ALL'
export const PROJECT_DOCUMENT_SET = 'PROJECT_DOCUMENT_SET'
export const PROJECT_DOCUMENT_UPDATE = 'PROJECT_DOCUMENT_UPDATE'
export const PROJECT_DOCUMENT_DELETE = 'PROJECT_DOCUMENT_DELETE'
export const PROJECT_DOCUMENT_EDIT = 'PROJECT_DOCUMENT_EDIT'
export const PROCUREMENT_TYPE_SET_ALL = 'PROCUREMENT_TYPE_SET_ALL'
export const PROCUREMENT_TYPE_SET = 'PROCUREMENT_TYPE_SET'
export const AWARD_EXCEPTIONS_SET_ALL = 'AWARD_EXCEPTIONS_SET_ALL'
export const AWARD_EXCEPTION_SET = 'AWARD_EXCEPTION_SET'
export const AWARDED_SET_ALL = 'AWARDED_SET_ALL'
export const AWARD_PROJECT_SUMMARY_SET = 'AWARD_PROJECT_SUMMARY_SET'
export const AWARD_ALL_APPROVALS_SET = 'AWARD_ALL_APPROVALS_SET'
export const AWARD_CURRENT_APPROVAL_SET = 'AWARD_CURRENT_APPROVAL_SET'
export const AWARD_SELECTED_SUPPLIERS_SET = 'AWARD_SELECTED_SUPPLIERS_SET'
export const AWARD_SELECTED_SUPPLIERS_UPDATE = 'AWARD_SELECTED_SUPPLIERS_UPDATE'
export const AWARD_WORKFLOW_TYPE_SET = 'AWARD_WORKFLOW_TYPE_SET'
export const AWARD_APPROVAL_PUBLIC_SET = 'AWARD_APPROVAL_PUBLIC_SET'
export const PROJECT_PHASES_SET_ALL = 'PROJECT_PHASES_SET_ALL'
export const PROJECT_ERROR_SET = 'PROJECT_ERROR_SET'
export const PROJECT_ERROR_UNSET = 'PROJECT_ERROR_UNSET'
export const PROJECT_ERROR_UNSET_ALL = 'PROJECT_ERROR_UNSET_ALL'
export const PROJECT_SET_SAVE_REQUIRED = 'PROJECT_SET_SAVE_REQUIRED'
export const PROJECT_BACKUP_SET = 'PROJECT_BACKUP_SET'
export const PROJECT_SET_LAST_SAVE = 'PROJECT_SET_LAST_SAVE'
export const PROJECT_SET_LOCKED = 'PROJECT_SET_LOCKED'
export const PROJECT_ROLE_SET_ALL = 'PROJECT_ROLE_SET_ALL'
export const PROJECT_VALIDATION_ERROR_SET = 'PROJECT_VALIDATION_ERROR_SET'
export const PROJECT_UPDATE_SUBMISSIONS = 'PROJECT_UPDATE_SUBMISSIONS'
export const PROJECT_UPDATE_ADDENDUMS = 'PROJECT_UPDATE_ADDENDUMS'
export const PROJECT_UPDATE_VENDOR_QUESTIONS = 'PROJECT_UPDATE_VENDOR_QUESTIONS'
export const PROJECT_UPDATE_PLANTAKERS = 'PROJECT_UPDATE_PLANTAKERS'
export const PROJECT_UPDATE_DOCUMENT = 'PROJECT_UPDATE_DOCUMENT'
export const PROJECT_USER_SET_ALL = 'PROJECT_USER_SET_ALL'
export const PROJECT_USER_ROLE_SET_ALL = 'PROJECT_USER_ROLE_SET_ALL'
export const PROJECT_UPDATE_SUPPLIERS = 'PROJECT_UPDATE_SUPPLIERS'
export const PROJECT_COMMENT_SET_ALL = 'PROJECT_COMMENT_SET_ALL'
export const PROJECT_SET_FORCE_VALIDATE_BACKDATE = 'PROJECT_SET_FORCE_VALIDATE_BACKDATE'
export const PROJECT_TO_COPY_SET = 'PROJECT_TO_COPY_SET'
export const PROJECT_TO_SAVE_AS_TEMPLATE_SET = 'PROJECT_TO_SAVE_AS_TEMPLATE_SET'
export const USER_PROJECT_COMMENT_SET_ALL = 'USER_PROJECT_COMMENT_SET_ALL'
export const PROJECT_TASK_SET_ALL = 'PROJECT_TASK_SET_ALL'
export const PROJECT_TASK_SET_ALL_BY_USER = 'PROJECT_TASK_SET_ALL_BY_USER'
export const PROJECT_USER_FETCH_STATUS_SET = 'PROJECT_USER_FETCH_STATUS_SET'
export const PROJECT_JOURNAL_ENTRY_SET_ALL = 'PROJECT_JOURNAL_ENTRY_SET_ALL'
export const PROJECT_JOURNAL_ENTRY_SET = 'PROJECT_JOURNAL_ENTRY_SET'
export const PROJECT_JOURNAL_ENTRY_COMMENT_ADD = 'PROJECT_JOURNAL_ENTRY_COMMENT_ADD'
export const PROJECT_JOURNAL_DOCUMENTS_SET = 'PROJECT_JOURNAL_DOCUMENTS_SET'

export const EVALUATION_SET_PROJECT_EVALUATION = 'EVALUATION_SET_PROJECT_EVALUATION'
export const EVALUATION_SET_EVALUATIONS = 'EVALUATION_SET_EVALUATIONS'
export const EVALUATION_SET_EVALTAION_GUIDE_TEMPLATES = 'EVALUATION_SET_EVALTAION_GUIDE_TEMPLATES'
export const EVALUATION_SET_NON_DISCLOSURE_TEMPLATES = 'EVALUATION_SET_NON_DISCLOSURE_TEMPLATES'
export const EVALUATION_SET_CONFLICT_OF_INTEREST_TEMPLATES =
  'EVALUATION_SET_CONFLICT_OF_INTEREST_TEMPLATES'
export const EVALUATION_SET_SCORE_CARD_TEMPLATES = 'EVALUATION_SET_SCORE_CARD_TEMPLATES'

export const INTAKE_SET_PROJECT_DATA = 'INTAKE_SET_PROJECT_DATA'
export const INTAKE_UPDATE_PROJECT_DATA = 'INTAKE_UPDATE_PROJECT_DATA'
export const INTAKE_SET_PENDING_REQUESTS = 'INTAKE_SET_PENDING_REQUESTS'
export const INTAKE_SET_NEW_PROJECT_DATA = 'INTAKE_SET_NEW_PROJECT_DATA'
export const INTAKE_SET_REQUESTERS = 'INTAKE_SET_REQUESTERS'
export const INTAKE_SET_PROJECTS_ALL = 'INTAKE_SET_PROJECTS_ALL'

export const SAVED_SEARCH_FILTERS_SET = 'SAVED_SEARCH_FILTERS_SET'
export const PROJECT_SEARCH_SAVED_SET = 'PROJECT_SEARCH_SAVED_SET'
export const PROJECT_SEARCH_FILTERS_SET = 'PROJECT_SEARCH_FILTERS_SET'
export const PROJECT_SEARCH_COLUMNS_SET = 'PROJECT_SEARCH_COLUMNS_SET'
export const PROJECT_SEARCH_FILTERS_ACTIVE_SET = 'PROJECT_SEARCH_FILTERS_ACTIVE_SET'
export const PROJECT_SEARCH_RESULT_SET = 'PROJECT_SEARCH_RESULT_SET'
export const PROJECT_CAN_SAVE_SET = 'PROJECT_CAN_SAVE_SET'
export const SAVED_PROJECT_SEARCH_SET_ALL = 'SAVED_PROJECT_SEARCH_SET_ALL'
export const SAVED_PROJECT_SEARCH_SET = 'SAVED_PROJECT_SEARCH_SET'
export const SAVED_PROJECT_SEARCH_ADD = 'SAVED_PROJECT_SEARCH_ADD'
export const SAVED_SEARCH_SET_ACTIVE = 'SAVED_SEARCH_SET_ACTIVE'

export const TENDER_SET = 'TENDER_SET'
export const TENDERNODE_SET = 'TENDERNODE_SET'
export const TENDERNODE_UPDATE_OR_ADD = 'TENDERNODE_UPDATE_OR_ADD'
export const TENDERNODE_SAVE_REQUIRED = 'TENDERNODE_SAVE_REQUIRED'

export const TENDER_SET_SEARCH = 'TENDER_SET_SEARCH'
export const TENDERTABLE_SET_FROMDTO = 'TENDERTABLE_SET_FROMDTO'
export const TENDERTABLE_SET = 'TENDERTABLE_SET'
export const TENDERTABLE_SET_SUMMARY = 'TENDERTABLE_SET_SUMMARY'
export const TENDERTABLE_ADD_OR_REPLACE_SUMMARY = 'TENDERTABLE_ADD_OR_REPLACE_SUMMARY'
export const TENDERTABLE_REMOVE_SUMMARY = 'TENDERTABLE_REMOVE_SUMMARY'
export const TENDERTABLE_UPDATE = 'TENDERTABLE_UPDATE'
export const TENDERTABLE_CLEAR = 'TENDERTABLE_CLEAR'
export const TENDERTABLE_INSTRUCTION_TEMPLATE_SET_ALL = 'TENDERTABLE_INSTRUCTION_TEMPLATE_SET_ALL'
export const TENDERTABLEROW_SET = 'TENDERTABLEROW_SET'
export const TENDERTABLEROW_SET_FROMDTO = 'TENDERTABLEROW_SET_FROMDTO'
export const TENDERTABLEROW_CREATE = 'TENDERTABLEROW_CREATE'
export const TENDERTABLEROW_REORDER = 'TENDERTABLEROW_REORDER'
export const TENDERTABLEROW_DELETE = 'TENDERTABLEROW_DELETE'
export const TENDERTABLEROW_CLEAR = 'TENDERTABLEROW_CLEAR'
export const TENDERTABLECOLUMN_SET = 'TENDERTABLECOLUMN_SET'
export const TENDERTABLECOLUMN_SET_FROMDTO = 'TENDERTABLECOLUMN_SET_FROMDTO'
export const TENDERTABLECOLUMN_CREATE = 'TENDERTABLECOLUMN_CREATE'
export const TENDERTABLECOLUMN_REORDER = 'TENDERTABLECOLUMN_REORDER'
export const TENDERTABLECOLUMN_DELETE = 'TENDERTABLECOLUMN_DELETE'
export const TENDERTABLECOLUMN_CLEAR = 'TENDERTABLECOLUMN_CLEAR'
export const TENDERTABLEROWCOLUMN_SET = 'TENDERTABLEROWCOLUMN_SET'
export const TENDERTABLEROWCOLUMN_SET_FROMDTO = 'TENDERTABLEROWCOLUMN_SET_FROMDTO'
export const TENDERTABLEROWCOLUMN_CREATE = 'TENDERTABLEROWCOLUMN_CREATE'
export const TENDERTABLEROWCOLUMN_DELETE = 'TENDERTABLEROWCOLUMN_DELETE'
export const TENDERTABLEROWCOLUMN_CLEAR = 'TENDERTABLEROWCOLUMN_CLEAR'
export const TENDER_TYPE_SET_ALL = 'TENDER_TYPE_SET_ALL'

export const CONTRACT_EXTERNAL_SUPPLIERS_SET_ALL = 'CONTRACT_EXTERNAL_SUPPLIERS_SET_ALL'
export const CONTRACT_DATELIST_SET_ALL = 'CONTRACT_DATELIST_SET_ALL'

export const TERMS_SET_ALL = 'TERMS_SET_ALL'

export const USER_SET = 'USER_SET'
export const USER_SET_ALL = 'USER_SET_ALL'
export const USER_SET_PURCHASERS = 'USER_SET_PURCHASERS'
export const USER_CREATE = 'USER_CREATE'
export const USER_DELETE_USERS_FROM_GROUP = 'USER_DELETE_USERS_FROM_GROUP'
export const USER_ADD_GROUPS = 'USER_ADD_GROUPS'
export const USER_REPLACE_USERS_GROUP = 'USER_REPLACE_USERS_GROUP'
export const USER_DELETE_USERS_GROUP = 'USER_DELETE_USERS_GROUP'

export const VENDOR_SET = 'VENDOR_SET'
export const VENDOR_SET_SEARCH = 'VENDOR_SET_SEARCH'
export const VENDOR_LISTS_SET = 'VENDOR_LISTS_SET'
export const VENDOR_SET_SEARCH_TOTAL = 'VENDOR_SET_SEARCH_TOTAL'
export const VENDOR_LIST_SET = 'VENDOR_LIST_SET'
export const VENDOR_DOCUMENTS_SET = 'VENDOR_DOCUMENTS_SET'
export const VENDOR_DIVERSITY_CLASSIFICATION_TYPE_SET = 'VENDOR_DIVERSITY_CLASSIFICATION_TYPE_SET'

export const VENDOR_PERFORMANCE_SUMMARY_SET = 'VENDOR_PERFORMANCE_SUMMARY_SET'
export const VENDOR_PERFORMANCE = 'VENDOR_PERFORMANCE'
export const VENDOR_PERFORMANCE_REMOVE = 'VENDOR_PERFORMANCE_REMOVE'
export const VENDOR_PERFORMANCE_STATUS_LOG_SET = 'VENDOR_PERFORMANCE_STATUS_LOG_SET'
export const VENDOR_PERFORMANCE_STATUS_LOG_HISTORY_SET_ALL =
  'VENDOR_PERFORMANCE_STATUS_LOG_HISTORY_SET_ALL'
export const PERFORMANCE_STATUS_SET_SUMMARY = 'PERFORMANCE_STATUS_SET_SUMMARY'
export const PERFORMANCE_STATUS_EVALUATION_SET = 'PERFORMANCE_STATUS_EVALUATION_SET'

export const LOCALIZATION_SET_LOCALE = 'LOCALIZATION_SET_LOCALE'

export const INITIALIZE_CLIENT = 'INITIALIZE_CLIENT'

export const GET_TEAM = 'GET_TEAM'
export const SET_TEAM_UPDATED = 'SET_TEAM_UPDATED'

export const DASHBOARD_WIDGET_SET = 'DASHBOARD_WIDGET_SET'
export const DASHBOARD_USER_WIDGET_SET = 'DASHBOARD_USER_WIDGET_SET'
export const DASHBOARD_USER_PROJECTSLEAD_WIDGET_RESET = 'DASHBOARD_USER_PROJECTSLEAD_WIDGET_RESET'
export const DASHBOARD_TEAMMEMBER_PROJECT_WIDGET_RESET = 'DASHBOARD_TEAMMEMBER_PROJECT_WIDGET_RESET'

export const SUPPLIER_SEARCH_RESULTS_SET = 'SUPPLIER_SEARCH_RESULTS_SET'
export const SUPPLIER_SEARCH_TERM_SET = 'SUPPLIER_SEARCH_TERM_SET'

export const SUPPLIER_SUBMISSION_SUBMISSION_RESULTS_SET =
  'SUPPLIER_SUBMISSION_SUBMISSION_RESULTS_SET'
export const SUPPLIER_SUBMISSION_VENDORGUID_SET = 'SUPPLIER_SUBMISSION_VENDORGUID_SET'

export const PERMISSION_SET = 'PERMISSION_SET'

export const ROUTEPERMISSION_SET = 'ROUTEPERMISSION_SET'
export const ROUTEPERMISSION_USER_SET = 'ROUTEPERMISSION_USER_SET'

export const SSO_ACTIVE_SET = 'SSO_ACTIVE_SET'
export const SSO_CERT_SET = 'SSO_CERT_SET'
export const SSO_URL_SET = 'SSO_URL_SET'

export const APPROVAL_EXCEPTIONS_SET = 'APPROVAL_EXCEPTIONS_SET'
export const APPROVAL_EXCEPTION_SET = 'APPROVAL_EXCEPTION_SET'

export const PROCUREMENT_METHOD_LIMITED_TYPES_SET = 'PROCUREMENT_METHOD_LIMITED_TYPES_SET'
export const PROCUREMENT_METHOD_LIMITED_TYPE_SET = 'PROCUREMENT_METHOD_LIMITED_TYPE_SET'

export const PERFORMANCE_EVALUATION_TYPE_SET_ALL = 'PERFORMANCE_EVALUATION_TYPE_SET_ALL'

export const PERFORMANCE_TEMPLATE_SET_ALL = 'PERFORMANCE_TEMPLATE_SET_ALL'
export const PERFORMANCE_WORKFLOW_SET = 'PERFORMANCE_WORKFLOW_SET'

export const PERFORMANCE_STATUS_SET_ALL = 'PERFORMANCE_STATUS_SET_ALL'
export const PERFORMANCE_EVALUATION_STATUS_SET_ALL = 'PERFORMANCE_EVALUATION_STATUS_SET_ALL'

export const SNAPSHOT_LAYOUT_SET_LIST = 'SNAPSHOT_LAYOUT_SET_LIST'

export const FEATURES_SET = 'FEATURES_SET'

// global
export const SHOW_APP_BAR = 'SHOW_APP_BAR'
export const USER_NAV_MENU_SET = 'USER_NAV_MENU_SET '
export const ACTIVITY_FEED_MENU_SET = 'ACTIVITY_FEED_MENU_SET'
export const MESSAGE_CENTRE_MENU_SET = 'MESSAGE_CENTRE_MENU_SET'
export const PROJECT_SNAPSHOT_SET = 'PROJECT_SNAPSHOT_SET'
export const PROJECT_SNAPSHOT_TOGGLE = 'PROJECT_SNAPSHOT_TOGGLE'
export const NO_REDIRECT_SET = 'NO_REDIRECT_SET'
