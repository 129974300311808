import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  performanceStatuses: [],
  performanceSummary: null,
  // performanceStatusEvaluation: null || { satisfactory: Number, nonSatisfactory: Number }
  performanceStatusEvaluation: null
}
export const getters = {
  getPerformanceStatuses: (state) => state.performanceStatuses,
  getPerformanceSummary: (state) => state.performanceSummary,
  getPerformanceStatusEvaluation: (state) => state.performanceStatusEvaluation
}
export const actions = {
  fetchVendorPerformanceStatusAll: ({ commit }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/PerformanceStatuses/All`
    return api.get(url).then((response) => {
      let performanceStatuses = []
      if (response.data.count > 0) {
        performanceStatuses = response.data.data.performanceStatusDTO.values
        commit(types.PERFORMANCE_STATUS_SET_ALL, performanceStatuses)
      }
      return performanceStatuses
    })
  },
  fetchPerformanceStatusSummary: ({ commit }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/PerformanceStatuses/Summary`
    return api.get(url).then((response) => {
      const data = response?.data?.data?.performanceData?.values?.[0] || {}
      commit(types.PERFORMANCE_STATUS_SET_SUMMARY, data)
      return data
    })
  },
  fetchPerformanceStatusEvaluation: ({ commit }) => {
    const url = `api/v1/customer/{customerId}/node/{nodeId}/PerformanceStatuses/PerformanceEvaluation`
    return api.get(url).then((response) => {
      const data =
        response?.data?.data?.performanceEvaluationSatisfactoryReportDTO?.values?.[0] || null
      if (data) {
        const { satisfactory, nonSatisfactory } = data

        // Don't update the store if there are no evaluations that meet criteria
        if (satisfactory + nonSatisfactory > 0) {
          commit(types.PERFORMANCE_STATUS_EVALUATION_SET, data)
        }
      }
      return data
    })
  }
}

export const mutations = {
  [types.PERFORMANCE_STATUS_SET_ALL](state, performanceStatus) {
    state.performanceStatuses = performanceStatus
  },
  [types.PERFORMANCE_STATUS_SET_SUMMARY](state, performanceSummary) {
    state.performanceSummary = performanceSummary
  },
  [types.PERFORMANCE_STATUS_EVALUATION_SET](state, performanceStatusEvaluation) {
    state.performanceStatusEvaluation = performanceStatusEvaluation
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
