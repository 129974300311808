export default [
  {
    name: 'ProjectAwardSummary',
    path: 'summary',
    meta: { area: 'award' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/award/award-summary-view/award-summary-view.vue'
      )
  },
  {
    name: 'ProjectAwardApproval',
    path: 'approval',
    meta: { area: 'award' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/award/award-approval-view/award-approval-view.vue'
      )
  },
  {
    name: 'ProjectAwardAwards',
    path: 'awards',
    meta: { area: 'award' },
    component: () =>
      import('@/views/projects/project-view/project-sub-views/award/awards-view/awards-view.vue')
  }
]
