import { api } from '@/api/api'
import { useDataSanitizing } from '@/utils/dataSanitizing'

const { sanitizeData } = useDataSanitizing()

const actions = {
  // eslint-disable-next-line no-unused-vars
  sendForgotPassword: (_, { email }) => {
    if (!email) {
      return Promise.reject(new Error('Invalid email'))
    }

    // Request a password reset email
    const url = `/api/v1/auth/forgot-password`

    return api.post(url, { email })
  },
  // eslint-disable-next-line no-unused-vars
  validateResetPassword: async (_, { params }) => {
    const { email, token } = params

    if (!email || !token) {
      return Promise.reject(new Error('Invalid email or token'))
    }

    const sanitizedEmail = sanitizeData(email)
    const sanitizedToken = sanitizeData(token)

    const url = `/api/v1/auth/verify-email`
    const response = await api.post(url, { email: sanitizedEmail, token: sanitizedToken })

    const isValid = response.data?.data?.verifyPasswordRequest?.values[0]?.isValid

    if (isValid) return true
    return Promise.reject(response)
  },
  // eslint-disable-next-line no-unused-vars
  resetPassword: (_, { params }) => {
    const url = `/api/v1/auth/change-forgotten-password`

    const sanitizedEmail = sanitizeData(params.email)
    const sanitizedToken = sanitizeData(params.token)
    const sanitizedNewPassword = sanitizeData(params.newPassword)

    return api.post(url, {
      email: sanitizedEmail,
      token: sanitizedToken,
      newPassword: sanitizedNewPassword
    })
  }
}

export default {
  namespaced: false,
  actions
}
