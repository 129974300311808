import store from '@/store'
import { ref } from 'vue'

function ProtectLockedProjectSubreq() {
  return (to, from, next) => {
    const $store = ref(store)

    const project = $store.value.getters['getProject']
    const isLocked = $store.value.getters['getProjectLocked']

    if (!project || project.id !== to.params?.id || isLocked !== false) {
      next({ name: 'ProjectBuildSubreqsEdit', params: to.params })
    } else {
      next()
    }
  }
}

export default [
  {
    name: 'ProjectBuildSettings',
    path: 'settings',
    meta: { area: 'build' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/build/settings-view/settings-view.vue'
      )
  },
  {
    name: 'ProjectBuildDetails',
    path: 'details',
    meta: { area: 'build' },
    component: () =>
      import('@/views/projects/project-view/project-sub-views/build/details-view/details-view.vue')
  },
  {
    name: 'ProjectBuildDocuments',
    path: 'documents',
    meta: { area: 'build' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/build/document-upload-view/document-upload-view.vue'
      )
  },
  {
    name: 'ProjectBuildCompliance',
    path: 'compliance',
    meta: { area: 'build' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/build/compliance-view/compliance-view.vue'
      )
  },
  {
    name: 'ProjectBuildInvitations',
    path: 'invitations',
    meta: { area: 'build' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/build/invitations-view/invitations-view.vue'
      )
  },
  {
    name: 'ProjectBuildDates',
    path: 'dates',
    meta: { area: 'build' },
    component: () =>
      import('@/views/projects/project-view/project-sub-views/build/dates-view/dates-view.vue')
  },
  {
    name: 'ProjectPreparePublish',
    path: 'publish',
    meta: { area: 'build' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/build/prepare-publish-view/prepare-publish-view.vue'
      )
  },
  // Submission requirements
  {
    name: 'ProjectBuildSubreqs',
    path: 'submission-requirements',
    meta: { area: 'build' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/build/submission-requirements-view/submission-requirements-view.vue'
      )
  },
  {
    name: 'ProjectBuildSubreqsEdit',
    path: 'submission-requirements/edit',
    meta: { area: 'build' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/build/submission-requirements-view/submission-requirements-view.vue'
      )
  },
  {
    name: 'ProjectBuildSubreqsDocumentUploads',
    path: 'submission-requirements/documents',
    beforeEnter: ProtectLockedProjectSubreq(),
    meta: { area: 'build' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/build/submission-requirements-view/submission-requirements-subviews/document-uploads-subview/document-uploads-subview.vue'
      )
  },
  {
    name: 'ProjectBuildBuildInstructions',
    path: 'submission-requirements/instructions',
    beforeEnter: ProtectLockedProjectSubreq(),
    meta: { area: 'build' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/build/submission-requirements-view/submission-requirements-subviews/instructions-subview/instructions-subview.vue'
      )
  },
  {
    name: 'ProjectBuildPriceSchedule',
    path: 'submission-requirements/prices',
    beforeEnter: ProtectLockedProjectSubreq(),
    meta: { area: 'build' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/build/submission-requirements-view/submission-requirements-subviews/prices-schedule-subview/prices-schedule-subview.vue'
      )
  },
  {
    name: 'ProjectBuildQuestions',
    path: 'submission-requirements/questions',
    beforeEnter: ProtectLockedProjectSubreq(),
    meta: { area: 'build' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/build/submission-requirements-view/submission-requirements-subviews/questions-subview/questions-subview.vue'
      )
  },
  {
    name: 'ProjectBuildReferences',
    path: 'submission-requirements/references',
    beforeEnter: ProtectLockedProjectSubreq(),
    meta: { area: 'build' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/build/submission-requirements-view/submission-requirements-subviews/references-subview/references-subview.vue'
      )
  },
  {
    name: 'ProjectBuildSecurity',
    path: 'submission-requirements/security',
    beforeEnter: ProtectLockedProjectSubreq(),
    meta: { area: 'build' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/build/submission-requirements-view/submission-requirements-subviews/security-subview/security-subview.vue'
      )
  },
  {
    name: 'ProjectBuildSpecifications',
    path: 'submission-requirements/specifications',
    beforeEnter: ProtectLockedProjectSubreq(),
    meta: { area: 'build' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/build/submission-requirements-view/submission-requirements-subviews/specifications-subview/specifications-subview.vue'
      )
  },
  {
    name: 'ProjectBuildSubcontractors',
    path: 'submission-requirements/subcontractors',
    beforeEnter: ProtectLockedProjectSubreq(),
    meta: { area: 'build' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/build/submission-requirements-view/submission-requirements-subviews/subcontractors-subview/subcontractors-subview.vue'
      )
  },
  {
    name: 'ProjectBuildTerms',
    path: 'submission-requirements/terms',
    beforeEnter: ProtectLockedProjectSubreq(),
    meta: { area: 'build' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/build/submission-requirements-view/submission-requirements-subviews/terms-subview/terms-subview.vue'
      )
  }
]
