import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  contractPOTypes: []
}

export const getters = {
  getContractPOTypes: (state) => state.contractPOTypes
}
export const actions = {
  fetchContractPOTypes: ({ commit }) => {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/ContractPOTypes'
    return api.get(url).then((response) => {
      const contractPOTypes = response?.data?.data?.contractPOTypeDTO?.values || []
      commit(types.CONTRACTPOTYPES_TYPES_SET, contractPOTypes)
      return contractPOTypes
    })
  }
}
export const mutations = {
  [types.CONTRACTPOTYPES_TYPES_SET](state, contractPOTypes) {
    state.contractPOTypes = contractPOTypes
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
