import { api } from '@/api/api'
import util from '@/utils/utility'
import * as types from '../../mutation-types'

const state = {
  tenderTableColumns: [],
  currColumn: null
}

export const getters = {
  getTenderTableColumns: (state) => state.tenderTableColumns,
  getTenderTableColumn: (state) => (id) => {
    return state.tenderTableColumns.find((ttc) => ttc.id === id)
  },
  getCurrColumn: (state) => state.currColumn
}
export const actions = {
  fetchTenderTableColumns: ({ commit }, tenderTableId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/TenderTableColumns/${tenderTableId}`
    return api.get(url).then((response) => {
      commit(types.TENDERTABLECOLUMN_SET_FROMDTO, {
        tenderTableColumns: response.data.data.tenderTableColumnDTO.values,
        tenderTableId
      })
      return response
    })
  },
  createTenderTableColumn: ({ commit }, tenderTableId) => {
    commit(types.TENDERTABLECOLUMN_CREATE, tenderTableId)
  },
  setTenderTableColumns: ({ commit }, props) => {
    commit(types.TENDERTABLECOLUMN_SET_FROMDTO, props)
  },
  setTenderTableColumn: ({ commit }, props) => {
    commit(types.TENDERTABLECOLUMN_SET, props)
  },
  reorderTenderTableColumns: ({ commit }, props) => {
    commit(types.TENDERTABLECOLUMN_REORDER, props)
  },
  deleteTenderTableColumn: ({ commit }, tenderTableColumnId) => {
    commit(types.TENDERTABLECOLUMN_DELETE, tenderTableColumnId)
  },
  clearTenderTableColumns: ({ commit }) => {
    commit(types.TENDERTABLECOLUMN_CLEAR)
  }
}
export const mutations = {
  [types.TENDERTABLECOLUMN_SET_FROMDTO](state, vals) {
    state.tenderTableColumns = vals.tenderTableColumns
      .map((ttc) => {
        return {
          id: ttc.id,
          tenderTableId: vals.tenderTableId,
          name: ttc.name,
          columnTypeName: ttc.columnTypeName,
          sortOrder: ttc.sortOrder,
          isEditableonPublic: ttc.isEditableonPublic,
          columnWidth: ttc.columnWidth,
          isRequired: ttc.isRequired,
          isSubtotalIncluded: ttc.isSubtotalIncluded,
          isHiddenOnPublic: ttc.isHiddenOnPublic,
          deleted: ttc.deleted,
          tenderTableTotalCalculation: ttc.tenderTableTotalCalculation
        }
      })
      .sort((a, b) => {
        return a.sortOrder - b.sortOrder
      })
  },
  [types.TENDERTABLECOLUMN_SET](state, updatedCol) {
    const colIndex = state.tenderTableColumns.findIndex((ttc) => ttc.id === updatedCol.id)

    if (colIndex === -1) return false

    Object.assign(state.tenderTableColumns[colIndex], updatedCol)
  },
  [types.TENDERTABLECOLUMN_CREATE](state, tenderTableId) {
    const nextSortOrder = state.tenderTableColumns.filter((ttc) => ttc.deleted === false).length

    state.currColumn = {
      id: util.guid(),
      tenderTableId: tenderTableId,
      name: 'New Column',
      columnTypeName: 'DescriptiveText',
      sortOrder: nextSortOrder,
      isEditableonPublic: false,
      columnWidth: 150,
      isRequired: false,
      isSubtotalIncluded: false,
      isHiddenOnPublic: false,
      deleted: false
    }

    state.tenderTableColumns.push(state.currColumn)
  },
  [types.TENDERTABLECOLUMN_REORDER](state, props) {
    const newTargetIndex =
      props.target <= props.columns[0] ? props.target : props.target - props.columns.length

    // only reorder if col and target are different
    if (newTargetIndex !== props.columns[0]) {
      const movedElems = state.tenderTableColumns.splice(props.columns[0], props.columns.length)
      state.tenderTableColumns.splice(newTargetIndex, 0, ...movedElems)
    }

    let newIndex = 0
    state.tenderTableColumns.forEach((ttc) => {
      if (ttc.deleted === false) {
        ttc.sortOrder = newIndex++
      }
    })
  },
  [types.TENDERTABLECOLUMN_DELETE](state, tenderTableColumnId) {
    const matchedCol = state.tenderTableColumns.find((ttc) => ttc.id === tenderTableColumnId)
    matchedCol.deleted = true
    matchedCol.sortOrder = 9999

    // resort the array
    state.tenderTableColumns.sort(function (a, b) {
      return a.sortOrder - b.sortOrder
    })

    // update the sortOrder
    let newIndex = 0
    state.tenderTableColumns.forEach((ttc) => {
      if (ttc.deleted === false) {
        ttc.sortOrder = newIndex++
      }
    })
  },
  [types.TENDERTABLECOLUMN_CLEAR](state) {
    state.tenderTableColumns = []
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
