export default {
  props: {
    visible: Boolean
  },
  computed: {
    transitionName() {
      return this.dir > 0 ? 'slideleft' : 'slideright'
    },
    onFirstStep() {
      return this.currentStepNumber === 1
    },
    onLastStep() {
      return this.currentStepNumber === this.totalSteps
    },
    getStepClass() {
      const stepClasses = [`is-step-${this.currentStepNumber}`]
      if (this.onLastStep) stepClasses.push('is-last-step')
      return stepClasses.join(' ')
    },
    isVisible: {
      get() {
        return this.visible
      },
      set() {
        this.handleClickClose()
      }
    }
  },
  data() {
    return {
      currentStepNumber: 1,
      dir: 1,
      totalSteps: 4
    }
  },
  methods: {
    handleClickNext() {
      if (this.onLastStep) return
      this.dir = 1
      this.$nextTick(() => {
        this.currentStepNumber = parseInt(this.currentStepNumber) + 1
      })
    },
    handleClickPrevious() {
      if (this.onFirstStep) return
      this.dir = -1
      this.$nextTick(() => {
        this.currentStepNumber = parseInt(this.currentStepNumber) - 1
      })
    },
    handleClickClose() {
      this.reset()
      this.$emit('close')
    }
  }
}
