<script setup>
import PieChart from '@/components/pie-chart/pie-chart.vue'
import { computed, toRefs } from 'vue'

const props = defineProps({
  datasets: [Array, Object],
  heading: String,
  percentage: [String, Number],
  expired: String,
  inprogress: String,
  interim: String,
  final: String,
  custom: String,
  textColor: String,
  chartSize: {
    type: String,
    default: '200px'
  }
})

const { expired, inprogress, interim, final, custom } = toRefs(props)

const hasSubtext = computed(() => {
  return expired.value || inprogress.value || interim.value || final.value || custom.value
})
</script>

<template>
  <div class="chart-data-card" v-bind="$attrs">
    <app-title size="4" heading="3" class="chart-data-heading has-no-margin-bottom" centered>
      {{ props.heading }}
    </app-title>
    <PieChart
      :chart-data="{ datasets: props.datasets }"
      :aria-label="`chart-${props.heading}`"
      class="chart"
      role="img"
      :size="props.chartSize"
      :text-color="props.textColor"
    >
      <template #percentage>{{ props.percentage }}</template>
    </PieChart>
    <div v-if="hasSubtext" class="chart-data-column__subtext">
      <p v-if="props.expired">{{ props.expired }}</p>
      <p v-if="props.inprogress">{{ props.inprogress }}</p>
      <p v-if="props.interim">{{ props.interim }}</p>
      <p v-if="props.final">{{ props.final }}</p>
    </div>
  </div>
</template>

<style lang="scss">
.chart-data-card {
  min-width: 200px;
  position: relative;
  height: 100%;
  font-size: $size-6;
  padding: $gap-small $gap-tiny;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  .chart-data-heading {
    display: flex;
    width: 100%;
  }

  .chart {
    margin: 16px 0;
  }
}
</style>
