import { api } from '@/api/api'
import util from '@/utils/utility'
import * as types from '../../mutation-types'

const state = {
  tender: null,
  tenderTypes: [],
  tenderSearch: []
}

export const getters = {
  getTender: (state) => state.tender,
  getTenderTypes: (state) => state.tenderTypes,
  getTenderSearch: (state) => state.tenderSearch
}
export const actions = {
  fetchTender: ({ commit }, tenderId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Tenders?tenderId=${tenderId}&filter.fields=Tender`
    return api.get(url).then((response) => {
      const data = response.data.data.tenderDTO.values[0]
      commit(types.TENDER_SET, data)
      return data
    })
  },
  fetchTenderSearch: ({ commit }, params) => {
    let url = '/api/v1/customer/{customerId}/node/{nodeId}/Tenders/Search?'
    let connectAmpersand = false

    if (params.limit) {
      url += `filter.limit=${params.limit}`
      connectAmpersand = true
    }

    if (params.start) {
      url += `${connectAmpersand ? '&' : ''}filter.start=${params.start}`
      connectAmpersand = true
    }

    if (params.dateFrom && params.dateFrom.length > 0) {
      url += `${connectAmpersand ? '&' : ''}filter.dateFrom=${
        params.dateFrom ? new Date(params.dateFrom).toISOString() : ''
      }`
      connectAmpersand = true
    }

    if (params.dateTo && params.dateTo.length > 0) {
      url += `${connectAmpersand ? '&' : ''}filter.dateTo=${
        params.dateTo ? new Date(params.dateTo).toISOString() : ''
      }`
      connectAmpersand = true
    }

    if (params.status && params.status.length > 0) {
      url += `${connectAmpersand ? '&' : ''}filter.status=${params.status}`
      connectAmpersand = true
    }

    if (params.searchText && params.searchText.length > 0) {
      url += `${connectAmpersand ? '&' : ''}filter.searchText=${params.searchText}`
    }

    return api
      .get(url)
      .then((response) => {
        const result = response.data.data.searchResult
          ? response.data.data.searchResult.values
          : null
        commit(types.TENDER_SET_SEARCH, result)
        return new Promise(function (resolve) {
          resolve(result)
        })
      })
      .catch((err) => {
        commit(types.TENDER_SET_SEARCH, null)
        throw err
      })
  },
  resetTenderSearch: ({ commit }) => {
    commit(types.TENDER_SET_SEARCH, null)
  },
  fetchTenderTypes: ({ commit }) => {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/TenderType'
    return api.get(url).then((result) => {
      const tenderTypes = result.data.data.tenderTypeDTO.values
      commit(types.TENDER_TYPE_SET_ALL, tenderTypes)
      return tenderTypes
    })
  }
}
export const mutations = {
  [types.TENDER_SET](state, tender) {
    state.tender = {
      id: tender ? tender.id : util.guid(),
      number: tender ? tender.number : '',
      name: tender ? tender.name : '',
      submissionNoteDocument: tender ? tender.submissionNoteDocument : ''
    }
  },
  [types.TENDER_TYPE_SET_ALL](state, tenderTypes) {
    state.tenderTypes = tenderTypes
  },
  [types.TENDER_SET_SEARCH](state, tenders) {
    state.tenderSearch = tenders
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
