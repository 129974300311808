<template>
  <div ref="wrapper" class="app-input-file__wrapper">
    <div :class="{ 'has-upload': hasUploadControl }" class="app-input-file is-flex">
      <label
        :for="id"
        :class="{ 'is-required': required }"
        @focus="focused = true"
        @blur="focused = false"
        @mouseover="hovering = true"
        @mouseout="hovering = false"
      >
        <span class="label-text">
          {{ label }}
          <app-permission-tooltip
            v-if="!hasPermissionToEdit"
            class="permission-icon has-margin-left-tiny"
          />
        </span>
      </label>
      <input
        :id="id"
        ref="input"
        :name="name"
        :disabled="disabled || !hasPermissionToEdit"
        :accept="accept"
        :multiple="multiple"
        type="file"
        class="app-input-file__input"
        @change="handleChange"
      />
      <div :class="{ 'is-required': required && !fileText }" class="input has-margin-right-tiny">
        <span v-if="fileText" class="input-file-name">{{ fileText }}</span>
        <span v-else class="default-text">{{ defaultText }}</span>
        <span
          v-if="clearable && hasValue"
          class="icon is-right is-small app-input-file__clear-btn"
          aria-label="Clear Input"
          tabindex="0"
          role="button"
          @click="clear"
          @keyup.enter="clear"
        >
          <app-icon icon="x-circle"></app-icon>
        </span>
      </div>
      <div class="app-input-file__actions-right">
        <app-button
          v-if="hasBrowseControl"
          :class="{ 'has-margin-right-tiny': hasUploadControl }"
          @click="handleClickBrowse"
        >
          {{ $t('ui.components.app-input-file.browse') }}
          <app-permission-tooltip
            v-if="!hasPermissionToEdit"
            class="permission-icon has-margin-left-tiny"
          />
        </app-button>
        <app-button
          v-show="hasUploadControl"
          :disabled="disableUploadBtn"
          type="primary"
          @click="handleClickUpload"
        >
          {{ $t('ui.common.upload') }}
          <app-permission-tooltip
            v-if="!hasPermissionToEdit"
            class="permission-icon has-margin-left-tiny"
          />
        </app-button>
      </div>
    </div>
    <div class="app-input-file__messages">
      <div v-if="isUploaded && !isUploadedError" class="upload-indicator">
        {{ $t('ui.components.app-input-file.uploadIndicatorSuccess') }}
      </div>
      <div v-if="isUploadedError && !isUploaded" class="upload-indicator upload-error">
        {{ $t('ui.components.app-input-file.uploadIndicatorSuccess') }}
      </div>
    </div>
  </div>
</template>

<script>
import InputPermissionMixins from '@/mixins/input-permission-mixins'
import uuid from 'uuid/v1'

export default {
  name: 'app-input-file',
  mixins: [InputPermissionMixins],
  props: {
    value: null,
    name: {
      type: String,
      default: `fileUpload_${uuid()}`
    },
    accept: {
      type: String,
      default: '*'
    },
    label: String,
    id: {
      type: String,
      default: uuid()
    },
    required: Boolean,
    placeholder: String,
    onUpload: Function,
    multiple: Boolean,
    clearable: {
      type: Boolean,
      default: true
    },
    browseControl: {
      type: Boolean,
      default: true
    },
    uploadControl: {
      type: Boolean,
      default: true
    },
    targetObject: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      files: null,
      fileText: null,
      focused: false,
      hovering: false,
      disableUploadBtn: true,
      isUploadedError: false,
      isUploaded: false,
      reader: null
    }
  },
  computed: {
    hasActionsRight() {
      return this.$slots['actionsRight']
    },
    hasBrowseControl() {
      return this.browseControl
    },
    hasUploadControl() {
      return this.uploadControl
    },
    defaultText() {
      if (this.label) return this.label
      if (this.placeholder) return this.placeholder
      return this.$t('ui.components.app-input-file.placeholderDefault')
    },
    hasValue() {
      return this.$refs.input !== null
    },
    getFiles() {
      return this.getInputElementFiles()
    },
    getInput() {
      return this.$refs.input
    }
  },
  watch: {
    value(val) {
      if (val === null) {
        this.clear()
      }
    }
  },
  methods: {
    handleClickBrowse() {
      this.$refs.input.click()
    },
    handleChange(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files) return
      this.handleNewFiles(files)
      // a blank value indicates the user clicked cancel, done enable the button
      if (this.$refs.input.value !== '') {
        this.disableUploadBtn = false
      }

      this.isUploaded = false
      this.isUploadedError = false
      this.$emit('input', [this.$refs.input.files])
    },
    handleNewFiles(files) {
      let postFiles = Array.prototype.slice.call(files)
      if (!this.multiple) {
        postFiles = postFiles.slice(0, 1)
      }
      if (postFiles.length === 0) {
        return
      }
      this.fileText = postFiles.map((f) => f.name).join(', ')
    },
    handleClickUpload() {
      if (!this.getInputElementFiles().length) return false
      this.disableUploadBtn = true
      // We have a target object, assign the base64 data to it
      if (this.targetObject !== null) {
        const uploadFile = this.$refs.input.files[0]
        this.reader = new FileReader()

        window.postFileLoad = {
          targetObject: this.targetObject,
          reader: this.reader,
          name: uploadFile.name
        }

        this.reader.addEventListener(
          'load',
          function () {
            window.postFileLoad.targetObject.fileData = {
              name: window.postFileLoad.name,
              data: window.postFileLoad.reader.result
            }
          },
          false
        )

        if (uploadFile) {
          this.reader.readAsDataURL(uploadFile)
          this.isUploaded = true
        } else {
          this.isUploaded = false
          this.isUploadedError = true
        }
      }

      this.$emit('uploaded', this.$refs.input.files)
    },
    clear(e) {
      if (e) {
        e.preventDefault()
      }

      if (this.$refs.input !== null) {
        this.$refs.input.value = null
        this.$refs.input.files = null
      }

      this.fileText = null
      this.disableUploadBtn = true

      this.isUploaded = false
      this.isUploadedError = false

      this.$emit('change', null)
      this.$emit('clear')
    },
    getInputElementFiles() {
      return this.$refs.input.files
    }
  }
}
</script>

<style lang="scss">
$input-text-size: $size-4;

.app-input-file {
  display: flex;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: $input-height;
  justify-content: flex-start;
  align-items: center;

  &__wrapper {
    width: 100%;
  }

  &__clear-btn {
    position: absolute;
    right: 10px;
    top: calc(#{$input-height} / 2);
    transform: translateY(-50%);
  }

  &__actions-right {
    display: flex;
    flex-flow: row nowrap;
    margin-left: auto;
    flex: 1 1 0;
    width: 78px;

    .has-upload & {
      width: 110px;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    left: -9999px;
  }

  .input {
    height: $input-height;
    position: relative;
    font-size: $input-text-size;

    max-width: calc(100% - 80px);

    &-file-name {
      width: calc(100% - 20px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: $input-text-size;
      font-weight: 500;
    }

    &.is-required {
      &:after {
        content: '*';
        color: $c-danger;
        margin-left: 5px;
      }
    }
  }

  &.has-upload {
    .input {
      max-width: calc(100% - 150px);
    }
  }

  .app-button {
    height: $input-height;
  }

  .label-text {
    position: absolute;
    left: -9999px;
    visibility: hidden;
  }

  .default-text {
    font-size: $input-text-size;
    color: $c-text-light;
    padding-left: 4px;
  }

  &__messages {
    display: flex;
    justify-content: center;
    padding: $gap-tiny;

    .upload-indicator {
      font-size: $size-6;
      color: $c-primary;

      &.upload-error {
        color: $c-danger;
      }
    }
  }
}
</style>
