import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  performanceWorkflow: []
}
export const getters = {
  getPerformanceWorkflow: (state) => state.performanceWorkflow
}
export const actions = {
  fetchPerformanceWorkflow: ({ commit }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/PerformanceWorkflow`

    return api.get(url).then((response) => {
      const performanceWorkflow = response?.data?.data?.performanceWorkflowDTO?.values || []
      commit(types.PERFORMANCE_WORKFLOW_SET, performanceWorkflow)
      return performanceWorkflow
    })
  }
}
export const mutations = {
  [types.PERFORMANCE_WORKFLOW_SET]: (state, templates) => {
    state.performanceWorkflow = templates
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
