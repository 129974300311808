<script setup>
import AppDropdown from '@/components/app-dropdown/app-dropdown.vue'
import { useStore } from '@/store'
import { computed, ref, nextTick } from 'vue'
import i18n from '@/plugins/i18n'
import { useErrorNotification } from '@/utils/error'
import { useRouter } from 'vue-router/composables'
import { useElementLoading } from '@/utils/ui'

const $store = useStore()
const $router = useRouter()
const { notifyApiError } = useErrorNotification()
const { loader, showLoading, hideLoading } = useElementLoading()

const switchAgency = ref(($store.getters.getAuthCustomer || {}).id || '')
const drpSwitchAgencyEl = ref(null)

const isSiteAdmin = computed(() => {
  return $store.getters.getIsSiteAdmin
})

const user = computed(() => {
  return $store.getters.getLoggedInUser
})

const switchAgencyBlocked = computed(() => {
  return (
    $store.getters.getProjectSaveRequired ||
    $store.getters.getTenderNodeSaveRequired ||
    $store.getters['controlPanel/getControlPanelSaveRequired']
  )
})

const customerAll = computed(() => {
  return $store.getters.getCustomerAll || []
})

const myProfileUrl = computed(() => {
  const userId = ($store.getters.getAuthUser || {}).id || ''
  return `/team/teammember/${userId}`
})

function switchAgencyAction(val, event) {
  if (switchAgencyBlocked.value) {
    notifyApiError({
      title: '',
      message: i18n.t('ui.common.warningUnsavedBeforeSwitching'),
      type: 'warning'
    })
    return
  }

  switchAgency.value = event
  const customer = customerAll.value?.filter((customer) => customer.customerId === event)[0]

  // // Todo: need to be replaced by API Call
  const model = {
    customerId: customer.customerId,
    userId: ($store.getters.getAuthUser || {}).id || '',
    customerNodeId: customer.customerNodeId,
    nodeId: customer.tenderNodeId
  }
  $store.dispatch('setAuthCredentials', model)

  // // go to root of the site
  this.$router.push('/')

  // // reload
  this.$router.go()
}

async function logoutUser() {
  await nextTick()
  $store.dispatch('clearAllSubmissionsNotifications')

  showLoading()
  try {
    await $store.dispatch('logout')
    $router.push('/login')
  } catch (e) {
    notifyApiError(e)
  } finally {
    if (loader.value) hideLoading()
  }
}
</script>

<template>
  <nav id="app-user-nav">
    <AppDropdown
      id="app-user-nav__dropdown"
      menu-classes="app-user-nav__menu"
      menu-position="right"
    >
      <template #trigger="{ toggleDropdown }">
        <app-button
          class="is-clear app-user-nav-account-button has-padding-left has-full-height has-padding-right"
          @click="toggleDropdown"
        >
          <app-avatar
            :src="user.avatar"
            :alt="user.fullName"
            type="circle"
            class="has-no-margin-right"
          ></app-avatar>
          <app-icon icon="chevron-down" size="small" color="white" />
        </app-button>
      </template>
      <ul role="menu">
        <li class="is-flex is-aligned-center is-full-width has-padding">
          <app-avatar :src="user.avatar" :alt="user.fullName" size="large" />
          <app-title size="2" heading="2" class="has-no-margin-bottom has-margin-left-small">
            {{ user.fullName }}
          </app-title>
        </li>
        <li v-if="isSiteAdmin" class="has-full-width has-padding">
          <app-select
            id="drpSwitchAgency"
            ref="drpSwitchAgencyEl"
            class="has-no-margin-bottom"
            :value="switchAgency"
            :label="$t('ui.components.navBar.general.switchAgency')"
            filterable
            @change="switchAgencyAction(switchAgency, $event)"
          >
            <el-option
              v-for="c in customerAll"
              :key="c.customerId"
              :value="c.customerId"
              :label="`${c.customerName}`"
            />
          </app-select>
        </li>
        <li class="has-full-width has-padding">
          <router-link id="lnkMyProfile" :to="myProfileUrl">
            <app-icon icon="user" class="has-margin-right-small" />{{
              $t('ui.components.navBar.user.popup.settings')
            }}
          </router-link>
        </li>
        <li class="has-full-width has-padding">
          <a
            id="lnkLogout"
            role="button"
            tabindex="0"
            @keydown.enter="logoutUser()"
            @click="logoutUser()"
          >
            <app-icon icon="log-out" class="has-margin-right-small" />
            {{ $t('ui.components.navBar.user.popup.logout') }}
          </a>
        </li>
      </ul>
    </AppDropdown>
  </nav>
</template>

<style lang="scss">
#app-user-nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#app-user-nav__dropdown {
  height: 100%;
}

.app-user-nav-account-button {
  height: 100%;

  &:hover,
  &:focus {
    background-color: $c-text !important;
  }
  &:focus-visible {
    box-shadow: $focus-shadow !important;
  }
}

.app-user-nav__menu {
  width: 265px;
  padding: 0 !important;

  li {
    border-bottom: 1px solid $c-border;

    &:last-child {
      border-bottom: none;
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;

    &:focus-visible {
      box-shadow: $focus-shadow !important;
    }
  }
}
</style>
