import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  leadAgencyName: [],
  leadAgencyNameNew: null
}

export const getters = {
  getLeadAgencyName: (state) => state.leadAgencyName,
  getLeadAgencyNameNew: (state) => state.leadAgencyNameNew
}

export const actions = {
  fetchLeadAgencyName: ({ commit }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/LeadAgencyName`

    return api.get(url).then((response) => {
      if (response.data.count > 0) {
        const leadAgencyName = response.data.data.leadAgencyNameDTO.values
        commit(types.LEAD_AGENCY_NAME_SET_ALL, leadAgencyName)
        return leadAgencyName
      }
    })
  },
  saveLeadAgencyName: ({ commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/LeadAgencyName`
    return api.post(url, model).then((response) => {
      const leadAgencyName = response.data.data.leadAgencyNameDTO.values[0]
      commit(types.LEAD_AGENCY_NAME_SET, leadAgencyName)
      return leadAgencyName
    })
  },
  updateLeadAgencyName: ({ commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/LeadAgencyName`
    return api.put(url, model).then((response) => {
      const leadAgencyName = response.data.data.leadAgencyNameDTO.values[0]
      commit(types.LEAD_AGENCY_NAME_SET, leadAgencyName)
      return leadAgencyName
    })
  },
  deleteLeadAgencyName: (_, id) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/LeadAgencyName?leadAgencyNameId=${id}`
    return api.delete(url)
  }
}

export const mutations = {
  [types.LEAD_AGENCY_NAME_SET_ALL](state, leadAgencyName) {
    state.leadAgencyName = leadAgencyName
  },
  [types.LEAD_AGENCY_NAME_SET](state, leadAgencyName) {
    state.leadAgencyNameNew = leadAgencyName
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
