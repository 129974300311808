<template>
  <app-grid class="has-border has-padding-small has-margin-bottom-large agency-actiity-container">
    <div slot="actionsRight" class="is-flex">
      <app-grid-actions
        :grid-page-size="gridPageSize"
        :grid-current-page="gridCurrentPage"
        :grid-total-pages="gridTotalPages"
        :grid-total-rows="gridTotalRows"
        :column-defs="columnDefs"
        :has-export="true"
        @handleClickPrev="handleClickPrev"
        @handleClickNext="handleClickNext"
        @handleGridPageSizeChange="handleGridPageSizeChange"
        @handleExport="handleExport"
      >
      </app-grid-actions>
    </div>
    <el-table
      ref="activityTable"
      :data="activitiesByPage"
      stripe
      fit
      width="100%"
      @sort-change="handleSortChange"
    >
      <el-table-column
        v-for="column in filteredColumnDefs"
        :key="column.field"
        :prop="column.field"
        :label="column.columnHeading"
        sortable
      >
        <template slot-scope="props">
          <span>{{ props.row[column.field] }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div slot="actionsBottomRight" class="is-flex">
      <app-grid-actions
        :grid-page-size="gridPageSize"
        :grid-current-page="gridCurrentPage"
        :grid-total-pages="gridTotalPages"
        :grid-total-rows="gridTotalRows"
        :column-defs="columnDefs"
        :has-export="true"
        @handleClickPrev="handleClickPrev"
        @handleClickNext="handleClickNext"
        @handleGridPageSizeChange="handleGridPageSizeChange"
        @handleExport="handleExport"
      >
      </app-grid-actions>
    </div>
  </app-grid>
</template>

<script>
import tableMixins from '@/mixins/table-mixins.js'
import { exportTableToXLS } from '@/utils/exportTable'

export default {
  name: 'agency-activity',
  mixins: [tableMixins],
  props: {
    viewString: String,
    activityData: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      activityList: this.activityData,
      gridCurrentPage: 1,
      gridPageSize: 20,
      columnDefs: [
        {
          columnHeading: this.$t(`ui.views.dashboard.contract-builder-dashboard-view.date`),
          field: 'date',
          visible: true
        },
        {
          columnHeading: this.$t(`ui.views.dashboard.contract-builder-dashboard-view.type`),
          field: 'type',
          visible: true
        },
        {
          columnHeading: this.$t(`ui.views.dashboard.contract-builder-dashboard-view.activity`),
          field: 'activity',
          visible: true
        }
      ]
    }
  },
  computed: {
    gridTotalRows() {
      return this.activityList.length
    },
    gridTotalPages() {
      return Math.ceil(this.activityList.length / this.gridPageSize)
    },
    activitiesByPage() {
      const start = (this.gridCurrentPage - 1) * this.gridPageSize
      return this.activityList.slice(start, start + this.gridPageSize)
    },
    filteredColumnDefs() {
      return this.columnDefs.filter((col) => col.visible)
    }
  },
  methods: {
    handleSortChange(row) {
      this.activityList = this.standardTableSortWithDate(row, this.activityList, ['date'])
    },
    handleExport() {
      exportTableToXLS('Agency Activity', this.activityList, this.visibleColumns)
    }
  }
}
</script>
