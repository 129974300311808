import { api } from '@/api/api'
import * as types from '../../mutation-types'

const state = {
  layoutList: null
}

export const getters = {
  getLayoutList: (state) => {
    if (state.layoutList)
      return state.layoutList.map((l) => {
        return {
          x: l.hStart,
          y: l.vStart,
          w: l.width,
          h: l.height,
          i: l.id,
          component: l.widgetId
        }
      })
    else return []
  }
}

export const actions = {
  fetchLayoutList: async ({ commit }) => {
    const url = '/api/v1/Snapshot/Layout'
    const response = await api.get(url)
    if (response.data.count !== 0) {
      const layoutList = response.data.data.snapshotLayoutDTO.values
      commit(types.SNAPSHOT_LAYOUT_SET_LIST, layoutList)
    }
  },
  saveLayoutList: async ({ commit }, list) => {
    const serverList = list.map((l) => {
      return {
        hStart: l.x,
        vStart: l.y,
        width: l.w,
        height: l.h,
        id: l.i,
        widgetId: l.component
      }
    })
    const url = '/api/v1/Snapshot/Layout'
    // save the changes
    await api.put(url, serverList)
    // go get the result (with server assigned IDs)
    const getResp = await api.get(url)
    if (getResp.data.count !== 0) {
      const layoutList = getResp.data.data.snapshotLayoutDTO.values
      commit(types.SNAPSHOT_LAYOUT_SET_LIST, layoutList)
    }
  }
}

export const mutations = {
  [types.SNAPSHOT_LAYOUT_SET_LIST](state, layoutList) {
    state.layoutList = layoutList
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
