import { api, uploadApi } from '@/api/api'
import i18n from '@/plugins/i18n.js'
import * as types from '../../mutation-types'

export const state = {
  contractPerformanceRating: [],
  contractPerformanceFinalRating: [],
  contractPerformanceStatistics: [],
  contractPerformanceEvaluation: [],
  contractPerformancePreviewLinks: [],
  performanceEvaluation: []
}

export const getters = {
  getContractPerformanceRating: (state) => state.contractPerformanceRating,
  getContractPerformanceFinalRating: (state) => state.contractPerformanceFinalRating,
  getContractPerformanceStatistics: (state) => state.contractPerformanceStatistics,
  getContractPerformanceEvaluation: (state) => state.contractPerformanceEvaluation,
  getContractPerformancePreviewLinks: (state) => state.contractPerformancePreviewLinks,
  getPerformanceEvaluation: (state) => state.performanceEvaluation
}
export const actions = {
  createContractPerformance(_, model) {
    const url = `api/v1/customer/{customerId}/node/{nodeId}/PerformanceEvaluation`
    return api.post(url, model).then((response) => {
      const performanceEvaluationDTO = response.data.data.performanceEvaluationDTO.values[0]
      return performanceEvaluationDTO
    })
  },
  updateContractPerformance(_, model) {
    const url = `api/v1/customer/{customerId}/node/{nodeId}/PerformanceEvaluation`
    return api.put(url, model).then((response) => {
      const performanceEvaluationDTO = response.data.data.performanceEvaluationDTO.values[0]
      return performanceEvaluationDTO
    })
  },
  fetchContractVendorsPerformanceRating: ({ commit }, contractId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/PerformanceEvaluation/ContractVendorsPerformanceRating?contractId=${contractId}`
    return api.get(url).then((response) => {
      let performanceRatingDTO = []
      if (response.data.count > 0) {
        performanceRatingDTO = response.data.data.performanceEvaluationDTO.values
        commit(types.CONTRACT_PERFORMANCE_RATING_SET, performanceRatingDTO)
      }
      return performanceRatingDTO
    })
  },
  fetchContractPerformanceEvaluation: ({ commit }, contractId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Contracts/PerformanceEvaluation?contractId=${contractId}`
    commit(types.CONTRACT_PERFORMANCE_ADD, [])
    return api.get(url).then((response) => {
      const contractPerformanceEvaluation = response?.data?.data?.evaluationSummary?.values || []
      commit(types.CONTRACT_PERFORMANCE_ADD, contractPerformanceEvaluation)
      return contractPerformanceEvaluation
    })
  },
  fetchPerformanceEvaluation: ({ commit }, performanceEvaluationId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/PerformanceEvaluation/?performanceEvaluationId=${performanceEvaluationId}`
    return api.get(url).then((response) => {
      const contractPerformanceEvaluation =
        response?.data?.data?.performanceEvaluationDTO?.values || []
      commit(types.CONTRACT_PERFORMANCE_EVALUATION, contractPerformanceEvaluation)
      return contractPerformanceEvaluation
    })
  },
  fetchContractVendorsPerformanceFinalRating: ({ commit }, contractId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/PerformanceEvaluation/ContractVendorsPerformanceFinalRating?contractId=${contractId}`
    return api.get(url).then((response) => {
      const performanceFinalRatingDTO =
        response?.data?.data?.performanceFinalRatingDTO?.values || []
      commit(types.CONTRACT_PERFORMANCE_FINAL_RATING_SET, performanceFinalRatingDTO)
      return performanceFinalRatingDTO
    })
  },
  fetchPerformanceEvaluationsStatistics: ({ commit }, contractId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/PerformanceEvaluation/PerformanceEvaluationStatistic?contractId=${contractId}`
    return api.get(url).then((response) => {
      const performanceEvaluationStatusDTO =
        response?.data?.data?.performanceEvaluationStatusDTO?.values || []
      commit(types.CONTRACT_PERFORMANCE_STATISTIC_SET, performanceEvaluationStatusDTO)
      return performanceEvaluationStatusDTO
    })
  },
  fetchContractPerformanceEvaluationPreviewLinks: ({ commit }, performanceEvaluationId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/PerformanceEvaluation/PreviewLinks?performanceEvaluationId=${performanceEvaluationId}`
    return api.get(url).then((response) => {
      let previewLinkDTO = []
      if (response?.data?.data?.searchResult?.values?.length) {
        previewLinkDTO = response.data.data.searchResult.values.map((v) => {
          const { role } = v
          let newRole = role
          if (role.toLowerCase() === 'purchasing representative') {
            newRole = i18n.t('ui.common.procurementLead')
          }
          if (role.toLowerCase() === 'vendor') {
            newRole = i18n.t('ui.common.vendor')
          }

          return Object.assign(v, { role: newRole })
        })
        commit(types.CONTRACT_PERFORMANCE_EVALUATION_PREVIEW_LINKS_SET, previewLinkDTO)
      }
      return previewLinkDTO
    })
  },
  deletePerformanceEvaluation: ({ commit }, performanceEvaluationId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/PerformanceEvaluation?performanceEvaluationId=${performanceEvaluationId}`
    return api.delete(url).then(() => {
      commit(types.CONTRACT_PERFORMANCE_REMOVE, performanceEvaluationId)
      commit(types.VENDOR_PERFORMANCE_REMOVE, performanceEvaluationId)
      return ''
    })
  },
  handleUpdateAppeal: (_, model) => {
    const url = `api/v1/customer/{customerId}/node/{nodeId}/PerformanceEvaluation/PerformanceEvaluationGeneralInput`
    return uploadApi.post(url, model)
  },
  handleDeleteGeneralInternalDocument: (_, model) => {
    const { generalInputId, documentId } = model
    const url = `api/v1/customer/{customerId}/node/{nodeId}/PerformanceEvaluation/PerformanceEvaluationGeneralInput/${generalInputId}/Document/${documentId}`
    return api.delete(url)
  },
  performanceEvaluationSendEmail: (_, performanceEvaluationId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/PerformanceEvaluation/SendEmail?performanceEvaluationId=${performanceEvaluationId}`

    return api.post(url).then((response) => response?.data?.data?.searchResult?.values?.[0] || [])
  }
}
export const mutations = {
  [types.CONTRACT_PERFORMANCE_RATING_SET](state, contractPerformanceRating) {
    state.contractPerformanceRating = contractPerformanceRating
  },
  [types.CONTRACT_PERFORMANCE_FINAL_RATING_SET](state, contractPerformanceFinalRating) {
    state.contractPerformanceFinalRating = contractPerformanceFinalRating
  },
  [types.CONTRACT_PERFORMANCE_ADD]: (state, contractPerformanceEvaluation) => {
    state.contractPerformanceEvaluation = contractPerformanceEvaluation
  },
  [types.CONTRACT_PERFORMANCE_STATISTIC_SET]: (state, contractPerformanceStatistics) => {
    state.contractPerformanceStatistics = contractPerformanceStatistics
  },
  [types.CONTRACT_PERFORMANCE_EVALUATION]: (state, performanceEvaluation) => {
    state.performanceEvaluation = performanceEvaluation
  },
  [types.CONTRACT_PERFORMANCE_EVALUATION_PREVIEW_LINKS_SET](
    state,
    contractPerformancePreviewLinks
  ) {
    state.contractPerformancePreviewLinks = contractPerformancePreviewLinks
  },
  [types.CONTRACT_PERFORMANCE_REMOVE]: (state, id) => {
    const performanceEvaluations = state.contractPerformanceEvaluation.filter(
      (e) => e.evaluationId !== id
    )

    state.contractPerformanceEvaluation = performanceEvaluations
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
