<script setup>
import dayjs from '@/utils/dayjs'
import { ref } from 'vue'
const props = defineProps(['declineComment', 'intakeDetails', 'intakeModifieduser'])
const emit = defineEmits(['close', 'decline'])
const viewString = 'ui.views.projectIntakeView.declineModal.'

const customToolBar = [
  'undo',
  'redo',
  'heading',
  'bold',
  'italic',
  'alignment',
  'link',
  'strikethrough',
  'bulletedList',
  'numberedList'
]

function closeDialog() {
  emit('close')
}

function formatDate(dateString) {
  return dayjs(dateString).format('ddd, MMM D, YYYY h:mm A')
}
const declineDetails = ref(props.declineComment)
function declineRequest() {
  // TODO call decline API
  emit('decline', { status: 3, details: declineDetails.value })
}
const dateModified = formatDate(props.intakeDetails.dateModifiedUtc)
</script>

<template>
  <el-dialog
    :visible="true"
    append-to-body
    :close-on-click-modal="false"
    class="intake-decline-modal"
    @close="closeDialog"
  >
    <div v-if="props.intakeDetails.status === 1" class="is-flex-column is-aligned-center">
      <app-icon icon="alert-circle"></app-icon>
      <h1>{{ $t(viewString + 'title') }}</h1>
      <span class="has-margin-top-tiny">{{ $t(viewString + 'sub-heading') }} </span>
      <app-editor
        id="intakeDeclineRequest"
        v-model="declineDetails"
        :toolbar="customToolBar"
        class="has-margin-bottom-tiny has-margin-top-small content"
      >
      </app-editor>
      <app-button
        type="primary"
        class="has-margin-top-small"
        :disabled="!declineDetails"
        min-width
        @click="declineRequest"
      >
        {{ $t(viewString + 'buttonText') }}
      </app-button>
    </div>

    <div v-if="props.intakeDetails.status === 3" class="is-flex-column is-aligned-center">
      <h1>Rationale for declining request</h1>
      <div class="inner-border">
        <div class="cell-person" style="display: flex; align-items: center">
          <app-avatar :src="props.intakeModifieduser.avatarUrl" alt="name"></app-avatar>
          <div class="modifiedUserContainer">
            {{ props.intakeModifieduser.firstName }} {{ props.intakeModifieduser.lastName }}
            <div v-text="dateModified"></div>
          </div>
        </div>
        <app-editor
          id="intakeDeclineRequest"
          v-model="declineDetails"
          :toolbar="customToolBar"
          disabled
          class="has-margin-bottom-tiny has-margin-top-small content"
        >
        </app-editor>
      </div>
      <app-button type="primary" class="has-margin-top-small" min-width @click="closeDialog">
        {{ $t(viewString + 'close') }}
      </app-button>
    </div>
  </el-dialog>
</template>

<style lang="scss">
.modifiedUserContainer {
  margin-left: 10px;
  margin-top: 10px;
}

.intake-decline-modal {
  h1 {
    padding-bottom: 20px;
  }
  .app-icon.is-base {
    &,
    svg {
      width: 56px;
      height: 56px;
    }

    svg {
      color: $white;
      fill: $c-primary;
    }
  }

  #intakeDeclineRequest {
    width: 550px;
  }
}

.cell-person {
  padding-bottom: 10px;
}
</style>
