<template>
  <div
    class="doc-preview has-padding-xlg has-border has-max-width-readable has-margin-left-auto has-margin-right-auto"
  >
    <div class="doc-preview__header is-flex has-margin-bottom-small">
      <div class="doc-number has-margin-bottom-">
        <slot name="number"> Document {{ document.fileName }} </slot>
      </div>

      <app-title size="4" heading="3" class="doc-title">
        <span class="has-margin-right-tiny" :class="getIconClass()" />
        {{ document.fileName }}
      </app-title>
      <div class="actions is-flex">
        <app-button id="previousDocument" class="has-margin-right-small" @click="$emit('previous')"
          ><app-icon icon="chevron-left"
        /></app-button>
        <app-button id="nextDocument" @click="$emit('next')"
          ><app-icon icon="chevron-right"
        /></app-button>
        <app-button
          id="closeDocument"
          type="text"
          class="has-margin-left-small"
          aria-label="Close"
          @click="$emit('close')"
        >
          <app-icon icon="x" />
        </app-button>
      </div>
    </div>
    <div
      class="doc-preview__document has-border is-flex is-direction-column is-aligned-center is-justified-center"
    >
      <!-- default: download document -->
      <div
        v-if="!isPreviewFriendly"
        role="button"
        tabindex="0"
        class="document-download is-flex is-direction-column is-aligned-center is-justified-center"
        @click="handleDownloadDocument"
      >
        <app-icon icon="download-cloud" size="large" class="has-margin-bottom-medium" />
        <p type="text">Download {{ document.title }}</p>
      </div>
      <!-- if possible, preview document -->
      <div v-else-if="hasDocumentPreview">
        <DocumentPreview :file="documentToPreviewStream" />
      </div>
    </div>
  </div>
</template>

<script>
import { Icons } from '@/views/award-approval/mixins/award-approval-mixins.js'
import { mapActions } from 'vuex'
import errorHandler from '@/utils/error'
import DocumentPreview from '@/components/document-preview/document-preview.vue'
import uiHandler from '@/utils/ui'

const IDocument = () => {
  return {
    id: 0,
    title: 'Test Doc',
    location: '',
    type: 'pdf'
  }
}

export default {
  name: 'award-approval-doc-preview',
  components: {
    DocumentPreview
  },
  mixins: [Icons],
  props: {
    document: {
      type: Object,
      default: IDocument,
      required: true
    },
    awardApprovalId: String,
    awardApprovalWorkflowId: String,
    userAccessToken: String
  },
  data() {
    return {
      commentsOpen: false,
      docLoaded: false,
      comments: [],
      currentDocumentId: null,
      documentToPreviewStream: null,
      maxFileSizePreview: 5242880 // 5mb in bytes
    }
  },
  computed: {
    isPreviewFriendly() {
      // file type
      const re = /(\.pdf)$/i
      if (!re.exec(this.document.fileName) || this.documentFileSize > this.maxFileSizePreview) {
        return false
      }

      return true
    },
    hasDocumentPreview() {
      return this.documentToPreview !== null
    },
    documentToPreview() {
      if (this.document.id === this.currentDocumentId) {
        return this.documentToPreviewStream
      }

      this.handleDocumentPreview()

      return this.documentToPreviewStream
    },
    documentFileSize() {
      return this.document.sizeInByte ?? 0
    }
  },
  methods: {
    ...mapActions([
      'awardApprovalPublicPageDocumentPreview',
      'awardApprovalPublicPageDocumentDownload'
    ]),
    handleDownloadDocument() {
      uiHandler.showLoading(this)

      this.awardApprovalPublicPageDocumentDownload({
        awardApprovalId: this.awardApprovalId,
        awardApprovalWorkflowId: this.awardApprovalWorkflowId,
        userAccessToken: this.userAccessToken,
        documentId: this.document.id
      })
        .catch((e) => errorHandler.handleApiError(this, e))
        .finally(() => uiHandler.hideLoading(this))
    },
    async handleDocumentPreview() {
      uiHandler.showLoading(this)

      if (this.documentToPreviewStream) {
        window.URL.revokeObjectURL(this.documentToPreviewStream)
        this.documentToPreviewStream = null
      }

      try {
        this.documentToPreviewStream = await this.awardApprovalPublicPageDocumentPreview({
          awardApprovalId: this.awardApprovalId,
          awardApprovalWorkflowId: this.awardApprovalWorkflowId,
          userAccessToken: this.userAccessToken,
          documentId: this.document.id
        })

        this.currentDocumentId = this.document.id
        uiHandler.hideLoading(this)
      } catch (e) {
        errorHandler.handleApiError(this, e)
        uiHandler.hideLoading(this)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.doc-preview {
  background: white;
}

.doc-preview__header {
  flex-direction: column;
  align-items: flex-start;

  .doc-number {
    margin-bottom: $gap-small;

    @include breakpoint('md') {
      margin-bottom: 0;
    }
  }

  .actions {
    margin-top: $gap-small;
    flex: row nowrap;

    @include breakpoint('md') {
      margin-top: 0;
      margin-left: auto;
    }
  }

  @include breakpoint('md') {
    align-items: center;
    flex-direction: row;

    .doc-title {
      margin-left: auto;
      margin-bottom: 0;
    }
  }
}
.doc-preview__document {
  min-height: 250px;
  background: $c-background;
  position: relative;

  .doc-preview-notes:not(.is-open) {
    display: none;
    opacity: 0;
  }

  &:hover,
  &:active,
  &:focus {
    .doc-preview-notes {
      display: block;
      opacity: 1;
      animation: fadein 0.2s;
    }
  }
}

.document-download {
  cursor: pointer;
  padding: $gap;
  color: $team-member-primary;

  &:focus,
  &:hover {
    background: rgba($c-grey-light, 0.1);
  }
}

:deep(.fiv-sqo) {
  font-size: 2em;
}

:deep(.app-icon svg) {
  height: 40px !important;
  width: 40px !important;
}

@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
