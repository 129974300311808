import { api } from '@/api/api'
import * as types from '../../../mutation-types'

export const state = {
  tenderNode: null,
  saveRequired: false
}
export const getters = {
  getTenderNode: (state) => state.tenderNode,
  getTenderNodeSaveRequired: (state) => state.saveRequired,
  getTenderNodeLvp: (state) => (state.tenderNode ? state.tenderNode.lvp : null),
  getIsAwardApprovalPaidVerion: (state) =>
    !!(state.tenderNode && state.tenderNode.includeBidApprovalWorkflow)
}
export const actions = {
  setTenderNode: ({ commit }, tenderNode) => {
    commit(types.TENDERNODE_SET, tenderNode)
  },
  fetchTenderNode: ({ commit }, tenderNodeId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Tenders/TenderNode?tenderNodeId=${tenderNodeId}`
    return api.get(url).then((response) => {
      const data = response.data.data.tenderNodeDTO.values[0]
      commit(types.TENDERNODE_SET, response.data.data.tenderNodeDTO.values[0])
      return data
    })
  },
  updateTenderNode: ({ commit }, tenderNode) => {
    const newTenderNode = Object.assign({}, state.tenderNode, tenderNode)
    commit(types.TENDERNODE_SET, newTenderNode)
  },
  updateTenderNodeSaveRequired: ({ commit }, value) => {
    commit(types.TENDERNODE_SAVE_REQUIRED, value)
  },
  saveTenderNode: (_, tenderNode) => {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/TenderNodes'
    return api.put(url, tenderNode).then((response) => {
      const tenderNode = response.data.data.tenderNodeDTO.values
      return tenderNode
    })
  }
}
export const mutations = {
  [types.TENDERNODE_SET](state, tenderNode) {
    state.tenderNode = tenderNode
  },
  [types.TENDERNODE_SAVE_REQUIRED](state, value) {
    state.saveRequired = value
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
