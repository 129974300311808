export function usePermissionLevels() {
  const permissionLevels = ['ERROR', 'ALL', 'VIEW', 'NONE']

  // getAdminLevels returns keys of adminLevels
  function getPermissionLevels() {
    // return an object with keys that match permissions levels and values that match their index
    return permissionLevels.reduce((acc, level, index) => {
      acc[level] = index
      return acc
    }, {})
  }

  // function that takes a number and returns the corresponding permission level at that index
  function getPermissionLevelName(level) {
    return permissionLevels[level]
  }

  return { permissionLevels, getPermissionLevels, getPermissionLevelName }
}
