import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  termsAndConditionsTemplates: []
}
export const getters = {
  getTermsAndConditionsTemplates: (state) => state.termsAndConditionsTemplates
}
export const actions = {
  fetchTermsAndConditionsTemplates: ({ commit }, group) => {
    if (!group) {
      commit(types.TERMS_AND_CONDITIONS_TEMPLATES_SET_ALL, [])
      return
    }

    const url = `/api/v1/customer/{customerId}/node/{nodeId}/OnlineSubmissionTemplate/Search?groupName=${group}`
    return api.get(url).then((res) => {
      const templates = res.data.data.onlineSubmissionTemplateDTO.values
      commit(types.TERMS_AND_CONDITIONS_TEMPLATES_SET_ALL, templates)
      return res
    })
  }
}
export const mutations = {
  [types.TERMS_AND_CONDITIONS_TEMPLATES_SET_ALL]: (state, templates) => {
    state.termsAndConditionsTemplates = templates
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
