<template>
  <el-dialog
    :visible="visible"
    width="700px"
    append-to-body
    class="dashboard-widget-modal"
    @close="handleClose"
  >
    <app-title size="3" heading="3">Select a Widget to Add </app-title>
    <div class="is-flex is-direction-row is-justified-between">
      <div class="has-margin-right-large has-margin-top">
        <label for="widgetFilter">Search For Widgets</label>
        <app-input
          id="widgetFilter"
          v-model="filter"
          aria-label="Search by Keyword"
          icon-left="search"
        ></app-input>
      </div>
      <app-listgroup class="has-max-width-narrow has-min-width-narrow has-max-height-narrow">
        <app-listgroup-item
          v-for="(widget, w) in filteredWidgets"
          :key="`widget-${w}`"
          :class="{ active: widget.selected }"
          @click="widget.selected = !widget.selected"
        >
          <span slot="prepend">
            <app-icon v-if="!widget.customIcon" :icon="widget.icon" />
            <div v-if="widget.customIcon" class="intake-icon has-margin-right-tiny">
              <img
                :src="widget.customIcon"
                alt="file with a plus sign"
                aria-label="Project intake section"
              />
            </div>
          </span>
          <span>{{ widget.name }}</span>
          <span slot="subtitle">{{ widget.description }}</span>
        </app-listgroup-item>
      </app-listgroup>
    </div>
    <span slot="footer">
      <app-button v-if="selectedWidgets.length" class="has-margin-right" @click="addSelectedWidgets"
        >Add Selected Widgets
      </app-button>
      <app-button @click="addAllWidgets">Add All Widgets</app-button>
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import { useFeatureHelpers } from '@/utils/featureHelpers'

const { isModuleEnabled } = useFeatureHelpers()

export default {
  name: 'add-widget-modal',
  props: {
    visible: Boolean,
    widgetsToAdd: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      filter: '',
      clonedWidgetsToAdd: []
    }
  },
  computed: {
    filteredWidgets() {
      if (!this.filter) return this.clonedWidgetsToAdd
      const filterText = this.filter.toLowerCase()
      return this.clonedWidgetsToAdd.filter((widget) => {
        return (
          widget.name.toLowerCase().indexOf(filterText) > -1 ||
          widget.description.toLowerCase().indexOf(filterText) > -1
        )
      })
    },
    selectedWidgets() {
      return this.clonedWidgetsToAdd.filter((widget) => widget.selected)
    }
  },
  mounted() {
    let clonedWidgetsToAdd = cloneDeep(this.widgetsToAdd)
    // Feature gate myTasks
    const myTasksWidgetId = 'CD4B0B74-8AB3-44CB-892A-81FBE21AEE8D'
    if (!isModuleEnabled('BuyerDashboard.MyTasks')) {
      clonedWidgetsToAdd = clonedWidgetsToAdd.filter(
        (w) => w.id.toLowerCase() !== myTasksWidgetId.toLowerCase()
      )
    }
    this.clonedWidgetsToAdd = clonedWidgetsToAdd
  },
  methods: {
    addSelectedWidgets() {
      this.$emit('add-selected-widget', this.selectedWidgets)
    },
    addAllWidgets() {
      this.$emit('add-all-widgets')
    },
    handleClose() {
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style lang="scss">
.dashboard-widget-modal {
  .active {
    background: #ffcd79 !important;
  }
  .app-listgroup {
    overflow-y: scroll;
  }
}
</style>
