<template>
  <div
    :class="[
      'is-' + size,
      'is-' + background,
      { 'has-gutters': gutters, 'is-white': light, 'has-content-bottom': hasContentBottom }
    ]"
    class="app-toolbar"
  >
    <div class="app-toolbar-left">
      <app-button
        v-if="back"
        class="is-large icon-only app-toolbar-back"
        @click.native="handleClickBack"
      >
        <app-icon icon="arrow-left"></app-icon>
      </app-button>
      <slot></slot>
    </div>
    <div v-if="hasContentMiddle" class="app-toolbar-middle">
      <slot name="toolbarMiddle"></slot>
    </div>
    <div v-if="hasContentRight || toggle" class="app-toolbar-right">
      <slot name="toolbarRight"></slot>
      <app-button v-if="toggle" class="icon-only app-toolbar-fullscreen" fullscreen-toggle>
        <app-icon id="maximizeIcon" icon="maximize-2"></app-icon>
        <app-icon id="minimizeIcon" icon="minimize-2" class="is-hidden"></app-icon>
        <span class="is-sr-only">Toggle Fullscreen</span>
      </app-button>
    </div>
    <div v-if="hasContentBottom" class="app-toolbar-bottom">
      <slot name="toolbarBottom" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-toolbar-group',
  props: {
    size: {
      type: String,
      default: 'large'
    },
    background: {
      type: String,
      default: 'grey'
    },
    toggle: {
      type: Boolean,
      default: false
    },
    back: {
      type: Boolean,
      default: false
    },
    gutters: {
      type: Boolean,
      default: true
    },
    light: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    hasContentRight() {
      return this.$slots.toolbarRight
    },
    hasContentBottom() {
      return this.$slots.toolbarBottom
    },
    hasContentMiddle() {
      return this.$slots.toolbarMiddle
    }
  },
  methods: {
    handleClickBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" src="./app-toolbar.scss"></style>
