export function isDev() {
  return import.meta.env.MODE === 'development' || import.meta.env.MODE === 'test'
}

export function isDevQA(context = window) {
  return isDev() || context.location?.hostname.includes('next.qa.bidsandtenders.ca')
}

export function isProd() {
  return import.meta.env.MODE !== 'production'
}
