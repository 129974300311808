import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  irrevocablePeriods: [],
  irrevocablePeriod: null
}

export const getters = {
  getIrrevocablePeriods: (state) => state.irrevocablePeriods,
  getIrrevocablePeriod: (state) => state.irrevocablePeriod
}

export const actions = {
  fetchIrrevocablePeriodsAll: ({ commit }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/IrrevocablePeriods/All`

    return api.get(url).then((response) => {
      if (response.data.count > 0) {
        const irrevocablePeriods = response.data.data.irrevocablePeriodDTO.values
        commit(types.IRREVOCABLE_PERIOD_SET_ALL, irrevocablePeriods)
        return irrevocablePeriods
      }
    })
  },
  saveIrrevocablePeriod: ({ commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/IrrevocablePeriods`
    return api.post(url, model).then((response) => {
      const irrevocablePeriod = response.data.data.irrevocablePeriodDTO.values[0]
      commit(types.IRREVOCABLE_PERIOD_SET, irrevocablePeriod)
      return irrevocablePeriod
    })
  },
  updateIrrevocablePeriod: ({ commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/IrrevocablePeriods`
    return api.put(url, model).then((response) => {
      const irrevocablePeriod = response.data.data.irrevocablePeriodDTO.values[0]
      commit(types.IRREVOCABLE_PERIOD_SET, irrevocablePeriod)

      return irrevocablePeriod
    })
  },
  deleteIrrevocablePeriod: (_, id) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/IrrevocablePeriods?irrevocablePeriodId=${id}`
    return api.delete(url)
  }
}

export const mutations = {
  [types.IRREVOCABLE_PERIOD_SET_ALL](state, irrevocablePeriods) {
    state.irrevocablePeriods = irrevocablePeriods
  },
  [types.IRREVOCABLE_PERIOD_SET](state, irrevocablePeriod) {
    state.irrevocablePeriod = irrevocablePeriod
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
