<template>
  <div
    :class="{
      'is-overlay': overlay,
      'is-modal': modal,
      'is-full-height': fullHeight,
      'is-overlay-contained': containedOverlay
    }"
    class="app-panel"
  >
    <div v-if="hasContentFixedTop" class="app-panel-fixed app-panel-fixed-top">
      <slot name="panelFixedTop"></slot>
    </div>
    <div v-if="normalScroll" class="app-panel">
      <div
        v-if="layout"
        :class="[layout === 'column' ? 'is-column' : 'is-row', sidebarClasses]"
        class="app-panel-layout normal-scroll"
      >
        <slot></slot>
      </div>
      <template v-if="!layout">
        <slot></slot>
      </template>
    </div>
    <div v-if="!normalScroll" class="app-panel-scroll">
      <div
        ref="scroll"
        :class="{ 'has-help': help }"
        class="app-panel-scroll-inner"
        @scroll="handleScroll($event)"
      >
        <div
          v-if="layout"
          :class="[layout === 'column' ? 'is-column' : 'is-row', sidebarClasses, layoutClasses]"
          class="app-panel-layout"
        >
          <slot></slot>
        </div>
        <template v-if="!layout">
          <slot></slot>
        </template>
      </div>
    </div>
    <div v-if="hasContentFixedBottom" class="app-panel-fixed app-panel-fixed-bottom">
      <slot name="panelFixedBottom"></slot>
    </div>
  </div>
</template>

<script>
import throttle from 'lodash/throttle'

// TODO switch to Vue3 syntax and add focus trap for overlay mode

export default {
  name: 'app-panel',
  props: {
    layout: {
      type: String,
      default: null
    },
    layoutClasses: String,
    overlay: {
      type: Boolean,
      default: false
    },
    containedOverlay: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: false
    },
    normalScroll: Boolean,
    help: Boolean,
    fullHeight: Boolean
  },
  data() {
    return {
      id: null,
      sidebarClasses: String,
      elPositionStart: null,
      elWidth: null,
      elHeight: null,
      elLeft: null,
      elTop: null,
      isInTransition: false,
      elPlaceholder: null,
      modalElement: null,
      isFullscreen: false,
      hasControls: false,
      transitionTime: 300,
      scrollDir: 0,
      scrollPos: 0
    }
  },
  computed: {
    hasContentFixedTop() {
      return this.$slots.panelFixedTop
    },
    hasContentFixedBottom() {
      return this.$slots.panelFixedBottom
    },
    hasRow() {
      return this.$slots.panelRow
    }
  },
  mounted() {
    this.showModalBackdrop()
    this.handleFullscreenToggleClick()
  },
  destroyed() {
    this.hideModalBackdrop()
  },
  methods: {
    toggleFullscreen() {
      // make sure panel is not mid-transition
      if (!this.isInTransition) {
        // get start position of panel
        this.elPositionStart = this.$el.getBoundingClientRect()
        // remove focus from toggle button
        if (!this.isFullscreen) {
          // set transition flag
          this.isInTransition = true
          // get initial size and position
          this.elWidth = this.$el.offsetWidth
          this.elHeight = this.$el.offsetHeight
          this.elLeft = this.elPositionStart.left
          this.elTop = this.elPositionStart.top
          // create and append clone (to be used for calculating reset position/dimensions)
          const panelClone = this.$el.cloneNode()
          panelClone.setAttribute('data-class', 'panel-clone')
          this.$el.parentNode.insertBefore(panelClone, this.$el)
          // apply fullscreen style classes to content element fullscreen styles
          this.$el.style.cssText = `top:${this.elTop}px; left:${this.elLeft}px; width:${this.elWidth}px; height:${this.elHeight}px`
          this.$el.classList.add('fullscreen-start')
          setTimeout(() => {
            this.$el.style.transitionDuration = `${this.transitionTime}ms`
            this.$el.classList.add('is-inTransition')
            this.$el.classList.add('fullscreen-end')
          }, 0)
          setTimeout(() => {
            this.$emit('toggleFullscreen')
            this.$el.classList.remove('is-inTransition')
            this.isFullscreen = true
            this.isInTransition = false
          }, this.transitionTime)
        } else {
          // set transition flag
          this.isInTransition = true
          // remove fullscreen-end styles
          const panelClone = this.$el.previousSibling
          this.$el.style.width = `${panelClone.offsetWidth}px`
          this.$el.style.height = `${panelClone.offsetHeight}px`
          this.$el.classList.add('is-inTransition')
          this.$el.classList.remove('fullscreen-end')
          // wait for animation to finish before removing fullscreen-start styles
          setTimeout(() => {
            // remove clone panel
            if (this.$el.parentNode) this.$el.parentNode.removeChild(panelClone)
            this.$el.setAttribute('style', '')
            this.$el.classList.remove('fullscreen-start')
            this.$el.classList.remove('is-inTransition')
            this.$emit('toggleFullscreen')
            this.isFullscreen = false
            this.isInTransition = false
          }, this.transitionTime + 100)
        }
      }
    },
    showModalBackdrop() {
      if (this.modal) {
        this.modalElement = document.createElement('div')
        this.modalElement.classList.add('app-panel-backdrop')
        this.$el.parentNode.insertBefore(this.modalElement, this.$el)
        this.$nextTick(() => {
          this.modalElement.classList.add('is-visible')
          this.modalElement.style.transitionDuration = `${this.transitionTime}ms`
        })
      }
    },
    hideModalBackdrop() {
      if (this.modal) {
        this.modalElement.classList.remove('is-visible')
        setTimeout(() => {
          this.modalElement.parentNode.removeChild(this.modalElement)
        }, this.transitionTime)
      }
    },
    setSidebarClasses() {
      if (this.layout) {
        // get sidebar count
        const elSidebars = this.$children.filter((element) =>
          element.$el.classList.contains('app-sidebar')
        )
        // check if sidebar is only of type and last child
        const isLastOnly =
          elSidebars.length === 1 &&
          this.$children[this.$children.length - 1].$el.classList.contains('app-sidebar')
        if (isLastOnly) {
          this.sidebarClasses = 'is-last-only'
        }
      }
    },
    handleFullscreenToggleClick() {
      if (!this.layout) {
        const fullscreenToggle = this.$el.querySelectorAll('[fullscreen-toggle]')[0]
        if (fullscreenToggle != null) {
          fullscreenToggle.addEventListener('click', () => {
            fullscreenToggle.blur()
            if (!this.isFullscreen) {
              setTimeout(() => {
                fullscreenToggle.querySelector('#minimizeIcon').classList.remove('is-hidden')
                fullscreenToggle.querySelector('#maximizeIcon').classList.add('is-hidden')
              }, this.transitionTime)
            } else {
              setTimeout(() => {
                fullscreenToggle.querySelector('#maximizeIcon').classList.remove('is-hidden')
                fullscreenToggle.querySelector('#minimizeIcon').classList.add('is-hidden')
              }, this.transitionTime)
            }
            this.toggleFullscreen()
          })
        }
      }
    },
    handleScroll: throttle(function (e) {
      const { scrollTop } = e.target
      if (!scrollTop) return
      // eslint-disable-next-line no-unused-vars
      const scrollDir = scrollTop > this.scrollPos ? 1 : -1
      this.scrollPos = scrollTop
    }, 30)
  }
}
</script>

<style lang="scss" src="./app-panel.scss"></style>
