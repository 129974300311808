<template>
  <div class="award-approval-view">
    <AwardApprovalHeader />
    <transition-group name="fade">
      <main v-if="isLoaded && !isSummary && !isBeingEdited" key="main">
        <AwardApprovalActions :name="approverName" @action="handleAction" />
        <div class="has-padding-large">
          <transition-group name="fade" mode="out-in">
            <AwardApprovalDocsList
              v-if="!previewDocument"
              key="docslist"
              :count="documentList.length"
            >
              <template v-for="(doc, d) in documentList">
                <AwardApprovalDoc
                  :key="`doc-${d}`"
                  :document="doc"
                  :doc-index="d"
                  @preview="handlePreviewDoc"
                />
              </template>
            </AwardApprovalDocsList>
            <AwardApprovalDocPreview
              v-else
              key="docspreview"
              :document="previewDocument"
              :award-approval-id="awardApprovalId"
              :award-approval-workflow-id="awardApprovalWorkflowId"
              :user-access-token="userAccessToken"
              @close="handlePreviewClose"
              @previous="handlePreviewPrevious"
              @next="handlePreviewNext"
            >
              <template #number>
                <span>Document {{ previewDocumentIndex + 1 }}/{{ documentList.length }}</span>
              </template>
            </AwardApprovalDocPreview>
          </transition-group>
        </div>
      </main>
      <main v-else-if="isLoaded && isBeingEdited" key="unvailable" class="is-unavailable">
        <AwardApprovalUnavailable />
      </main>
      <main v-else-if="isLoaded && isSummary" key="summary" class="is-summary">
        <AwardApprovalSummary :name="approverName" @document-download-all="documentDownloadAll" />
      </main>
      <main
        v-else
        key="loading"
        class="is-loading is-flex is-direction-column is-justified-center is-aligned-center"
      >
        <AppLoading />
      </main>
    </transition-group>
    <app-focus-trap :disabled="!showApprovalModal">
      <AwardApprovalModal
        :visible.sync="showApprovalModal"
        :mode="modalAction"
        @submit:approve="handleApprove"
        @submit:decline="handleDecline"
        @close="handleCloseModal"
      />
    </app-focus-trap>
    <AwardApprovalFooter />
  </div>
</template>

<script>
import AwardApprovalHeader from './layout/award-approval-header.vue'
import AwardApprovalFooter from './layout/award-approval-footer.vue'
import AwardApprovalActions from './actions/award-approval-actions.vue'
import AwardApprovalDocsList from './docs/award-approval-docs-list.vue'
import AwardApprovalModal from './actions/award-approval-action-modal.vue'
import AwardApprovalDoc from './docs/award-approval-doc.vue'
import AwardApprovalSummary from './award-approval-summary.vue'
import AwardApprovalDocPreview from './docs/award-approval-doc-preview.vue'
import AwardApprovalUnavailable from './award-approval-unavailable.vue'
import AppLoading from '@/components/app-loading/app-loading.vue'
import errorHandler from '@/utils/error'
import { mapActions, mapGetters } from 'vuex'
import throttle from 'lodash/throttle'
import uiHandler from '@/utils/ui'
import { approvalStatus } from '@/views/projects/project-view/project-sub-views/award/award-approval-view/award-approval-wizard/approval-wizard-defs.js'

export default {
  name: 'approval-view',
  components: {
    AwardApprovalHeader,
    AwardApprovalFooter,
    AwardApprovalActions,
    AwardApprovalDocsList,
    AwardApprovalDoc,
    AwardApprovalSummary,
    AwardApprovalUnavailable,
    AwardApprovalDocPreview,
    AwardApprovalModal,
    AppLoading
  },
  data() {
    return {
      modalAction: null,
      showApprovalModal: false,
      isLoaded: false,
      previewDocument: null,
      previewDocumentIndex: 0,
      documents: []
    }
  },
  computed: {
    ...mapGetters(['getAwardApprovalPublicPage']),
    userAccessToken() {
      return this.$route.query?.uat
    },
    awardApprovalId() {
      return this.$route.params?.awardApprovalId
    },
    awardApprovalWorkflowId() {
      return this.$route.params?.awardApprovalWorkflowId
    },
    awardApprovalPublic() {
      return this.getAwardApprovalPublicPage || []
    },
    approverName() {
      return this.awardApprovalPublic?.awardApprovalWorkflows[0]?.fullName
    },
    documentList() {
      return this.awardApprovalPublic?.awardApprovalDocuments
    },
    isBypass() {
      return true
    },
    awardApprovalApproverStatus() {
      return this.awardApprovalPublic?.awardApprovalWorkflows[0]?.status
    },
    isBeingEdited() {
      return this.awardApprovalPublic?.status === approvalStatus.CORRECTING
    },
    isSummary() {
      return (
        this.awardApprovalApproverStatus === approvalStatus.APPROVED ||
        this.awardApprovalApproverStatus === approvalStatus.DECLINED
      )
    }
  },
  mounted() {
    if (!this.userAccessToken || !this.awardApprovalId || !this.awardApprovalWorkflowId) {
      this.$router.push('/login')
    }

    this.fetchApprovalRecord()
  },
  methods: {
    ...mapActions([
      'fetchAwardApprovalPublicPage',
      'awardApprovalPublicPageStatusSet',
      'awardApprovalPublicPageDocumentDownloadAll'
    ]),
    fetchApprovalRecord() {
      uiHandler.showLoading(this)

      this.fetchAwardApprovalPublicPage({
        approvalId: this.awardApprovalId,
        workflowId: this.awardApprovalWorkflowId,
        uat: this.userAccessToken
      })
        .then(() => {
          this.checkIfTurnToApprove()
          uiHandler.hideLoading(this)
          this.isLoaded = true
        })
        .catch((e) => {
          const errorMessages = []
          errorMessages[e.response.status] = e.response.data[0]
          uiHandler.hideLoading(this)
          errorHandler.handleApiError(this, e, errorMessages)
        })
    },
    handleAction(action) {
      this.modalAction = action
      this.showApprovalModal = true
    },
    handleCloseModal() {
      this.showApprovalModal = false
      this.modalAction = null
    },
    handlePreviewDoc(docIndex) {
      this.previewDocumentIndex = parseInt(docIndex)
      this.previewDocument = this.documentList[docIndex]
    },
    handlePreviewClose() {
      this.previewDocument = null
      this.previewDocumentIndex = 0
    },
    handlePreviewPrevious: throttle(function () {
      this.previewDocumentIndex =
        this.previewDocumentIndex === 0
          ? this.documentList.length - 1
          : this.previewDocumentIndex - 1
      this.previewDocument = this.documentList[this.previewDocumentIndex]
    }, 40),
    handlePreviewNext: throttle(function () {
      this.previewDocumentIndex =
        this.previewDocumentIndex + 1 >= this.documentList.length
          ? 0
          : this.previewDocumentIndex + 1
      this.previewDocument = this.documentList[this.previewDocumentIndex]
    }, 40),
    handleApprove({ comments }) {
      this.approvalStatusSet('Approved', comments)
    },
    handleDecline({ comments }) {
      this.approvalStatusSet('Declined', comments)
    },
    approvalStatusSet(status, comments) {
      uiHandler.showLoading(this)

      this.awardApprovalPublicPageStatusSet({
        approvalId: this.awardApprovalId,
        workflowId: this.awardApprovalWorkflowId,
        uat: this.userAccessToken,
        status: status,
        comments: `"${comments}"`
      })
        .then(() => {
          this.showApprovalModal = false
          uiHandler.hideLoading(this)
          this.isLoaded = true
        })
        .catch((e) => {
          const errorMessages = []
          errorMessages[e.response.status] = e.response.data[0]
          uiHandler.hideLoading(this)
          errorHandler.handleApiError(this, e, errorMessages)
        })
    },
    checkIfTurnToApprove() {
      if (
        this.awardApprovalPublic?.status !== approvalStatus.CORRECTING &&
        this.awardApprovalPublic?.awardApprovalWorkflows[0]?.status !== approvalStatus.EMAIL_SENT &&
        this.awardApprovalPublic?.awardApprovalWorkflows[0]?.status !== approvalStatus.APPROVED &&
        this.awardApprovalPublic?.awardApprovalWorkflows[0]?.status !== approvalStatus.DECLINED
      )
        this.$router.push('/login')

      return
    },
    documentDownloadAll() {
      uiHandler.showLoading(this)

      this.awardApprovalPublicPageDocumentDownloadAll({
        awardApprovalId: this.awardApprovalId,
        awardApprovalWorkflowId: this.awardApprovalWorkflowId,
        userAccessToken: this.userAccessToken
      })
        .catch((e) => errorHandler.handleApiError(this, e))
        .finally(() => uiHandler.hideLoading(this))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~file-icon-vectors/dist/file-icon-square-o.min.css';
.award-approval-view {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;

  :deep(.app-loading) {
    border-color: rgba($team-member-primary, 0.3);
    border-top-color: $team-member-primary;
  }
}
main {
  min-height: calc(100vh - #{$team-member-header-height + $team-member-footer-height});
  background: $c-background;

  &.is-summary,
  &.is-unavailable {
    background: $white;
  }

  &.is-unavailable {
    height: 90%;
  }
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
  transform: translateX(-10px);
}

.fade-leave-active {
  position: absolute;
  width: 100vw;
}

.is-loading {
  min-height: calc(100vh - #{$team-member-header-height + $team-member-footer-height});
  width: 100vw;
}

.temp-control {
  position: fixed;
  right: $gap;
  bottom: 5px;

  .el-button {
    min-width: 0;
    height: auto;
    border-radius: 50%;
    border: 0;
    background: $team-member-primary;
    color: $white;

    &:active,
    &:focus {
      background: darken($team-member-primary, 10%);
    }
  }
}
</style>
