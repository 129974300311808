<template>
  <el-tooltip
    v-bind="$attrs"
    :content="content"
    class="permission-tooltip"
    effect="dark"
    placement="right-end"
  >
    <app-icon size="small" icon="lock" />
  </el-tooltip>
</template>

<script>
export default {
  name: 'permissionToolTip',
  props: {
    content: {
      type: String,
      default: 'You do not have permission to edit this property'
    }
  }
}
</script>
