<template>
  <aside
    ref="sidebar"
    :style="getCustomWidth()"
    :class="[
      'is-' + size,
      gutterSize ? 'has-gutters-' + gutterSize : '',
      isRight ? 'is-right' : 'is-left',
      {
        'has-toggle': toggle,
        'is-ready': isReady,
        'is-open': isOpen,
        'is-contained': contained,
        'has-controls': hasControls,
        'has-collapse': collapse,
        'is-collapsed': isCollapsed,
        'is-white': background === 'white',
        'collapse-active': collapseActive,
        'has-shadow': hasShadow,
        'ui-sidebar': isUiSidebar,
        'has-floating-controls': floatControls
      }
    ]"
    class="app-sidebar"
  >
    <div v-if="hasContentFixedTop || hasControls" class="app-sidebar-fixed">
      <div v-if="hasControls" class="app-sidebar-controls">
        <app-button
          v-if="collapse"
          ref="btnCollapse"
          aria-label="Toggle Sidebar Collapse"
          class="is-tiny is-clear icon-only app-sidebar-collapse-toggle"
          @click.native="handleToggleCollapse"
        >
          <app-icon v-if="!isCollapsed && !floatControls" type="grey" icon="minimize-2"></app-icon>
          <app-icon
            v-else-if="isCollapsed && !floatControls"
            type="grey"
            icon="maximize-2"
          ></app-icon>
          <app-icon v-else type="grey" icon="chevron-left"></app-icon>
        </app-button>
        <app-button
          v-if="toggle"
          :aria-label="closeText"
          :class="{ 'is-button-close': hasFancyClose }"
          class="is-clear"
          @click.native="handleClose"
        >
          <app-icon v-if="!hasFancyClose" icon="x"></app-icon>
          <span v-if="!hasFancyClose" class="close-text">{{ closeText }}</span>
        </app-button>
        <div v-if="hasTitle && isUiSidebar" class="app-sidebar-title">
          <app-title v-if="title && !hasSlotTitle" heading="1" size="3">
            {{ title }}
          </app-title>
          <slot name="title"></slot>
          <div v-if="hasSlotTitleButton" class="app-sidebar-title-button">
            <slot name="title-button" />
          </div>
        </div>
      </div>
      <slot name="sidebarFixedTop"></slot>
    </div>
    <div class="app-sidebar-scroll">
      <div class="app-sidebar-scroll-inner">
        <div v-if="hasTitle && !isUiSidebar" class="app-sidebar-title">
          <app-title v-if="title && !hasSlotTitle" heading="1" size="3">
            {{ title }}
          </app-title>
          <slot name="title"></slot>
        </div>
        <div class="app-sidebar-content">
          <slot></slot>
        </div>
      </div>
    </div>
    <div v-if="hasContentFixedBottom" class="app-sidebar-fixed">
      <slot name="sidebarFixedBottom"></slot>
    </div>
  </aside>
</template>
<script>
import debounce from 'lodash/debounce'

export default {
  name: 'SideBar',
  props: {
    size: {
      type: String,
      default: 'default'
    },
    gutterSize: String, // small, medium
    collapse: false,
    raised: false,
    title: String,
    toggle: false,
    background: String,
    floatControls: false,
    appended: false,
    closeText: {
      type: String,
      default: 'Close'
    },
    contained: false,
    isOpen: false,
    fancyClose: false,
    showBackdrop: false,
    hasShadow: false,
    type: {
      type: String,
      default: 'default'
    },
    width: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isCollapsed: false,
      collapseActive: false,
      isReady: true,
      isRight: null,
      elWidth: null,
      toggleTransitionTime: 300
    }
  },
  computed: {
    hasControls() {
      return this.collapse || this.toggle
    },
    hasContentFixedTop() {
      return this.$slots.sidebarFixedTop
    },
    hasContentFixedBottom() {
      return this.$slots.sidebarFixedBottom
    },
    hasTitle() {
      return this.title || this.$slots.title
    },
    hasSlotTitle() {
      return this.$slots.title
    },
    hasSlotTitleButton() {
      return this.$slots['title-button']
    },
    isUiSidebar() {
      return this.type === 'ui'
    },
    hasFancyClose() {
      return this.fancyClose || this.type === 'ui'
    }
  },
  watch: {
    isOpen() {
      this.handleToggle()
    }
  },
  beforeMount() {
    // if toggleable and the initial state is hidden, set isReady to false
    // this will remove the transition from the initial toggle event (prevents flash)
    if (this.toggle && !this.isOpen) {
      this.isReady = false
    }
  },
  mounted() {
    // if toggleable, check active state and run toggle event if false
    this.$nextTick(() => {
      this.isPositionedRight()
      if (this.toggle && !this.isOpen) {
        this.handleToggle()
      }
    })
    window.addEventListener('resize', this.handleResize)
    if (this.appended) {
      document.getElementById('app').appendChild(this.$refs.sidebar)
    }
  },
  methods: {
    getCustomWidth() {
      if (this.width) {
        return `width:${this.width}`
      }
    },
    isPositionedRight() {
      let elem = this.$el.nextElementSibling
      const sibs = []
      while (elem) {
        sibs.push(elem)
        elem = elem.nextElementSibling
      }
      const panelSibs = sibs.filter((item) => item.classList.contains('app-panel'))
      if (panelSibs.length) {
        this.isRight = false
      } else {
        this.isRight = true
      }
    },
    handleToggle() {
      // get width of sidebar
      this.elWidth = this.$el.offsetWidth
      // set negative margin based on whether sidebar is before or after content panel
      const elTransitionTime = this.isReady ? this.toggleTransitionTime : '0'
      const elMargin = this.isRight ? 'margin-right' : 'margin-left'

      // apply our transition styles
      if (this.isOpen) {
        Object.assign(this.$el.style, {
          [elMargin]: '0',
          'transition-duration': `${elTransitionTime}ms`
        })
        // add backdrop
        if (this.showBackdrop) {
          const backdrop = document.createElement('div')
          backdrop.setAttribute('class', 'app-sidebar-backdrop')
          this.$el.parentNode.insertBefore(backdrop, this.$el)
          this.$nextTick(() => {
            backdrop.classList.add('is-active')
          })
        }
      } else {
        Object.assign(this.$el.style, {
          [elMargin]: `-${this.elWidth}px`,
          'transition-duration': `${elTransitionTime}ms`
        })
        if (this.showBackdrop && this.isReady) {
          const backdrop = this.$el.previousSibling
          if (this.$el.parentNode) this.$el.parentNode.removeChild(backdrop)
        }
      }
      // set isReady flag. all subsequent events will have the 300ms transition
      this.isReady = true
    },
    handleToggleCollapse() {
      this.isCollapsed = !this.isCollapsed
      this.$emit('collapse', this.isCollapsed)
      this.$refs.btnCollapse.$el.blur()
      this.collapseActive = true
      // remove transition active class
      setTimeout(() => {
        this.collapseActive = false
      }, 300)
      this.$emit('sidebartoggled', this.isOpen)
    },
    handleClose() {
      this.$emit('close')
    },
    handleResize: debounce(function () {
      if (this.toggle && !this.isOpen) {
        this.handleToggle()
      }
    }, 300),
    unmountAppendedFromDom() {
      if (this.appended) {
        document.getElementById('app').removeChild(this.$refs.sidebar)
      }
    }
  }
}
</script>

<style lang="scss" src="./app-sidebar.scss"></style>
