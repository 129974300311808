export function alphaSort(a, b, c, d) {
  a = a.toLowerCase()
  b = b.toLowerCase()
  if (c && d) {
    c = c.toLowerCase()
    d = d.toLowerCase()
    return a < b ? -1 : a > b ? 1 : c < d ? -1 : c > d ? 1 : 0
  } else {
    return a < b ? -1 : a > b ? 1 : 0
  }
}

const getDate = (d) => {
  return (d && d.length > 0 && new Date(d)) || null
}

const getString = (d) => {
  return (!!d && d.length > 0 && d.toString()) || null
}

// Evaluate for performance hits.
// creating new date inside sort may not be ideal.
export const sortDateBy = (a, b, dateField = 'date') => {
  const dateA = getDate(a[dateField])
  const dateB = getDate(b[dateField])

  if (dateA === null || dateB === null) {
    return (dateA === null) - (dateB === null)
  }

  return (dateA === null) - (dateB === null) || dateB - dateA
}

export const sortStringBy = (a, b, field) => {
  const compA = getString(a[field])
  const compB = getString(b[field])

  if (compA === null || compB === null) {
    return (compA === null) - (compB === null)
  }

  return compA.localeCompare(compB)
}

export const aSortDateBy = (arr, dateField = 'date', reverse = false) => {
  const arrCopy = [...arr]

  return reverse
    ? arrCopy.sort((a, b) => sortDateBy(b, a, dateField))
    : arrCopy.sort((a, b) => sortDateBy(a, b, dateField))
}

export const aSortStringBy = (arr, field, reverse = false) => {
  if (!field) return 0
  const arrCopy = [...arr]
  return reverse
    ? arrCopy.sort((a, b) => sortStringBy(b, a, field))
    : arrCopy.sort((a, b) => sortStringBy(a, b, field))
}
