import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  projectUsers: [],
  projectUsersRole: [],
  fetchingProjectUsers: false
}

export const getters = {
  getProjectUsers: (state) => state.projectUsers,
  getProjectUsersRole: (state) => state.projectUsersRole
}

export const actions = {
  fetchProjectUsers({ commit }, projectId) {
    const url = `${
      `/api/v1/customer/{customerId}/node/{nodeId}/projectUsers?` + 'projectId='
    }${projectId}&filter.fields=User,Roles`

    // check fetch status
    if (state.fetchingProjectUsers === true) {
      return new Promise((resolve) => {
        resolve([])
      })
    }
    // update the fetch status
    commit(types.PROJECT_USER_FETCH_STATUS_SET, true)

    return api
      .get(url)
      .then((response) => {
        const projectUsers = response?.data?.data?.projectUserDTO?.values || []
        commit(types.PROJECT_USER_SET_ALL, projectUsers)
        return projectUsers
      })
      .finally(() => commit(types.PROJECT_USER_FETCH_STATUS_SET, false))
  },
  fetchProjectUserByRole({ commit }, roleId) {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/projectUsers?roleId=${roleId}`

    return api.get(url).then((response) => {
      const projectUsers = response?.data?.data?.projectUserDTO?.values || []
      commit(types.PROJECT_USER_ROLE_SET_ALL, projectUsers)
      return projectUsers
    })
  },
  deleteProjectUser: (_, projectUserId) => {
    return api.delete(
      `/api/v1/customer/{customerId}/node/{nodeId}/projectUsers?projectUserId=${projectUserId}`
    )
  }
}

const mutations = {
  [types.PROJECT_USER_SET_ALL](state, projectUsers) {
    state.projectUsers = projectUsers
  },
  [types.PROJECT_USER_FETCH_STATUS_SET](state, status) {
    state.fetchingProjectUsers = status
  },
  [types.PROJECT_USER_ROLE_SET_ALL](state, projectUsersRole) {
    state.projectUsersRole = projectUsersRole
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
