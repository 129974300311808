import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

export default {
  name: 'projects-buyers',
  computed: {
    ...mapGetters(['getDashboardUserProjectLeads']),
    procurementLeadProjectList() {
      return cloneDeep(this.getDashboardUserProjectLeads).sort((a, b) =>
        a.name.localeCompare(b.name)
      )
    }
  },
  data() {
    return {
      viewString: 'ui.views.dashboard.manager-dashboard-view.',
      selectedProjectLead: null,
      columnDefs: [
        {
          header: this.$t(`ui.views.dashboard.manager-dashboard-view.headingName`),
          field: 'name',
          width: 300,
          sortable: true
        },
        {
          header: this.$t(`ui.views.dashboard.manager-dashboard-view.headingInProgress`),
          field: 'design',
          sortable: false
        },
        {
          header: this.$t(`ui.views.dashboard.manager-dashboard-view.headingScheduled`),
          field: 'scheduled',
          sortable: false
        },
        {
          header: this.$t(`ui.views.dashboard.manager-dashboard-view.headingPlanned`),
          field: 'planned',
          sortable: false
        },
        {
          header: this.$t(`ui.views.dashboard.manager-dashboard-view.headingPublished`),
          field: 'open',
          sortable: false
        },
        {
          header: this.$t(`ui.views.dashboard.manager-dashboard-view.headingClosedUnofficial`),
          field: 'closed',
          sortable: false
        },
        {
          header: this.$t(`ui.views.dashboard.manager-dashboard-view.headingAwarded`),
          field: 'awarded',
          sortable: false
        },
        {
          header: this.$t(`ui.views.dashboard.manager-dashboard-view.headingTotal`),
          field: 'total',
          sortable: false
        }
      ]
    }
  }
}
