<template>
  <app-grid class="buyer-dashboard-overview__uploads has-margin-top">
    <div slot="actionsRight">
      <app-grid-pagination
        :page-size="gridPageSize"
        :current-page="gridCurrentPage"
        :total-pages="gridTotalPages"
        :total-rows="gridTotalRows"
        @clickPrev="handleClickPrev"
        @clickNext="handleClickNext"
        @pageSizeChange="handleGridPageSizeChange"
      >
      </app-grid-pagination>
      <app-button :disabled="toApprove.length < 1" @click="approveAll()">
        Approve Selected
      </app-button>
      <el-dropdown :show-timeout="0" :hide-timeout="0" :hide-on-click="false" trigger="click">
        <app-column-button />
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(column, index) in columnDefs" :key="'visible-column' + index">
            <app-checkbox
              :id="column.field"
              v-model="column.visible"
              :label="column.columnHeading"
              :name="column.field"
              :value="column.field"
              class="has-margin-bottom-tiny"
            >
            </app-checkbox>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-table
      ref="bidTable"
      :data="tableData"
      class="table-bids"
      width="100%"
      stripe
      @sort-change="handleColumnReorder"
    >
      <span slot="empty">{{ $t('ui.views.dashboard.dashboard-view.overviewUploadsEmpty') }}</span>
      <el-table-column
        v-for="(column, c) in visibleColumns"
        :key="`column-${c}`"
        :label="column.columnHeading"
        :align="getAlign(column.field)"
        :min-width="column.width || '150'"
        :property="column.field"
        sortable="custom"
      >
        <template slot-scope="props">
          <router-link
            v-if="column.field === 'projectName'"
            :to="`/projects/${props.row.projectId}/contract/documents`"
          >
            {{ props.row[column.field] }}
          </router-link>
          <span
            v-if="
              column.field === 'type' ||
              column.field === 'category' ||
              column.field === 'supplier' ||
              column.field === 'projectNumber'
            "
            >{{ props.row[column.field] }}</span
          >
          <div
            v-if="column.field === 'procurementLead' || column.field === 'contractAdmin'"
            class="is-aligned-center"
          >
            <span v-if="props.row[column.field] !== 'N/A'" class="is-flex is-aligned-center">
              <app-avatar :src="props.row[column.field].avatarUrl" size="medium" />
              {{ props.row[column.field].firstName + ' ' + props.row[column.field].lastName }}</span
            >
            <span v-if="props.row[column.field].inActive" class="is-danger">{{
              $t('ui.common.deactivated')
            }}</span>
          </div>
          <span v-if="column.field === 'uploadedDate'">{{
            props.row[column.field] | dateString
          }}</span>
          <span v-if="column.field === 'approved'">
            <app-checkbox
              v-model="props.row.approved"
              :value="1"
              class="has-margin-left-auto has-margin-right-auto"
              @change="handleClickApprove(props.row)"
            />
          </span>
          <span v-if="column.field === 'download'">
            <app-button type="text" @click="handleClickDownload(props.row)">
              <app-icon icon="download" />
            </app-button>
          </span>
        </template>
      </el-table-column>
    </el-table>
  </app-grid>
</template>

<script>
import TableMixins from '@/mixins/table-mixins.js'
import errorHandler from '@/utils/error'
import cloneDeep from 'lodash/cloneDeep'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'dashboard-buyer-overview-uploads',
  mixins: [TableMixins],
  data() {
    return {
      viewString: 'ui.views.dashboard.buyer-dashboard-view.',
      toApprove: [],
      columnDefs: [
        {
          field: 'projectNumber',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingProjectNumber'),
          visible: true
        },
        {
          field: 'projectName',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingProjectName'),
          visible: true
        },
        {
          field: 'category',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingCategory'),
          visible: true
        },
        {
          field: 'type',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingType'),
          visible: true
        },
        {
          field: 'supplier',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingSupplier'),
          visible: true
        },
        {
          field: 'procurementLead',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingProcurementLead'),
          visible: true
        },
        {
          field: 'contractAdmin',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingContractAdmin'),
          visible: true
        },
        {
          field: 'uploadedDate',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingUploadedDate'),
          visible: true
        },
        {
          field: 'approved',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingApprove'),
          visible: true,
          width: 100
        },
        {
          field: 'download',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingDownload'),
          visible: true,
          width: 100
        }
      ],
      data: []
    }
  },
  computed: {
    ...mapGetters([
      'getUsers',
      'getPendingDocuments',
      'getInsuranceTypes',
      'getContractBondDocumentTypes',
      'getContractVendorDocumentTypes',
      'getInsuranceDocument',
      'getContractVendorDocument',
      'getContractBondDocument',
      'getCertificate'
    ]),
    dataSource() {
      return this.data
    }
  },
  mounted() {
    this.$emit('loading', true)

    const promises = [this.fetchPendingDocumentsByNode()]
    if (!this.getUsers.length) {
      promises.push(this.fetchUsers())
    }
    if (!this.getContractVendorDocumentTypes.length) {
      promises.push(this.fetchContractVendorDocumentTypes())
    }
    if (!this.getContractBondDocumentTypes.length) {
      promises.push(this.fetchContractBondDocumentTypes())
    }
    if (!this.getInsuranceTypes.length) {
      promises.push(this.fetchInsuranceTypes())
    }

    Promise.all(promises)
      .then(() => {
        this.buildData()
        this.$emit('error', false)
      })
      .catch(() => this.$emit('error', false))
      .finally(() => this.$emit('loading', false))
  },
  methods: {
    getAlign(field) {
      switch (field) {
        case 'view':
        case 'approved':
        case 'download':
          return 'center'
        default:
          return 'left'
      }
    },
    buildData() {
      const docs = cloneDeep(this.getPendingDocuments)
      const users = cloneDeep(this.getUsers)
      const insuranceTypes = cloneDeep(this.getInsuranceTypes)
      const bondTypes = cloneDeep(this.getContractBondDocumentTypes)
      const vendorDocTypes = cloneDeep(this.getContractVendorDocumentTypes)
      // throw all the types into one array
      const docTypes = insuranceTypes.concat(bondTypes, vendorDocTypes)
      const pendingDocs = []
      if (docs?.length) {
        docs.forEach((doc) => {
          let procLead = []
          if (doc.projectId) procLead = users.filter((user) => user.id === doc.procurementLeadId)
          let docType = null
          let docCategory = null
          const id = doc.documentId
          if (doc.category.toLowerCase() === 'certificate') {
            docType = 'Certificate'
          } else if (doc.category.toLowerCase() === 'bond') {
            docType = 'Bonds and Security'
            docCategory = docTypes.filter((type) => type.id === doc.type)
          } else if (doc.category.toLowerCase() === 'vendordocument') {
            docType = 'Supplier Document'
            docCategory = docTypes.filter((type) => type.id === doc.type)
          } else if (doc.category.toLowerCase() === 'insurance') {
            docType = 'Insurance'
            docCategory = docTypes.filter((type) => type.id === doc.type)
          }

          let docContractAdmin = []
          if (docType) {
            if (doc.contractAdminId) {
              const docContractAdmins = doc.contractAdminId.split(',')
              const admin = docContractAdmins[0]
              docContractAdmin = users.filter((user) => user.id === admin.toLowerCase())
            }
          }

          if (doc.approved === false) {
            pendingDocs.push({
              projectName: doc.projectName || 'N/A',
              projectId: doc.projectId || 'N/A',
              projectNumber: doc.projectNumber || 'N/A',
              category: docType,
              type: docCategory?.[0]?.text || 'N/A',
              supplier: doc.companyName,
              procurementLead: procLead.length > 0 ? procLead[0] : 'N/A',
              contractAdmin: doc.contractAdminId ? docContractAdmin[0] : 'N/A',
              uploadedDate: doc.created,
              contractId: doc.contractId,
              docId: id,
              approved: doc.approved,
              documentId: doc.documentId,
              fileName: doc.fileName
            })
          }
        })
      }
      this.data = pendingDocs
    },
    handleColumnReorder(row) {
      switch (row.prop) {
        case 'procurementLead':
        case 'contractAdmin':
          this.data = this.data.sort((a, b) => {
            const a1 = a[row.prop]?.firstName || a[row.prop]
            const b1 = b[row.prop]?.firstName || b[row.prop]
            return row.order === 'ascending' ? a1.localeCompare(b1) : b1.localeCompare(a1)
          })
          break
        default:
          this.data = this.standardTableSortWithDate(row, this.data, ['expiredDate'])
      }
    },
    handleClickDownload(row) {
      this.$emit('loading', true)

      this.downloadDocument({
        documentId: row.documentId,
        fileName: row.fileName
      })
        .then(() => (this.toApprove = []))
        .catch((e) => errorHandler.handleApiError(this, e))
        .finally(() => this.$emit('loading', false))
    },
    handleClickApprove(row) {
      if (row.approved === true) {
        this.toApprove.push(row)
      } else {
        const removeIndex = this.toApprove
          .map(function (item) {
            return item.docId
          })
          .indexOf(row.docId)
        this.toApprove.splice(removeIndex, 1)
      }
    },
    approveAll() {
      const promises = []

      this.toApprove.forEach((row) => {
        let fetcher = null
        let getter = null
        let updater = null
        switch (row.category) {
          case 'Insurance':
            fetcher = this.fetchInsuranceDocument
            getter = this.getInsuranceDocument
            updater = this.updateInsuranceDocument
            break
          case 'Supplier Document':
            fetcher = this.fetchContractVendorDocument
            getter = this.getContractVendorDocument
            updater = this.updateContractVendorDocument
            break
          case 'Bonds and Security':
            fetcher = this.fetchContractBondDocument
            getter = this.getContractBondDocument
            updater = this.updateContractBondsDocument
            break
          default:
            fetcher = this.fetchCertificate
            getter = this.getCertificate
            updater = this.updateCertificate
        }

        promises.push(
          fetcher(row.docId, row.contractId)
            .then(() => {
              const doc = cloneDeep(getter(row.docId))
              doc.approved = row.approved
              return updater({
                model: doc,
                contractId: row.contractId
              })
            })
            .catch((e) => errorHandler.handleApiError(this, e))
        )
      })

      this.$emit('loading', true)

      Promise.all(promises)
        .then(() => this.fetchPendingDocumentsByNode())
        .then(() => (this.toApprove = []))
        .then(() => this.buildData())
        .catch((e) => errorHandler.handleApiError(this, e))
        .finally(() => this.$emit('loading', false))
    },
    ...mapActions([
      'downloadDocument',
      'fetchInsuranceDocument',
      'updateInsuranceDocument',
      'fetchContractVendorDocument',
      'updateContractVendorDocument',
      'fetchContractBondDocument',
      'updateContractBondsDocument',
      'fetchCertificate',
      'updateCertificate',
      'fetchPendingDocumentsByNode',
      'fetchUsers',
      'fetchContractVendorDocumentTypes',
      'fetchContractBondDocumentTypes',
      'fetchInsuranceTypes'
    ])
  }
}
</script>

<style lang="scss">
.buyer-dashboard-overview__uploads {
  .el-checkbox__label {
    margin: 0 auto;
  }

  .app-grid-actions.app-grid-actions-top {
    margin-bottom: 0 !important;
  }
}
</style>
