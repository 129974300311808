import { api } from '@/api/api'

export const actions = {
  fetchVendorQuestions(_, tenderId) {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/VendorQuestions?tenderId=${tenderId}`

    return api.get(url).then((response) => response.data.data.tenderVendorQuestionDTO.values)
  },
  updateVendorQuestion(_, { vendorQuestion, answerViaEmail }) {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/VendorQuestions?answerViaEmail=${answerViaEmail}`

    return api.put(url, vendorQuestion).then((response) => {
      const vendorQuestion = response.data.data.tenderVendorQuestionDTO.values[0]
      return vendorQuestion
    })
  },
  createVendorQuestion(_, vendorQuestion) {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/VendorQuestions`

    return api.post(url, vendorQuestion).then((response) => {
      const vendorQuestion = response.data.data.tenderVendorQuestionDTO.values[0]
      return vendorQuestion
    })
  }
}

export default {
  namespaced: false,
  actions
}
