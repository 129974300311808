import { api, uploadApi } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  contractBondDocuments: []
}

export const getters = {
  getContractBondDocuments: (state) => state.contractBondDocuments,
  getContractBondDocument: (state) => (id) => {
    return state.contractBondDocuments.find((i) => i.id === id)
  }
}
export const actions = {
  fetchContractBondDocuments: ({ commit }, contractId) => {
    const url =
      `/api/v1/customer/{customerId}/node/{nodeId}/contract/${contractId}/ContractBondsDocuments` +
      `?filter.fields=Vendor,ContractBondsDocumentType`
    return api.get(url).then((response) => {
      let contractBondDocuments = []
      if (response.data.count > 0) {
        contractBondDocuments = response.data.data.contractBondsDocumentDTO.values
        commit(types.CONTRACT_BOND_DOCUMENTS_SET, contractBondDocuments)
      }
      return contractBondDocuments
    })
  },
  fetchContractBondDocument: ({ commit }, id, contractId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/contract/${contractId}/ContractBondsDocuments/${id}`
    return api.get(url).then((response) => {
      let contractBondDocuments = []
      if (response.data.count > 0) {
        contractBondDocuments = response.data.data.contractBondsDocumentDTO.values
        commit(types.CONTRACT_BOND_DOCUMENTS_SET, contractBondDocuments)
      }
      return contractBondDocuments
    })
  },
  createContractBondsDocument: ({ commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/contract/${model.contractId}/ContractBondsDocuments`
    return uploadApi.post(url, model.formData).then((response) => {
      let contractBondsDocument = {}
      if (response.data.count > 0) {
        contractBondsDocument = response.data.data.contractBondsDocumentDTO.values[0]
        commit(types.CONTRACT_BONDS_DOCUMENT_UPDATE_OR_ADD, contractBondsDocument)
      }
      return contractBondsDocument
    })
  },
  updateContractBondsDocument: ({ commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/contract/${model.contractId}/ContractBondsDocuments`
    return api.put(url, model.model).then((response) => {
      let contractBondsDocument = {}
      if (response.data.count > 0) {
        contractBondsDocument = response.data.data.contractBondsDocumentDTO.values[0]
        commit(types.CONTRACT_BONDS_DOCUMENT_UPDATE_OR_ADD, contractBondsDocument)
      }

      return contractBondsDocument
    })
  },
  deleteContractBondDocument: ({ commit }, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/contract/${params.contractId}/ContractBondsDocuments/${params.documentId}`
    return api.delete(url).then(() => {
      commit(types.CONTRACT_BOND_DOCUMENTS_DELETE, params.documentId)
      return ''
    })
  }
}
export const mutations = {
  [types.CONTRACT_BOND_DOCUMENTS_SET](state, contractBondDocuments) {
    state.contractBondDocuments = contractBondDocuments
  },
  [types.CONTRACT_BOND_DOCUMENTS_DELETE](state, contractBondDocumentsId) {
    state.contractBondDocuments = state.contractBondDocuments.filter(
      (cbd) => cbd.id !== contractBondDocumentsId
    )
  },
  [types.CONTRACT_BONDS_DOCUMENT_UPDATE_OR_ADD](state, contractBondsDocument) {
    const matchedIndex = state.contractBondDocuments.findIndex(
      (i) => i.id === contractBondsDocument.id
    )
    if (matchedIndex !== -1) {
      // pop it off the array and push a new one so that it triggers a recalc on the ui
      state.contractBondDocuments.splice(matchedIndex, 1)
    }
    state.contractBondDocuments.push(contractBondsDocument)
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
