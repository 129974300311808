import intakeSVG from '@/assets/images/icon-intake.svg'

const buyerDashboardWidgets = [
  {
    id: 'EBF846DD-3189-4D63-A53D-6435B244209B',
    component: 'projectCounter',
    name: 'Project Counter',
    icon: 'sliders',
    description: 'View the total number of projects by status in your organization.',
    selected: false,
    sizes: {
      selected: 'max',
      min: null,
      max: [12, 6]
    }
  },
  {
    id: 'B277CD3B-E39B-467B-9E8D-7D7DD8EDD670',
    component: 'calendar',
    name: 'Important Dates',
    icon: 'calendar',
    description: 'Calendar view of important dates and events for your projects.',
    selected: false,
    sizes: {
      selected: 'max',
      min: null,
      max: [12, 24]
    }
  },
  {
    id: '0774BB30-AF6B-435B-B0DD-707C7E999168',
    component: 'totalProjects',
    name: 'My Total Projects',
    icon: 'flag',
    description: 'View the total number of projects that you are the procurement lead on.',
    selected: false,
    sizes: {
      selected: 'max',
      min: null,
      max: [6, 4]
    }
  },
  {
    id: '8C55D13D-ACA6-45FF-B3A3-1CFB7B337E10',
    component: 'comments',
    name: 'Comments',
    icon: 'message-square',
    description: 'View all of the comments left on your projects.',
    selected: false,
    sizes: {
      selected: 'min',
      min: [6, 12],
      max: [12, 12]
    }
  },
  {
    id: 'A5DEDC9F-CCC5-496B-906E-E47637102C82',
    component: 'openProjects',
    name: 'My Open Sourcing Projects',
    icon: 'book-open',
    description: 'View all your published projects.',
    selected: false,
    sizes: {
      selected: 'max',
      min: null,
      max: [6, 4]
    }
  },
  {
    id: 'CC6DD10C-CEC9-4FD3-973E-C383F460C476',
    component: 'questionsSubmitted',
    name: 'Questions Submitted',
    icon: 'help-circle',
    description: 'View all of your unanswered supplier questions on your projects.',
    selected: false,
    sizes: {
      selected: 'max',
      min: null,
      max: [6, 4]
    }
  },
  {
    id: 'FE23BEC1-E977-4060-B56B-CAC5D4CFCADC',
    component: 'awardedValue',
    name: 'Value of My Awarded Projects',
    icon: 'dollar-sign',
    description: 'View the total value of all your projects in the Awarded status.',
    selected: false,
    sizes: {
      selected: 'max',
      min: null,
      max: [6, 4]
    }
  },
  {
    id: 'CD4B0B74-8AB3-44CB-892A-81FBE21AEE8D',
    component: 'tasks',
    name: 'My Tasks',
    icon: 'bookmark',
    description: 'View all of your assigned tasks.',
    selected: false,
    sizes: {
      selected: 'min',
      min: [6, 12],
      max: [12, 12]
    }
  },
  {
    id: '449C521A-3F76-42EC-847F-9F8A7DDA96AF',
    component: 'expiredContractDocuments',
    name: 'Expired Contract Documents',
    icon: 'alert-circle',
    description: 'View the total number of expired documents for all projects.',
    selected: false,
    sizes: {
      selected: 'max',
      min: null,
      max: [6, 4]
    }
  },
  {
    id: 'AE01D371-E043-4F5F-A59B-E84387C44A89',
    component: 'overview',
    name: 'Overview',
    icon: 'eye',
    description:
      'View information including: Your Projects, Document Uploads Pending Approval, Expired Documents, and Project Ending.',
    selected: false,
    sizes: {
      selected: 'max',
      min: null,
      max: [12, 12]
    }
  },
  {
    id: '958D0790-9A5B-4C7D-84FD-A6AFB7DD8BB8',
    component: 'projectBuyers',
    name: 'Source Projects by Lead',
    icon: 'trending-up',
    description: 'View all the source projects by status and procurement lead. ',
    selected: false,
    sizes: {
      selected: 'max',
      min: [6, 8],
      max: [12, 8]
    }
  },
  {
    id: '68E3CF3B-7550-4FAA-8F30-48B9DCEC303A',
    component: 'projectTeamMembers',
    name: 'Source Projects by Team Member',
    icon: 'crosshair',
    description: 'View and filter source projects by team member.',
    selected: false,
    sizes: {
      selected: 'max',
      min: [6, 12],
      max: [12, 12]
    }
  },
  {
    id: '2178f9c0-8e23-11ee-8c24-b70e6f9f504c',
    component: 'intakeEssentials',
    name: 'Intake: pending requests',
    icon: '',
    customIcon: intakeSVG,
    description: 'View and filter pending intake requests.',
    selected: false,
    sizes: {
      selected: 'max',
      min: null,
      max: [12, 20]
    }
  }
]

export default buyerDashboardWidgets
