import { ref, nextTick, onMounted, onUnmounted } from 'vue'

export default function useClickOutside(elementRef, callback) {
  const isClickOutside = ref(false)

  const checkClickOutside = async (event) => {
    if (elementRef.value && !elementRef.value.contains(event.target)) {
      callback(event)
      isClickOutside.value = true
    } else {
      isClickOutside.value = false
    }
    await nextTick()
  }

  onMounted(() => {
    document.addEventListener('click', checkClickOutside, true)
  })

  onUnmounted(() => {
    document.removeEventListener('click', checkClickOutside, true)
  })

  return { isClickOutside }
}
