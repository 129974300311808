import IntakeService from '@/services/intake/intake.service'
import { useStore } from '@/store'
import { NULL_DATE } from '@/utils/constants'
import { currencyStringToFloat } from '@/utils/utility'
import { sourceMethods } from '@/views/projects/project-new-wizard-view/project-wizard-defs'
import dayjs from 'dayjs'
import uuid from 'uuid/v1'
import { computed } from 'vue'
/**
 * Limited new project struct copied
 * from project store
 * extend if we intend to use more fields
 * or set the currentNewProject data directly
 * from the intake project data
 */
const StructNewProjectData = {
  contractDetails: {
    amount: null,
    contractDocuments: null,
    otherDocuments: null,
    otherDocumentsList: []
  },
  defaultTeamLead: null,
  departmentProjectLead: null,
  divisionCategory: null,
  id: null,
  isCopy: false,
  projectDepartment: null,
  projectName: null,
  projectType: null,
  sourceDetails: {
    bidClassification: null,
    bidMeetings: null,
    closingMethod: null,
    dateEstimatedAward: null,
    description: null,
    id: null,
    includeOtherDocument: false,
    plannedIssueDate: null,
    plannedIssueDateUtc: null,
    procurementMethodLimitedType: [],
    procurementMethodLimitedTypeDescription: null,
    procurementType: null,
    type: null,
    initialPurchasingStartDate: null
  },
  sourceMethod: null,
  teamLead: null
}

const MeetingLocationStruct = {
  deleted: false,
  description: '',
  endDate: null,
  id: null,
  location: '',
  required: false,
  startDate: null,
  tempId: null
}

const TeamMemberStruct = {
  id: null,
  firstName: null,
  lastName: null,
  email: null
}

export default function UseIntakeNewProject(config = {}) {
  const store = config.store || useStore()
  const intakeService = config.intakeService || IntakeService()
  const hasIntakeNewProjectData = computed(() => store.getters.getNewIntakeProjectData !== null)

  function getMappedProcurementType(procurementTypeText) {
    const procurementTypes = store.getters.getProcurementTypes
    const type =
      procurementTypes.find((pt) => pt.text === procurementTypeText) || procurementTypeText
    if (type) return type.id
    return null
  }

  function getUserOrTeamMember({ firstName, lastName, email }) {
    if (!email) return null

    // first user in store.getters.getUsers where email === user.email
    const users = store.getters.getUsers
    const userInStore = users.find((u) => u.email === email)
    if (userInStore) return userInStore

    // if not found, create a new team member
    return {
      ...TeamMemberStruct,
      firstName,
      lastName,
      email
    }
  }

  function mapLocationsToSiteMeetings(locations) {
    return locations.map((location) => {
      const { city, country, postalCode, province, streetAddress, unit } = location

      const tempId = uuid()

      const startDate = dayjs(NULL_DATE).format('YYYY-MM-DDTHH:mm:ss')

      const locationString = [streetAddress, unit, city, province, postalCode, country]
        .filter(Boolean)
        .join(', ')
      return {
        ...MeetingLocationStruct,
        location: locationString,
        id: tempId,
        tempId,
        startDate
      }
    })
  }

  function mapIntakeDataToProjectData(intakeData) {
    if (!intakeData) return null

    let emails = []

    if (intakeData.projectDetail.supplierEmails) {
      emails = intakeData.projectDetail.supplierEmails
        .split(';')
        .map((e) => ({ email: e, isEmailInvitee: true }))
    }

    const dataFromIntake = {
      intakeRequestId: intakeData.id,
      projectName: intakeData.projectDetail.projectName,
      projectDepartment: intakeData.contactDetails.departmentId,
      divisionCategory: intakeData.contactDetails.divisionId,
      teamLead: intakeData.contactDetails.procurementLeadId,
      users: [],
      sourceMethod:
        intakeData.projectDetail.applyToProject === 'NONE' ||
        !intakeData.projectDetail.applyToProject
          ? sourceMethods.PUBLIC
          : sourceMethods.LIMITED
    }

    let bidClassification = intakeData.projectDetail.classification
    if (bidClassification.toLowerCase() === 'services related to goods') {
      bidClassification = 'ServicesRelatedToGoods'
    }

    const sourceDataFromIntake = Object.assign({}, StructNewProjectData.sourceDetails, {
      procurementType: getMappedProcurementType(intakeData.projectDetail.bidType),
      type: getMappedProcurementType(intakeData.projectDetail.bidType),
      bidClassification,
      bidMeetings: intakeData.projectDetail.isSiteVisit,
      description: intakeData.projectDetail.description,
      categories: intakeData.projectDetail.categoryIds.map((c) => ({ id: c })),
      dateEstimatedAward: intakeData.projectDetail.deliveryConstructionStartDate,
      dateAvailable: intakeData.projectDetail.estimatedPostingDate
        ? dayjs(
            new Date(intakeData.projectDetail.estimatedPostingDate).setHours(12, 0, 0, 0)
          ).format('YYYY-MM-DDTHH:mm:ss')
        : null,
      estimatedAnnualValue: currencyStringToFloat(intakeData.financialDetails.projectBudget),
      estimatedValue: currencyStringToFloat(intakeData.financialDetails.estimatedTotalProjectValue),
      meetingLocations: null,
      vendorInvitationListNew: emails,
      initialPurchasingStartDate: intakeData.projectDetail.dateCreatedUtc,
      teamMembers: []
    })
    // add meeting locations
    if (intakeData.projectDetail.isSiteVisit) {
      sourceDataFromIntake.meetingLocations = mapLocationsToSiteMeetings(
        intakeData.projectDetail.locations
      )
    }

    // add requestor as user or team member
    const requestor = getUserOrTeamMember(intakeData.contactDetails)
    if (requestor.id) {
      dataFromIntake.users.push(requestor)
    }

    const contractDataFromIntake = Object.assign({}, StructNewProjectData.contractDetails, {})

    return {
      ...StructNewProjectData,
      ...dataFromIntake,
      sourceDetails: sourceDataFromIntake,
      contractDetails: contractDataFromIntake
    }
  }

  async function getIntakeDataById(id) {
    try {
      const response = await intakeService.getIntakeProjectData(id)
      return { ...response.data, id }
    } catch (err) {
      throw err
    }
  }

  async function createProjectFromIntakeData(intakeData) {
    try {
      if (!store.getters.getProcurementTypes.length) {
        await store.dispatch('fetchProcurementTypes')
      }
      const mappedData = mapIntakeDataToProjectData(intakeData)
      await store.dispatch('createNewProjectFromIntake', mappedData)
      const newIntake = await store.getters.getNewIntakeProjectData
      return newIntake
    } catch (err) {
      throw err
    }
  }

  return {
    createProjectFromIntakeData,
    getIntakeDataById,
    mapIntakeDataToProjectData,
    hasIntakeNewProjectData
  }
}
