<template>
  <div :class="sizeClass" class="app-bar">
    <div class="app-bar__primary">
      <router-link to="/" class="app-bar-logo-link">
        <img
          id="logo-svg"
          src="@/assets/images/logo/B&T_Logo_Name_White.svg"
          :class="{ visible: logoVisible }"
          aria-label="Bids and tenders"
          alt="bids and tenders"
        />
      </router-link>
      <div class="app-bar-left">
        <AppMainNav />
      </div>
      <div class="app-bar-right">
        <app-user-nav />
        <AppSettingsNav />
      </div>
    </div>
  </div>
</template>
<script>
import AppMainNav from '../app-mainnav/app-main-nav.vue'
import AppUserNav from '@/components/app-user-nav/app-user-nav.vue'
import AppSettingsNav from '@/components/app-settings-nav/app-settings-nav.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'app-bar',
  components: {
    AppUserNav,
    AppMainNav,
    AppSettingsNav
  },
  props: {
    size: String
  },
  data() {
    return {
      project: null,
      isAdmin: true,
      logoVisible: false
    }
  },
  computed: {
    ...mapGetters(['getProjects', 'getProject']),
    displayName() {
      const customer = this.$store.getters.getCustomer
      return customer ? customer.displayName : ''
    },
    currentProject() {
      return this.getProject || false
    },
    appPath() {
      return this.$route.matched && this.$route.matched[0] ? this.$route.matched[0].path : false
    },
    sizeClass() {
      return this.size ? `is-size-${this.size}` : ''
    }
  },
  mounted() {
    // logo animation timer
    this.logoTimer = window.setTimeout(() => {
      this.logoVisible = true
    }, 1000)
  },
  beforeDestroy() {
    this.logoTimer = null
  }
}
</script>

<style lang="scss" src="./app-bar.scss"></style>
