import { api } from '@/api/api'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import * as types from './../../mutation-types.js'

export const state = {
  entries: []
}

export const getters = {
  getProjectJournalEntries: (state) => state.entries
}

export const actions = {
  fetchProjectJournalEntries: ({ commit }, projectId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ProjectJournals/All?projectId=${projectId}`
    return api
      .get(url)
      .then((response) => {
        if (response.data.count > 0) {
          const entries = response.data.data.projectJournalDTO.values
          commit(types.PROJECT_JOURNAL_ENTRY_SET_ALL, entries)
          return entries
        }
      })
      .catch((err) => {
        commit(types.PROJECT_JOURNAL_ENTRY_SET_ALL, null)
        throw err
      })
  },
  addNewProjectJournalEntry: ({ commit }, newEntry) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ProjectJournals`
    return api.post(url, newEntry).then((response) => {
      const projectJournal = response.data.data.projectJournalDTO.values[0]
      commit(types.PROJECT_JOURNAL_ENTRY_SET, projectJournal)
      return projectJournal
    })
  },
  setNewEntry: ({ commit }, entry) => {
    commit('tempAddEntry', entry)
    return Promise.resolve(state.entries)
  },
  setEntryPinned: ({ commit }, projectJournal) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ProjectJournals/Pinned`
    return api.post(url, projectJournal).then(() => {
      const { id, pinned } = projectJournal
      const index = findIndex(state.entries, { id })
      const entry = cloneDeep(state.entries[index])
      entry.pinned = pinned
      commit('updateEntryPinned', { index, entry })
      return { index, entry }
    })
  },
  updateProjectJournalEntry: ({ commit, state }, entry) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ProjectJournals`
    return api.put(url, entry).then((response) => {
      const projectJournal = response.data.data.projectJournalDTO.values[0]
      const { id } = projectJournal
      const index = findIndex(state.entries, { id })
      const entry = cloneDeep(state.entries[index])

      entry.note = projectJournal.note
      commit('updateEntryContent', { index, entry })
      return [index, entry]
    })
  },
  addProjectJournalEntryComment: ({ commit, state }, { id, model }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ProjectJournalComments`
    return api.post(url, model).then((response) => {
      const comment = response.data.data.projectJournalCommentDTO.values[0]
      const index = findIndex(state.entries, { id: id })

      commit(types.PROJECT_JOURNAL_ENTRY_COMMENT_ADD, { index, comment })
      return [index, comment]
    })
  },
  deleteProjectJournalEntry: ({ commit }, id) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ProjectJournals?projectJournalId=${id}`
    return api.delete(url).then(() => {
      commit('tempRemoveEntry', id)
      return ''
    })
  },
  deleteProjectJournalComment: ({ commit }, { projectJournalId, commentId }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ProjectJournalComments?projectJournalCommentId=${commentId}`
    return api.delete(url).then(() => {
      const index = findIndex(state.entries, { id: projectJournalId })
      commit('tempRemoveComment', { index, commentId })
      return ''
    })
  }
}

export const mutations = {
  [types.PROJECT_JOURNAL_ENTRY_SET_ALL]: (state, entries) => {
    state.entries = entries
  },
  [types.PROJECT_JOURNAL_ENTRY_SET]: (state, { index, entry }) => {
    state.entries[index] = entry
  },
  [types.PROJECT_JOURNAL_ENTRY_COMMENT_ADD]: (state, { index, comment }) => {
    state.entries[index].comments.unshift(comment)
  },
  updateEntryContent: (state, { index, entry }) => {
    const { note } = entry
    state.entries[index].note = note
  },
  updateEntryPinned: (state, { index, entry }) => {
    state.entries[index].pinned = entry.pinned
  },
  tempAddEntry: (state, entry) => {
    if (entry.comments === null) {
      entry.comments = []
    }
    state.entries.unshift(entry)
  },
  tempRemoveEntry: (state, entry) => {
    const entries = state.entries.filter((e) => e.id !== entry)

    state.entries = entries
  },
  tempRemoveComment: (state, { index, commentId }) => {
    const comments = state.entries[index].comments.filter((c) => c.id !== commentId)

    state.entries[index].comments = comments
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
