import PermissionsMixins from '@/security/permissions/permissions-mixins.js'
import store from '@/store'
import { useFeatureHelpers } from '@/utils/featureHelpers.js'
import ApprovalView from '@/views/award-approval/award-approval-view.vue'
import BuyerDashboardView from '@/views/dashboard/buyer-dashboard-view/buyer-dashboard-view.vue'
import ContractBuilderDashboardView from '@/views/dashboard/contract-builder-dashboard-view/contract-builder-dashboard-view.vue'
import SupplierDashboardView from '@/views/dashboard/supplier-dashboard-view/supplier-dashboard-view.vue'
import LoginView from '@/views/login/login-view/login-view.vue'
import ProjectBusView from '@/views/projects/project-view/project-bus-view/project-bus-view.vue'
import Vue from 'vue'
import Router from 'vue-router'
import ControlPanelRoutes from './control-panel/control-panel-routes.js'
import DocsRoutes from './docs/routes-docs.js'
import ProjectRoutes from './project/project-routes.js'
import ReportsRoutes from './reports/reports-routes.js'
import SupplierRoutes from './supplier/supplier-routes.js'
import TeamRoutes from './team/team-routes.js'

Vue.use(Router)

const { featureGateRoute } = useFeatureHelpers()

const DocsDevRedirect = {
  path: '/docs',
  redirect: { name: 'Dashboard' }
}

const UseDocsRoutes = store.getters.getFeatureEnabled('documentation').enabled
  ? DocsRoutes
  : DocsDevRedirect

const router = new Router({
  routes: [
    UseDocsRoutes,
    ReportsRoutes,
    SupplierRoutes,
    TeamRoutes,
    ControlPanelRoutes,
    {
      path: '/debug-view',
      meta: { Auth: true },
      name: 'DebugView',
      beforeEnter: featureGateRoute('DEBUG_VIEW'),
      component: () => import('@/views/debug/debug-view.vue')
    },
    {
      path: '/approval/:awardApprovalId/approver/:awardApprovalWorkflowId',
      meta: { Auth: false, hideAppBar: true },
      name: 'Approval',
      component: ApprovalView
    },
    {
      path: '/login',
      meta: { Auth: false, hideAppBar: true },
      name: 'Login',
      component: LoginView
    },
    {
      path: '/verifyEmail',
      meta: { Auth: false, hideAppBar: true },
      name: 'VerifyResetEmail',
      beforeEnter: featureGateRoute('resetPassword'),
      component: () => import('@/views/login/reset-password/verify-email-view.vue')
    },
    {
      path: '/resetpassword',
      meta: { Auth: false, hideAppBar: true },
      name: 'ResetPassword',
      beforeEnter: featureGateRoute('resetPassword'),
      component: () => import('@/views/login/reset-password/reset-password-view.vue')
    },
    {
      path: '/esignature/landing',
      meta: { Auth: false, hideAppBar: true },
      name: 'esignature',
      component: () => import('@/views/esignature/esignature-view.vue'),
      beforeEnter: (to, from, next) => {
        next()
      }
    },
    {
      path: '/maintenance/landing',
      meta: { Auth: false, hideAppBar: true },
      name: 'maintenance',
      component: () => import('@/views/maintenance/maintenance-view.vue'),
      beforeEnter: (to, from, next) => {
        next()
      }
    },
    {
      path: '/intake-request',
      meta: { Auth: false, hideAppBar: true },
      name: 'intake',
      component: () => import('@/views/intake-request/intake-request.vue')
    },
    {
      path: '/',
      meta: { Auth: true },
      name: 'Dashboard',
      component: BuyerDashboardView
    },
    {
      path: '/supplier-dashboard',
      meta: { Auth: true },
      name: 'SupplierDashboard',
      component: SupplierDashboardView,
      beforeEnter: (to, from, next) => {
        if (store.getters.getTenderNode && !store.getters.getTenderNode.contractorPerformance) {
          next({ path: '/' })
        } else {
          next()
        }
      }
    },
    {
      path: '/contract-builder-dashboard',
      meta: { Auth: true },
      name: 'ContractBuilderDashboard',
      component: ContractBuilderDashboardView
    },
    ...ProjectRoutes,
    {
      path: '/project-loading',
      meta: { Auth: true },
      name: 'ProjectLoadingView',
      component: ProjectBusView
    },
    {
      path: '*',
      redirect: { path: '/' }
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta.hideAppBar) {
    store.dispatch('setShowAppBar', { showAppBar: false })
  } else {
    store.dispatch('setShowAppBar', { showAppBar: true })
  }

  if (import.meta.env?.VITE_MAINTENANCE_MODE === 'true') {
    const storeNoRedirect = localStorage?.getItem('NoRedirect') || 'false'
    const queryNoRedirect = to.query?.noredirect || 'false'

    if (to?.name !== 'maintenance' && queryNoRedirect === 'false' && storeNoRedirect === 'false') {
      localStorage.setItem('NoRedirect', 'false')
      next('/maintenance/landing')
    } else if (
      to?.name === 'maintenance' &&
      (queryNoRedirect === 'true' || storeNoRedirect === 'true')
    ) {
      localStorage.setItem('NoRedirect', 'true')
      next('/login')
    }
  } else if (to?.name === 'maintenance' && import.meta.env?.VITE_MAINTENANCE_MODE === 'false') {
    localStorage.setItem('NoRedirect', 'true')
    next('/login')
  }

  // close any open menus
  store.dispatch('setUserNavMenuOpen', false)
  store.dispatch('setActivityFeedMenuOpen', false)
  store.dispatch('setMessageCentreMenuOpen', false)
  // don't close snapshot mode if it is snapshot that's navigating us back to the project
  if (to.path.indexOf('build\\snapshot') < 0) store.dispatch('setProjectSnapshotOpen', false)

  // move the feedback thing on project pages
  let zenvaVerticalPosition = '0px'
  let zenvaHorizontalPosition = '20px'

  if (to.path.match(/projects\//)) {
    zenvaVerticalPosition = '1200px'
    zenvaHorizontalPosition = '20px'
  }

  setTimeout(() => {
    if (window.zE) {
      window.zE('webWidget', 'updateSettings', {
        webWidget: {
          offset: {
            horizontal: zenvaHorizontalPosition,
            vertical: zenvaVerticalPosition,
            zIndex: '99999'
          }
        }
      })
    }
  }, 30)

  if (to.params.area) {
    if (to.params.area === 'source') {
      store.dispatch('checkProject', to.params.id).then((response) => {
        const project = response
        if (project.tenders === null || project.tenders.length === 0) {
          next(`/projects/${to.params.id}/build`)
        }
      })
    }

    if (to.params.area === 'contract') {
      store.dispatch('checkProject', to.params.id).then((response) => {
        const project = response
        if (project.contracts === null || project.contracts.length === 0) {
          next(`/projects/${to.params.id}/build`)
        }
      })
    }
  }

  PermissionsMixins.methods.checkRoutePermission(store, to)

  if (to.matched.some((record) => record.meta.Auth)) {
    const passthrough = to.query.passthrough

    if (!passthrough && !store.getters.isAuthorized) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
    } else {
      if (passthrough) {
        store.dispatch('validateSession').then(() => {
          // remove passthrough on query string to prevent infinite loop, then reload the page
          window.location = window.location.href.split('?')[0]
          window.location.reload()
        })
      } else next()
    }
  } else {
    next()
  }
})

export default router
