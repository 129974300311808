import DashboardComponent from '@/views/dashboard/components/dashboard-component/dashboard-component.vue'
import cloneDeep from 'lodash/cloneDeep'
import OverviewExpiredDocs from './overview-expired-docs/overview-expired-docs.vue'
import OverviewProjectsEnding from './overview-projects-ending/overview-projects-ending.vue'
import OverviewProjects from './overview-projects/overview-projects.vue'
import OverviewDocumentUploads from './overview-uploads/overview-uploads.vue'

export default {
  name: 'dashboard-buyer-overview',
  components: {
    DashboardComponent,
    OverviewProjects,
    OverviewDocumentUploads,
    OverviewProjectsEnding,
    OverviewExpiredDocs
  },
  props: {
    hasDelete: Boolean,
    sizeText: String
  },
  data() {
    return {
      activeTab: 'projects',
      dashboardReady: true,
      viewString: 'ui.views.dashboard.buyer-dashboard-view.',
      viewStringCommon: 'ui.views.dashboard.dashboard-view.',
      tabsList: ['projects', 'uploads', 'expired', 'ending'],
      tabLoadState: {}
    }
  },
  created() {
    this.setupTabLoadState()
  },
  methods: {
    handleCommand(command) {
      switch (command) {
        case 'delete':
          this.$emit('delete')
          break
        case 'changeSize':
          this.$emit('changeSize')
          break
      }
    },
    setupTabLoadState() {
      const list = {}
      this.tabsList.forEach(
        (tab) =>
          (list[tab] = {
            loading: false,
            error: false
          })
      )
      this.tabLoadState = cloneDeep(list)
    },
    handleLoading(val) {
      this.tabLoadState[this.activeTab].loading = val
    },
    handleError(val) {
      this.tabLoadState[this.activeTab].error = val
    }
  }
}
