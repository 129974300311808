import { api, downloadApi } from '@/api/api'
import util from '@/utils/utility.js'
import Vue from 'vue'
import * as types from '../../mutation-types'

// five minutes
const MAX_THUMBNAIL_CACHE_TIME = 5 * 60 * 1000

function commonDownload(url) {
  return downloadApi.get(url).then(util.forceFileDownload)
}

export const state = {
  thumbnails: {},
  thumbnailNames: [],
  reportCategories: [],
  subscribedReports: [],
  scheduledReports: [],
  customReports: [],
  reportData: []
}

const mutations = {
  [types.REPORTS_ADDTHUMBNAIL](state, thumbnail) {
    state.thumbnails[thumbnail.name] = thumbnail
  },
  [types.REPORTS_THUMBNAILNAMES](state, names) {
    state.thumbnailNames = names
  },
  [types.REPORTS_REPORTCATEGORIES](state, categories) {
    state.reportCategories = categories
  },
  [types.REPORTS_SUBSCRIBED](state, subscribed) {
    state.subscribedReports = subscribed
  },
  [types.REPORTS_REMOVE_RECIPIENT](state, recipientId) {
    state.subscribedReports = state.subscribedReports.filter((r) => r.id !== recipientId)
  },
  [types.REPORTS_SCHEDULED](state, scheduled) {
    state.scheduledReports = scheduled
  },
  [types.REPORTS_REMOVE_SCHEDULED](state, scheduledReportId) {
    state.scheduledReports = state.scheduledReports.filter((r) => r.id !== scheduledReportId)
  },
  [types.REPORTS_UPDATE_SCHEDULED](state, scheduledReport) {
    // find the one
    const report = state.scheduledReports.find((r) => r.id === scheduledReport.id)
    // update it
    report.start = scheduledReport.start
    report.sendAt = scheduledReport.sendAt
    report.frequency = scheduledReport.frequency
    report.recipientDTOs = scheduledReport.recipientDTOs
    // trigger a refresh could also use Vue.set()
    state.scheduledReports = [
      ...state.scheduledReports.filter((r) => r.id !== scheduledReport.id),
      report
    ]
  },
  [types.REPORTS_THUMBNAILCATEGORY](state, opts) {
    if (opts.add) {
      state.thumbnails[opts.name].categories.push(opts.category)
    } else {
      state.thumbnails[opts.name].categories = state.thumbnails[opts.name].categories.filter(
        (c) => c !== opts.category
      )
    }
  },
  [types.REPORTS_DATA](state, data) {
    state.reportData = data
  },
  [types.REPORTS_CUSTOM](state, custom) {
    state.customReports = custom
  },
  [types.REPORTS_REMOVE_CUSTOM](state, customReportId) {
    state.customReports = state.customReports.filter((r) => r.id !== customReportId)
  },
  [types.REPORTS_UPDATE_CUSTOM](state, customReport) {
    state.customReports = [
      ...state.customReports.filter((r) => r.id !== customReport.id),
      customReport
    ]
  },
  [types.REPORTS_CUSTOM_PENDING](state, customReports) {
    const s = new Set()
    for (const r of customReports) {
      s.add(r.id)
    }
    for (const c of state.customReports) {
      Vue.set(c, 'isPending', s.has(c.id))
    }
  }
}

export const getters = {
  getThumbnails: (state) => state.thumbnails,
  getThumbnailNames: (state) => state.thumbnailNames,
  getReportCategories: (state) => state.reportCategories,
  getSubscribedReports: (state) => state.subscribedReports,
  getScheduledReports: (state) => state.scheduledReports,
  getCustomReports: (state) => state.customReports,
  getCustomReportData: (state) => state.reportData
}

export const actions = {
  generateReport: async (_, reportType) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Report/generateReport/${reportType}`
    let response
    try {
      response = await api.post(url)
      if (response.status === 200) {
        return Promise.resolve(response)
      }
    } catch (err) {
      throw err
    }
  },
  downloadNamedReport: (_, reportName) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Report/Download/${reportName}`
    return commonDownload(url)
  },
  fetchNamedThumbnail: async ({ state, commit, rootState }, opts) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Report/Thumbnail/${opts.name}`
    try {
      const nodeId = (rootState.authCredential.authTenderNode || {}).id || '' // localStorage.getItem('nodeId')

      // update the thumbnail if we must, or if it doesn't exist yet
      // or if we've logged in to a different agency
      // or if the cache has expired
      if (
        opts.required ||
        !state.thumbnails[opts.name] ||
        state.thumbnails[opts.name].nodeId !== nodeId ||
        state.thumbnails[opts.name].timestamp < Date.now() - MAX_THUMBNAIL_CACHE_TIME
      ) {
        const response = await api.get(url)
        if (response.status === 200) {
          const thumbnail = response.data.data.searchResult.values[0]
          thumbnail.timestamp = Date.now()
          thumbnail.nodeId = nodeId
          commit(types.REPORTS_ADDTHUMBNAIL, thumbnail)
        }
      }
    } catch (err) {
      throw err
    }
  },
  fetchThumbnailNames: async ({ state, commit }) => {
    if (!state.thumbnailNames.length) {
      const url = `/api/v1/customer/{customerId}/node/{nodeId}/Report/Names`
      try {
        const response = await api.get(url)
        if (response.status === 200) {
          commit(types.REPORTS_THUMBNAILNAMES, response.data.data.searchResult.values)
        }
      } catch (err) {
        throw err
      }
    }
  },
  fetchReportCategories: async ({ state, commit }) => {
    if (!state.reportCategories.length) {
      const url = `/api/v1/customer/{customerId}/node/{nodeId}/Report/Categories`
      try {
        const response = await api.get(url)
        if (response.status === 200) {
          commit(types.REPORTS_REPORTCATEGORIES, response.data.data.reportCategoryNameDTO.values)
        }
      } catch (err) {
        throw err
      }
    }
  },
  fetchSubscribedReports: async ({ commit }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Report/ScheduledForUser`
    try {
      const response = await api.get(url)
      if (response.status === 200) {
        commit(types.REPORTS_SUBSCRIBED, response.data.data.scheduledReportRecipientDTO.values)
      }
    } catch (err) {
      throw err
    }
  },
  fetchScheduledReports: async ({ commit }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Report/ScheduledByUser`
    try {
      const response = await api.get(url)
      if (response.status === 200) {
        commit(types.REPORTS_SCHEDULED, response.data.data.scheduledReportDTO.values)
      }
    } catch (err) {
      throw err
    }
  },
  updateFavorite: async ({ commit }, opts) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Report/Favorite/${opts.name}`
    try {
      const response = opts.add ? await api.get(url) : await api.delete(url)
      if (response.status === 200) {
        commit(types.REPORTS_THUMBNAILCATEGORY, opts)
      }
    } catch (err) {
      throw err
    }
  },
  deleteScheduledReport: async ({ commit }, scheduledReportId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Report/Scheduled/${scheduledReportId}`
    try {
      const response = await api.delete(url)
      if (response.status === 200) {
        commit(types.REPORTS_REMOVE_SCHEDULED, scheduledReportId)
      }
    } catch (err) {
      throw err
    }
  },
  deleteRecipient: async ({ commit }, recipientId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Report/Recipient/${recipientId}`
    try {
      const response = await api.delete(url)
      if (response.status === 200) {
        commit(types.REPORTS_REMOVE_RECIPIENT, recipientId)
      }
    } catch (err) {
      throw err
    }
  },
  createScheduledReport: async ({ dispatch }, scheduledReport) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Report/Scheduled`
    try {
      const response = await api.post(url, scheduledReport)
      if (response.status === 200) {
        dispatch('fetchScheduledReports')
      }
    } catch (err) {
      throw err
    }
  },
  updateScheduledReport: async ({ commit }, scheduledReport) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Report/Scheduled`
    try {
      const response = await api.put(url, scheduledReport)
      if (response.status === 200) {
        commit(types.REPORTS_UPDATE_SCHEDULED, scheduledReport)
      }
    } catch (err) {
      throw err
    }
  },

  fetchCustomReports: async ({ commit }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Report/CustomProject`
    try {
      const response = await api.get(url)
      if (response.status === 200 && response.data.data.customProjectReportDTO) {
        commit(types.REPORTS_CUSTOM, response.data.data.customProjectReportDTO.values)
      }
    } catch (err) {
      throw err
    }
  },
  fetchPendingCustomReports: async ({ commit }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Report/PendingCustomProject`
    try {
      const response = await api.get(url)
      if (response.status === 200 && response.data.data.customProjectReportDTO) {
        commit(types.REPORTS_CUSTOM_PENDING, response.data.data.customProjectReportDTO.values)
      }
    } catch (err) {
      throw err
    }
  },
  fetchCustomReportData: async ({ commit }, testReportInfo) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Report/CustomData`
    try {
      const response = await api.post(url, testReportInfo)
      if (response.status === 200) {
        commit(types.REPORTS_DATA, response.data.data.searchResult.values)
        return Promise.resolve(response)
      }
    } catch (err) {
      throw err
    }
  },
  downloadCustomReport: (_, customReportId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Report/DownloadCustomProject/${customReportId}`
    return commonDownload(url)
  },
  getCustomReportUri: async (_, customReportId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Report/GetCustomProjectUri/${customReportId}`
    try {
      const response = await api.get(url)
      if (response.status === 200) {
        const reportUrl = response.data.data.searchResult.values[0].uri
        const reportName = response.data.data.searchResult.values[0].name
        const azureResponse = await fetch(reportUrl)
        const blob = await azureResponse.blob()
        util.forceFileDownload(null, blob, reportName)
      }
    } catch (err) {
      throw err
    }
  },
  refreshCustomReport: async (_, customReportId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Report/RefreshCustomProject/${customReportId}`
    try {
      const response = await api.get(url)
      if (response.status === 200) {
        // nothing to do yet - this starts a background thread
        // and signalr will notify when the report is refreshed
      }
    } catch (err) {
      throw err
    }
  },
  createCustomReport: async ({ dispatch }, customReport) => {
    let id = ''
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Report/CustomProject`

    try {
      const response = await api.post(url, customReport)
      if (response.status === 200) {
        id = response.data.data.searchResult.values[0]
        dispatch('fetchCustomReports')
      }
    } catch (err) {
      throw err
    }
    return id
  },
  updateCustomReport: async ({ commit }, customReport) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Report/CustomProject`
    try {
      const response = await api.put(url, customReport)
      if (response.status === 200) {
        commit(types.REPORTS_UPDATE_CUSTOM, customReport)
      }
    } catch (err) {
      throw err
    }
  },
  deleteCustomReport: async ({ commit }, customReportId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Report/CustomProject/${customReportId}`
    try {
      const response = await api.delete(url)
      if (response.status === 200) {
        commit(types.REPORTS_REMOVE_CUSTOM, customReportId)
      }
    } catch (err) {
      throw err
    }
  },
  setCustomReportData: ({ commit }, reportData) => {
    commit(types.REPORTS_DATA, reportData)
  },
  resetCustomReportData: ({ commit }) => {
    commit(types.REPORTS_DATA, [])
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
