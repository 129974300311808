import exportFromJSON from 'export-from-json'

function transformData(data, columns) {
  if (!columns) return data
  return data.map((val) => {
    const dto = {}
    for (let i = 0; i < columns.length; i++) {
      dto[columns[i].headerName || columns[i].columnHeading] = val[columns[i].field]
    }
    return dto
  })
}

export function exportTableToXLS(fileName, exportData, columns) {
  exportFromJSON({
    fileName,
    data: transformData(exportData, columns),
    exportType: exportFromJSON.types.xls,
    fileNameFormatter: (name) => name
  })
}
