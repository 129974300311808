import { api } from '@/api/api'
import { useDataSanitizing } from '@/utils/dataSanitizing'
import * as types from '../mutation-types'

const { sanitizeData } = useDataSanitizing()

const state = {
  isLocalAuth: false,
  hasLocalAuthOption: false,
  authHost: null
}

export const getters = {
  isLocalAuth: (state) => state.isLocalAuth,
  hasLocalAuthOption: (state) => state.hasLocalAuthOption,
  getAuthPublicUrl: () => {
    const item = localStorage.getItem('authPublicUrl')
    return sanitizeData(item)
  },
  getAuthLegacyAdminUrl: () => {
    const item = localStorage.getItem('authLegacyAdminUrl')
    return sanitizeData(item)
  }
}

export const actions = {
  setAuthStatus: ({ commit }, isAuth) => {
    commit(types.AUTH_SET_ISAUTH, isAuth)
  },
  clearLoginType: ({ commit }) => {
    commit(types.AUTH_SET_TYPE, false)
    commit(types.AUTH_SET_OPTION, false)
  },
  getLoginType: async ({ commit, state }, model) => {
    try {
      const response = await api.post('/api/v1/login/loginType', model)
      if (response.status === 200 && response.data.data.searchResult.count === 1) {
        switch (response.data.data.searchResult.values[0]) {
          case 'local':
            commit(types.AUTH_SET_TYPE, true)
            commit(types.AUTH_SET_OPTION, false)
            break
          case 'sso':
            commit(types.AUTH_SET_TYPE, false)
            commit(types.AUTH_SET_OPTION, false)
            break
          case 'both':
            commit(types.AUTH_SET_TYPE, false)
            commit(types.AUTH_SET_OPTION, true)
            break
        }
      }
      return Promise.resolve(state.isLocalAuth)
    } catch (err) {
      commit(types.AUTH_SET_TYPE, false)
      commit(types.AUTH_SET_OPTION, false)
      throw err
    }
  },
  fetchHosts: async ({ commit }) => {
    try {
      const response = await api.get('/api/v1/customer/{customerId}/node/{nodeId}/Hosts')
      if (response.status === 200) {
        commit(types.AUTH_SET_HOST, response.data.data.hostDTO.values[0])
      }
    } catch (err) {
      throw err
    }
  },
  authorize: ({ commit, state }, model) => {
    return api
      .post('/api/v1/login', model)
      .then((response) => {
        commit(types.AUTH_SET_ISAUTH, response.status === 200)
        commit(types.AUTH_SET_CUSTOMER, response.data.data.customerDTO.values[0])
        commit(types.AUTH_SET_USER, response.data.data.userDTO.values[0])
        commit(types.AUTH_SET_NODE, response.data.data.tenderNodeDTO.values[0])
        commit(types.AUTH_SET_CUSTOMERNODE, response.data.data.customerNodeDTO.values[0])
        commit(types.AUTH_SET_HOST, response.data.data.hostDTO.values[0])
        return state.authCustomer
      })
      .catch((err) => {
        commit(types.AUTH_SET_ISAUTH, false)
        throw err
      })
  },
  validateSession: ({ commit, getters }) => {
    return api
      .post('/api/v1/login/validate')
      .then((response) => {
        if (!getters.isAuthorized) {
          commit(types.AUTH_SET_ISAUTH, response.status === 200)
          commit(types.AUTH_SET_CUSTOMER, response.data.data.customerDTO.values[0])
          commit(types.AUTH_SET_USER, response.data.data.userDTO.values[0])
          commit(types.AUTH_SET_NODE, response.data.data.tenderNodeDTO.values[0])
          commit(types.AUTH_SET_CUSTOMERNODE, response.data.data.customerNodeDTO.values[0])
          commit(types.AUTH_SET_HOST, response.data.data.hostDTO.values[0])
        }

        return response
      })
      .catch((err) => {
        commit(types.AUTH_SET_ISAUTH, false)
        throw err
      })
  },
  refreshSession: () => {
    return api.get('/api/v1/Utilities/RefreshSession')
  },
  logout: ({ commit }, istimeOut = false) => {
    localStorage.setItem('customerTimeZone', '')
    localStorage.setItem('authPublicUrl', '')
    localStorage.setItem('authLegacyAdminUrl', '')
    localStorage.setItem('session-timeout', '')

    commit(types.AUTH_SET_ISAUTH, false)
    commit(types.AUTH_SET_CUSTOMER, null)
    commit(types.AUTH_SET_USER, null)
    commit(types.AUTH_SET_NODE, null)
    commit(types.AUTH_SET_CUSTOMERNODE, null)
    return api.post('/api/v1/login/signoff').then((response) => {
      localStorage.setItem('session-timeout', '')
      if (
        istimeOut === false &&
        response.data.count !== 0 &&
        response.data.data.searchResult.values[0].redirectUrl
      ) {
        window.location.href = response.data.data.searchResult.values[0].redirectUrl
      }
      return response
    })
  }
}

export const mutations = {
  [types.AUTH_SET_TYPE](state, isLocalAuth) {
    state.isLocalAuth = isLocalAuth
  },
  [types.AUTH_SET_OPTION](state, hasLocalAuthOption) {
    state.hasLocalAuthOption = hasLocalAuthOption
  },
  [types.AUTH_SET_HOST](state, authHost) {
    state.authHost = authHost
    localStorage.setItem('authPublicUrl', `https://${authHost.hostname}`)
    localStorage.setItem('authLegacyAdminUrl', authHost.legacyAdminUrl)
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
