import { api } from '@/api/api'
import util from '@/utils/utility'
import * as types from '../../mutation-types'

const state = {
  tenderTable: null,
  tenderTableSummary: [],
  onlineSubmissionTemplate: []
}

export const getters = {
  getTenderTable: (state) => state.tenderTable,
  getTenderTableSummary: (state) => state.tenderTableSummary,
  getOnlineSubmissionTemplate: (state) => state.onlineSubmissionTemplate
}
export const actions = {
  fetchTenderTable: ({ commit }, tenderTableId) => {
    commit(types.TENDERTABLE_CLEAR)

    const url = `/api/v1/customer/{customerId}/node/{nodeId}/TenderTables?tenderTableId=${tenderTableId}&filter.fields=Tender`
    return api.get(url).then((response) => {
      const tenderTable = response.data.data.tenderTableDTO.values[0]
      commit(types.TENDERTABLE_SET_FROMDTO, {
        tenderTable: tenderTable,
        tender: tenderTable.tender,
        tableType: 0
      })
      return tenderTable
    })
  },
  fetchTenderTableSummary: ({ commit }, tenderId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/TenderTables/Summary?tenderId=${tenderId}`
    return api.get(url).then((response) => {
      if (response.data.count !== 0) {
        const tenderTable = response.data.data.searchResult.values

        commit(types.TENDERTABLE_SET_SUMMARY, tenderTable)

        return response
      }
    })
  },
  createTenderTable: ({ commit }, prop) => {
    commit(types.TENDERTABLE_SET_FROMDTO, {
      tenderTable: prop.tenderTable,
      tableType: prop.tableType,
      tender: prop.tender
    })
  },
  setTenderTable: ({ commit }, tenderTable) => {
    commit(types.TENDERTABLE_SET, tenderTable)
  },
  setTenderTableSummary: ({ commit }, tenderTableSummary) => {
    commit(types.TENDERTABLE_SET_SUMMARY, tenderTableSummary)
  },
  clearTenderTable: ({ commit }) => {
    commit(types.TENDERTABLE_CLEAR)
  },
  updateTenderTable: ({ commit }, props) => {
    // update the DTO with row and columns
    commit(types.TENDERTABLE_UPDATE, props)

    const url = '/api/v1/customer/{customerId}/node/{nodeId}/TenderTables/'
    return api.put(url, state.tenderTable).then((response) => {
      // set updated objects with updated IDs
      const tenderTable = response.data.data.tenderTableDTO.values[0]
      commit(types.TENDERTABLE_SET_FROMDTO, {
        tenderTable: tenderTable,
        tender: tenderTable.tender,
        tableType: 0
      })

      // reset the row columns data to null
      commit(types.TENDERTABLE_UPDATE, {
        tenderTableRows: null,
        tenderTableColumns: null,
        tenderTableRowColumns: null
      })
      return Promise.resolve({
        id: response.data.data.tenderTableDTO.values[0].id,
        tenderTableRows: response.data.data.tenderTableDTO.values[0].tenderTableRows,
        tenderTableColumns: response.data.data.tenderTableDTO.values[0].tenderTableColumns,
        tenderTableRowColumns: response.data.data.tenderTableDTO.values[0].tenderTableRowColumns
      })
    })
  },
  fetchOnlineSubmissionTemplates: ({ commit }, group) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/OnlineSubmissionTemplate/Search?groupName=${group}`
    return api.get(url).then((res) => {
      const templates = res.data.data.onlineSubmissionTemplateDTO.values

      commit(types.TENDERTABLE_INSTRUCTION_TEMPLATE_SET_ALL, templates)
      return res
    })
  },
  copyTenderTable: ({ commit }, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/TenderTables/Copy/${params.tenderTableId}?tenderId=${params.tenderId}&includeData=${params.includeData}`
    return api.post(url).then((response) => {
      const tenderTableSummary = response.data.data.searchResult.values[0]
      commit(types.TENDERTABLE_ADD_OR_REPLACE_SUMMARY, tenderTableSummary)
      return tenderTableSummary
    })
  },
  searchTenderTables: (_, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/TenderTables/Search?keywords=${encodeURIComponent(
      params.keywords
    )}&type=${params.type}`
    return api.get(url).then((response) => {
      let tenderTableSummary = []

      if (response.data && response.data.count > 0) {
        tenderTableSummary = response.data.data.searchResult.values
      }
      return tenderTableSummary
    })
  },
  deleteTenderTable: ({ commit }, tenderTableIds) => {
    const ids = tenderTableIds.join()
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/TenderTables?tenderTableId=${ids}`
    return api.delete(url).then(() => {
      for (let i = 0; i < tenderTableIds.length; i++) {
        commit(types.TENDERTABLE_REMOVE_SUMMARY, tenderTableIds[i])
      }
      return ''
    })
  },
  sortOrder: ({ commit }, tenderTableList) => {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/TenderTables/SortOrder/'

    return api.put(url, tenderTableList).then((response) => {
      if (response.data.count !== 0) {
        const tenderTable = response.data.data.searchResult.values

        commit(types.TENDERTABLE_SET_SUMMARY, tenderTable)

        return tenderTable
      }
    })
  },
  copyAllTenderTables: ({ commit }, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/TenderTables/CopyAll?tenderId=${params.tenderId}&tenderToCopyTablesId=${params.tenderToCopyTablesId}`
    return api.post(url).then((response) => {
      const tenderTableSummary = response?.data?.data?.searchResult?.values || []
      commit(types.TENDERTABLE_SET_SUMMARY, tenderTableSummary)
      return tenderTableSummary
    })
  }
}
export const mutations = {
  [types.TENDERTABLE_SET_FROMDTO](state, props) {
    const tenderTable = props.tenderTable

    state.tenderTable = {
      id: tenderTable ? tenderTable.id : util.guid(),
      name: tenderTable ? tenderTable.name : '',
      isLineNumberIncluded: tenderTable ? tenderTable.isLineNumberIncluded : true,
      startNumber: tenderTable ? tenderTable.startNumber : 1,
      numberofRows: tenderTable ? tenderTable.numberofRows : 1,
      isIncludedForSummary: tenderTable ? tenderTable.isIncludedForSummary : false,
      isAutoComputeTax: tenderTable ? tenderTable.isAutoComputeTax : false,
      sortOrder: tenderTable ? tenderTable.sortOrder : 0,
      isRequired: tenderTable ? tenderTable.isRequired : true,
      tenderTableHeadingText: tenderTable ? tenderTable.tenderTableHeadingText : '',
      tenderTableFooterText: tenderTable ? tenderTable.tenderTableFooterText : '',
      total: tenderTable ? tenderTable.total : 0,
      allowPlantakerToAddRows: tenderTable ? tenderTable.allowPlantakerToAddRows : false,
      maximumOptionalRows: tenderTable ? tenderTable.maximumOptionalRows : null,
      deleted: tenderTable ? tenderTable.deleted : false,
      tender: props.tender,
      tenderTableRows:
        tenderTable && tenderTable.tenderTableRows ? tenderTable.tenderTableRows : null,
      tenderTableColumns:
        tenderTable && tenderTable.tenderTableColumns ? tenderTable.tenderTableColumns : null,
      tenderTableRowColumns:
        tenderTable && tenderTable.tenderTableRowColumns ? tenderTable.tenderTableRowColumns : null,
      tableTypeName: tenderTable ? tenderTable.tableTypeName : '',
      tableType: tenderTable ? tenderTable.tableType : props.tableType,
      taxes: tenderTable ? tenderTable.taxes : null,
      taxesDropdownList: tenderTable ? tenderTable.taxesDropdownList : null,
      hasPromptPayDiscount: tenderTable ? tenderTable.hasPromptPayDiscount : null,
      promptPayDiscountType: tenderTable ? tenderTable.promptPayDiscountType : null
    }
  },
  [types.TENDERTABLE_SET](state, tenderTable) {
    state.tenderTable = tenderTable
  },
  [types.TENDERTABLE_SET_SUMMARY](state, tenderTableSummary) {
    state.tenderTableSummary = tenderTableSummary
  },
  [types.TENDERTABLE_ADD_OR_REPLACE_SUMMARY](state, tenderTableSummary) {
    if (state.tenderTableSummary === null) state.tenderTableSummary = []

    const matchedIndex = state.tenderTableSummary.findIndex(
      (tts) => tts.tenderTableId === tenderTableSummary.tenderTableId
    )
    if (matchedIndex !== -1) {
      state.tenderTableSummary.splice(matchedIndex, 1)
    }
    state.tenderTableSummary.push(tenderTableSummary)
  },
  [types.TENDERTABLE_REMOVE_SUMMARY](state, id) {
    const matchedIndex = state.tenderTableSummary.findIndex((tts) => tts.tenderTableId === id)
    if (matchedIndex !== -1) {
      state.tenderTableSummary.splice(matchedIndex, 1)
    }
  },
  [types.TENDERTABLE_UPDATE](state, props) {
    state.tenderTable.tenderTableRows = props.tenderTableRows
    state.tenderTable.tenderTableColumns = props.tenderTableColumns
    state.tenderTable.tenderTableRowColumns = props.tenderTableRowColumns
  },
  [types.TENDERTABLE_INSTRUCTION_TEMPLATE_SET_ALL]: (state, templates) => {
    state.onlineSubmissionTemplate = templates
  },
  [types.TENDERTABLE_CLEAR](state) {
    state.tenderTable = null
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
