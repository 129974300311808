<script setup>
import { useStandardInput } from '@/composables/input.js'
import { computed, ref } from 'vue'
const emit = defineEmits(['input', 'rawInput', 'isValid', 'on-masked'])

const props = defineProps({
  value: [String, Date],
  label: String,
  required: Boolean,
  hideErrors: Boolean,
  validate: {
    type: Function,
    default: () => true
  },
  errorMessage: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  id: {
    type: String,
    required: true
  },
  title: {
    type: String,
    default: ''
  },
  tooltipType: {
    type: String,
    default: null
  },
  tooltip: String,
  tooltipTheme: {
    type: String,
    default: 'light'
  }
})

const inputEl = ref(null)

const {
  touched,
  isDirty,
  firstPass,
  isValid,
  OnBlur,
  OnInputChanged,
  tooltip,
  value,
  errorMessage,
  showRequiredError
} = useStandardInput({
  emit,
  props,
  el: inputEl
})

defineExpose({
  isValid,
  isDirty,
  touched
})

// el-input doesn't like dates, so we need to transform the value just in case
const transformedValue = computed(() => {
  if (value.value && typeof (value.value === 'Date')) {
    return `${value.value}`
  }
  return value.value
})
</script>

<template>
  <div class="app-input-text has-margin-bottom">
    <label
      v-if="props.label"
      :for="props.id"
      class="is-inline-block has-margin-bottom-tiny"
      :class="{ 'is-required': props.required }"
    >
      {{ props.label }}
    </label>
    <el-input
      :id="props.id"
      ref="inputEl"
      :value="transformedValue"
      :name="`text_field_${id}`"
      :title="props.title"
      :class="{ 'is-invalid': firstPass && isDirty && !isValid }"
      :placeholder="$t(props.placeholder)"
      v-bind="$attrs"
      @blur="OnBlur"
      @input="OnInputChanged"
    >
      <template v-for="(index, name) in $slots" #[name]>
        <slot :name="name" />
      </template>
    </el-input>
    <div
      v-if="tooltip || (!tooltip && props.tooltip)"
      class="app-input-tooltip"
      :class="{ 'is-dark': props.tooltipTheme === 'dark' }"
    >
      {{ tooltip || props.tooltip }}
    </div>
    <!-- Error messages -->
    <Transition name="fade" :duration="200">
      <div v-if="showRequiredError" class="has-margin-top-tiny error input-error">
        <span class="is-danger">{{ $t('ui.errors.global.requiredField') }}</span>
      </div>
    </Transition>
    <Transition name="fade" :duration="200">
      <div v-if="firstPass && isDirty && !isValid" class="has-margin-top-tiny error input-error">
        <slot name="error">
          <span class="is-danger">{{ $t(errorMessage) }}</span>
        </slot>
      </div>
    </Transition>
  </div>
</template>

<style lang="scss">
@import 'src/styles/components/_app-inputs.scss';

.app-input-text {
  @include app-inputs;
  position: relative;

  [disabled],
  &[disabled] {
    pointer-events: auto !important;
    cursor: auto !important;
  }

  textarea {
    height: 120px;
  }

  input::placeholder,
  textarea::placeholder {
    color: $input-placeholder-color;
  }

  .el-input .el-input__count {
    position: absolute;
    right: 5px;
    bottom: -34px;
  }

  .app-input-tooltip {
    position: absolute;
    max-width: 100%;
    opacity: 0;
    left: 50%;
    bottom: 3px;
    font-size: 0.8rem;
    padding: 4px;
    margin-right: -8px;
    border-radius: 3px;
    background: $c-background;
    word-break: break-all;
    transform: translateX(-50%) translateY(105%);
    border: 1px solid $c-border;
    box-shadow: 0 0 5px rgba($black, 0.2);
    z-index: 10000;
    transition: all 0.2s;

    &::after {
      content: '';
      position: absolute;
      top: -5px; /* Adjust this value to position the arrow correctly outside the tooltip box */
      left: 50%;
      transform: translateX(-50%) rotate(-45deg);
      width: 10px;
      height: 10px;
      background: white; /* Match the background color of the tooltip */
      border-right: 1px solid #ccc; /* Light grey border - adjust as per your tooltip border */
      border-top: 1px solid #ccc;
    }

    &.is-dark {
      background: $black;
      color: $white;

      &::after {
        background: $black;
        border-right: 1px solid $white;
        border-top: 1px solid $white;
      }
    }
  }

  .el-input {
    &:hover,
    &:focus,
    &:focus-within {
      ~ .app-input-tooltip {
        opacity: 1;
        transform: translateX(-50%) translateY(100%);
      }
    }
  }
}
</style>
