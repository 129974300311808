export const projectTypes = {
  SOURCE: 'SOURCE',
  CONTRACT: 'CONTRACT',
  SOURCE_CONTRACT: 'SOURCE_CONTRACT',
  TEMPLATE: 'TEMPLATE'
}
export const projectStatus = {
  ARCHIVED: 'Archived',
  AWARDED: 'Awarded',
  CANCELLED: 'Cancelled',
  CLOSED: 'Closed',
  DESIGN_MODE_PENDING: 'Design',
  OPEN: 'Open',
  OPEN_SCHEDULED: 'Scheduled',
  PLANNED: 'Planned',
  REQUEST_FOR_APPROVA: 'Request for Approval',
  TEMPLATE: 'Template',
  TERMINATED: 'Terminated',
  UNOFFICIAL_RESULTS: 'Unofficial',
  UPCOMING: 'Upcoming'
}
//
// The title case value of sourceMethod is the one we sent in the database
// This is to align with the existing format of data with our current database
export const sourceMethods = {
  PUBLIC: 'Public',
  PUBLIC_PRE_QUALIFIED: 'Prequalification',
  INVITATIONAL: 'Private',
  LIMITED: 'Limited'
}
export const sourceClosingMethods = {
  SEALED: 'SEALED',
  UNSEALED: 'UNSEALED'
}
export const vendorSubmissionMethods = {
  HARD_COPY: 'HARD_COPY',
  ONLINE: 'ONLINE'
}
export const submissionMethod = {
  ONLINE: 1,
  HARD_COPY: 0
}
export const submissionType = {
  SUBMISSION_DO_NOT_DISPLAY: 1,
  SUBMISSION_DISPLAY_NAMES: 2,
  SUBMISSION_DISPLAY_RESULTS: 3,
  SUBMISSION_DISPLAY_CHOOSE: 4
}
export const unsealManuallyType = {
  AUTOMATICALLY_DISPLAY: 2,
  LET_ME_CHOOSE: 0,
  OTHERS: 1
}
export const projectSubmissionRequirements = {
  Instructions: 'F918B4E3-6CD2-432B-BF8B-71832FAE3E1F',
  Prices: '06FCACE3-A9AC-4413-A36D-8B4FEF518BDE',
  Questions: '6434FBC7-09CC-4D15-AB09-568E76D24B61',
  Specifications: '0BDEAE50-E824-42BB-A4D4-D5A49D08947C',
  References: '1945ECDE-D54F-434A-BED1-34EBA960AC8A',
  Subcontractors: 'CDB929EB-C85A-4522-B41B-12874904BB3C',
  Documents: 'C7D1523B-2D24-40BB-9F66-B8D642733CBF',
  Security: '0C874124-CCFA-4F04-991E-2A47E5B46B03'
}

export const bluePrintTypes = {
  INFORMAL: 'INFORMAL',
  PLANNED_PROCUREMENT: 'PLANNED_PROCUREMENT'
}

export const classifications = {
  GOODS: 'Goods',
  SERVICES: 'Services',
  CONSTRUCTION: 'Construction',
  SERVICESRELATEDTOGOODS: 'ServicesRelatedToGoods'
}
