import { api, uploadApi } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  vendorDocuments: []
}
export const getters = {
  getVendorDocuments: (state) => state.vendorDocuments
}
export const actions = {
  fetchVendorDocuments: ({ commit }, vendorId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/vendor/${vendorId}/VendorDocuments`
    return api.get(url).then((response) => {
      let vendorDocuments = []

      if (response.data.count > 0) {
        vendorDocuments = response.data.data.vendorDocumentDTO.values
        commit(types.VENDOR_DOCUMENTS_SET, vendorDocuments)
      }
      return vendorDocuments
    })
  },
  createVendortDocument: (_, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/vendor/${model.vendorId}/VendorDocuments`
    return uploadApi.post(url, model.formData)
  },
  updateVendorDocument: (_, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/vendor/${model.vendorId}/VendorDocuments`
    return api.put(url, model.model)
  },
  deleteVendorDocument: (_, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/vendor/${model.vendorId}/VendorDocuments/${model.vendorDocumentId}`
    return api.delete(url)
  }
}
export const mutations = {
  [types.VENDOR_DOCUMENTS_SET](state, vendorDocuments) {
    state.vendorDocuments = vendorDocuments
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
