export const projectPhases = [
  {
    name: 'PROJECT_PHASE_INTAKE',
    value: 'Intake',
    id: 'F79C2411-00C8-4FAB-B401-6C2F3F4F9EA0'
  },
  {
    name: 'PROJECT_PHASE_PLAN',
    value: 'Build',
    id: '6F0EEB95-37FD-495A-8C75-79F2DE9DE19B'
  },
  {
    name: 'PROJECT_PHASE_SOURCE',
    value: 'Source',
    id: 'F94842B4-FC99-4F39-93D9-78F9234039C9'
  },
  {
    name: 'PROJECT_PHASE_AWARD',
    value: 'Award',
    id: 'B0CC0F75-3662-49C9-98E7-6849FD654621'
  },
  {
    name: 'PROJECT_PHASE_CONTRACT',
    value: 'Contract',
    id: '8B91192E-3881-40AC-9413-E75972F72598'
  }
]
export const sourceStatuses = [
  {
    name: 'Archived',
    value: 'archived'
  },
  {
    name: 'Awarded',
    value: 'Awarded'
  },
  {
    name: 'Cancelled',
    value: 'cancelled'
  },
  {
    name: 'Closed',
    value: 'closed'
  },
  {
    name: 'Design Mode',
    value: 'design'
  },
  {
    name: 'Open',
    value: 'open'
  },
  {
    name: 'Planned',
    value: 'planned'
  },
  {
    name: 'Request for Approval',
    value: 'Request for Approval'
  },
  {
    name: 'Scheduled to Publish',
    value: 'Scheduled to Publish'
  },
  {
    name: 'Template',
    value: 'template'
  },
  {
    name: 'Terminated',
    value: 'terminated'
  },
  {
    name: 'Unofficial Results',
    value: 'Unofficial'
  },
  {
    name: 'Upcoming',
    value: 'upcoming'
  }
]
export const sourceDateTypes = [
  {
    name: 'Published',
    value: 'published'
  },
  {
    name: 'Awarded',
    value: 'awarded'
  }
]
export const procurementMethods = [
  {
    name: 'Public',
    value: 'public'
  },
  {
    name: 'Pre-Qualified',
    value: 'prequalification'
  },
  {
    name: 'Invitational',
    value: 'private'
  },
  {
    name: 'Limited',
    value: 'limited'
  }
]
export const procurementClassifications = [
  {
    name: 'Goods',
    value: 'goods'
  },
  {
    name: 'Services',
    value: 'services'
  },
  {
    name: 'Construction',
    value: 'construction'
  },
  {
    name: 'Services Related to Goods',
    value: 'servicesRelatedToGoods'
  }
]
export const contractStatuses = [
  {
    name: 'Active',
    value: 'Active'
  },
  {
    name: 'Cancelled',
    value: 'Cancelled'
  },
  {
    name: 'Closed',
    value: 'Closed'
  },
  {
    name: 'Closed-Archived',
    value: 'Closed-Archived'
  },
  {
    name: 'Design Mode',
    value: 'Design'
  },
  {
    name: 'Expired',
    value: 'Expired'
  },
  {
    name: 'Warranty',
    value: 'Warranty'
  }
]
export const contractDateTypes = [
  {
    name: 'Open',
    value: 'open'
  },
  {
    name: 'Firm',
    value: 'firm'
  },
  {
    name: 'Warranty',
    value: 'warranty'
  }
]
export const commodityCategories = [
  {
    name: 'Advertising',
    value: 'Advertising',
    children: []
  },
  {
    name: 'Aggregates',
    value: 'Aggregates',
    children: [
      {
        name: 'Asphalt',
        value: 'Asphalt'
      },
      {
        name: 'Concrete',
        value: 'Concrete'
      },
      {
        name: 'Granular Materials',
        value: 'Granular Materials'
      },
      {
        name: 'Sand',
        value: 'Sand'
      },
      {
        name: 'Winter Salt',
        value: 'Winter Salt'
      }
    ]
  },
  {
    name: 'Animal & Wildlife',
    value: 'Animal & Wildlife',
    children: [
      {
        name: 'Services',
        value: 'Services'
      },
      {
        name: 'Supplies',
        value: 'Supplies'
      }
    ]
  },
  {
    name: 'Arts and Crafts',
    value: 'Arts and Crafts',
    children: [
      {
        name: 'Services',
        value: 'Services'
      },
      {
        name: 'Supplies',
        value: 'Supplies'
      }
    ]
  },
  {
    name: 'Assistive Devices',
    value: 'Assistive Devices',
    children: []
  },
  {
    name: 'Audio/Visual/Photography/Theatre',
    value: 'Audio/Visual/Photography/Theatre',
    children: [
      {
        name: 'Services',
        value: 'Services'
      },
      {
        name: 'Supplies and Equipment',
        value: 'Supplies and Equipment'
      }
    ]
  },
  {
    name: 'Automotive',
    value: 'Automotive',
    children: [
      {
        name: 'Emergency Vehicles',
        value: 'Emergency Vehicles'
      },
      {
        name: 'Excavators',
        value: 'Excavators'
      },
      {
        name: 'Fire Trucks',
        value: 'Fire Trucks'
      },
      {
        name: 'Graders',
        value: 'Graders'
      },
      {
        name: 'Heavy Trucks ',
        value: 'Heavy Trucks '
      },
      {
        name: 'Lawn and Farm Equipment',
        value: 'Lawn and Farm Equipment'
      },
      {
        name: 'Light Vehicles ',
        value: 'Light Vehicles '
      },
      {
        name: 'Loaders',
        value: 'Loaders'
      },
      {
        name: 'Lubricants',
        value: 'Lubricants'
      },
      {
        name: 'Off Road Equipment',
        value: 'Off Road Equipment'
      },
      {
        name: 'Rental/Leasing Services',
        value: 'Rental/Leasing Services'
      },
      {
        name: 'Services',
        value: 'Services'
      },
      {
        name: 'Supplies & Equipment',
        value: 'Supplies & Equipment'
      },
      {
        name: 'Tractor Implements',
        value: 'Tractor Implements'
      },
      {
        name: 'Trailers',
        value: 'Trailers'
      },
      {
        name: 'Watercraft',
        value: 'Watercraft'
      }
    ]
  },
  {
    name: 'Building Operation Supplies and Equipment - ONLY',
    value: 'Building Operation Supplies and Equipment - ONLY',
    children: [
      {
        name: 'Air Filters',
        value: 'Air Filters'
      },
      {
        name: 'Appliances',
        value: 'Appliances'
      },
      {
        name: 'Building Materials',
        value: 'Building Materials'
      },
      {
        name: 'Cabinets',
        value: 'Cabinets'
      },
      {
        name: 'Doors/Windows/Locks',
        value: 'Doors/Windows/Locks'
      },
      {
        name: 'Electrical',
        value: 'Electrical'
      },
      {
        name: 'Fabricated',
        value: 'Fabricated'
      },
      {
        name: 'Flooring',
        value: 'Flooring'
      },
      {
        name: 'Hardware',
        value: 'Hardware'
      },
      {
        name: 'Janitorial',
        value: 'Janitorial'
      },
      {
        name: 'Lighting',
        value: 'Lighting'
      },
      {
        name: 'Paint',
        value: 'Paint'
      },
      {
        name: 'Plumbing',
        value: 'Plumbing'
      },
      {
        name: 'Window Coverings',
        value: 'Window Coverings'
      }
    ]
  },
  {
    name: 'Building Services',
    value: 'Building Services',
    children: [
      {
        name: 'Building Automation',
        value: 'Building Automation'
      },
      {
        name: 'Cleaning',
        value: 'Cleaning'
      },
      {
        name: 'Elevator',
        value: 'Elevator'
      },
      {
        name: 'Fencing',
        value: 'Fencing'
      },
      {
        name: 'Generator Equipment',
        value: 'Generator Equipment'
      },
      {
        name: 'Graffiti Removal',
        value: 'Graffiti Removal'
      },
      {
        name: 'Janitorial',
        value: 'Janitorial'
      },
      {
        name: 'Locksmith',
        value: 'Locksmith'
      },
      {
        name: 'Mat & Linen',
        value: 'Mat & Linen'
      },
      {
        name: 'Movers & Storage',
        value: 'Movers & Storage'
      },
      {
        name: 'Overhead Door',
        value: 'Overhead Door'
      },
      {
        name: 'Parking Lighting Maintenance',
        value: 'Parking Lighting Maintenance'
      },
      {
        name: 'Pest Control',
        value: 'Pest Control'
      },
      {
        name: 'Security & Monitoring',
        value: 'Security & Monitoring'
      },
      {
        name: 'Sprinkler System',
        value: 'Sprinkler System'
      },
      {
        name: 'Window Cleaning',
        value: 'Window Cleaning'
      }
    ]
  },
  {
    name: 'Business Services',
    value: 'Business Services',
    children: [
      {
        name: 'Answering Services',
        value: 'Answering Services'
      },
      {
        name: 'Armored Car',
        value: 'Armored Car'
      },
      {
        name: 'Courier Services, Overnight & Delivery',
        value: 'Courier Services, Overnight & Delivery'
      },
      {
        name: 'Document Destruction',
        value: 'Document Destruction'
      },
      {
        name: 'Election Service & Supplies',
        value: 'Election Service & Supplies'
      },
      {
        name: 'Records Management',
        value: 'Records Management'
      },
      {
        name: 'Scanning Services, Document Conversion services',
        value: 'Scanning Services, Document Conversion services'
      },
      {
        name: 'Transcription Services',
        value: 'Transcription Services'
      }
    ]
  },
  {
    name: 'Cemetery Supplies & Services',
    value: 'Cemetery Supplies & Services',
    children: []
  },
  {
    name: 'Clothing and Uniform',
    value: 'Clothing and Uniform',
    children: [
      {
        name: 'Dry Cleaning/ Laundry',
        value: 'Dry Cleaning/ Laundry'
      },
      {
        name: 'EMS Uniforms',
        value: 'EMS Uniforms'
      },
      {
        name: 'Fire Uniforms/ Bunker Gear',
        value: 'Fire Uniforms/ Bunker Gear'
      },
      {
        name: 'Footwear',
        value: 'Footwear'
      },
      {
        name: 'Safety Clothing',
        value: 'Safety Clothing'
      },
      {
        name: 'School Uniforms',
        value: 'School Uniforms'
      },
      {
        name: 'Transit Uniforms',
        value: 'Transit Uniforms'
      },
      {
        name: 'Uniform Rental',
        value: 'Uniform Rental'
      }
    ]
  },
  {
    name: 'Community & Health Services Programs',
    value: 'Community & Health Services Programs',
    children: [
      {
        name: 'Community Funding',
        value: 'Community Funding'
      },
      {
        name: 'Institutional Supplies/Services LTC',
        value: 'Institutional Supplies/Services LTC'
      },
      {
        name: 'Medical Supplies/Support Services',
        value: 'Medical Supplies/Support Services'
      },
      {
        name: 'Medical/Dental Services',
        value: 'Medical/Dental Services'
      },
      {
        name: 'Services',
        value: 'Services'
      },
      {
        name: 'Supplies',
        value: 'Supplies'
      }
    ]
  },
  {
    name: 'Construction ',
    value: 'Construction ',
    children: [
      {
        name: 'Affordable Housing',
        value: 'Affordable Housing'
      },
      {
        name: 'Building & Renovation',
        value: 'Building & Renovation'
      },
      {
        name: 'Demolition',
        value: 'Demolition'
      },
      {
        name: 'Drainage',
        value: 'Drainage'
      },
      {
        name: 'Heavy Duty Equipment',
        value: 'Heavy Duty Equipment'
      },
      {
        name: 'Infrastructure',
        value: 'Infrastructure'
      },
      {
        name: 'Infrastructure (Traffic Signalization)',
        value: 'Infrastructure (Traffic Signalization)'
      },
      {
        name: 'Infrastructure (Wastewater)',
        value: 'Infrastructure (Wastewater)'
      },
      {
        name: 'Municipal Drains',
        value: 'Municipal Drains'
      },
      {
        name: 'Parks, Trails & Landscape',
        value: 'Parks, Trails & Landscape'
      },
      {
        name: 'Remediation Services',
        value: 'Remediation Services'
      },
      {
        name: 'Restoration Services ',
        value: 'Restoration Services '
      },
      {
        name: 'Roads, Bridge, Sidewalks',
        value: 'Roads, Bridge, Sidewalks'
      },
      {
        name: 'Supplies (barricades, pylons, speed signs, etc.)',
        value: 'Supplies (barricades, pylons, speed signs, etc.)'
      }
    ]
  },
  {
    name: 'Consulting Services - Professional Services',
    value: 'Consulting Services - Professional Services',
    children: [
      {
        name: 'Actuary',
        value: 'Actuary'
      },
      {
        name: 'Administrative',
        value: 'Administrative'
      },
      {
        name: 'Advertising',
        value: 'Advertising'
      },
      {
        name: 'Architectural',
        value: 'Architectural'
      },
      {
        name: 'Asbestos removal',
        value: 'Asbestos removal'
      },
      {
        name: 'Auction',
        value: 'Auction'
      },
      {
        name: 'Auditing',
        value: 'Auditing'
      },
      {
        name: 'Disaster & Emergency Management',
        value: 'Disaster & Emergency Management'
      },
      {
        name: 'Document Accessibility',
        value: 'Document Accessibility'
      },
      {
        name: 'Economic Development',
        value: 'Economic Development'
      },
      {
        name: 'Energy',
        value: 'Energy'
      },
      {
        name: 'Engineering',
        value: 'Engineering'
      },
      {
        name: 'Environmental',
        value: 'Environmental'
      },
      {
        name: 'Geotechnical',
        value: 'Geotechnical'
      },
      {
        name: 'Hazardous Materials',
        value: 'Hazardous Materials'
      },
      {
        name: 'Human Resources',
        value: 'Human Resources'
      },
      {
        name: 'Information Technology',
        value: 'Information Technology'
      },
      {
        name: 'Insurance',
        value: 'Insurance'
      },
      {
        name: 'Landscape Architecture',
        value: 'Landscape Architecture'
      },
      {
        name: 'Legal',
        value: 'Legal'
      },
      {
        name: 'Management',
        value: 'Management'
      },
      {
        name: 'Occupational Hygiene/Industrial Hygiene',
        value: 'Occupational Hygiene/Industrial Hygiene'
      },
      {
        name: 'Other',
        value: 'Other'
      },
      {
        name: 'Planning',
        value: 'Planning'
      },
      {
        name: 'Public Relations',
        value: 'Public Relations'
      },
      {
        name: 'Recreation',
        value: 'Recreation'
      },
      {
        name: 'Roofing',
        value: 'Roofing'
      },
      {
        name: 'Surveying (OLS/Legal)',
        value: 'Surveying (OLS/Legal)'
      },
      {
        name: 'Training',
        value: 'Training'
      },
      {
        name: 'Translation',
        value: 'Translation'
      },
      {
        name: 'Travel',
        value: 'Travel'
      }
    ]
  },
  {
    name: 'Contracted Services',
    value: 'Contracted Services',
    children: []
  },
  {
    name: 'Cosmetology',
    value: 'Cosmetology',
    children: []
  },
  {
    name: 'Emergency Services',
    value: 'Emergency Services',
    children: []
  },
  {
    name: 'Environmental Goods & Services',
    value: 'Environmental Goods & Services',
    children: [
      {
        name: 'Green Power',
        value: 'Green Power'
      },
      {
        name: 'Landfill',
        value: 'Landfill'
      },
      {
        name: 'Pollution & Odour Control',
        value: 'Pollution & Odour Control'
      },
      {
        name: 'Portable Toilets & Septic Services',
        value: 'Portable Toilets & Septic Services'
      },
      {
        name: 'Recycling Containers (green, blue, rain barrel, composter, etc.)',
        value: 'Recycling Containers (green, blue, rain barrel, composter, etc.)'
      },
      {
        name: 'Sewage Pumping Station',
        value: 'Sewage Pumping Station'
      },
      {
        name: 'Soil testing, Boring, Drilling, Geotechnical, Soil Management',
        value: 'Soil testing, Boring, Drilling, Geotechnical, Soil Management'
      },
      {
        name: 'Waste Container',
        value: 'Waste Container'
      },
      {
        name: 'Waste Disposal / Recycling',
        value: 'Waste Disposal / Recycling'
      }
    ]
  },
  {
    name: 'Equipment - Other',
    value: 'Equipment - Other',
    children: []
  },
  {
    name: 'Event Planning or Recreation',
    value: 'Event Planning or Recreation',
    children: []
  },
  {
    name: 'Facilities, Redevelopment, Trades, Machinery, Construction, Architectural or Engineering',
    value:
      'Facilities, Redevelopment, Trades, Machinery, Construction, Architectural or Engineering',
    children: []
  },
  {
    name: 'Financial Services',
    value: 'Financial Services',
    children: [
      {
        name: 'ABM/ATM Services (interac)',
        value: 'ABM/ATM Services (interac)'
      },
      {
        name: 'Accounting Services',
        value: 'Accounting Services'
      },
      {
        name: 'Banking',
        value: 'Banking'
      },
      {
        name: 'Billing',
        value: 'Billing'
      },
      {
        name: 'Collection Services',
        value: 'Collection Services'
      },
      {
        name: 'Mailing',
        value: 'Mailing'
      },
      {
        name: 'Payment Provider & Credit Card Processing',
        value: 'Payment Provider & Credit Card Processing'
      }
    ]
  },
  {
    name: 'Fire Services',
    value: 'Fire Services',
    children: [
      {
        name: 'Fire and Intrusion System',
        value: 'Fire and Intrusion System'
      },
      {
        name: 'Fire Protection Equipment (Nozzles, Fire extinguisher, props, etc.)',
        value: 'Fire Protection Equipment (Nozzles, Fire extinguisher, props, etc.)'
      },
      {
        name: 'Maintenance',
        value: 'Maintenance'
      },
      {
        name: 'Sprinkler System',
        value: 'Sprinkler System'
      },
      {
        name: 'Supplies',
        value: 'Supplies'
      }
    ]
  },
  {
    name: 'First Aid',
    value: 'First Aid',
    children: [
      {
        name: 'Services',
        value: 'Services'
      },
      {
        name: 'Supplies',
        value: 'Supplies'
      }
    ]
  },
  {
    name: 'Flags',
    value: 'Flags',
    children: []
  },
  {
    name: 'Food',
    value: 'Food',
    children: [
      {
        name: 'Appliances',
        value: 'Appliances'
      },
      {
        name: 'Catering',
        value: 'Catering'
      },
      {
        name: 'Services',
        value: 'Services'
      },
      {
        name: 'Supplies and Equipment',
        value: 'Supplies and Equipment'
      },
      {
        name: 'Vending',
        value: 'Vending'
      }
    ]
  },
  {
    name: 'Fuel',
    value: 'Fuel',
    children: [
      {
        name: 'Fuel Pumps/ Fuel Management',
        value: 'Fuel Pumps/ Fuel Management'
      },
      {
        name: 'Fuel/ Propane/Diesel/Gas/ Lubricants',
        value: 'Fuel/ Propane/Diesel/Gas/ Lubricants'
      }
    ]
  },
  {
    name: 'Furniture',
    value: 'Furniture',
    children: [
      {
        name: 'Library Furniture',
        value: 'Library Furniture'
      },
      {
        name: 'Office Furniture',
        value: 'Office Furniture'
      },
      {
        name: 'Office Partitions/ Wall Systems',
        value: 'Office Partitions/ Wall Systems'
      },
      {
        name: 'Outdoor Furnishings',
        value: 'Outdoor Furnishings'
      },
      {
        name: 'Shelving & Storage',
        value: 'Shelving & Storage'
      },
      {
        name: 'Visual Display Services',
        value: 'Visual Display Services'
      }
    ]
  },
  {
    name: 'Health & Safety',
    value: 'Health & Safety',
    children: []
  },
  {
    name: 'Heavy Construction - Earth Handling Equipment (Industrial)',
    value: 'Heavy Construction - Earth Handling Equipment (Industrial)',
    children: [
      {
        name: 'Services',
        value: 'Services'
      },
      {
        name: 'Supplies and Equipment',
        value: 'Supplies and Equipment'
      }
    ]
  },
  {
    name: 'Human Resources ',
    value: 'Human Resources ',
    children: [
      {
        name: 'Education and Training',
        value: 'Education and Training'
      },
      {
        name: 'Recruitment Services',
        value: 'Recruitment Services'
      }
    ]
  },
  {
    name: 'Information Technology',
    value: 'Information Technology',
    children: [
      {
        name: 'Computer Hardware',
        value: 'Computer Hardware'
      },
      {
        name: 'Computer Software',
        value: 'Computer Software'
      },
      {
        name: 'GIS',
        value: 'GIS'
      },
      {
        name: 'GPS Products',
        value: 'GPS Products'
      },
      {
        name: 'Servers/Racks/Data Centre/Storage',
        value: 'Servers/Racks/Data Centre/Storage'
      },
      {
        name: 'Services',
        value: 'Services'
      }
    ]
  },
  {
    name: 'Landscaping & Forestry',
    value: 'Landscaping & Forestry',
    children: [
      {
        name: 'Arborist',
        value: 'Arborist'
      },
      {
        name: 'Arborist Consultation',
        value: 'Arborist Consultation'
      },
      {
        name: 'Artificial Turf',
        value: 'Artificial Turf'
      },
      {
        name: 'Bedding Plants',
        value: 'Bedding Plants'
      },
      {
        name: 'Fertilizer',
        value: 'Fertilizer'
      },
      {
        name: 'Field Restoration',
        value: 'Field Restoration'
      },
      {
        name: 'Grass Cutting',
        value: 'Grass Cutting'
      },
      {
        name: 'Horticultural Supplies',
        value: 'Horticultural Supplies'
      },
      {
        name: 'Interlocking Brick Maintenance',
        value: 'Interlocking Brick Maintenance'
      },
      {
        name: 'Landscape & Forestry Supplies',
        value: 'Landscape & Forestry Supplies'
      },
      {
        name: 'Mulch',
        value: 'Mulch'
      },
      {
        name: 'Property Maintenance',
        value: 'Property Maintenance'
      },
      {
        name: 'Seeding',
        value: 'Seeding'
      },
      {
        name: 'Small Landscape Equipment (ex. Blowers, push mowers, weed wackers, etc.)',
        value: 'Small Landscape Equipment (ex. Blowers, push mowers, weed wackers, etc.)'
      },
      {
        name: 'Snow Removal',
        value: 'Snow Removal'
      },
      {
        name: 'Sod',
        value: 'Sod'
      },
      {
        name: 'Supplies & Services',
        value: 'Supplies & Services'
      },
      {
        name: 'Top Soil',
        value: 'Top Soil'
      },
      {
        name: 'Tree  Maintenance',
        value: 'Tree  Maintenance'
      },
      {
        name: 'Tree planting',
        value: 'Tree planting'
      },
      {
        name: 'Turf Maintenance',
        value: 'Turf Maintenance'
      }
    ]
  },
  {
    name: 'Law, Enforcement & Security',
    value: 'Law, Enforcement & Security',
    children: [
      {
        name: 'Equipment/Supplies',
        value: 'Equipment/Supplies'
      },
      {
        name: 'Services',
        value: 'Services'
      }
    ]
  },
  {
    name: 'Leasing/Rental',
    value: 'Leasing/Rental',
    children: []
  },
  {
    name: 'Library',
    value: 'Library',
    children: [
      {
        name: 'Services',
        value: 'Services'
      },
      {
        name: 'Supplies',
        value: 'Supplies'
      }
    ]
  },
  {
    name: 'Machinery and Tools',
    value: 'Machinery and Tools',
    children: [
      {
        name: 'Powered & Non-Powered, Supplies & Accessories',
        value: 'Powered & Non-Powered, Supplies & Accessories'
      },
      {
        name: 'Services',
        value: 'Services'
      }
    ]
  },
  {
    name: 'Maintenance - Other',
    value: 'Maintenance - Other',
    children: []
  },
  {
    name: 'Medical ',
    value: 'Medical ',
    children: [
      {
        name: 'Services',
        value: 'Services'
      },
      {
        name: 'Supplies',
        value: 'Supplies'
      }
    ]
  },
  {
    name: 'Medical or Clinical Equipment, Supplies, Services or Pharmacy',
    value: 'Medical or Clinical Equipment, Supplies, Services or Pharmacy',
    children: []
  },
  {
    name: 'Non-Medical Equipment, Furniture, Supplies, Stationary or Services',
    value: 'Non-Medical Equipment, Furniture, Supplies, Stationary or Services',
    children: []
  },
  {
    name: 'Non-Profit Agencies',
    value: 'Non-Profit Agencies',
    children: []
  },
  {
    name: 'Office/School Equipment',
    value: 'Office/School Equipment',
    children: [
      {
        name: 'Early Learning ',
        value: 'Early Learning '
      },
      {
        name: 'Equipment (photocopiers, fax machine, plotters, etc.)',
        value: 'Equipment (photocopiers, fax machine, plotters, etc.)'
      },
      {
        name: 'Mailing System  Equipment & Maintenance',
        value: 'Mailing System  Equipment & Maintenance'
      },
      {
        name: 'Office Supplies/School Supplies',
        value: 'Office Supplies/School Supplies'
      },
      {
        name: 'Services',
        value: 'Services'
      }
    ]
  },
  {
    name: 'Other',
    value: 'Other',
    children: []
  },
  {
    name: 'Parking',
    value: 'Parking',
    children: [
      {
        name: 'Meters/Pay Display',
        value: 'Meters/Pay Display'
      }
    ]
  },
  {
    name: 'Parks',
    value: 'Parks',
    children: [
      {
        name: 'Concession',
        value: 'Concession'
      },
      {
        name: 'Engineered Wood Fiber Surface',
        value: 'Engineered Wood Fiber Surface'
      },
      {
        name: 'Engineered Wood Surfacing Surface',
        value: 'Engineered Wood Surfacing Surface'
      }
    ]
  },
  {
    name: 'Parks Equipment and Supplies',
    value: 'Parks Equipment and Supplies',
    children: []
  },
  {
    name: 'Pest Control',
    value: 'Pest Control',
    children: []
  },
  {
    name: 'Printing Supplies and Services',
    value: 'Printing Supplies and Services',
    children: [
      {
        name: 'Services',
        value: 'Services'
      },
      {
        name: 'Supplies',
        value: 'Supplies'
      }
    ]
  },
  {
    name: 'Professional, Strategic, Administrative or Management Services',
    value: 'Professional, Strategic, Administrative or Management Services',
    children: []
  },
  {
    name: 'Promotional & Novelties',
    value: 'Promotional & Novelties',
    children: [
      {
        name: 'Promotional & Novelties',
        value: 'Promotional & Novelties'
      }
    ]
  },
  {
    name: 'Real Estate',
    value: 'Real Estate',
    children: [
      {
        name: 'Appraisal Services',
        value: 'Appraisal Services'
      },
      {
        name: 'Real Estate Brokerage Services',
        value: 'Real Estate Brokerage Services'
      },
      {
        name: 'Sale/Lease of Property',
        value: 'Sale/Lease of Property'
      }
    ]
  },
  {
    name: 'Recreation/Education',
    value: 'Recreation/Education',
    children: [
      {
        name: 'Arena & Stadium Services',
        value: 'Arena & Stadium Services'
      },
      {
        name: 'Arena & Stadium Supplies',
        value: 'Arena & Stadium Supplies'
      },
      {
        name: 'Blade Sharpening',
        value: 'Blade Sharpening'
      },
      {
        name: 'Bleachers/Stands',
        value: 'Bleachers/Stands'
      },
      {
        name: 'Court Surface Specialists',
        value: 'Court Surface Specialists'
      },
      {
        name: 'Fitness Equipment',
        value: 'Fitness Equipment'
      },
      {
        name: 'Ice Resurfacer',
        value: 'Ice Resurfacer'
      },
      {
        name: 'Instructors',
        value: 'Instructors'
      },
      {
        name: 'Musical Instruments - Repairs',
        value: 'Musical Instruments - Repairs'
      },
      {
        name: 'Musical Instruments - Supplies',
        value: 'Musical Instruments - Supplies'
      },
      {
        name: 'Outdoor Shades',
        value: 'Outdoor Shades'
      },
      {
        name: 'Paint (Field marking, ice paint, etc.)',
        value: 'Paint (Field marking, ice paint, etc.)'
      },
      {
        name: 'Park Development',
        value: 'Park Development'
      },
      {
        name: 'Playground Equipment',
        value: 'Playground Equipment'
      },
      {
        name: 'Pool Services',
        value: 'Pool Services'
      },
      {
        name: 'Pool Supplies',
        value: 'Pool Supplies'
      },
      {
        name: 'Recreation Flooring / Restoration (Gym Floor, Rubberized, etc.)',
        value: 'Recreation Flooring / Restoration (Gym Floor, Rubberized, etc.)'
      },
      {
        name: 'Science Equipment & Supplies',
        value: 'Science Equipment & Supplies'
      },
      {
        name: 'Sporting & Athletic Goods & Supplies ',
        value: 'Sporting & Athletic Goods & Supplies '
      },
      {
        name: 'Sporting Goods',
        value: 'Sporting Goods'
      },
      {
        name: 'Sports Field Lighting',
        value: 'Sports Field Lighting'
      },
      {
        name: 'Welding Equipment & Supplies',
        value: 'Welding Equipment & Supplies'
      }
    ]
  },
  {
    name: 'Road Maintenance',
    value: 'Road Maintenance',
    children: [
      {
        name: 'Roadside Safety (Pylons, Barricades, etc.)',
        value: 'Roadside Safety (Pylons, Barricades, etc.)'
      },
      {
        name: 'Services (crack sealing, pavement markings, etc.)',
        value: 'Services (crack sealing, pavement markings, etc.)'
      },
      {
        name: 'Snow Removal',
        value: 'Snow Removal'
      },
      {
        name: 'Street Cleaning',
        value: 'Street Cleaning'
      },
      {
        name: 'Streetlight supply & maintenance',
        value: 'Streetlight supply & maintenance'
      },
      {
        name: 'Supplies',
        value: 'Supplies'
      },
      {
        name: 'Traffic Marking Paint',
        value: 'Traffic Marking Paint'
      }
    ]
  },
  {
    name: 'Security, Monitoring, Intrusion, Fire or Enforcement',
    value: 'Security, Monitoring, Intrusion, Fire or Enforcement',
    children: []
  },
  {
    name: 'Signs',
    value: 'Signs',
    children: [
      {
        name: 'Design',
        value: 'Design'
      },
      {
        name: 'Flags, Flag poles, Banners, Accessories',
        value: 'Flags, Flag poles, Banners, Accessories'
      },
      {
        name: 'Maintenance',
        value: 'Maintenance'
      },
      {
        name: 'Signs ',
        value: 'Signs '
      }
    ]
  },
  {
    name: 'Social Services',
    value: 'Social Services',
    children: [
      {
        name: 'Public Health',
        value: 'Public Health'
      },
      {
        name: 'Social Housing',
        value: 'Social Housing'
      }
    ]
  },
  {
    name: 'Special Events',
    value: 'Special Events',
    children: []
  },
  {
    name: 'Surplus Sales',
    value: 'Surplus Sales',
    children: [
      {
        name: 'Surplus',
        value: 'Surplus'
      }
    ]
  },
  {
    name: 'Telecommunications',
    value: 'Telecommunications',
    children: [
      {
        name: 'Cell Phones & Devices',
        value: 'Cell Phones & Devices'
      },
      {
        name: 'Data/Voice Cabling',
        value: 'Data/Voice Cabling'
      },
      {
        name: 'Long Distance',
        value: 'Long Distance'
      },
      {
        name: 'Services',
        value: 'Services'
      },
      {
        name: 'Supplies and Equipment',
        value: 'Supplies and Equipment'
      }
    ]
  },
  {
    name: 'Textiles',
    value: 'Textiles',
    children: [
      {
        name: 'Textiles (Curtains, Blinds, etc.)',
        value: 'Textiles (Curtains, Blinds, etc.)'
      }
    ]
  },
  {
    name: 'Trade Services',
    value: 'Trade Services',
    children: [
      {
        name: 'Carpentry',
        value: 'Carpentry'
      },
      {
        name: 'Carpet/ Flooring',
        value: 'Carpet/ Flooring'
      },
      {
        name: 'Concrete',
        value: 'Concrete'
      },
      {
        name: 'Electrical',
        value: 'Electrical'
      },
      {
        name: 'Glass and Glazing',
        value: 'Glass and Glazing'
      },
      {
        name: 'Heating, Ventilating and Air Conditioning (HVAC)',
        value: 'Heating, Ventilating and Air Conditioning (HVAC)'
      },
      {
        name: 'Insulation',
        value: 'Insulation'
      },
      {
        name: 'Painting and Wallpapering',
        value: 'Painting and Wallpapering'
      },
      {
        name: 'Plastering Drywall',
        value: 'Plastering Drywall'
      },
      {
        name: 'Plumbing',
        value: 'Plumbing'
      },
      {
        name: 'Roofing and Siding',
        value: 'Roofing and Siding'
      },
      {
        name: 'Tile and Marble Work, All Types',
        value: 'Tile and Marble Work, All Types'
      },
      {
        name: 'Trade Services,  (Not Otherwise Classified)',
        value: 'Trade Services,  (Not Otherwise Classified)'
      }
    ]
  },
  {
    name: 'Transit and Transportation',
    value: 'Transit and Transportation',
    children: []
  },
  {
    name: 'Transit Capital',
    value: 'Transit Capital',
    children: [
      {
        name: 'Bus',
        value: 'Bus'
      },
      {
        name: 'Fleet Overhaul',
        value: 'Fleet Overhaul'
      },
      {
        name: 'Major Capital',
        value: 'Major Capital'
      }
    ]
  },
  {
    name: 'Transit Operations',
    value: 'Transit Operations',
    children: [
      {
        name: 'Equipment & Supplies',
        value: 'Equipment & Supplies'
      },
      {
        name: 'Fleet Mechanical, electrical, structural',
        value: 'Fleet Mechanical, electrical, structural'
      },
      {
        name: 'Operations & Maintenance',
        value: 'Operations & Maintenance'
      },
      {
        name: 'Taxi, WheelTrans, Specialty Transport',
        value: 'Taxi, WheelTrans, Specialty Transport'
      }
    ]
  },
  {
    name: 'Water and Sewer',
    value: 'Water and Sewer',
    children: [
      {
        name: 'Catch Basin Cleaning',
        value: 'Catch Basin Cleaning'
      },
      {
        name: 'Cathodic Protection',
        value: 'Cathodic Protection'
      },
      {
        name: 'CCTV Inspection',
        value: 'CCTV Inspection'
      },
      {
        name: 'Emergency Watermain',
        value: 'Emergency Watermain'
      },
      {
        name: 'Flow Testing',
        value: 'Flow Testing'
      },
      {
        name: 'Hydrant Inspections',
        value: 'Hydrant Inspections'
      },
      {
        name: 'Hydrant Painting',
        value: 'Hydrant Painting'
      },
      {
        name: 'New or Replacement Water/Sewer Lines',
        value: 'New or Replacement Water/Sewer Lines'
      },
      {
        name: 'Wastewater Treatment & Services',
        value: 'Wastewater Treatment & Services'
      },
      {
        name: 'Water Meters',
        value: 'Water Meters'
      },
      {
        name: 'Water Treatment Chemicals Supplies & Services',
        value: 'Water Treatment Chemicals Supplies & Services'
      },
      {
        name: 'Watermain Swabbing',
        value: 'Watermain Swabbing'
      },
      {
        name: 'Well & Water Related Services',
        value: 'Well & Water Related Services'
      }
    ]
  }
]
export const awardStatuses = [
  {
    name: 'Pending Approval',
    value: 'Pending Approval'
  },
  {
    name: 'Approved',
    value: 'Approved'
  },
  {
    name: 'Awarded',
    value: 'Awarded'
  }
]

export const filterDictionary = {
  term: { name: 'Search Term', value: '', default: '', type: 'project' },
  selectedProjectPhase: { name: 'Phase', value: null, default: null, type: 'project' },
  selectedProjectLead: { name: 'Project Lead', value: null, default: null, type: 'project' },
  projectDivision: { name: 'Division', value: null, default: null, type: 'project' },
  projectDepartment: { name: 'Department', value: null, default: null, type: 'project' },
  sourceStatus: { name: 'Source Status', value: [], default: [], type: 'source' },
  sourceDateType: { name: 'Date Type', value: null, default: null, type: 'source' },
  sourceStartDate: { name: 'Start Date', value: null, default: null, type: 'source' },
  sourceEndDate: { name: 'End Date', value: null, default: null, type: 'source' },
  procurementMethod: { name: 'Procurement Method', value: null, default: null, type: 'source' },
  commodityCategories: { name: 'Commodity Category', value: null, default: [], type: 'source' },
  procurementTypes: { name: 'Procurement Type', value: null, default: null, type: 'source' },
  procurementClassification: {
    name: 'Procurement Classification',
    value: null,
    default: null,
    type: 'source'
  },
  bidPlantakersCount: { name: 'Plantakers', value: null, default: null, type: 'source' },
  bidCategories: { name: 'Source Categories', value: null, default: null, type: 'source' },
  contractPONumbers: { name: 'PO Numbers', value: null, default: null, type: 'contract' },
  contractInternalContractNumber: {
    name: 'Internal Contract Number',
    value: null,
    default: null,
    type: 'contract'
  },
  contractSuppliers: { name: 'Suppliers', value: null, default: null, type: 'contract' },
  contractStatus: { name: 'Contract Status', value: [], default: [], type: 'contract' },
  contractDateType: { name: 'Date Type', value: null, default: null, type: 'contract' },
  contractStartDate: { name: 'Start Date', value: null, default: null, type: 'contract' },
  contractEndDate: { name: 'End Date', value: null, default: null, type: 'contract' },
  contractCategories: { name: 'Contract Categories', value: null, default: [], type: 'contract' },
  awardStatus: { name: 'Award Status', value: [], default: [], type: 'award' },
  requesterIntake: { name: 'Intake Requester', value: null, default: null, type: 'intake' },
  procurementTypesIntake: {
    name: 'Intake Procurement Type',
    value: null,
    default: null,
    type: 'intake'
  },
  procurementClassificationIntake: {
    name: 'Intake Procurement Classification',
    value: null,
    default: null,
    type: 'intake'
  },
  intakePostingDate: { name: 'Estimated Posting Date', value: null, default: null, type: 'intake' },
  intakeStartDate: { name: 'Estimated Start Date', value: null, default: null, type: 'intake' }
}

export const filterDictionaryMapper = {
  requesterIntake: 'requesterIntake',
  procurementTypesIntake: 'procurementType',
  procurementClassificationIntake: 'procurementClassification',
  intakePostingDate: 'estimatedPostingDate',
  intakeStartDate: 'estimatedStartDate'
}
