import { api } from '@/api/api'
import * as types from '../../mutation-types'

const state = {
  auditAggregateTypes: [],
  auditVendorAggregateTypes: [],
  auditAggregateVendors: [],
  auditAggregateUsers: [],
  auditAggregateSortedTypes: [],
  auditSearchResults: [],
  auditSearchResultsTotal: 0,
  auditVendorSearchResults: {},
  auditVendorSearchResultsTotal: 0
}

export const getters = {
  getAuditAggregateTypes: (state) => state.auditAggregateTypes,
  getAuditVendorAggregateTypes: (state) => state.auditVendorAggregateTypes,
  getAuditAggregateVendors: (state) => state.auditAggregateVendors,
  getAuditAggregateUsers: (state) => state.auditAggregateUsers,
  getAuditAggregateSortedTypes: (state) => state.auditAggregateSortedTypes,
  getAuditSearchResults: (state) => state.auditSearchResults,
  getAuditSearchResultsTotal: (state) => state.auditSearchResultsTotal,
  getAuditVendorSearchResults: (state) => state.auditVendorSearchResults,
  getAuditVendorSearchResultsTotal: (state) => state.auditVendorSearchResultsTotal
}
export const actions = {
  fetchAuditAggregates: ({ commit }, projectId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/AuditEvents/Aggregates?projectId=${projectId}`
    return api.get(url).then((response) => {
      let auditAggs = []
      if (response.data.count > 0) {
        auditAggs = response.data.data.elasticAuditAggregateDTO.values[0]
      }
      commit(types.AUDIT_AGGREGATE_TYPES_SET, auditAggs.types)
      commit(types.AUDIT_AGGREGATE_VENDORS_SET, auditAggs.vendors)
      commit(types.AUDIT_AGGREGATE_USERS_SET, auditAggs.users)
      return auditAggs
    })
  },
  fetchAuditVendorAggregates: ({ commit }, vendorId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/AuditEvents/VendorAggregates?vendorId=${vendorId}`
    return api.get(url).then((response) => {
      let auditAggs = []
      if (response.data.count > 0) {
        auditAggs = response.data.data.elasticAuditAggregateDTO.values[0]
      }
      commit(types.AUDIT_VENDOR_AGGREGATE_TYPES_SET, auditAggs.types)
      return auditAggs
    })
  },
  fetchAuditTypeAggregates: ({ commit }, projectId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/AuditEvents/ActivityAggregates?projectId=${projectId}`
    return api.get(url).then((response) => {
      let auditAggs = []
      if (response.data.count > 0) {
        auditAggs = response.data.data.elasticAuditActivityAggregateDTO.values
      }

      commit(types.AUDIT_AGGREGATE_SORTED_TYPES_SET, auditAggs)
      return auditAggs
    })
  },
  fetchAuditResults: ({ commit }, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/AuditEvents/Search?projectId=${
      params.projectId
    }&keywords=${params.keywords !== null ? encodeURIComponent(params.keywords) : ''}&eventType=${
      params.eventType !== null ? encodeURIComponent(params.eventType) : ''
    }&companyName=${
      params.companyName !== null ? encodeURIComponent(params.companyName) : ''
    }&userName=${
      params.userName !== null ? encodeURIComponent(params.userName) : ''
    }&filter.dateFrom=${params.startDate}&filter.dateTo=${params.endDate}&filter.start=${
      params.start
    }&filter.limit=${params.limit}&filter.sortColumn=${params.sortColumn}&filter.sortDirection=${
      params.sortDirection
    }`
    return api.get(url).then((response) => {
      let results = []
      let total = 0
      if (response.data.count > 0) {
        results = response.data.data.searchResult.values[0]
        total = response.data.data.searchResult.total
      }
      commit(types.AUDIT_SEARCH_RESULTS_SET, results)
      commit(types.AUDIT_SEARCH_RESULTS_TOTAL_SET, total)
      return results
    })
  },
  fetchVendorAuditResults: ({ commit }, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/AuditEvents/SearchByVendor?vendorId=${
      params.vendorId
    }&keywords=${params.keywords !== null ? encodeURIComponent(params.keywords) : ''}&eventType=${
      params.eventType !== null ? encodeURIComponent(params.eventType) : ''
    }&companyName=${
      params.companyName !== null ? encodeURIComponent(params.companyName) : ''
    }&userName=${
      params.userName !== null ? encodeURIComponent(params.userName) : ''
    }&filter.dateFrom=${params.startDate}&filter.dateTo=${params.endDate}&filter.start=${
      params.start
    }&filter.limit=${params.limit}&filter.sortColumn=${params.sortColumn}&filter.sortDirection=${
      params.sortDirection
    }`
    return api.get(url).then((response) => {
      let results = {}
      let total = 0
      if (response.data.count > 0) {
        results = response.data.data.searchResult.values[0]
        total = response.data.data.searchResult.total
      }
      commit(types.AUDIT_VENDOR_SEARCH_RESULTS_SET, results)
      commit(types.AUDIT_VENDOR_SEARCH_RESULTS_TOTAL_SET, total)
      return results
    })
  }
}
export const mutations = {
  [types.AUDIT_AGGREGATE_SORTED_TYPES_SET](state, auditAggSortedTypes) {
    state.auditAggregateSortedTypes = auditAggSortedTypes
  },
  [types.AUDIT_AGGREGATE_TYPES_SET](state, auditAggTypes) {
    state.auditAggregateTypes = auditAggTypes
  },
  [types.AUDIT_VENDOR_AGGREGATE_TYPES_SET](state, auditAggTypes) {
    state.auditVendorAggregateTypes = auditAggTypes
  },
  [types.AUDIT_AGGREGATE_VENDORS_SET](state, auditAggVendors) {
    state.auditAggregateVendors = auditAggVendors
  },
  [types.AUDIT_AGGREGATE_USERS_SET](state, auditAggUsers) {
    state.auditAggregateUsers = auditAggUsers
  },
  [types.AUDIT_SEARCH_RESULTS_SET](state, results) {
    state.auditSearchResults = results
  },
  [types.AUDIT_SEARCH_RESULTS_TOTAL_SET](state, total) {
    state.auditSearchResultsTotal = total
  },
  [types.AUDIT_VENDOR_SEARCH_RESULTS_SET](state, results) {
    state.auditVendorSearchResults = results
  },
  [types.AUDIT_VENDOR_SEARCH_RESULTS_TOTAL_SET](state, total) {
    state.auditVendorSearchResultsTotal = total
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
