const defaultPerformanceReport = {
  reportType: 'performance',
  performanceTemplateType: [],
  performanceEvaluationType: [],
  performanceEvaluationStatus: [],
  performanceDepartment: [],
  performanceDivision: [],
  performanceProcurementLead: [],
  performanceContractAdmin: [],
  dateFiltering: null,
  dateRangeType: null,
  dateRange: null,
  dateStart: null,
  dateEnd: null,
  dateKind: null,
  reportColumns: [23, 24],
  selectedFromIndex: null,
  selectedToIndex: null,
  endDate: null,
  fromBase: null,
  fromUnit: null,
  fromValue: null,
  name: null,
  node: null,
  phase: null,
  startDate: null,
  toBase: null,
  toUnit: null,
  toValue: null
}

const initialState = () => {
  return {
    performanceReport: { ...defaultPerformanceReport }
  }
}

const getters = {
  getPerformanceReport: (state) => state.performanceReport
}

const actions = {
  setPerformanceReport: ({ commit }, update) => {
    commit('performanceReportSet', update)
    return Promise.resolve()
  },
  resetPerformanceReport: ({ commit }) => {
    commit('performanceReportReset')
    return Promise.resolve()
  }
}

const mutations = {
  performanceReportSet(state, report) {
    state.performanceReport = { ...state.performanceReport, ...report }
  },
  performanceReportReset(state) {
    state.performanceReport = { ...defaultPerformanceReport }
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations
}
