<template>
  <component
    :is="tag"
    :class="[type, { 'show-shadow': showShevron }]"
    class="dashboard-cal-event is-flex is-aligned-center has-margin-bottom-small"
    @mouseover="showShevron = true"
    @mouseleave="showShevron = false"
  >
    <div
      class="is-flex is-direction-column is-justified-between"
      :class="{ 'has-margin-right': !showShevron }"
    >
      <div class="dashboard-cal-event__title has-padding-bottom-small">
        {{ title }} {{ excerptText }}
      </div>
      <div class="dashboard-cal-event__info has-padding-bottom-small">
        {{ typeName }}: {{ time }}
      </div>
      <div class="dashboard-cal-event__info">Procurement Lead: {{ lead }}</div>
    </div>
    <app-icon v-if="showShevron" icon="chevron-right" aria-hidden="true"></app-icon>
  </component>
</template>
<script>
import dayjs from '@/utils/dayjs'
import { eventTypes } from '@/views/dashboard/dashboard-defs'

export default {
  name: 'dashboard-cal-event',
  props: {
    date: String,
    title: String,
    excerpt: String,
    lead: String,
    typeName: String,
    type: String,
    tag: {
      type: String,
      default: 'div'
    }
  },
  data() {
    return {
      showShevron: false,
      eventTypes
    }
  },
  computed: {
    time() {
      return dayjs(this.date).format('hh:mm a')
    },
    excerptText() {
      if (!this.excerpt) return ''
      return `- ${this.excerpt}`
    }
  }
}
</script>
<style lang="scss">
.dashboard-cal-event {
  color: black;
  padding: 8px 0;
  border: 1px solid $c-border;
  border-left: 8px solid $marker-published;
  border-radius: 5px;

  &.published {
    border-left-color: $marker-published;
  }
  &.closed {
    border-left-color: $marker-closing;
  }
  &.awarded {
    border-left-color: $marker-awarded;
  }
  &.site-meeting {
    border-left-color: $marker-meeting;
  }
  &.question-deadline {
    border-left-color: $marker-deadline;
  }

  &.show-shadow {
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.2);
  }

  &__info {
    font-size: 12px;
  }

  &__title {
    font-weight: 500;
  }

  &__info,
  &__title {
    padding-left: 10px;
  }
}
</style>
