<template>
  <div
    :size="size"
    :class="[{ 'is-square': type === 'square', 'is-loading': loading }, size ? 'is-' + size : '']"
    class="app-avatar"
  >
    <slot name="app-avatar-image">
      <img :src="imageSrc" :alt="alt" />
    </slot>
  </div>
</template>

<script>
import { useDataSanitizing } from '@/utils/dataSanitizing.js'

const { sanitizeData } = useDataSanitizing()

export default {
  name: 'app-avatar',
  props: {
    size: String,
    src: String,
    alt: String,
    loading: false,
    type: {
      type: String,
      default: 'circle'
    }
  },
  data() {
    return {}
  },
  computed: {
    imageSrc() {
      return sanitizeData(`${this.src}`)
    }
  }
}
</script>

<style lang="scss" src="./app-avatar.scss"></style>
