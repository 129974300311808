<template>
  <div class="agency-details-container is-flex is-direction-column">
    <div class="is-flex">
      <label for="agency-details-allowBuilder" class="column is-3"
        >{{ $t(viewString + 'turnOnContractBuilder') }}
      </label>
      <app-switch
        id="agency-details-allowBuilder"
        v-model="agencyDetails.allowContractBuilder"
        class="column is-5"
      >
      </app-switch>
    </div>

    <div class="is-flex">
      <label for="agencyId" class="column is-3">{{ $t(viewString + 'agencyName') }}: </label>
      <app-select
        id="agencyId"
        v-model="agencyDetails.agencyId"
        :disabled="isManageMode"
        filterable
        class="column is-5 has-no-margin-bottom"
      >
        <el-option
          v-for="(list, i) in customerAll"
          :key="`assess-list-${i}`"
          :label="list.customerName"
          :value="list.tenderNodeId"
        />
      </app-select>
    </div>

    <div class="is-flex">
      <label for="agencyContact" class="column is-3"
        >{{ $t(viewString + 'leadAgencyContact') }}:
      </label>
      <div class="column is-5">
        <app-input
          id="agencyContact"
          v-model="agencyDetails.contactEmail"
          :label="$t(viewString + 'leadAgencyContact')"
          class="has-no-margin-bottom"
        >
        </app-input>
        <span v-if="!emailIsValid" class="error">{{ $t(viewString + 'enterValidEmail') }} </span>
      </div>
    </div>

    <div class="is-flex">
      <label class="column is-3">{{ $t(viewString + 'emailReminders') }}: </label>
      <div class="column is-5">
        <template v-for="item in agencyDetails.reminder">
          <app-checkbox :key="item.id" v-model="item.val" :label="item.label" :value="item.val">
          </app-checkbox>
        </template>
      </div>
    </div>

    <AgencyDetailsBilling
      :view-string="viewString"
      :billing-periods="agencyDetails.billingPeriods"
      @add-new-billing-period="handleAdd"
      @remove-new-billing-period="handleRemove"
    >
    </AgencyDetailsBilling>
  </div>
</template>

<script>
import uuid from 'uuid/v1'
import AgencyDetailsBilling from './agency-details-billing-period.vue'
import emailUtil from '@/utils/email'
import { mapGetters, mapActions } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'agency-details',
  components: {
    AgencyDetailsBilling
  },
  props: {
    viewString: String,
    agencyDetails: {
      type: Object,
      default: () => ({})
    },
    isManageMode: { type: Boolean, default: false }
  },
  data() {
    return {
      emailIsValid: emailUtil.validEmail(this.agencyDetails.contactEmail)
    }
  },
  computed: {
    ...mapGetters(['getCustomerAll', 'getContractBuilderLicenseConfigurations']),
    customerAll() {
      let customerList = cloneDeep(this.getCustomerAll) || []

      if (
        !this.isManageMode &&
        customerList.length &&
        this.contractBuilderLicenseConfigurationAll.length
      ) {
        customerList = customerList.filter(
          (r) =>
            !this.contractBuilderLicenseConfigurationAll.some((e) => e.node.id === r.tenderNodeId)
        )
      }

      return customerList
    },
    contractBuilderLicenseConfigurationAll() {
      return this.getContractBuilderLicenseConfigurations || []
    }
  },
  watch: {
    'agencyDetails.contactEmail': function (val) {
      const check = emailUtil.validEmail(val)
      this.emailIsValid = check
      this.$emit('email-valid', check)
    }
  },
  mounted() {
    if (!this.getCustomerAll || !this.getCustomerAll.length) {
      this.fetchCustomerAll()
    }
  },
  methods: {
    ...mapActions(['fetchCustomerAll']),
    handleAdd() {
      this.agencyDetails.billingPeriods = [
        ...this.agencyDetails.billingPeriods,
        this.getEmptyBillingPeriod()
      ]
    },
    handleRemove(id) {
      const billingPeriod = this.agencyDetails.billingPeriods.filter(
        (period) => period.id === id
      )[0]

      if (billingPeriod.transactionUsed > 0) {
        this.$notify({
          title: 'Warning',
          message: this.$t(`${this.viewString}msgCannotDelete`),
          type: 'warning'
        })
      } else {
        this.agencyDetails.billingPeriods = this.agencyDetails.billingPeriods.filter(
          (period) => period.id !== id
        )
      }
    },
    getEmptyBillingPeriod() {
      return { id: uuid(), startDate: '', endDate: '', purchased: 100, price: '' }
    }
  }
}
</script>

<style lang="scss">
.agency-details-container {
  span {
    font-size: 1rem;
  }

  .error {
    color: red;
  }
}
</style>
