import * as types from '@/store/mutation-types'

const state = {
  apiRoot: import.meta.env.VITE_API_BASE,
  showAppBar: true,
  noredirect: false
}

const getters = {
  apiRoot: (state) => state.apiRoot,
  getShowAppBar: (state) => state.showAppBar,
  getNoRedirect: (state) => state.noredirect
}

const actions = {
  setShowAppBar: ({ commit }, payload) => {
    commit(types.SHOW_APP_BAR, payload)
  },
  setNoRedirect: ({ commit }, value) => {
    commit(types.NO_REDIRECT_SET, value)
  }
}

const mutations = {
  [types.SHOW_APP_BAR](state, payload) {
    state.showAppBar = payload.showAppBar
  },
  [types.NO_REDIRECT_SET](state, value) {
    state.noredirect = value
  }
}

export default {
  namespaced: false,
  state,
  getters,
  mutations,
  actions
}
