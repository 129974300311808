<template>
  <app-grid class="buyer-dashboard-overview__bids has-margin-top">
    <div slot="actionsRight">
      <label class="has-padding-right">{{ $t(viewString + 'labelProjectsClosing') }}</label>
      <el-dropdown
        :show-timeout="0"
        :hide-timeout="0"
        trigger="click"
        class="has-margin-right-small"
        @command="handleSelectBidClosingRange"
      >
        <app-button>
          <label class="has-padding-right is-inline-block"
            >{{ selectedBidClosingRange | capitalize }} {{ bidDaysString }}</label
          >
          <app-icon icon="chevron-down"> </app-icon>
        </app-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="any">
            {{ $t('ui.common.any') }}
          </el-dropdown-item>
          <el-dropdown-item
            v-for="(days, index) in bidClosingRanges"
            :key="'selected-bid-range' + index"
            :command="days"
          >
            {{ days }}
            {{ days > 1 ? $t('ui.common.days') : $t('ui.common.day') }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <app-grid-pagination
        :page-size="gridPageSize"
        :current-page="gridCurrentPage"
        :total-pages="gridTotalPages"
        :total-rows="gridTotalRows"
        @clickPrev="handleClickPrev"
        @clickNext="handleClickNext"
        @pageSizeChange="handleGridPageSizeChange"
      >
      </app-grid-pagination>

      <el-dropdown :show-timeout="0" :hide-timeout="0" :hide-on-click="false" trigger="click">
        <app-column-button />
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(column, index) in columnDefs" :key="'visible-column' + index">
            <app-checkbox
              :id="column.field"
              v-model="column.visible"
              :label="column.columnHeading"
              :name="column.field"
              :value="column.field"
              class="has-margin-bottom-tiny"
            >
            </app-checkbox>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-table ref="bidTable" :data="tableData" class="table-bids" width="100%" stripe tabindex="0">
      <span slot="empty">{{ $t('ui.views.dashboard.dashboard-view.overviewProjectsEmpty') }}</span>
      <el-table-column
        v-for="(column, c) in visibleColumns"
        :key="`column-${c}`"
        :label="column.columnHeading"
        :align="getAlign(column.field)"
        :min-width="column.width || '150'"
        :property="column.field"
        :sortable="column.field === 'projectName'"
      >
        <template slot-scope="props">
          <span v-if="column.field === 'projectNumber'">{{ props.row[column.field] }}</span>
          <router-link
            v-if="column.field === 'projectName'"
            :to="`/projects/${props.row.projectId}/build/settings`"
          >
            {{ props.row[column.field] }}
          </router-link>
          <el-tag
            v-if="column.field === 'bidStatus'"
            :type="props.row.bidStatus === 'planned' ? 'primary' : 'secondary'"
          >
            {{ props.row.bidStatus }}
          </el-tag>
          <span v-if="column.field === 'publishedDate'">{{
            props.row[column.field] | dateString
          }}</span>
          <span v-if="column.field === 'closingDate'">{{
            props.row[column.field] | dateString
          }}</span>
          <span v-if="column.field === 'estimatedValue'">${{ props.row.estimatedValue }}</span>
        </template>
      </el-table-column>
    </el-table>
  </app-grid>
</template>

<script>
import TableMixins from '@/mixins/table-mixins.js'
import dayjs from '@/utils/dayjs'
import cloneDeep from 'lodash/cloneDeep'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'buyer-dashboard-overview-projects',
  mixins: [TableMixins],
  data() {
    return {
      bidClosingRanges: [7, 30, 60, 90],
      selectedBidClosingRange: 7,
      dateEnd: new Date().setDate(new Date().getDate() + 7),
      viewString: 'ui.views.dashboard.buyer-dashboard-view.',
      columnDefs: [
        {
          field: 'projectNumber',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingProjectNumber'),
          visible: true
        },
        {
          field: 'projectName',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingProjectTitle'),
          visible: true
        },
        {
          field: 'bidStatus',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingStatus'),
          visible: true
        },
        {
          field: 'publishedDate',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingBidPublished'),
          visible: true
        },
        {
          field: 'closingDate',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingBidClosing'),
          visible: true
        },
        {
          field: 'estimatedValue',
          columnHeading: this.$t('ui.views.dashboard.buyer-dashboard-view.headingEstimatedValue'),
          visible: true
        }
      ],
      allProjects: []
    }
  },
  computed: {
    ...mapGetters(['getMyProjects']),
    dataSource() {
      return this.allProjects
    },
    bidDaysString() {
      if (this.selectedBidClosingRange === 'any') {
        return this.$t(`${this.viewString}anyDays`)
      }
      return this.selectedBidClosingRange > 1 ? this.$t('ui.common.days') : this.$t('ui.common.day')
    }
  },
  mounted() {
    this.getUsersProjects()
  },
  methods: {
    ...mapActions(['fetchMyProjects']),
    handleSelectBidClosingRange(val) {
      this.selectedBidClosingRange = val
      const today = new Date()
      const endingDate = val === 'any' ? null : today.setDate(today.getDate() + val)
      this.dateEnd = endingDate
      this.getUsersProjects()
    },
    getUsersProjects() {
      this.$emit('loading', true)

      this.fetchMyProjects({
        start: this.gridPageSize * (this.gridCurrentPage - 1),
        limit: this.gridPageSize,
        sortcolumn: 'projectName',
        sortdirection: this.sortDirection,
        dateFrom: new Date(),
        dateTo: this.dateEnd,
        searchText: this.selectedBidClosingRange
      })
        .then(() => {
          this.buildData()
          this.$emit('error', false)
        })
        .catch(() => this.$emit('loading', false))
        .finally(() => this.$emit('loading', false))
    },
    buildData() {
      this.allProjects = cloneDeep(this.getMyProjects || []).map((proj) => ({
        bidStatus: proj.bidStatus,
        publishedDate: proj.bidOpenDate !== '9999-12-31T23:59:59' ? proj.bidOpenDate : null,
        closingDate: proj.bidCloseDate !== '9999-12-31T23:59:59' ? proj.bidCloseDate : null,
        projectName: proj.projectName,
        projectNumber: proj.projectNumber,
        projectId: proj.projectId,
        estimatedValue: proj.estimatedValue
      }))
    },
    filterDataSource(proj) {
      if (this.selectedBidClosingRange === 'any') {
        return true
      } else {
        const closeDiff = -1 * dayjs().diff(proj.closingDate, 'days')
        return closeDiff >= 0 && closeDiff < this.selectedBidClosingRange
      }
    },
    handleClickNext() {
      this.gridCurrentPage++
      this.getUsersProjects()
    },
    handleClickPrev() {
      this.gridCurrentPage--
      this.getUsersProjects()
    },

    handleGridPageSizeChange(size) {
      this.gridCurrentPage = 1
      this.gridTotalRows = 0
      this.gridTotalPages = 1
      this.gridPageSize = Number(size)
      this.getUsersProjects()
    },
    getAlign(field) {
      switch (field) {
        case 'estimatedValue':
          return 'right'
        case 'bidStatus':
          return 'center'
        default:
          return 'left'
      }
    }
  }
}
</script>
<style lang="scss">
.buyer-dashboard-overview__bids {
  .app-grid-actions.app-grid-actions-top {
    margin-bottom: 0 !important;
  }
}
</style>
