export const wizardStepDef = {
  SELECTION: 'SELECTION',
  ADD_SUPPLIER: 'ADD_SUPPLIER',
  DOCUMENTS: 'DOCUMENTS',
  WORKFLOW: 'WORKFLOW',
  PREVIEW: 'PREVIEW'
}

export const approvalTypes = {
  UPLOAD: 'UPLOAD',
  UPLOAD_CREATE: 'UPLOAD_CREATE'
}

export const approvalStatus = {
  INCOMPLETE: 'Incomplete',
  APPROVED: 'Approved',
  DRAFT: 'Draft',
  PENDING_APPROVAL: 'Pending Approval',
  EMAIL_SENT: 'Email Sent',
  CORRECTING: 'Correcting',
  DECLINED: 'Declined',
  CANCELLED: 'Cancelled',
  CLOSED: 'Closed'
}

export const addNewMemberTypes = {
  TEAM_MEMBER: 'TEAM_MEMBER',
  EMAIL: 'EMAIL'
}
