import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  tradeAgreements: [],
  tradeAgreementsSPA: []
}

export const getters = {
  getTradeAgreements: (state) => state.tradeAgreements,
  getTradeAgreementsSPA: (state) => state.tradeAgreementsSPA
}

export const actions = {
  fetchTradeAgreements: ({ commit }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/TradeAgreement`

    return api.get(url).then((response) => {
      if (response.data.count > 0) {
        const tradeAgreements = response.data.data.tradeAgreementDTO.values
        commit(types.TRADE_AGREEMENT_SET_ALL, tradeAgreements)
        return tradeAgreements
      }
    })
  },
  fetchTradeAgreementsSPA: ({ commit }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/TradeAgreement/SPAAll`

    return api.get(url).then((response) => {
      if (response.data.count > 0) {
        const tradeAgreementsSPA = response.data.data.tradeAgreementSPADTO.values
        commit(types.TRADE_AGREEMENT_SPA_SET_ALL, tradeAgreementsSPA)
        return tradeAgreementsSPA
      }
    })
  }
}

export const mutations = {
  [types.TRADE_AGREEMENT_SET_ALL](state, tradeAgreements) {
    state.tradeAgreements = tradeAgreements
  },
  [types.TRADE_AGREEMENT_SPA_SET_ALL](state, tradeAgreementsSPA) {
    state.tradeAgreementsSPA = tradeAgreementsSPA
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
