<template>
  <div :class="{ 'is-autoHeight': autoHeight }" class="app-grid">
    <div
      v-if="hasActions"
      :class="{ 'has-actions-left': hasActionsLeft, 'has-actions-right': hasActionsRight }"
      class="app-grid-actions app-grid-actions-top"
    >
      <transition name="fade">
        <div v-if="hasActionsLeft" class="app-grid-actionsLeft">
          <slot name="actionsLeft"></slot>
        </div>
      </transition>
      <transition name="fade">
        <div v-if="hasActionsRight" class="app-grid-actionsRight">
          <slot name="actionsRight"></slot>
        </div>
      </transition>
    </div>
    <div class="app-grid-wrapper">
      <div class="app-grid-wrapper-main">
        <slot></slot>
      </div>
    </div>
    <div
      v-if="hasActionsBottom"
      :class="{
        'has-actions-left': hasActionsBottomLeft,
        'has-actions-right': hasActionsBottomRight
      }"
      class="app-grid-actions app-grid-actions-bottom"
    >
      <div v-if="hasActionsBottomLeft" class="app-grid-actionsLeft">
        <slot name="actionsBottomLeft"></slot>
      </div>
      <div v-if="hasActionsBottomRight" class="app-grid-actionsRight">
        <slot name="actionsBottomRight"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-grid',
  props: { autoHeight: false },
  computed: {
    hasActionsLeft() {
      return this.$slots.actionsLeft
    },
    hasActionsRight() {
      return this.$slots.actionsRight
    },
    hasActionsBottomLeft() {
      return this.$slots.actionsBottomLeft
    },
    hasActionsBottomRight() {
      return this.$slots.actionsBottomRight
    },
    hasActions() {
      if (this.hasActionsLeft || this.hasActionsRight) {
        return true
      } else {
        return false
      }
    },
    hasActionsBottom() {
      return this.hasActionsBottomLeft || this.hasActionsBottomRight
    }
  }
}
</script>

<style lang="scss" src="./app-grid.scss"></style>
