import { api, downloadApi, uploadApiMultipart } from '@/api/api'
import util from '@/utils/utility.js'
import * as types from './../../mutation-types.js'

export const state = {
  awardExceptions: [],
  awardException: null,
  awarded: [],
  projectSummary: null,
  allApprovals: [],
  currentApproval: null,
  selectedSuppliers: [],
  isWorkflowType: null
}

export const getters = {
  getAwardExceptions: (state) => state.awardExceptions,
  getAwardException: (state) => state.awardException,
  getAwarded: (state) => state.awarded,
  getprojectSummary: (state) => state.projectSummary,
  getAllAwardApprovals: (state) => state.allApprovals,
  getCurrentApproval: (state) => state.currentApproval,
  getCurrentApprovalSuppliers: (state) =>
    state.currentApproval && state.currentApproval.formattedApprovalSuppliers,
  getSelectedSuppliers: (state) => state.selectedSuppliers,
  getIsWorkflowType: (state) => state.isWorkflowType,
  getCurrentApprovalWorkflows: (state) =>
    (state.currentApproval && state.currentApproval.awardApprovalWorkflows) || []
}

export const actions = {
  fetchAwarded: ({ commit }, tender) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Submissions/Awarded?tenderId=${tender}`
    return api.get(url).then((response) => {
      let awarded = []
      if (response.data.count > 0) {
        awarded = response.data.data.searchResult.values
        commit(types.AWARDED_SET_ALL, awarded)
      }
      return response
    })
  },
  fetchAwardExceptions: ({ commit }) => {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/AwardExceptions'
    return api.get(url).then((response) => {
      if (response.data.count > 0) {
        const exceptions = response.data.data.awardExceptionDTO.values
        commit(types.AWARD_EXCEPTIONS_SET_ALL, exceptions)
        return exceptions
      }
    })
  },
  saveAwardException: ({ commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/AwardExceptions`
    return api.post(url, model).then((response) => {
      const awardException = response.data.data.awardExceptionDTO.values[0]
      commit(types.AWARD_EXCEPTION_SET, awardException)
      return awardException
    })
  },
  updateAwardException: ({ commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/AwardExceptions`
    return api.put(url, model).then((response) => {
      const awardException = response.data.data.awardExceptionDTO.values[0]
      commit(types.AWARD_EXCEPTION_SET, awardException)
      return awardException
    })
  },
  deleteAwardException: (_, id) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/AwardExceptions?awardExceptionId=${id}`
    return api.delete(url)
  },
  fetchAwardProjectSummary: ({ commit }, { projectId }) => {
    const url = `api/v1/customer/{customerId}/node/{nodeId}/awards/summary?projectId=${projectId}`
    return api.get(url).then((response) => {
      let awardSummary = []
      if (response.data.count > 0) {
        awardSummary = response.data.data.awardSummaryDTO.values[0].awardSummaryDetail
        commit(types.AWARD_PROJECT_SUMMARY_SET, awardSummary)
      }
      return awardSummary
    })
  },
  fetchAllApprovals: ({ commit, rootState }) => {
    const projectId = rootState.project.project.id
    const url = `api/v1/customer/{customerId}/node/{nodeId}/Awards/approvals?projectId=${projectId}`
    return api.get(url).then((response) => {
      let allApprovals = []
      if (response.data.count > 0) {
        allApprovals = response.data.data.awardApprovalDTO.values
        commit(types.AWARD_ALL_APPROVALS_SET, allApprovals)
      }
      return allApprovals
    })
  },
  fetchAwardPhaseStatus: (_, projectId) => {
    const url = `api/v1/customer/{customerId}/node/{nodeId}/Awards/AwardPhaseStatus?projectId=${projectId}`

    return api.get(url).then((response) => {
      if (response.data.count !== 0) {
        const status = response.data.data.searchResult.values[0]
        return status
      }
    })
  },
  createAwardApproval: ({ commit, rootState }, model) => {
    const projectId = rootState.project.project.id
    const url = `api/v1/customer/{customerId}/node/{nodeId}/Awards/approvals?projectId=${projectId}`
    return api.post(url, model).then((response) => {
      const newApproval = response.data.data.awardApprovalDTO.values[0]
      commit(types.AWARD_CURRENT_APPROVAL_SET, newApproval)
      return newApproval
    })
  },
  updateAwardApproval: ({ commit }, model) => {
    const approvalId = state.currentApproval && state.currentApproval.id
    const url = `api/v1/customer/{customerId}/node/{nodeId}/Awards/approvals/${approvalId}`
    return api.put(url, model).then((response) => {
      const approval = response.data.data.awardApprovalDTO.values[0]
      commit(types.AWARD_CURRENT_APPROVAL_SET, approval)

      return approval
    })
  },
  getAwardApprovalById: ({ commit }) => {
    const approvalId = state.currentApproval && state.currentApproval.id
    const url = `api/v1/customer/{customerId}/node/{nodeId}/Awards/approvals/${approvalId}`
    return api.get(url).then((response) => {
      const newApproval = response.data.data.awardApprovalDTO.values[0]
      commit(types.AWARD_CURRENT_APPROVAL_SET, newApproval)

      return newApproval
    })
  },
  deleteAwardApprovalById: ({ state }) => {
    const approvalId = state.currentApproval && state.currentApproval.id
    const url = `api/v1/customer/{customerId}/node/{nodeId}/Awards/approvals/${approvalId}`
    return api.delete(url)
  },
  downloadApprovalReport: ({ state }, fileType) => {
    const approvalId = state.currentApproval && state.currentApproval.id
    const url = `api/v1/customer/{customerId}/node/{nodeId}/Awards/approvals/${approvalId}/download-report?fileType=${fileType}`
    return downloadApi.get(url).then(util.forceFileDownload)
  },
  uploadAwardDocument: ({ state }, payload) => {
    const approvalId = state.currentApproval && state.currentApproval.id
    const url = `api/v1/customer/{customerId}/node/{nodeId}/Awards/approvals/${approvalId}/documents`
    const { formData } = payload

    return uploadApiMultipart.post(url, formData).then((response) => {
      const documents = response.data.data.awardApprovalDTO.values[0].awardApprovalDocuments
      return documents
    })
  },
  attachApprovalReport: ({ state, commit }, hasApprovalReport) => {
    const approvalId = state.currentApproval && state.currentApproval.id
    const url = `api/v1/customer/{customerId}/node/{nodeId}/Awards/approvals/${approvalId}/summary-report?attach=${hasApprovalReport}`
    return api.post(url).then((response) => {
      const newApproval = response.data.data.awardApprovalDTO.values[0]
      commit(types.AWARD_CURRENT_APPROVAL_SET, newApproval)

      return newApproval
    })
  },
  createAwardApprovalWorkflow: ({ state }, payload) => {
    const approvalId = state.currentApproval && state.currentApproval.id
    const url = `api/v1/customer/{customerId}/node/{nodeId}/awards/approvals/${approvalId}/workflow`
    return api.post(url, payload).then((response) => {
      const workflow = response.data.data.awardApprovalWorkflowDTO.values[0]
      return workflow
    })
  },
  updateAwardUser: ({ state, commit }, payload) => {
    const { id: workflowId, ...user } = payload
    const approvalId = state.currentApproval && state.currentApproval.id
    const url = `api/v1/customer/{customerId}/node/{nodeId}/awards/approvals/${approvalId}/workflow/${workflowId}`
    return api.put(url, user).then((response) => {
      const approval = response.data.data.awardApprovalDTO.values[0]
      commit(types.AWARD_CURRENT_APPROVAL_SET, approval)

      return approval
    })
  },
  reOrderAwardApprovalWorkflow: (_, payload) => {
    const url = `api/v1/customer/{customerId}/node/{nodeId}/awards/approvals/workflow/re-order`
    return api.post(url, payload).then((response) => {
      const workflows = response.data.data.awardApprovalWorkflowDTO.values[0]
      return Promise.resolve(workflows)
    })
  },
  deleteAwardUser: (_, workflowId) => {
    const url = `api/v1/customer/{customerId}/node/{nodeId}/awards/approvals/workflow/${workflowId}`
    return api.delete(url)
  },
  awardApprovalSendForApproval: ({ commit }) => {
    const approvalId = state.currentApproval && state.currentApproval.id
    const url = `api/v1/customer/{customerId}/node/{nodeId}/Awards/approvals/${approvalId}/send`

    return api.post(url).then((response) => {
      const allApprovals = response?.data?.data?.awardApprovalDTO?.values || []
      commit(types.AWARD_ALL_APPROVALS_SET, allApprovals)
      return allApprovals
    })
  },
  awardApprovalStatusUpdate: ({ commit }, params) => {
    const approvalId = state.currentApproval && state.currentApproval.id
    const url = `api/v1/customer/{customerId}/node/{nodeId}/Awards/approvals/${approvalId}/status?status=${params.status}`

    return api.put(url, params.comments).then((response) => {
      const allApprovals = response?.data?.data?.awardApprovalDTO?.values || []
      commit(types.AWARD_ALL_APPROVALS_SET, allApprovals)
      commit(types.AWARD_CURRENT_APPROVAL_SET, allApprovals.filter((r) => r.id === approvalId)[0])

      return allApprovals
    })
  },
  awardApprovalSendForApprovalResend: ({ commit }, workflowId) => {
    const approvalId = state.currentApproval && state.currentApproval.id
    const url = `api/v1/customer/{customerId}/node/{nodeId}/Awards/approvals/${approvalId}/approver/${workflowId}/Resend`

    return api.post(url).then((response) => {
      const allApprovals = response?.data?.data?.awardApprovalDTO?.values || []
      commit(types.AWARD_ALL_APPROVALS_SET, allApprovals)
      commit(types.AWARD_CURRENT_APPROVAL_SET, allApprovals.filter((r) => r.id === approvalId)[0])

      return allApprovals
    })
  },
  awardApproverStatusReset: ({ commit }, workflowId) => {
    const approvalId = state.currentApproval && state.currentApproval.id
    const url = `api/v1/customer/{customerId}/node/{nodeId}/Awards/approvals/${approvalId}/approver/${workflowId}/StatusReset`

    return api.post(url).then((response) => {
      const allApprovals = response?.data?.data?.awardApprovalDTO?.values || []
      commit(types.AWARD_ALL_APPROVALS_SET, allApprovals)
      commit(types.AWARD_CURRENT_APPROVAL_SET, allApprovals.filter((r) => r.id === approvalId)[0])

      return allApprovals
    })
  },
  setAwardApproval: ({ commit }, payload) => {
    commit(types.AWARD_CURRENT_APPROVAL_SET, payload)
  },
  updateSelectedSuppliers: ({ commit }, payload) => {
    commit(types.AWARD_SELECTED_SUPPLIERS_UPDATE, payload)
  },
  setSelectedSuppliers: ({ commit }, payload) => {
    commit(types.AWARD_SELECTED_SUPPLIERS_SET, payload)
  },
  setWorkflowType: ({ commit }, payload) => {
    commit(types.AWARD_WORKFLOW_TYPE_SET, payload)
  }
}
export const mutations = {
  [types.AWARD_EXCEPTIONS_SET_ALL](state, exceptions) {
    state.awardExceptions = exceptions
  },
  [types.AWARD_EXCEPTION_SET](state, exception) {
    state.awardException = exception
  },
  [types.AWARDED_SET_ALL](state, awarded) {
    state.awarded = awarded
  },
  [types.AWARD_PROJECT_SUMMARY_SET](state, summary) {
    state.projectSummary = summary
  },
  [types.AWARD_ALL_APPROVALS_SET](state, allApprovals) {
    state.allApprovals = allApprovals
  },
  [types.AWARD_CURRENT_APPROVAL_SET](state, currentApproval) {
    if (currentApproval) {
      if (
        currentApproval.awardApprovalDocuments &&
        currentApproval.awardApprovalDocuments.length > 1
      ) {
        currentApproval.awardApprovalDocuments.sort((a, b) => a.displayOrder - b.displayOrder)
      }
      if (
        currentApproval.awardApprovalWorkflows &&
        currentApproval.awardApprovalWorkflows.length > 1
      ) {
        currentApproval.awardApprovalWorkflows.sort((a, b) => a.displayOrder - b.displayOrder)
      }
    }
    state.currentApproval = currentApproval
  },
  [types.AWARD_SELECTED_SUPPLIERS_UPDATE](state, payload) {
    const arr = state.selectedSuppliers.filter((sup) => sup !== payload.remove)
    arr.push(payload.add)
    state.selectedSuppliers = arr
  },
  [types.AWARD_SELECTED_SUPPLIERS_SET](state, payload) {
    state.selectedSuppliers = payload
  },
  [types.AWARD_WORKFLOW_TYPE_SET](state, payload) {
    state.isWorkflowType = payload
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
