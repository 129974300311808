<template>
  <el-dialog v-bind="$attrs" :close-on-click-modal="false" width="690px" @close="handleClose">
    <template #title>
      <div class="is-flex">
        <app-icon :icon="actionIcon" class="has-margin-right-small" :color="iconColor" />
        <app-title size="4" heading="2">{{ actionTitle }}</app-title>
      </div>
    </template>
    <template #default>
      <div>
        <div v-if="isLoaded && mode !== null">
          <p v-if="mode === 'approve'" class="has-margin-bottom-small">
            Are you sure you want to approve the award?
          </p>
          <p v-else class="has-margin-bottom-small">
            Please provide your reason for declining this award. This will be shared with the sender
            only.
          </p>
          <app-input
            v-if="mode !== 'approve'"
            ref="comments"
            v-model="comments"
            class="has-no-margin-bottom"
            type="textarea"
            :rows="8"
            :required="true"
          >
            <template v-if="!isValid" #append>
              <p>Comments are required to decline award.</p>
            </template>
          </app-input>
        </div>
        <div v-else>
          <AppLoading />
        </div>
      </div>
    </template>
    <template #footer>
      <div class="is-flex approval-footer-actions">
        <app-button
          id="submitAction"
          type="primary"
          min-width
          :disabled="!isValid"
          @click="handleSubmit"
        >
          {{ mode === 'approve' ? 'Approve' : 'Decline' }}
        </app-button>
        <app-button id="cancelAction" min-width @click="$emit('close')"> Cancel </app-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import AppLoading from '@/components/app-loading/app-loading.vue'
const modalModes = ['approve', 'decline']

export default {
  name: 'award-approval-action-modal',
  components: {
    AppLoading
  },
  props: {
    mode: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      modalModes,
      comments: '',
      isLoaded: false
    }
  },
  computed: {
    actionTitle() {
      switch (this.mode) {
        case 'approve':
          return 'Approve Award'
        case 'decline':
          return 'Decline Award'
      }
      return ''
    },
    actionIcon() {
      switch (this.mode) {
        case 'approve':
          return 'check-circle'
        case 'decline':
          return 'x-circle'
      }
      return ''
    },
    iconColor() {
      return this.mode === 'approve' ? 'green' : '#cf483e'
    },
    isValid() {
      return this.mode === 'approve' || this.comments.length > 0
    }
  },
  watch: {
    mode(val) {
      if (val) this.setComments()
      if (this.$refs.comments) this.$refs.comments.manuallySetDirty(false)
      this.isLoaded = val !== null
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.comments) this.$refs.comments.$el.focus()
    })
  },
  methods: {
    setComments(comments = '') {
      this.comments = comments
    },
    handleSubmit() {
      if (!this.isValid) return

      this.$emit(`submit:${this.mode}`, { comments: this.comments })
    },
    handleClose() {
      this.isLoaded = false
      this.$emit('close')
      this.$emit('update:visible', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.app-button {
  &.is-primary {
    background: $team-member-primary;

    &:hover,
    &:focus,
    &:active {
      background: darken($team-member-primary, 10%);
    }
  }
}

.approval-footer-actions {
  flex-direction: column;

  .app-button:first-child {
    margin-bottom: $gap-small;
    margin-right: 0;
  }

  @include breakpoint('sm') {
    flex-direction: row;
    .app-button:first-child {
      margin-bottom: 0;
      margin-right: $gap-small;
    }
  }
}
</style>
