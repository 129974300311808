import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  insuranceTypes: [],
  insuranceType: null
}

export const getters = {
  getInsuranceTypes: (state) => state.insuranceTypes,
  getInsuranceType: (state) => (id) => {
    return state.insuranceTypes.find((c) => c.id === id)
  },
  getInsuranceTypeNew: (state) => state.insuranceType
}

export const actions = {
  fetchInsuranceTypes: ({ commit }) => {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/InsuranceTypes/All'
    return api.get(url).then((response) => {
      const insuranceTypes = response?.data?.data?.insuranceTypeDTO?.values || []
      commit(types.INSURANCE_TYPES_SET, insuranceTypes)
      return insuranceTypes
    })
  },
  saveInsuranceType: ({ commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/InsuranceTypes`
    return api.post(url, model).then((response) => {
      const insuranceType = response?.data?.data?.insuranceTypeDTO?.values?.[0] || null
      commit(types.INSURANCE_UPDATE_OR_ADD, insuranceType)
      return insuranceType
    })
  },
  updateInsuranceType: ({ commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/InsuranceTypes`
    return api.put(url, model).then((response) => {
      const insuranceType = response?.data?.data?.insuranceTypeDTO?.values?.[0] || null
      commit(types.INSURANCE_UPDATE_OR_ADD, insuranceType)
      return insuranceType
    })
  },
  deleteInsuranceType: (_, id) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/InsuranceTypes?insuranceTypeId=${id}`
    return api.delete(url)
  }
}
export const mutations = {
  [types.INSURANCE_TYPES_SET](state, insuranceTypes) {
    state.insuranceTypes = insuranceTypes
  },
  [types.INSURANCE_UPDATE_OR_ADD](state, insuranceType) {
    state.insuranceType = insuranceType
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
