import tableMixins from '@/mixins/table-mixins.js'
import emailWizardMixins from '@/views/email-wizard/email-wizard-mixins.js'

export default {
  name: 'email-wizard-step-contacts',
  mixins: [emailWizardMixins, tableMixins],
  computed: {
    includeProjectUsers: {
      get() {
        return this.email.includeProjectUsers
      },
      set(val) {
        this.updateEmail('includeProjectUsers', val)
        if (val) {
          this.updateEmail('projectUsers', [...this.emailProjectUsers])
        } else {
          this.updateEmail('projectUsers', [])
        }
      }
    },
    hasProjectUsers() {
      return this.emailProjectUsers && this.emailProjectUsers.length > 0
    }
  },
  data() {
    return {
      companies: [],
      allCompanyContacts: [],
      removedCompanyContacts: [],
      selectedProjectUsers: [],
      gridCurrentPage: 1,
      gridPageSize: 5,
      columnDefs: [
        {
          headerName: this.$t('ui.views.email-wizard.email-wizard-view.headingCompany'),
          field: 'name',
          visible: true
        },
        {
          headerName: this.$t('ui.views.email-wizard.email-wizard-view.headingContact'),
          field: 'contact',
          visible: true
        }
      ]
    }
  },
  created() {
    this.allCompanyContacts = [...this.emailContacts]
  },
  methods: {
    handleRemoveMember(userId) {
      // function to call when the contacts are removed
      const filteredList = this.email.projectUsers.filter((tm) => tm.user.id !== userId)
      this.updateEmail('projectUsers', filteredList)

      if (filteredList.length === 0) {
        this.includeProjectUsers = false
      }
    },
    handleSelectionChange(selectedRows) {
      this.updateEmail('contacts', selectedRows)
    },

    handleClickCompose() {
      this.WizardBus.$emit('next')
    },
    getContactDisplayName(contact) {
      if (contact.name && contact.name !== null) {
        return contact.name
      } else {
        return contact.email
      }
    }
  }
}
