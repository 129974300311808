import heroImage from '@/assets/images/SVGs/heroLarge@2x.svg'
import logoColorFallback from '@/assets/images/BTLogos/PNG/B&T_Logo_Name_Colour.png'
import logo from '@/assets/images/BTLogos/SVG/B&T_Logo_Name_Colour.svg'
import AppInputText from '@/components/app-input-text/app-input-text.vue'
import AppLogo from '@/components/app-logo/app-logo.vue'
import { useDataSanitizing } from '@/utils/dataSanitizing.js'
import { validEmail } from '@/utils/email.js'
import errorHandler from '@/utils/error'
import { createCompletedSubmissionsAllNotification } from '@/utils/notification'
import uiHandler from '@/utils/ui'
import VueForm from 'vue-form'
import { mapActions, mapGetters } from 'vuex'
import LoginFooter from './login-view-footer.vue'
const { sanitizeHTMLData } = useDataSanitizing()

export default {
  name: 'Login',
  mixins: [VueForm],
  components: {
    AppLogo,
    AppInputText,
    LoginFooter
  },
  data() {
    return {
      model: {
        username: '',
        password: '',
        domain: ''
      },
      resetModel: {
        user: '',
        domain: ''
      },
      viewString: 'ui.views.login.login-view.',
      errMsg: '',
      resetErrMsg: '',
      formstate: {
        username: {},
        password: {},
        domain: {}
      },
      resetformstate: {
        user: {},
        domain: {}
      },
      usernameValid: false,
      resetPasswordUsernameValid: false,
      domainValid: false,
      passwordValid: false,
      resetPassword: false,
      resetPasswordSent: false,
      loaded: false,
      animationReady: false,
      articles: [],
      administratorContact: 'help@esolutionsgroup.ca',
      lockedDialogVisible: false,
      userRequestsSso: true,
      showLocalAuthUI: false,
      logo,
      logoColorFallback,
      heroImage
    }
  },
  beforeDestroy() {
    clearTimeout(this.loadedTimer)
    clearTimeout(this.readyTimer)
    clearTimeout(this.cleanResetTimer)
  },
  watch: {
    showLocalAuthUI: function (newval, oldval) {
      // focus on username input when local auth UI is shown
      if (newval && newval !== oldval) {
        this.$nextTick().then(() => {
          const input = document.getElementById('username')
          input.focus()
        })
      }
    }
  },
  computed: {
    readyRequestedTenders() {
      return this.getReadyRequestedTenders
    },
    validationCheck() {
      return {
        loginForm: this.$refs?.usernameInput?.isValid && this.$refs?.passwordInput?.isValid,
        resetForm: this.$refs?.resetUserInput?.isValid,
        domainForm: this.$refs?.domainInput?.isValid
      }
    },
    displayName() {
      const customer = this.getCustomer
      return customer ? customer.DisplayName : 'Town of Lorem'
    },
    loginFormValid() {
      return this.usernameValid && this.passwordValid
    },
    resetFormValid() {
      return this.resetPasswordUsernameValid
    },
    domainFormValid() {
      return this.domainValid
    },
    ...mapGetters([
      'isLocalAuth',
      'hasLocalAuthOption',
      'getAuthCustomer',
      'getAuthUser',
      'getAuthTenderNode',
      'getCustomer',
      'getAuthCustomerNode',
      'getGroups',
      'getReadyRequestedTenders',
      'getRoutePermission',
      'getIsSiteAdmin'
    ])
  },
  methods: {
    async handleSubmit() {
      this.errMsg = ''
      if (this.showLocalAuthUI) {
        if (!this.loginFormValid) return
        this.authorizeUser()
      } else {
        if (!this.domainFormValid) return
        try {
          await this.getLoginType(this.model)
          // if the system is configured for sso only
          if (!this.isLocalAuth && !this.hasLocalAuthOption) {
            window.location = `${import.meta.env.VITE_API_BASE}/api/v1/sso/ssoStart?domain=${
              this.model.domain
            }`
            // if the system is configured for both local and sso
          } else if (!this.isLocalAuth && this.hasLocalAuthOption) {
            // see what the user wants to do
            if (this.userRequestsSso)
              window.location = `${import.meta.env.VITE_API_BASE}/api/v1/sso/ssoStart?domain=${
                this.model.domain
              }`
            else this.showLocalAuthUI = true
          } else if (this.isLocalAuth) {
            this.showLocalAuthUI = true
          }
        } catch (e) {
          const status = e.response?.status || 0
          this.errMsg =
            status === 400
              ? this.$t('ui.views.login.login-view.errorInvalidDomain')
              : this.$t('ui.views.login.login-view.errorInvalidCredentials')
        }
      }
    },
    handleResetSubmit() {
      if (!this.resetFormValid) return
      uiHandler.showLoading(this)
      const { user } = this.resetModel
      const { domain } = this.model
      this.resetErrMsg = ''
      this.sendForgotPassword({ domain, email: user, isTeammemberportal: false })
        .then(() => {
          this.resetPasswordSent = true
          this.$notify({
            title: this.$t('ui.common.success'),
            message: this.$t('ui.views.login.login-view.sentPasswordReset'),
            type: 'success'
          })
          setTimeout(() => {
            uiHandler.hideLoading(this)
            this.handleCancelReset()
          }, 1000)
        })
        .catch((e) => {
          const errorMessages = []
          errorMessages[404] = this.$t('ui.views.login.login-view.errorUserNotFound')
          errorMessages[400] = this.$t('ui.views.login.login-view.errorInvalidEmail')
          const { status } = e.response
          if (status && errorMessages[status]) {
            this.resetErrMsg = errorMessages[status]
          } else {
            this.resetErrMsg = this.$t('ui.views.login.login-view.errorInvalidCredentials')
          }

          uiHandler.hideLoading(this)
        })
    },
    handleReset() {
      this.resetPassword = true
    },
    handleCancelReset() {
      this.resetPassword = false
      this.cleanResetTimer = setTimeout(() => {
        this.resetformstate._reset()
      }, 500)
    },
    showLockedDialog() {
      this.lockedDialogVisible = true
    },
    authorizeUser: function () {
      if (this.model.username.includes('@')) {
        if (!this.validEmail(this.model.username)) {
          this.appInitialized = false
          this.errMsg = this.$t('ui.views.login.login-view.invalidEmailFormat')
          return
        }
      }

      uiHandler.showLoading(this)
      this.errMsg = ''
      this.authorize(this.model)
        .then(() => {
          this.initializeData()
        })
        .catch((e) => {
          this.appInitialized = false
          if (e.response && e.response.status === 429) {
            this.showLockedDialog()
          } else {
            errorHandler.handleApiError(this, e)
            this.errMsg = this.$t('ui.views.login.login-view.errorInvalidCredentials')
          }
          this.showLoading(1)
        })
    },
    randomIntFromInterval(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    showLoading(index) {
      // load for up to [index] seconds
      setTimeout(() => {
        uiHandler.hideLoading(this)
      }, Math.random() * (1000 * index))
    },
    initializeData: function () {
      this.showLoading(3)

      const customer = this.getAuthCustomer
      this.setCustomer(customer)
      this.setCustomerTimeZone(customer.timeZoneDetail.code)
      this.fetchCustomer(customer.id)

      const user = this.getAuthUser
      this.setLoggedInUser(user)

      const tenderNode = this.getAuthTenderNode
      this.setTenderNode(tenderNode)

      const customerNode = this.getAuthCustomerNode
      this.setCustomerNode(customerNode)

      // fetch the customer

      if (this.getIsSiteAdmin === true) {
        this.fetchCustomerAll()
      }

      if (!this.getGroups.length) {
        this.fetchGroups()
      }

      if (!this.getRoutePermission.length) {
        this.fetchRoutePermission()
      }

      // get all the pending submissions from session storage
      this.restoreCompletedSubmissionsAll()
      if (this.readyRequestedTenders) {
        // create a notification for those that are ready to be downloaded
        for (const tenderStatus of this.readyRequestedTenders) {
          createCompletedSubmissionsAllNotification(this, tenderStatus.name, tenderStatus.id)
        }
      }

      customer.gaId = 'UA-54516992-1'
      customer.logo = 'https://s3.amazonaws.com/uifaces/faces/twitter/adhiardana/128.jpg'

      // Clear previous search filters if any
      this.updateProjectSearchFilters(null)

      this.appInitialized = true
      this.$router.push('/')
    },
    sliceFormTitles(list) {
      list.forEach((item) => {
        const text = item.innerText.trim().split('')
        const markup = text.map(
          (letter, index) =>
            `<span style="animation-delay:${index * 0.02}s;transition-delay:${index * 0.02}s;">${
              letter.match(/\s/) ? '&nbsp;' : letter
            }</span>`
        )
        item.innerHTML = markup.join('')
      })
    },
    handleResetDomain() {
      this.showLocalAuthUI = false
    },
    validEmail(email) {
      return validEmail(email)
    },
    sanitizeHTMLData,
    ...mapActions([
      'authorize',
      'setCustomer',
      'setCustomerTimeZone',
      'sendForgotPassword',
      'setTenderNode',
      'setLoggedInUser',
      'setCustomerNode',
      'fetchCustomer',
      'fetchCustomerAll',
      'fetchGroups',
      'restoreCompletedSubmissionsAll',
      'downloadCompletedSubmissionsAll',
      'addAllSubmissionsNotification',
      'getLoginType',
      'fetchRoutePermission',
      'updateProjectSearchFilters'
    ])
  }
}
