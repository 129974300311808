<script setup>
import { ref, computed, nextTick, onMounted, onBeforeUnmount } from 'vue'
import { useRouter, useRoute } from 'vue-router/composables'
import debounce from 'lodash/debounce'
import AppIcon from '@/components/app-icon/app-icon'
// eslint-disable-next-line no-unused-vars
import Teleport from 'vue2-teleport'
import useClickOutside from '@/composables/clickOutside'

const dropdown = ref(null)
const menu = ref(null)
const isOpen = ref(false)
const focusedIndex = ref(-1)

const router = useRouter()
const route = useRoute()

const props = defineProps({
  id: {
    type: String,
    default: `app-dropdown-${Math.random().toString(36).substr(2, 9)}`
  },
  controlText: {
    type: String,
    default: 'Dropdown'
  },
  icon: {
    type: String,
    default: null
  },
  controlClasses: {
    type: [String, Array],
    default: ''
  },
  menuClasses: {
    type: [String, Array],
    default: ''
  },
  hasArrow: {
    type: Boolean,
    default: true
  },
  menuPosition: {
    type: String,
    default: 'left'
  }
})
const controlClasses = computed(() => {
  return [props.controlClasses]
})

const menuClasses = computed(() => {
  const classes = [props.menuClasses]
  if (isOpen.value) {
    classes.push('is-open')
  }
  return classes
})

async function moveMenuToDropdown() {
  await nextTick()
  if (menu.value && isOpen) {
    menu.value.style.top = `${dropdown.value.getBoundingClientRect().bottom}px`

    if (props.menuPosition === 'left') {
      menu.value.style.left = `${dropdown.value.getBoundingClientRect().left}px`
    } else {
      // line up menu right corner with dropdown right corner
      menu.value.style.left = `${
        dropdown.value.getBoundingClientRect().right - menu.value.getBoundingClientRect().width
      }px`
    }
  }
}

const handleResize = debounce(async function () {
  await moveMenuToDropdown()
}, 100)

const toggleDropdown = debounce(async function () {
  isOpen.value = !isOpen.value
  focusedIndex.value = -1
  await nextTick()
  moveMenuToDropdown()
}, 100)

function handleTabKey(e, focusable) {
  if (!isOpen.value) return

  event.preventDefault() // Prevent the default behavior (page scroll)
  // handle shift key
  if (event.shiftKey) {
    if (focusedIndex.value > 0) {
      focusedIndex.value--
      focusable[focusedIndex.value]?.focus()
      return
    } else {
      dropdown.value.querySelector('button, a').focus()
      isOpen.value = false
      return
    }
  }

  if (focusedIndex.value < focusable.length - 1) {
    focusedIndex.value++
    focusable[focusedIndex.value]?.focus()
    return
  } else {
    dropdown.value.querySelector('button, a').focus()
    isOpen.value = false
    return
  }
}
function handleKeydown(event) {
  const focusable = menu.value.querySelectorAll(
    'a, button:not([disabled]), input, [tabindex]:not([tabindex="-1"])'
  )
  switch (event.key) {
    case 'ArrowDown':
      event.preventDefault() // Prevent the default behavior (page scroll)
      if (focusedIndex.value < focusable.length - 1) {
        focusedIndex.value++
      } else {
        focusedIndex.value = 0 // Loop back to the first element
      }
      focusable[focusedIndex.value]?.focus()
      break
    case 'ArrowUp':
      event.preventDefault()
      if (focusedIndex.value > 0) {
        focusedIndex.value--
      } else {
        focusedIndex.value = focusable.length - 1 // Loop back to the last element
      }
      focusable[focusedIndex.value].focus()
      break
    case 'Tab':
      handleTabKey(event, focusable)
      break
    case 'Escape':
      if (focusedIndex.value > -1) {
        // focus on first child of dropdown ref
        dropdown.value.querySelector('button, a').focus()
      }
      isOpen.value = false
      break
  }
}

async function handleClickMenu(event) {
  // Start with the target of the click event
  let targetElement = event.target

  // Traverse up the DOM until you find the clicked link or the menu container
  while (
    targetElement != null &&
    targetElement !== event.currentTarget &&
    !(
      targetElement instanceof HTMLAnchorElement ||
      targetElement.tagName === 'ROUTER-LINK' ||
      targetElement.tagName === 'BUTTON'
    )
  ) {
    targetElement = targetElement.parentNode
  }

  // If a link was clicked and it's not the menu container itself
  if (targetElement && targetElement !== event.currentTarget) {
    // Prevent default navigation
    event.preventDefault()

    isOpen.value = false

    await nextTick()
    const to = targetElement.getAttribute('to')
    if (to && to !== route.path) {
      // For router-links, use the router
      router.push(targetElement.getAttribute('to'))
    } else if (targetElement.getAttribute('href')) {
      // For normal links, change location directly
      window.location.href = targetElement.getAttribute('href')
    }
  }
}

function handleClickOutsideMenu(e) {
  if (dropdown.value.contains(e.target)) {
    return
  }

  isOpen.value = false
}

useClickOutside(menu, handleClickOutsideMenu)

onMounted(() => {
  window.addEventListener('resize', handleResize)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})
</script>

<template>
  <div
    :id="props.id"
    ref="dropdown"
    class="app-dropdown"
    :class="{ 'has-dropdown-open': isOpen }"
    @keydown="handleKeydown"
  >
    <slot name="trigger" :toggle-dropdown="toggleDropdown">
      <app-button
        :class="controlClasses"
        :aria-expanded="isOpen"
        :aria-controls="`${props.id}-menu`"
        @click="toggleDropdown"
      >
        <AppIcon v-if="props.icon" :icon="props.icon" />
        <span>{{ props.controlText }}</span>
        <AppIcon v-if="props.hasArrow" icon="chevron-down" />
      </app-button>
    </slot>
    <Teleport to="body">
      <div
        :id="`${props.id}-menu`"
        ref="menu"
        :hidden="!isOpen"
        class="app-dropdown__menu"
        :class="menuClasses"
        @keydown="handleKeydown"
        @click="handleClickMenu"
      >
        <slot></slot>
      </div>
    </Teleport>
  </div>
</template>

<style lang="scss">
.app-dropdown__menu {
  position: fixed;
  z-index: 100;
  display: none;
  background-color: white;
  padding: $gap-tiny;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

  a {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
    &:focus-visible {
      box-shadow: $focus-shadow !important;
    }
  }

  &.is-open {
    display: block;
  }
}
</style>
