export default {
  name: 'dashboard-component',
  props: {
    border: Boolean,
    marker: String,
    size: {
      type: String,
      default: 'medium'
    },
    height: String,
    icon: String,
    title: String,
    hasDelete: Boolean,
    sizeText: String,
    isLoading: Boolean,
    hasError: Boolean,
    customIcon: String
  },
  data() {
    return {
      viewString: 'ui.views.dashboard.dashboard-view.'
    }
  },
  computed: {
    hasCustomIcon() {
      return this.customIcon
    },
    hasHeader() {
      return this.icon || this.title
    },
    hasIcon() {
      return !!this.icon
    },
    hasComponent() {
      return this.$slots['component']
    },
    markerClass() {
      if (!this.marker) return ''
      return `has-marker-${this.marker}`
    },
    heightClass() {
      if (this.height === null || !this.height) return ''
      return `is-height-${this.height}`
    },
    sizeClass() {
      if (this.size === null) return 'column is-12'
      if (this.size === 'auto') return 'is-size-auto'
      switch (this.size) {
        case 'large':
          return 'column is-full-mobile is-three-quarters-widescreen'
        case 'medium':
          return 'column is-full-mobile is-half-widescreen'
        case 'small':
          return 'column is-6 is-one-quarter-widescreen'
        case 'full':
          return 'column is-12'
      }
    }
  },
  methods: {
    handleCommand(command) {
      switch (command) {
        case 'delete':
          this.$emit('delete')
          break
        case 'changeSize':
          this.$emit('changeSize')
          break
      }
    }
  }
}
