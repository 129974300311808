import { api } from '@/api/api'
import * as types from '@/store/mutation-types'
import { alphaSort } from '@/utils/array'
import CustomerService from '@/services/customer/customer.service'

const state = {
  groups: [],
  isTeamGroupSidebarVisible: false,
  activeUsersInCurrentGroup: [],
  activeUsersNotInCurrentGroup: [],
  isPermissionsOverlayVisible: false
}

const getters = {
  getGroups: (state) => state.groups,
  getGroup: (state) => (id) => state.groups.find((group) => group.id === id),
  getGroupByName: (state) => (name) => state.groups.find((group) => group.name === name),
  getIsTeamGroupSidebarVisible: (state) => state.isTeamGroupSidebarVisible,
  getActiveUsersInCurrentGroup: (state) => state.activeUsersInCurrentGroup,
  getActiveUsersNotInCurrentGroup: (state) => state.activeUsersNotInCurrentGroup,
  getIsPermissionsOverlayVisible: (state) => state.isPermissionsOverlayVisible
}

const actions = {
  fetchGroups: async ({ commit }, forceRefresh) => {
    if (state.groups.length && !forceRefresh) {
      return Promise.resolve(state.data)
    }

    const groups = await CustomerService().getUserGroups()

    commit(types.GROUP_SET_GROUPS, groups)
    return groups
  },
  createGroup: ({ commit }, payload) => {
    // special case, User groups takes the customerId for both the customer and node
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/UserGroups'
    return api.post(url, payload).then((response) => {
      commit(types.GROUP_SET_GROUPS, response.data.data.userGroupDTO.values)
      return response
    })
  },
  saveGroup: ({ commit }, payload) => {
    // special case, User groups takes the customerId for both the customer and node
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/UserGroups'
    return api.put(url, payload).then((response) => {
      commit(types.GROUP_SET_GROUPS, response.data.data.userGroupDTO.values)
      return response
    })
  },
  deleteGroup: ({ commit }, payload) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/UserGroups?userGroupId=${payload.userGroupId}`
    return api.delete(url).then(function (response) {
      commit(types.GROUP_SET_GROUPS, response.data.data.userGroupDTO.values)
      return ''
    })
  },
  removeUsersFromGroups: ({ commit }, payload) => {
    const userIds = payload.users.map((x) => x.id).join(',')
    const groupIds = payload.groups.map((x) => x.id).join(',')
    return api
      .delete(
        `/api/v1/customer/{customerId}/node/{nodeId}/UserGroups/Users?userId=${userIds}&userGroupId=${groupIds}`
      )
      .then((response) => {
        const groups = response.data.data.userGroupDTO.values
        commit(types.GROUP_SET_GROUPS, groups)
        commit(types.USER_REMOVE_GROUPS, {
          userIds: userIds,
          groupIds: groupIds,
          groups: groups
        })
        return groups
      })
  },
  addUsersToGroups: ({ commit }, payload) => {
    const model = {
      userId: payload.users.map((x) => x.id).join(','),
      groupId: payload.groups.map((x) => x.id).join(',')
    }

    return api
      .post('/api/v1/customer/{customerId}/node/{nodeId}/UserGroups/Users', model)
      .then(function (response) {
        const groups = response.data.data.userGroupDTO.values
        commit(types.GROUP_SET_GROUPS, groups)
        commit(types.USER_ADD_GROUPS, {
          userIds: model.userId,
          groupIds: model.groupId,
          groups: groups
        })
        return groups
      })
  },
  setIsTeamGroupSidebarVisible: ({ commit }, payload) => {
    commit(types.GROUP_SET_SIDEBAR_VISIBILITY, payload)
  },
  toggleIsTeamGroupSidebarVisible: ({ commit }) => {
    commit(types.GROUP_TOGGLE_SIDEBAR_VISIBILITY)
  },
  setActiveUsersInCurrentGroup: ({ commit }, payload) => {
    commit(types.GROUP_SET_ACTIVE_USERS_IN_GROUP, payload)
  },
  setActiveUsersNotInCurrentGroup: ({ commit }, payload) => {
    commit(types.GROUP_SET_ACTIVE_USERS_NOT_IN_GROUP, payload)
  },
  setIsPermissionsOverlayVisible: ({ commit }, payload) => {
    commit(types.GROUP_SET_PERMISSIONS_OVERLAY_VISIBILITY, payload)
  }
}

const mutations = {
  [types.GROUP_SET_GROUPS](state, groups) {
    state.groups = groups.sort((a, b) => alphaSort(a.name, b.name))
  },
  [types.GROUP_SET_SIDEBAR_VISIBILITY](state, isVisible) {
    state.isTeamGroupSidebarVisible = isVisible
  },
  [types.GROUP_TOGGLE_SIDEBAR_VISIBILITY](state) {
    state.isTeamGroupSidebarVisible = !state.isTeamGroupSidebarVisible
  },
  [types.GROUP_SET_ACTIVE_USERS_IN_GROUP](state, userList) {
    state.activeUsersInCurrentGroup = userList
  },
  [types.GROUP_SET_ACTIVE_USERS_NOT_IN_GROUP](state, userList) {
    state.activeUsersNotInCurrentGroup = userList
  },
  [types.GROUP_SET_PERMISSIONS_OVERLAY_VISIBILITY](state, isVisible) {
    state.isPermissionsOverlayVisible = isVisible
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
