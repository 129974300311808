<template>
  <div class="app-logo">
    <!-- svg -->
    <div v-if="!showFallbackImage" class="logotext" v-html="sanitizeHTMLData(svg)" />

    <!-- img -->
    <img v-if="showFallbackImage" class="logotext" :src="sanitizeHTMLData(LogoImage)" />
  </div>
</template>

<script setup>
import LogoImage from '@/assets/images/BTLogos/PNG/B&T_Logo_Name_Colour.png'
import Logo from '@/assets/images/BTLogos/SVG/B&T_Logo_Name_Colour.svg'
import { useDataSanitizing } from '@/utils/dataSanitizing'

import { computed, onBeforeMount, ref } from 'vue'

const props = defineProps({
  proFlag: {
    type: Boolean,
    default: true
  },
  forceFallback: {
    type: Boolean,
    default: false
  }
})

const svg = ref(null)
const proflag = ref(null)

const showFallbackImage = computed(() => {
  return props.forceFallback || !svg.value
})

const { sanitizeHTMLData } = useDataSanitizing()

onBeforeMount(() => {
  try {
    fetch(Logo)
      .then((response) => response.text())
      .then((text) => {
        svg.value = text
      })
  } catch (e) {
    svg.value = null
    proflag.value = null
  }
})
</script>

<style lang="scss" scoped>
.app-logo {
  display: flex;
  align-items: center;

  /** 
  * Size ratios based on provided image specs
  */

  .logotext {
    width: 100%;
    height: 100%;
  }
}
</style>
