import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  plantakers: []
}

export const getters = {
  getPlantakers: (state) => state.plantakers
}

export const actions = {
  fetchPlantakers({ commit }, id) {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/plantakers?tenderId=${id}`
    return api.get(url).then((response) => {
      const plantakers = response?.data?.data?.planTakerDTO?.values || []
      commit(types.PLANTAKER_SET, plantakers)
      return plantakers
    })
  },
  updatePlantaker(_, plantakerModel) {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Plantakers`
    return api.put(url, plantakerModel).then((response) => {
      const plantakers = response.data.data.planTakerDTO.values[0]
      return plantakers
    })
  },
  createPlantaker(_, plantakerModel) {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Plantakers`
    return api.post(url, plantakerModel).then((response) => {
      const plantakers = response.data.data.planTakerDTO.values[0]
      return plantakers
    })
  },
  deleteHardCopySubmission: (_, plantakerSubmissionId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/plantakers/HardCopySubmission?plantakerSubmissionId=${plantakerSubmissionId}`
    return api.delete(url)
  }
}

const mutations = {
  [types.PLANTAKER_SET](state, plantakers) {
    state.plantakers = plantakers
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
