import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  projectRoles: []
}

export const getters = {
  getProjectRoles: (state) => state.projectRoles
}

export const actions = {
  fetchProjectRoles({ commit }) {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/projectRoles`

    return api.get(url).then((response) => {
      const projectRoles = response?.data?.data?.projectRoleDTO?.values || []
      commit(types.PROJECT_ROLE_SET_ALL, projectRoles)
      return projectRoles
    })
  }
}

const mutations = {
  [types.PROJECT_ROLE_SET_ALL](state, projectRoles) {
    state.projectRoles = projectRoles
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
