<template>
  <el-tooltip :content="getMessage()">
    <div class="app-verified-checkmark">
      <app-icon :size="getSize()" icon="check" color="white" />
    </div>
  </el-tooltip>
</template>

<script>
export default {
  name: 'app-verified-checkmark',
  props: {
    size: String,
    message: String
  },
  methods: {
    getSize() {
      return this.size || 'small'
    },
    getMessage() {
      return this.message || this.$t('ui.components.verified-supplier.message')
    }
  }
}
</script>

<style lang="scss">
.app-verified-checkmark {
  background: $marker-green;
  border-radius: 5px;
  position: relative;
  width: 22px;
  height: 22px;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: $marker-green;
    transform: rotate(45deg);
    border-radius: 5px;
  }

  .app-icon {
    position: relative;
    z-index: 1;
  }
}
</style>
