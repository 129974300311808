import AppTag from '@/components/app-tag/app-tag.vue'
import errorHandler from '@/utils/error'
import { useFeatureHelpers } from '@/utils/featureHelpers'
import uiHandler from '@/utils/ui'
import DashboardBuyerOverview from '@/views/dashboard/components/dashboard-buyer-overview/dashboard-buyer-overview.vue'
import DashboardComponent from '@/views/dashboard/components/dashboard-component/dashboard-component.vue'
import DashboardFeed from '@/views/dashboard/components/dashboard-feed-component/dashboard-feed-component.vue'
import DashboardImportantDates from '@/views/dashboard/components/dashboard-important-dates/dashboard-important-dates.vue'
import DashboardIntakeEssentialsButton from '@/views/dashboard/components/dashboard-intake-essentials/dashboard-intake-essentials-button.vue'
import DashboardIntakeEssentials from '@/views/dashboard/components/dashboard-intake-essentials/dashboard-intake-essentials-component.vue'
import DashboardList from '@/views/dashboard/components/dashboard-list-component.vue'
import DashboardProjectCounter from '@/views/dashboard/components/dashboard-project-counter-component/dashboard-project-counter-component.vue'
import DashboardProjectsBuyers from '@/views/dashboard/components/dashboard-projects-buyers-component/dashboard-projects-buyers-component.vue'
import DashboardProjectsTeamMembers from '@/views/dashboard/components/dashboard-projects-team-members-component/dashboard-projects-team-members-component.vue'
import DashboardStat from '@/views/dashboard/components/dashboard-stat-component.vue'
import DashboardTasksFeed from '@/views/dashboard/components/dashboard-tasks-feed/dashboard-tasks-feed.vue'
import cloneDeep from 'lodash/cloneDeep'
import VueGridLayout from 'vue-grid-layout'
import { mapActions, mapGetters } from 'vuex'
import AddWidgetModal from './add-widget-modal/add-widget-modal.vue'
import widgetList from './buyer-dashboard-widgets.js'
import intakeSVG from '@/assets/images/icon-intake.svg'
const { isModuleEnabled } = useFeatureHelpers()

const LAYOUT_COLUMNS = 12
const ROW_HEIGHT = 30
const MARGIN_SIZE = 10
const MAX_HEIGHT = 1000

export default {
  name: 'BuyerDashboardView',
  components: {
    AppTag,
    DashboardComponent,
    DashboardStat,
    DashboardList,
    DashboardFeed,
    DashboardProjectsBuyers,
    DashboardImportantDates,
    DashboardProjectCounter,
    DashboardBuyerOverview,
    DashboardTasksFeed,
    DashboardProjectsTeamMembers,
    DashboardIntakeEssentials,
    DashboardIntakeEssentialsButton,
    AddWidgetModal,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem
  },
  computed: {
    ...mapGetters([
      'getLoggedInUser',
      'getUsers',
      'getProjectTasksbyUser',
      'getDashboardwidgets',
      'getUserProjectComments',
      'getDashboardUserWidgets',
      'getDashboardUserProjectLeads',
      'getDepartments',
      'getTenderNode'
    ]),
    user() {
      return this.getLoggedInUser
    },
    userRole() {
      return 'Buyer'
    },
    projectTasksbyUser() {
      return this.getProjectTasksbyUser
    },
    projectComments() {
      return this.getUserProjectComments
    },
    openProjects() {
      return this.getDashboardwidgets?.openProjects?.[0]?.count || 0
    },
    awardedProjects() {
      return this.getDashboardwidgets?.awardedProjects?.[0]?.amount || '0'
    },
    totalProjects() {
      return this.getDashboardwidgets?.totalProjects?.[0]?.count || 0
    },
    incompleteQuestions() {
      return this.getDashboardwidgets?.incompleteQuestions || []
    },
    incompleteQuestionsCount() {
      return (
        this.getDashboardwidgets?.incompleteQuestions?.reduce((a, c) => a + c.questionsCount, 0) ||
        0
      )
    },
    incompleteQuestionsProjectsCount() {
      return this.getDashboardwidgets?.incompleteQuestions?.length || 0
    },
    expiredContractDocuments() {
      return this.getDashboardwidgets?.expiredContractDocuments?.[0]?.count || 0
    },
    widgetsToAdd() {
      return this.widgetList.filter((w) => !this.layout.find((l) => l.component === w.component))
    },
    isIntakeEssentialsEnabled() {
      return this.getTenderNode?.enableIntakeEssentials
    }
  },
  data() {
    return {
      widgetList,
      layout: [],
      layoutColumns: LAYOUT_COLUMNS,
      rowHeight: ROW_HEIGHT,
      marginSize: MARGIN_SIZE,
      viewString: 'ui.views.dashboard.buyer-dashboard-view.',
      viewStringCommon: 'ui.views.dashboard.dashboard-view.',
      projectsCounterAll: [],
      addDialogVisible: false,
      widgetsState: {},
      intakeSVG: intakeSVG
    }
  },
  mounted() {
    this.setupWidgetsState()

    uiHandler.showLoading(this)
    const promises = []

    promises.push(this.fetchUserWidgets().catch((e) => errorHandler.handleApiError(this, e)))

    if (!this.getUsers.length) {
      promises.push(this.fetchUsers().catch((e) => errorHandler.handleApiError(this, e)))
    }

    Promise.all(promises)
      .then(() => {
        this.featureGateWidgetsList()
        this.setUserWidgets()
      })
      .finally(() => uiHandler.hideLoading(this))
  },
  methods: {
    ...mapActions([
      'fetchUsers',
      'fetchDashboardWidget',
      'fetchUserProjectComments',
      'fetchProjectTasksByUser',
      'saveUserWidget',
      'fetchUserWidgets',
      'fetchSourceProjectandLeads',
      'fetchTenderTypes',
      'fetchSourceProjectsByTeamMember',
      'fetchDepartments'
    ]),
    setupWidgetsState() {
      const list = {}
      this.widgetList.forEach(
        (widget) =>
          (list[widget.component] = {
            loading: false,
            error: false
          })
      )
      this.widgetsState = cloneDeep(list)
    },
    setUserWidgets() {
      const userWidgets = this.getDashboardUserWidgets

      if (!userWidgets.length) {
        this.addAllWidgets()
      } else {
        const layoutDTO = []
        userWidgets.forEach((element) => {
          const widget = this.widgetList.filter(
            (r) => r.id.toLowerCase() === element.widgetId.toLowerCase()
          )[0]

          if (!widget) return // as in, if we removed it from the list of widgets

          widget.x = element.positionX
          widget.y = element.positionY
          widget.sizes.selected = element.size

          layoutDTO.push(widget)
        })

        layoutDTO.forEach((w) => this.positionNewItem(w))
        this.loadWidgetsData()
      }
    },
    featureGateWidgetsList() {
      const featureGateComponents = []
      if (!this.isIntakeEssentialsEnabled) featureGateComponents.push('intakeEssentials')
      if (!isModuleEnabled('BuyerDashboard.MyTasks')) featureGateComponents.push('tasks')

      this.widgetList = this.widgetList.filter((w) => !featureGateComponents.includes(w.component))
    },
    loadWidgetsData() {
      this.layout.forEach((widget) => {
        if (widget.component === 'projectCounter') {
          this.loadProjectCounter()
        } else if (widget.component === 'totalProjects') {
          this.loadTotalProjects()
        } else if (widget.component === 'comments') {
          this.loadProjectComments()
        } else if (widget.component === 'openProjects') {
          this.loadOpenProjects()
        } else if (widget.component === 'questionsSubmitted') {
          this.loadQuestionsSubmitted()
        } else if (widget.component === 'awardedValue') {
          this.loadAwardedProjects()
        } else if (widget.component === 'tasks') {
          this.loadProjectTask()
        } else if (widget.component === 'expiredContractDocuments') {
          this.loadExpiredContractDocuments()
        } else if (widget.component === 'projectBuyers') {
          this.loadProjectBuyers()
        } else if (widget.component === 'projectTeamMembers') {
          this.loadProjectTeamMembers()
        } else if (widget.component === 'intakeEssentials') {
          this.loadIntakeEssentials()
        }
      })
    },
    loadProjectTask() {
      if (!this.getProjectTasksbyUser.length && this.user.id) {
        const promises = [this.fetchProjectTasksByUser(this.user.id)]

        if (!this.getUsers) promises.push(this.fetchUsers())

        this.loadApiForWidget('tasks', promises)
      }
    },
    loadProjectBuyers() {
      if (!this.getDashboardUserProjectLeads.length) {
        this.loadApiForWidget('projectBuyers', [this.fetchSourceProjectandLeads()])
      }
    },
    loadIntakeEssentials() {
      const promises = []

      if (!this.getUsers.length) promises.push(this.fetchUsers())
      if (!this.getDepartments.length) promises.push(this.fetchDepartments())

      this.loadApiForWidget('projectTeamMembers', promises)
    },
    loadProjectTeamMembers() {
      const promises = [this.fetchTenderTypes(), this.fetchSourceProjectsByTeamMember()]

      if (!this.getUsers) promises.push(this.fetchUsers())

      this.loadApiForWidget('projectTeamMembers', promises)
    },
    loadExpiredContractDocuments() {
      if (this.getDashboardwidgets.expiredContractDocuments === null) {
        this.loadApiForWidget('expiredContractDocuments', [
          this.fetchDashboardWidget('expiredContractDocuments')
        ])
      }
    },
    loadAwardedProjects() {
      if (this.getDashboardwidgets.awardedProjects === null) {
        this.loadApiForWidget('awardedValue', [this.fetchDashboardWidget('awardedProjects')])
      }
    },
    loadQuestionsSubmitted() {
      if (this.getDashboardwidgets.incompleteQuestions === null) {
        this.loadApiForWidget('questionsSubmitted', [
          this.fetchDashboardWidget('incompleteQuestions')
        ])
      }
    },
    loadOpenProjects() {
      if (this.getDashboardwidgets.openProjects === null) {
        this.loadApiForWidget('openProjects', [this.fetchDashboardWidget('openProjects')])
      }
    },
    loadTotalProjects() {
      if (this.getDashboardwidgets.totalProjects === null) {
        this.loadApiForWidget('totalProjects', [this.fetchDashboardWidget('totalProjects')])
      }
    },
    loadProjectComments() {
      if (!this.getUserProjectComments.length) {
        const projCommentParams = {
          limit: 10,
          start: null,
          keywords: null
        }
        const promises = [this.fetchUserProjectComments(projCommentParams)]

        if (!this.getUsers) promises.push(this.fetchUsers())

        this.loadApiForWidget('comments', promises)
      }
    },
    handleWidgetErrorState(widget, val) {
      this.widgetsState[widget].error = val
    },
    handleWidgetLoadingState(widget, val) {
      this.widgetsState[widget].loading = val
    },
    loadProjectCounter() {
      const promises = []

      if (this.getDashboardwidgets.allDraftProjects === null) {
        promises.push(this.fetchDashboardWidget('allDraftProjects'))
      }

      if (this.getDashboardwidgets.allPlannedProjects === null) {
        promises.push(this.fetchDashboardWidget('allPlannedProjects'))
      }

      if (this.getDashboardwidgets.allOpenProjects === null) {
        promises.push(this.fetchDashboardWidget('allOpenProjects'))
      }

      if (this.getDashboardwidgets.allClosedProjects === null) {
        promises.push(this.fetchDashboardWidget('allClosedProjects'))
      }

      if (this.getDashboardwidgets.allAwardedProjects === null) {
        promises.push(this.fetchDashboardWidget('allAwardedProjects'))
      }

      if (this.getDashboardwidgets.allActiveProjects === null) {
        promises.push(this.fetchDashboardWidget('allActiveProjects'))
      }

      if (this.getDashboardwidgets.allWarrantyProjects === null) {
        promises.push(this.fetchDashboardWidget('allWarrantyProjects'))
      }

      this.loadApiForWidget('projectCounter', promises)
    },
    loadApiForWidget(name, promises) {
      this.widgetsState[name].loading = true
      this.widgetsState[name].error = false
      Promise.all(promises)
        .then(() => (this.widgetsState[name].error = false))
        .catch(() => (this.widgetsState[name].error = true))
        .finally(() => (this.widgetsState[name].loading = false))
    },
    addWidgets(widgetsArray) {
      widgetsArray.forEach((w) => this.positionNewItem(w))

      this.saveWidgets()
      this.loadWidgetsData()
    },
    positionNewItem(widget) {
      this.addDialogVisible = false

      const widgetMinWidth = widget.sizes[widget.sizes.selected][0]
      const widgetMinHeight = widget.sizes[widget.sizes.selected][1]

      // find the max height for each column
      const maxHeights = Array(LAYOUT_COLUMNS).fill(0)

      for (const l of this.layout) {
        for (let i = 0; i < l.w; i++) {
          if (maxHeights[l.x + i] < l.y + l.h) maxHeights[l.x + i] = l.y + l.h
        }
      }

      // find the minimum max where there's minimum width for this widget columns worth of space
      let startCol = widget.x >= 0 ? widget.x : 0
      let startHeight = widget.y >= 0 ? widget.y : MAX_HEIGHT

      if (!(widget.x >= 0)) {
        for (let col = 0; col <= LAYOUT_COLUMNS - widgetMinWidth; col++) {
          // if we might find better place to start
          if (startHeight > maxHeights[col]) {
            const curHeight = maxHeights[col]
            // if there's room at this height, remember this height and starting column
            let isRoom = true
            for (let i = 1; i < widgetMinWidth; i++) {
              if (maxHeights[col + i] > curHeight) isRoom = false
            }
            if (isRoom) {
              startCol = col
              startHeight = curHeight
            }
          }
        }
      }

      const next = this.layout.length + 1

      // reorder index values in case some widgets were removed from between
      const layout = cloneDeep(
        this.layout.map((val, index) => {
          val.i = index + 1
          return val
        })
      )
      layout.push({
        x: startCol,
        y: startHeight,
        w: widgetMinWidth,
        h: widgetMinHeight,
        i: next,
        sizes: widget.sizes,
        component: widget.component,
        id: widget.id
      })

      this.layout = layout
    },
    addAllWidgets() {
      for (const widget of this.widgetsToAdd) {
        this.positionNewItem(widget)
      }

      this.saveWidgets()
      this.loadWidgetsData()
    },
    removeWidget(widgetName) {
      this.layout = this.layout.filter((col) => col.component !== widgetName)

      this.saveWidgets()
    },
    changeSize(comp) {
      comp.sizes.selected = comp.sizes.selected === 'min' ? 'max' : 'min'
      comp.w = comp.sizes[comp.sizes.selected][0]
      comp.h = comp.sizes[comp.sizes.selected][1]
      this.reArrangeLayout()
    },
    reArrangeLayout() {
      const clone = cloneDeep(this.layout)
      this.layout = []
      clone.forEach((w) => this.positionNewItem(w))

      this.saveWidgets()
    },
    movedEvent(i, newX, newY) {
      const widget = this.layout.filter((r) => r.i === i)[0]
      widget.x = newX
      widget.y = newY

      this.saveWidgets()
    },
    saveWidgets() {
      const layoutDTO = []

      this.layout.forEach((element) => {
        layoutDTO.push({
          widgetId: element.id,
          size: element.sizes.selected,
          positionX: element.x,
          positionY: element.y
        })
      })

      uiHandler.showLoading(this)

      this.saveUserWidget(layoutDTO)
        .then(() => {
          this.addDialogVisible = false
          uiHandler.hideLoading(this)

          this.$notify({
            title: 'Success',
            message: this.$t(`${this.viewString}msgSaveSuccess`),
            type: 'success'
          })
        })
        .catch((e) => {
          uiHandler.hideLoading(this)
          const errorMessages = []
          errorMessages[e.response.status] = e.response.data[0]
          errorHandler.handleApiError(this, e, errorMessages)
        })
    }
  }
}
