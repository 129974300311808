export default [
  {
    name: 'ProjectSourceCompanies',
    path: 'companies',
    meta: { area: 'source' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/source/registered-companies-view/registered-companies-view.vue'
      )
  },
  {
    name: 'ProjectSourceQuestions',
    path: 'questions',
    meta: { area: 'source' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/source/questions-addenda-view/questions-addenda-view.vue'
      )
  },
  {
    name: 'ProjectSourceMeetings',
    path: 'bid-meetings',
    meta: { area: 'source' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/source/bid-meetings-view/bid-meetings-view.vue'
      )
  },
  {
    name: 'ProjectSourceSubmissions',
    path: 'submissions',
    meta: { area: 'source' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/source/submissions-view/submissions-view.vue'
      )
  },
  {
    name: 'ProjectSourceCompliance',
    path: 'compliance',
    meta: { area: 'source' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/source/compliance-view/compliance-view.vue'
      )
  },
  {
    name: 'ProjectSourceEvaluations',
    path: 'evaluations',
    meta: { area: 'source' },
    component: () =>
      import(
        '@/views/projects/project-view/project-sub-views/source/evaluation-view/evaluation-view.vue'
      )
  }
]
