import ProjectAwardRoutes from './project-award-routes.js'
import ProjectBuildRoutes from './project-build-routes.js'
import ProjectContractRoutes from './project-contract-routes.js'
import ProjectEvaluateRoutes from './project-evaluate-routes.js'
import ProjectIntakeRoutes from './project-intake-routes.js'
import ProjectIntakeViewRoutes from './project-intake-view-routes.js'
import ProjectSourceRoutes from './project-source-routes.js'

export default [
  {
    path: '/projects',
    meta: { Auth: true },
    name: 'ProjectSearchView',
    component: () =>
      import(
        /* webpackChunkName: "rproject" */ '@/views/projects/project-search-view/project-search-view.vue'
      )
  },
  {
    path: '/projects/new',
    meta: { Auth: true },
    name: 'ProjectNewView',
    component: () =>
      import(
        /* webpackChunkName: "rproject" */ '@/views/projects/project-new-wizard-view/project-wizard.vue'
      )
  },
  {
    path: '/projects/:id/form/:tabletype/',
    name: 'ProjectTableNewView',
    component: () =>
      import(
        /* webpackChunkName: "rproject" */ '@/views/projects/project-table-view/project-table-view.vue'
      ),
    props: true,
    exact: true,
    children: [
      {
        path: '/projects/:id/form/:tabletype/:formid',
        name: 'ProjectTableEditView',
        component: () =>
          import(
            /* webpackChunkName: "rproject" */ '@/views/projects/project-table-view/project-table-view.vue'
          ),
        props: true
      }
    ]
  },
  {
    path: '/projects/:id/activities',
    meta: { Auth: true, activeLink: 'activities' },
    name: 'ProjectActivitiesView',
    component: () =>
      import(
        /* webpackChunkName: "rproject" */ '@/views/projects/activities/activity-feed-view.vue'
      )
  },
  {
    path: '/projects/:id/messages',
    meta: { Auth: true, activeLink: 'messages' },
    name: 'ProjectMessagesView',
    component: () =>
      import(/* webpackChunkName: "rproject" */ '@/views/projects/messages/message-center-view.vue')
  },
  {
    path: '/projects/:id/team',
    meta: { Auth: true, activeLink: 'team', view: 'all' },
    name: 'ProjectTeamView',
    component: () =>
      import(
        /* webpackChunkName: "rproject" */ '@/views/projects/project-team-view/project-team-view.vue'
      ),
    children: [
      {
        path: 'add',
        meta: { Auth: true, activeLink: 'team', view: 'add' },
        name: 'ProjectTeamViewAdd',
        component: () =>
          import(
            /* webpackChunkName: "rproject" */ '@/views/projects/project-team-view/team-view-add/project-team-view-add.vue'
          )
      },
      {
        path: 'member/:userId',
        meta: { Auth: true, activeLink: 'team', view: 'edit' },
        name: 'ProjectTeamViewEdit',
        component: () =>
          import(
            /* webpackChunkName: "rproject" */ '@/views/projects/project-team-view/team-view-edit/project-team-view-edit.vue'
          ),
        props: true
      }
    ]
  },
  {
    path: '/projects/:id/snapshot',
    meta: { Auth: true },
    component: () =>
      import(
        /* webpackChunkName: "rproject" */ '@/views/projects/project-view/project-snapshot-view/project-snapshot-view.vue'
      ),
    props: true
  },
  {
    path: '/projects/:id/intake',
    name: 'ViewIntakeRequest',
    meta: { Auth: true },
    component: () =>
      import(
        /* webpackChunkName: "rproject" */ '@/views/projects/project-view/project-sub-views/intake/intake-view.vue'
      ),
    props: true,
    children: ProjectIntakeRoutes
  },
  {
    path: '/projects/:id',
    name: 'ProjectBaseView',
    meta: { Auth: true },
    component: () =>
      import(/* webpackChunkName: "rproject" */ '@/views/projects/project-view/project-view.vue'),
    props: true,
    redirect: (to) => {
      return `${to.path}/build/settings`
    },
    children: [
      {
        path: 'build',
        name: 'ProjectBuildView',
        component: () =>
          import(
            /* webpackChunkName: "rproject" */ '@/views/projects/project-view/project-sub-views/view-build.vue'
          ),
        meta: { area: 'build' },
        children: ProjectBuildRoutes
      },
      {
        path: 'source',
        name: 'ProjectSourceView',
        component: () =>
          import(
            /* webpackChunkName: "rproject" */ '@/views/projects/project-view/project-sub-views/view-source.vue'
          ),
        meta: { area: 'source' },
        children: ProjectSourceRoutes
      },
      {
        path: 'contract',
        name: 'ProjectContractView',
        component: () =>
          import(
            /* webpackChunkName: "rproject" */ '@/views/projects/project-view/project-sub-views/view-contract.vue'
          ),
        meta: { area: 'contract' },
        children: ProjectContractRoutes
      },
      {
        path: 'award',
        name: 'ProjectAwardView',
        component: () =>
          import(
            /* webpackChunkName: "rproject" */ '@/views/projects/project-view/project-sub-views/view-award.vue'
          ),
        meta: { area: 'award' },
        children: ProjectAwardRoutes
      },
      {
        path: '/projects/:id/intake-project',
        name: 'ViewIntakeProject',
        meta: { Auth: true },
        component: () =>
          import(
            /* webpackChunkName: "rproject" */ '@/views/projects/project-view/project-sub-views/view-intake-project'
          ),
        props: true,
        children: ProjectIntakeViewRoutes
      },
      {
        path: 'evaluate',
        name: 'ProjectEvaluateView',
        component: () =>
          import(
            /* webpackChunkName: "rproject" */ '@/views/projects/project-view/project-sub-views/view-evaluate.vue'
          ),
        meta: { area: 'evaluate' },
        children: ProjectEvaluateRoutes
      },
      {
        path: '*',
        name: 'ProjectPageNotFound',
        redirect: (to) => {
          const { id } = to.params
          const rPath = id ? `/projects/${id}/build/settings` : '/projects/'
          return rPath
        }
      }
    ]
  }
]
