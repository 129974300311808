const navItems = [
  {
    path: '/control-panel/project-settings',
    label: 'ui.components.app-mainnav.adminNavControlPanel'
  },
  {
    path: '/team/all',
    label: 'ui.components.app-mainnav.adminNavTeamMembers'
  },
  {
    path: '/messages',
    label: 'ui.components.app-mainnav.adminNavMessageCentre',
    disabled: true
  },
  {
    path: 'https://bidsandtenders.zendesk.com/hc/en-us/categories/360002752152-Buyer-Support-Portal-Pro',
    label: 'ui.components.app-mainnav.learningCentre',
    target: '_blank',
    tag: 'a'
  },
  {
    path: 'https://bidsandtenders.zendesk.com/hc/en-us/community/topics',
    label: 'ui.components.app-mainnav.communityHub',
    target: '_blank',
    tag: 'a'
  },
  {
    path: 'https://bidsandtenders.zendesk.com/hc/en-us/requests/new ',
    label: 'ui.components.app-mainnav.submitFeedback',
    target: '_blank',
    tag: 'a'
  },
  {
    path: 'https://calendar.bidsandtenders.ca/default/Month',
    label: 'ui.components.app-mainnav.bidClosingCalendar',
    target: '_blank',
    tag: 'a'
  },
  {
    path: '/contract-builder-dashboard',
    label: 'ui.views.projects.contract.contract-nav-view.contractBuilderNavItem',
    esolutionsOnly: true
  }
]

export default navItems
