<template>
  <div class="award-approval-doc has-padding-vertical">
    <div class="is-flex is-aligned-center is-direction-row">
      <div class="doc-icon has-margin-right">
        <slot name="icon">
          <span :class="getIconClass()"></span>
        </slot>
      </div>
      <div class="doc-title">
        <slot name="title">
          <app-title size="5" heading="5" class="has-no-margin-bottom">{{
            document.fileName
          }}</app-title>
        </slot>
      </div>
    </div>
    <div class="doc-actions">
      <slot name="actions">
        <app-button type="primary" @click="handleClickPreview">Review Document</app-button>
      </slot>
    </div>
  </div>
</template>
<script>
import uuid from 'uuid/v4'
import { Icons } from '@/views/award-approval/mixins/award-approval-mixins.js'

// default doc interface
const IDoc = () => ({
  id: uuid(),
  type: 'pdf',
  fileName: 'Test doc',
  location: ''
})

export default {
  name: 'award-approval-doc',
  mixins: [Icons],
  props: {
    document: {
      type: Object,
      default: IDoc
    },
    docIndex: {
      type: Number,
      required: true
    }
  },
  methods: {
    handleClickPreview() {
      this.$emit('preview', this.docIndex)
    }
  }
}
</script>

<style lang="scss" scoped>
.award-approval-doc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px dotted $c-border;

  @include breakpoint('sm') {
    flex-direction: row;
    align-items: center;
  }
}
.doc-actions {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: $gap-small;

  @include breakpoint('sm') {
    margin-left: auto;
    margin-top: 0;
    flex-direction: row;
    width: auto;
  }
}

.app-button.is-primary {
  background: $team-member-primary;

  &:hover,
  &:focus,
  &:active {
    background: darken($team-member-primary, 10%);
  }
}

:deep(.fiv-sqo) {
  font-size: 2em;
}
</style>
