export default {
  path: '/suppliers',
  meta: { Auth: true },
  name: 'Suppliers',
  component: () => import('@/views/suppliers/suppliers-view/suppliers-view.vue'),
  children: [
    {
      path: '/suppliers',
      component: () => import('@/views/suppliers/suppliers-all-view/suppliers-all-view.vue'),
      name: 'SuppliersAllView'
    },
    {
      path: '/suppliers/:id',
      name: 'SuppliersSupplierView',
      component: () =>
        import('@/views/suppliers/suppliers-supplier-view/suppliers-supplier-view.vue'),
      children: [
        {
          path: 'history',
          name: 'SupplierHistory',
          component: () =>
            import(
              '@/views/suppliers/suppliers-supplier-view/supplier-history-view/supplier-history-view.vue'
            ),
          props: true
        },
        {
          path: 'performance',
          name: 'SupplierPerformance',
          component: () =>
            import(
              '@/views/suppliers/suppliers-supplier-view/supplier-performance-view/supplier-performance-view.vue'
            ),
          props: true
        },
        {
          path: 'emails',
          name: 'SupplierEmails',
          component: () =>
            import(
              '@/views/suppliers/suppliers-supplier-view/supplier-emails-view/supplier-emails-view.vue'
            ),
          props: true
        },
        {
          path: 'activity-feed',
          name: 'SupplierActivityFeed',
          component: () =>
            import(
              '@/views/suppliers/suppliers-supplier-view/supplier-activity-feed-view/supplier-activity-feed-view.vue'
            ),
          props: true
        },
        {
          path: 'documents',
          name: 'SupplierDocuments',
          component: () =>
            import(
              '@/views/suppliers/suppliers-supplier-view/supplier-documents-view/supplier-documents-view.vue'
            ),
          props: true
        },
        {
          path: '*',
          name: 'SuppliersFallback',
          component: () =>
            import('@/views/suppliers/suppliers-supplier-view/suppliers-supplier-view.vue'),
          redirect: { name: 'SuppliersSupplierView' },
          props: true
        }
      ]
    }
  ]
}
