export const performanceRatingScales = {
  LOWEST: '22AC04E5-F56C-4784-B25F-5A177BE28019',
  AVG: 'E521BFE7-D164-4D24-B285-BD7260C41970',
  SUM: 'AC202685-0818-41D4-AE37-DA062BE4E43E',
  SECTIONWEIGHT: '08C92DC9-4A27-4A70-9B54-94017B8931B0'
}

export const performanceEvaluationStatus = {
  PENDING_REQUISITIONER_SUPER: 'PENDING_REQUISITIONER_SUPER',
  PENDING_DIRECTOR: 'PENDING_DIRECTOR',
  COMPLETE: 'COMPLETE',
  PENDING_PURCHASER: 'PENDING_PURCHASER',
  PENDING_REQUISITIONER: 'PENDING_REQUISITIONER',
  PENDING_MANAGER: 'PENDING_MANAGER',
  PENDING_VENDOR: 'PENDING_VENDOR',
  SCHEDULED: 'SCHEDULED',
  EXPIRED: 'EXPIRED',
  COMPLETE_APPEALCOMPLETE: 'COMPLETE_APPEALCOMPLETE',
  UNDER_APPEAL: 'UNDER_APPEAL',
  DRAFT: 'DRAFT'
}
