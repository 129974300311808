<template>
  <div class="award-approval-summary has-padding-xlg is-flex is-direction-column">
    <div class="summary-title has-margin-bottom-large">
      <slot name="title">
        <app-title size="3">Hello {{ name }}</app-title>
        <p>Thank you for reviewing the documents.</p>
      </slot>
    </div>
    <div class="summary-content is-flex">
      <app-button id="downloadDocuments" min-width @click="handleDownloadAll">
        <app-icon icon="download-cloud" />
        Download Documents
      </app-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'award-approval-summary',
  props: {
    name: {
      type: String,
      default: 'Team Member'
    }
  },
  methods: {
    handleDownloadAll() {
      this.$emit('document-download-all')
    }
  }
}
</script>

<style lang="scss">
.award-approval-view {
  .summary-title {
    text-align: center;

    .app-title-content {
      display: flex;
      justify-content: center;
    }

    @include breakpoint('sm') {
      text-align: left;

      .app-title-content {
        justify-content: flex-start;
      }
    }
  }
  .summary-content {
    flex-direction: column;

    .app-button:first-child {
      margin-bottom: $gap;
    }

    @include breakpoint('sm') {
      flex-direction: row;
      .app-button:first-child {
        margin-bottom: 0;
        margin-right: $gap-small;
      }
    }
  }
}
</style>
