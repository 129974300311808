import util from '@/utils/utility'
import SecureLS from 'secure-ls'
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import audit from './modules/audit/audit'
import auth from './modules/auth'
import authCredential from './modules/authCredential'
import awardApproval from './modules/awards/award-approval'
import certificate from './modules/contract/certificate'
import certificateType from './modules/contract/certificateType'
import contractBondDocument from './modules/contract/contractBondDocument'
import contractBondDocumentType from './modules/contract/contractBondDocumentType'
import contractBuilderLicenseConfiguration from './modules/contract/contractBuilderLicenseConfiguration'
import contractDocument from './modules/contract/contractDocument'
import contractPerformance from './modules/contract/contractPerformance'
import contractPOType from './modules/contract/contractPOType'
import contractVendorDocument from './modules/contract/contractVendorDocument'
import contractVendorDocumentType from './modules/contract/contractVendorDocumentType'
import insurance from './modules/contract/insurance'
import insuranceType from './modules/contract/insuranceType'
import performanceEvaluationDocuments from './modules/contract/performanceEvaluationDocuments'
import performanceEvaluationStatus from './modules/contract/performanceEvaluationStatus'
import performanceEvaluationType from './modules/contract/performanceEvaluationType'
import performanceTemplate from './modules/contract/performanceTemplate'
import performanceWorkflow from './modules/contract/performanceWorkflow'
import procurementMethodLimitedType from './modules/contract/procurementMethodLimitedType'
import contractSignature from './modules/contract/signature'
import controlPanel from './modules/control-panel/control-panel'
import libraryContent from './modules/control-panel/library-content'
import systemContent from './modules/control-panel/system-content'
import customer from './modules/customer/customer'
import customerNode from './modules/customer/node/customerNode'
import customerSsoSaml from './modules/customerSsoSaml/customerSsoSaml'
import dashboard from './modules/dashboard/dashboard'
import email from './modules/email/email'
import evaluation from './modules/evaluation/evaluation.module.js'
import exportFile from './modules/exportFile/exportFile'
import global from './modules/global'
import login from './modules/login/login'
import menus from './modules/menus/menus'
import permission from './modules/permission/permission'
import plantaker from './modules/plantaker/plantaker'
import projectRole from './modules/project-role/project-role'
import projectUser from './modules/project-user/project-user'
import awards from './modules/project/awards'
import category from './modules/project/category'
import commodityCategories from './modules/project/commodityCategories'
import contract from './modules/project/contract'
import department from './modules/project/department'
import division from './modules/project/division'
import document from './modules/project/document'
import documentTemplate from './modules/project/documentTemplate'
import features from './modules/features/features'
import instructionTemplate from './modules/project/instructionTemplate'
import irrevocablePeriod from './modules/project/irrevocablePeriod'
import leadAgencyName from './modules/project/leadAgencyName'
import procurementtype from './modules/project/procurementtype'
import project from './modules/project/project'
import projectComment from './modules/project/ProjectComment'
import projectJournal from './modules/project/projectJournal'
import projectJournalDocument from './modules/project/projectJournalDocument'
import projectSearch from './modules/project/projectSearch'
import projectTask from './modules/project/ProjectTask'
import questionTemplate from './modules/project/questionTemplate'
import securityTemplate from './modules/project/securityTemplate'
import tax from './modules/project/tax'
import term from './modules/project/term'
import intake from './modules/intake/intake.module'
import termsAndConditions from './modules/project/termsAndConditions'
import tradeagreement from './modules/project/tradeagreement'
import reports from './modules/reports/reports'
import reportsWizard from './modules/reports/reports-wizard'
import routePermission from './modules/routePermission/routePermission'
import snapshotLayout from './modules/snapshot/layout'
import submission from './modules/submission/submission'
import suppliersSearch from './modules/suppliers/suppliers-search'
import tendertablecolumn from './modules/tender-table-column/tender-table-column'
import tendertablerowcolumn from './modules/tender-table-row-column/tender-table-row-column'
import tendertablerow from './modules/tender-table-row/tender-table-row'
import tendertable from './modules/tender-table/tender-table'
import tenderNode from './modules/tender/node/tenderNode'
import tender from './modules/tender/tender'
import group from './modules/user/group'
import user from './modules/user/user'
import vendorInvitationList from './modules/vendor-invitation-list/vendor-invitation-list'
import vendorInvitation from './modules/vendor/invitation'
import performanceStatus from './modules/vendor/performanceStatus'
import vendor from './modules/vendor/vendor'
import vendorquestion from './modules/vendor/vendor-question'
import vendorDocument from './modules/vendor/vendorDocument'
import vendorPerformance from './modules/vendor/vendorPerformance'
const authGuid = util.createAuthGuidIfNeed()
const ls = new SecureLS({ encodingType: 'aes' })

Vue.use(Vuex)

const initialState = () => ({})

// eslint-disable-next-line no-undef
const debug = import.meta.env.MODE !== 'production'

const store = new Vuex.Store({
  state: initialState,
  mutations: {
    reset: (state) => {
      Object.assign(state, initialState())
    }
  },
  modules: {
    audit,
    auth,
    authCredential,
    certificate,
    certificateType,
    contractBondDocument,
    contractBondDocumentType,
    contractDocument,
    contractPOType,
    contractVendorDocumentType,
    contractVendorDocument,
    customer,
    customerNode,
    email,
    evaluation,
    features,
    global,
    group,
    insurance,
    insuranceType,
    login,
    project,
    division,
    department,
    document,
    category,
    leadAgencyName,
    irrevocablePeriod,
    intake,
    tradeagreement,
    instructionTemplate,
    termsAndConditions,
    questionTemplate,
    securityTemplate,
    documentTemplate,
    procurementtype,
    awards,
    tax,
    tender,
    tenderNode,
    contract,
    tendertable,
    tendertablecolumn,
    tendertablerow,
    tendertablerowcolumn,
    user,
    vendorInvitation,
    vendorInvitationList,
    vendorDocument,
    vendorPerformance,
    term,
    dashboard,
    exportFile,
    plantaker,
    projectUser,
    submission,
    vendor,
    vendorquestion,
    projectRole,
    controlPanel,
    libraryContent,
    systemContent,
    suppliersSearch,
    routePermission,
    permission,
    projectSearch,
    projectComment,
    projectTask,
    projectJournal,
    projectJournalDocument,
    menus,
    customerSsoSaml,
    procurementMethodLimitedType,
    snapshotLayout,
    reports,
    reportsWizard,
    performanceEvaluationType,
    performanceTemplate,
    performanceWorkflow,
    contractPerformance,
    contractSignature,
    performanceStatus,
    performanceEvaluationStatus,
    commodityCategories,
    contractBuilderLicenseConfiguration,
    awardApproval,
    performanceEvaluationDocuments
  },
  plugins: [
    createPersistedState({
      key: authGuid,
      paths: ['auth', 'authCredential', 'projectSearch', 'suppliersSearch'],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key)
      }
    })
  ],
  actions: {},
  getters: {},
  strict: debug /*
  plugins: debug ? [createLogger()] : [] */
})

export default store

export const useStore = () => store
