export const eventTypes = {
  CLOSING: 'closed',
  PUBLISHED: 'published',
  QUESTION_DEADLINE: 'question-deadline',
  SITE_MEETING: 'site-meeting',
  AWARDED: 'awarded'
}

export const calendarViews = {
  DAY: 'day',
  MONTH: 'month'
}

export const statusTypes = {
  IN_PROGRESS: 'In Progress',
  PLANNED: 'Planned',
  PUBLISHED: 'Published',
  SCHEDULED: 'Scheduled',
  CLOSED_UNOFFICIAL: 'Closed/Unofficial',
  AWARDED: 'Awarded',
  CANCELLED: 'Cancelled'
}

export const statusColorsMap = new Map()

// statusColorsMap.set(statusTypes.IN_PROGRESS, '#FFE083')
// statusColorsMap.set(statusTypes.PLANNED, '#E6DDFE')
// statusColorsMap.set(statusTypes.PUBLISHED, '#BED89')
// statusColorsMap.set(statusTypes.SCHEDULED, '#D6D6D6')
// statusColorsMap.set(statusTypes.CLOSED_UNOFFICIAL, '#3F3F3F')
// statusColorsMap.set(statusTypes.AWARDED, '#97DDE4')
// statusColorsMap.set(statusTypes.CANCELLED, '#F5C2C7')

statusColorsMap.set(statusTypes.IN_PROGRESS, 'var(--status-in-progress)')
statusColorsMap.set(statusTypes.PLANNED, 'var(--status-planned)')
statusColorsMap.set(statusTypes.PUBLISHED, 'var(--status-published)')
statusColorsMap.set(statusTypes.SCHEDULED, 'var(--status-scheduled)')
statusColorsMap.set(statusTypes.CLOSED_UNOFFICIAL, 'var(--status-closed-unofficial)')
statusColorsMap.set(statusTypes.AWARDED, 'var(--status-awarded)')
statusColorsMap.set(statusTypes.CANCELLED, 'var(--status-cancelled)')
