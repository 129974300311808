import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  departments: [],
  departmentsAll: [],
  department: null
}

export const getters = {
  getDepartments: (state) => state.departments,
  getDepartmentsAll: (state) => state.departmentsAll,
  getDepartment: (state) => state.department
}

export const actions = {
  fetchDepartments: ({ commit }, forceUpdate = false) => {
    // when signalr event received to fetch new departments
    if (forceUpdate) {
      commit(types.DEPARTMENTS_SET, [])
    }

    // Return cached value if exists
    if (state.departments.length) {
      return Promise.resolve(state.departments)
    }

    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Departments?filter.fields=Divisions,UserGroups`
    return api.get(url).then((response) => {
      const departments = response.data.data.departmentDTO.values
      commit(types.DEPARTMENTS_SET, departments)
      return departments
    })
  },
  fetchDepartmentsAll: ({ commit }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Departments/All`

    return api.get(url).then((response) => {
      const departments = response.data.data.departmentDTO.values
      commit(types.DEPARTMENT_SET_ALL, departments)
      return departments
    })
  },
  saveDepartment: ({ commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Departments`
    return api.post(url, model).then((response) => {
      const department = response.data.data.departmentDTO.values[0]
      commit(types.DEPARTMENT_SET, department)
      return department
    })
  },
  updateDepartmentName: ({ commit }, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Departments/UpdateName`
    return api.put(url, model).then((response) => {
      const department = response.data.data.departmentDTO.values[0]
      commit(types.DEPARTMENT_SET, department)
      return department
    })
  },
  deleteDepartment: (_, id) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Departments?departmentId=${id}`
    return api.delete(url)
  }
}

export const mutations = {
  [types.DEPARTMENTS_SET](state, departments) {
    state.departments = departments
  },
  [types.DEPARTMENT_SET_ALL](state, departments) {
    state.departmentsAll = departments
  },
  [types.DEPARTMENT_SET](state, department) {
    state.department = department
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
