export default {
  path: '/team',
  name: 'TeamView',
  component: () => import('@/views/team/team-view/team-view.vue'),
  // redirect to team all view,
  redirect: { name: 'TeamAllView' },
  props: true,
  children: [
    {
      path: 'teammember/:userId',
      name: 'TeamEditMemberView',
      component: () => import('@/views/team/team-member-view/team-member-view.vue'),
      props: true
    },
    {
      path: 'addteammember',
      name: 'TeamAddTeamMemberView',
      component: () => import('@/views/team/team-member-view/team-member-view.vue'),
      props: true
    },
    {
      path: 'all',
      name: 'TeamAllView',
      component: () => import('@/views/team/team-all-view/team-all-view.vue'),
      props: true
    },
    {
      path: 'groups/:groupId',
      component: () => import('@/views/team/team-group-view/team-group-view.vue'),
      name: 'TeamGroupView'
    }
  ]
}
