<template>
  <div class="agency-details-billing-container is-flex is-direction-column">
    <app-title size="5" heading="2" class="has-margin-left-tiny">
      {{ $t(viewString + 'billingPeriods') }}
    </app-title>

    <div class="is-flex">
      <div class="is-flex has-full-width">
        <label class="column is-3 has-margin-bottom-tiny">{{
          $t(viewString + 'billingStartDate')
        }}</label>
        <label class="column is-3 has-margin-bottom-tiny">{{
          $t(viewString + 'billingEndDate')
        }}</label>
        <label class="column is-3 has-margin-bottom-tiny">{{
          $t(viewString + 'purchasedTransactions')
        }}</label>
        <label class="column is-3 has-margin-bottom-tiny">{{
          $t(viewString + 'pricePerTransaction')
        }}</label>
      </div>
    </div>

    <div class="is-flex is-direction-column">
      <template v-for="column in billingPeriods">
        <div :key="column.id" class="is-flex has-full-width">
          <div class="column is-3 has-no-padding-bottom has-no-padding-top">
            <app-input
              v-model="column.startDate"
              :label="$t(viewString + 'billingStartDate')"
              type="date"
            >
            </app-input>
          </div>
          <div class="column is-3 has-no-padding-bottom has-no-padding-top">
            <app-input
              v-model="column.endDate"
              :label="$t(viewString + 'billingEndDate')"
              type="date"
            >
            </app-input>
          </div>
          <div class="column is-3 has-no-padding-bottom has-no-padding-top">
            <app-input
              v-model="column.purchased"
              :label="
                $t(viewString + 'purchasedTransactions') +
                (!column.transactionUsed
                  ? ''
                  : ' (' + $t(viewString + 'usedTrans') + column.transactionUsed + ')')
              "
              type="number"
              decimals="0"
            >
            </app-input>
          </div>
          <div class="column is-3 has-no-padding-bottom has-no-padding-top">
            <div class="is-flex row">
              <app-input
                v-model="column.price"
                :label="$t(viewString + 'pricePerTransaction')"
                is-currency
                decimals="2"
              >
              </app-input>
              <app-icon
                icon="trash-2"
                class="has-margin-top-tiny"
                style="cursor: pointer"
                @click.native="handleRemove(column.id)"
              />
            </div>
          </div>
        </div>
        <span
          v-if="column.error"
          :key="column.error + column.id"
          class="error has-margin-left has-margin-bottom"
          >{{ $t(`${viewString}${column.error}`) }}
        </span>
      </template>
    </div>

    <div class="is-flex is-justified-center has-margin-top-small">
      <app-button min-width type="primary" @click="handleAdd">
        {{ $t(viewString + 'addBillingPeriod') }}
      </app-button>
    </div>
  </div>
</template>

<script>
import appIcon from '../../../../components/app-icon/app-icon.vue'
export default {
  name: 'agency-details-billing',
  components: { appIcon },
  props: {
    viewString: String,
    billingPeriods: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleAdd() {
      this.$emit('add-new-billing-period')
    },
    handleRemove(id) {
      this.$emit('remove-new-billing-period', id)
    }
  }
}
</script>
