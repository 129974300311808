import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  instructionTemplates: []
}
export const getters = {
  getInstructionTemplates: (state) => state.instructionTemplates
}
export const actions = {
  fetchInstructionTemplates: ({ commit }, group) => {
    if (!group) {
      commit(types.INSTRUCTION_TEMPLATES_SET_ALL, [])
      return
    }

    const url = `/api/v1/customer/{customerId}/node/{nodeId}/OnlineSubmissionTemplate/Search?groupName=${group}`
    return api.get(url).then((res) => {
      const templates = res.data.data.onlineSubmissionTemplateDTO.values
      commit(types.INSTRUCTION_TEMPLATES_SET_ALL, templates)
      return res
    })
  }
}
export const mutations = {
  [types.INSTRUCTION_TEMPLATES_SET_ALL]: (state, templates) => {
    state.instructionTemplates = templates
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
