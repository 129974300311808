<template>
  <el-tooltip v-if="tooltip" :content="tooltip" :open-delay="700">
    <component
      :is="component"
      :id="id && id"
      :type="type && type"
      :class="componentClass"
      :style="componentStyle"
      :disabled="disabled"
      @click="handleClick"
    >
      <span class="app-button-inner">
        <slot></slot>
      </span>
    </component>
  </el-tooltip>
  <component
    :is="component"
    v-else
    :id="id && id"
    :type="type && type"
    :class="componentClass"
    :style="componentStyle"
    :disabled="disabled"
    @click="handleClick"
  >
    <span class="app-button-inner" :class="innerClass">
      <slot></slot>
    </span>
  </component>
</template>

<script>
export default {
  name: 'app-button',
  props: {
    tag: {
      type: String,
      default: 'button'
    },
    noHover: Boolean,
    id: String,
    type: String,
    minwidth: Boolean,
    minWidth: Boolean,
    fullwidth: Boolean,
    hoverWarning: Boolean,
    textOnly: Boolean,
    disabled: Boolean,
    maxWidth: [Number, String],
    tooltip: String,
    textAlignment: String,
    size: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      component: 'button',
      componentClasses: {
        input: 'button',
        button: 'button app-button',
        a: 'button'
      }
    }
  },
  computed: {
    componentClass() {
      let c = this.componentClasses[this.component]
      if (this.component === 'button') {
        c += this.type ? ` is-${this.type}` : ' is-default'
        c += this.size ? ` is-${this.size}` : ''
        if (this.minwidth || this.minWidth) c += ' has-min-width'
        if (this.fullwidth) c += ' is-fullwidth'
        if (this.hoverWarning) c += ' is-warning-on-hover'
        if (this.noHover) c += ' has-no-hover'
        if (this.maxWidth) c += ' has-set-max-width'
      }
      if (this.textOnly) c += ' is-text-only'
      return c
    },
    innerClass() {
      return this.textAlignment ? `is-justified-${this.textAlignment}` : ''
    },
    componentStyle() {
      const innerStyle = {}
      if (this.maxWidth) {
        const mw = typeof this.maxWidth === 'string' ? this.maxWidth : `${this.maxWidth}px`
        innerStyle['max-width'] = mw
      }
      return innerStyle
    }
  },
  created() {
    if (this.tag === 'submit' || this.tag === 'reset') {
      this.component = 'input'
      this.type = this.tag
    }
    if (this.tag === 'a') {
      this.component = 'a'
    }
  },
  methods: {
    handleClick() {
      // TODO, should take event as param and pass it in the emit.
      // will that break anything?
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" src="./app-button.scss"></style>
