import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  libraryContentTemplates: []
}
export const getters = {
  getLibraryContentTemplates: (state) => state.libraryContentTemplates,
  getActiveLibraryContentTemplates: (state) =>
    state.libraryContentTemplates.filter((temp) => temp.active)
}

export const actions = {
  fetchLibraryContentTemplates: ({ commit }, groupName) => {
    if (!groupName) {
      commit(types.CONTROL_PANEL_LIBRARY_CONTENT_SET_ITEM, [])
      return Promise.resolve('')
    }
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/OnlineSubmissionTemplate/All?groupName=${groupName}`
    return api.get(url).then((res) => {
      const templates = res.data.data.onlineSubmissionTemplateDTO.values
      commit(types.CONTROL_PANEL_LIBRARY_CONTENT_SET_ITEM, templates)
      return res
    })
  },
  createLibraryContentTemplate(_, libraryContentTemplateDTO) {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/OnlineSubmissionTemplate?groupName=${libraryContentTemplateDTO.groupName}`
    return api.post(url, libraryContentTemplateDTO).then((response) => {
      const template = response.data.data.onlineSubmissionTemplateDTO.values[0]
      return template
    })
  },
  updateLibraryContentTemplate(_, libraryContentTemplateDTO) {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/OnlineSubmissionTemplate?groupName=${libraryContentTemplateDTO.groupName}`
    return api.put(url, libraryContentTemplateDTO).then((response) => {
      const template = response.data.data.onlineSubmissionTemplateDTO.values[0]
      return template
    })
  },
  deleteLibraryContentTemplate: (_, libraryContentTemplateDTO) => {
    let url = `/api/v1/customer/{customerId}/node/{nodeId}/OnlineSubmissionTemplate?`
    url = `${url}onlineSubmissionTemplateId=${libraryContentTemplateDTO.id}&groupName=${libraryContentTemplateDTO.groupName}`
    return api.delete(url)
  }
}

export const mutations = {
  [types.CONTROL_PANEL_LIBRARY_CONTENT_SET_ITEM]: (state, templates) => {
    state.libraryContentTemplates = templates
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
