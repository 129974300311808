import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  divisions: []
}

export const getters = {
  getDivisions: (state) => state.divisions
}

export const actions = {
  fetchDivisions: ({ commit }, department) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Divisions?departmentId=${department}`

    return api.get(url).then((response) => {
      const divisions = response.data.data.divisionDTO.values
      commit(types.DIVISION_SET_ALL, divisions)
      return divisions
    })
  },
  saveDivision: (_, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Divisions`
    return api.post(url, model).then((response) => {
      const division = response.data.data.divisionDTO.values[0]
      return division
    })
  },
  updateDivision: (_, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Divisions`
    return api.put(url, model).then((response) => {
      const division = response.data.data.divisionDTO.values[0]
      return division
    })
  },
  deleteDivision: (_, id) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Divisions?divisionId=${id}`
    return api.delete(url)
  }
}

export const mutations = {
  [types.DIVISION_SET_ALL](state, divisions) {
    state.divisions = divisions
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
