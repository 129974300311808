import PermissionsMixins from '@/security/permissions/permissions-mixins'

export default {
  mixins: [PermissionsMixins],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    permissionParentSelector: {
      type: String,
      default: '.project-view-wrapper'
    }
  },
  computed: {
    hasRoutePermissionToView() {
      if (!this.hasParentSelector) return true

      return this.hasRoutePermissionToView
    },
    hasPermissionToEdit() {
      if (!this.hasParentSelector) return true

      return this.hasRoutePermissionToAction
    },
    hasControlPermission() {
      return this.checkRouteControlPermission(this.$store, this.$route, this)
    }
  },
  data() {
    return {
      hasParentSelector: false
    }
  },
  mounted() {
    this.checkParentSelector()
  },
  methods: {
    checkParentSelector() {
      if (!this.$refs.wrapper) return false

      const wrapper = this.$refs.wrapper
      this.hasParentSelector = wrapper.closest(this.permissionParentSelector)
    }
  }
}
