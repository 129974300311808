import { api } from '@/api/api'
import findIndex from 'lodash/findIndex'
import Vue from 'vue'
import * as types from '../../mutation-types'

export const state = {
  vendorPerformanceSummary: null,
  vendorPerformanceEvaluation: [],
  vendorPerformanceStatusLog: null,
  vendorPerformanceStatusLogHistory: []
}
export const getters = {
  getVendorPerformanceSummary: (state) => state.vendorPerformanceSummary,
  getVendorPerformanceEvaluation: (state) => state.vendorPerformanceEvaluation,
  getVendorPerformanceStatusLog: (state) => state.vendorPerformanceStatusLog,
  getVendorPerformanceStatusLogHistory: (state) => state.vendorPerformanceStatusLogHistory
}
export const actions = {
  fetchVendorPerformanceSummary: ({ commit }, vendorId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/VendorPerformances/GetVendorPerformanceSummary?vendorId=${vendorId}`

    return api
      .get(url)
      .then((response) => {
        if (response.data.count > 0) {
          const performance = response.data.data.vendorPerformanceSummaryDTO.values[0]
          commit(types.VENDOR_PERFORMANCE_SUMMARY_SET, performance)
          return performance
        }
      })
      .catch((err) => {
        commit(types.VENDOR_PERFORMANCE_SUMMARY_SET, null)
        throw err
      })
  },
  fetchVendorPerformanceEvaluation: ({ commit }, vendorId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/VendorPerformances/PerformanceEvaluation?vendorId=${vendorId}`
    return api.get(url).then((response) => {
      let vendorPerformanceEvaluation = []
      if (response.data.count > 0) {
        vendorPerformanceEvaluation = response.data.data.evaluationSummary.values
        commit(types.VENDOR_PERFORMANCE, vendorPerformanceEvaluation)
      }
      return vendorPerformanceEvaluation
    })
  },

  fetchVendorPerformanceStatusLog: ({ commit }, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/PerformanceVendorStatusLogs/Current?vendorId=${params.vendorId}&isActive=${params.isActive}`
    return api.get(url).then((response) => {
      let performanceStatus = null
      if (response.data.count > 0) {
        performanceStatus = response.data.data.performanceVendorStatusLogDTO.values[0]
      }

      commit(types.VENDOR_PERFORMANCE_STATUS_LOG_SET, performanceStatus)
      return performanceStatus
    })
  },
  AddVendorPerformanceStatusLog: ({ commit }, statusLog) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/PerformanceVendorStatusLogs`
    return api.post(url, statusLog).then((response) => {
      const status = response.data.data.performanceVendorStatusLogDTO.values[0]
      commit(types.VENDOR_PERFORMANCE_STATUS_LOG_SET, status)
      return status
    })
  },
  UpdateVendorPerformanceStatusLog: ({ commit }, { statusLog, fromHistory }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/PerformanceVendorStatusLogs?fromHistory=${fromHistory}`
    return api.put(url, statusLog).then((response) => {
      const status = response.data.data.performanceVendorStatusLogDTO.values[0]
      commit(types.VENDOR_PERFORMANCE_STATUS_LOG_SET, status)

      return status
    })
  },
  fetchVendorPerformanceStatusLogHistory: ({ commit }, vendorId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/PerformanceVendorStatusLogs/History?vendorId=${vendorId}`

    return api.get(url).then((response) => {
      let performanceStatus = []
      if (response.data.count > 0) {
        performanceStatus = response.data.data.performanceVendorStatusLogDTO.values
      }

      commit(types.VENDOR_PERFORMANCE_STATUS_LOG_HISTORY_SET_ALL, performanceStatus)
      return performanceStatus
    })
  },
  updateVendorPerformanceStatusLogHistory: ({ commit }, vendorPerformanceStatusLog) => {
    commit('tempUpdateHistory', { vendorPerformanceStatusLog })
  }
}

export const mutations = {
  [types.VENDOR_PERFORMANCE_SUMMARY_SET](state, performance) {
    state.vendorPerformanceSummary = performance
  },
  [types.VENDOR_PERFORMANCE]: (state, vendorPerformanceEvaluation) => {
    state.vendorPerformanceEvaluation = vendorPerformanceEvaluation
  },
  [types.VENDOR_PERFORMANCE_STATUS_LOG_SET]: (state, vendorPerformanceStatusLog) => {
    state.vendorPerformanceStatusLog = vendorPerformanceStatusLog
  },
  [types.VENDOR_PERFORMANCE_STATUS_LOG_HISTORY_SET_ALL]: (
    state,
    vendorPerformanceStatusLogHistory
  ) => {
    state.vendorPerformanceStatusLogHistory = vendorPerformanceStatusLogHistory
  },
  tempUpdateHistory: (state, { vendorPerformanceStatusLog }) => {
    const { id, complete } = vendorPerformanceStatusLog

    if (complete) {
      const status = state.vendorPerformanceStatusLogHistory.find((e) => e.id === id)

      if (status) {
        const index = findIndex(state.vendorPerformanceStatusLogHistory, { id })
        Vue.set(state.vendorPerformanceStatusLogHistory, index, vendorPerformanceStatusLog)
      } else {
        state.vendorPerformanceStatusLogHistory.unshift(vendorPerformanceStatusLog)
      }
    } else {
      const history = state.vendorPerformanceStatusLogHistory.filter((e) => e.id !== id)
      state.vendorPerformanceStatusLogHistory = history
    }
  },
  [types.VENDOR_PERFORMANCE_REMOVE]: (state, id) => {
    const performanceEvaluations = state.vendorPerformanceEvaluation.filter(
      (e) => e.evaluationId !== id
    )

    state.vendorPerformanceEvaluation = performanceEvaluations
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
