<template>
  <footer class="is-flex-column is-aligned-center has-full-width has-margin-top-auto">
    <ul class="is-flex has-full-width is-justified-center">
      <li>
        <a :href="externalURLPrivacy" target="_blank">
          {{ $t('ui.views.login.login-view.linkPrivacy') }}
        </a>
      </li>
      <li>
        <a :href="externalURLContactUs" target="_blank">
          {{ $t('ui.views.login.login-view.linkContact') }}
        </a>
      </li>
    </ul>
    <a :href="externalURLBids.url" target="_blank"> {{ externalURLBids.text }} </a>
    <p class="view-login__footer-version">
      {{ $t('ui.views.login.login-view.version') }} {{ version }} •
      {{ $t('ui.views.login.login-view.copyright') }} © {{ copyright }}
    </p>
  </footer>
</template>

<script setup>
import { ref, computed } from 'vue'

const externalURLPrivacy = ref('https://www.bidsandtenders.com/privacy-policy/')
const externalURLContactUs = ref('https://www.bidsandtenders.com/buyers/support/')
const version = ref(import.meta.env.VITE_BT_VERSION)
const copyright = computed(() => new Date().getFullYear())
const externalURLBids = computed(() => {
  const domain = window.location.hostname
  switch (domain) {
    case 'next.bidsandtenders.net':
      return {
        text: 'bidsandtenders.net',
        url: 'http://bidsandtenders.net/'
      }
    default:
      return {
        text: 'bidsandtenders.com',
        url: 'https://www.bidsandtenders.com/'
      }
  }
})
</script>

<style lang="scss" scoped>
$login-color-footer: #4b4f55;

footer {
  li:not(:last-child):after {
    content: '|';
  }
}
a {
  font-weight: bold;
  font-size: $size-6;
  color: $login-color-footer;
  padding: 10px;
  text-decoration: underline;

  &:hover,
  &:focus,
  &:active {
    color: $c-primary;
  }
}
.view-login__footer-version {
  font-size: $size-7;
}
</style>
