import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  projectComments: [],
  userProjectComments: []
}

export const getters = {
  getProjectComments: (state) => state.projectComments,
  getUserProjectComments: (state) => state.userProjectComments
}

export const actions = {
  saveProjectComment(_, model) {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ProjectComments`
    return api.post(url, model).then((response) => response.data.data.projectCommentDTO.values[0])
  },
  fetchProjectComments: ({ commit }, projectId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ProjectComments?projectId=${projectId}`
    return api
      .get(url)
      .then((response) => {
        if (response.data.count > 0) {
          const result = response.data.data.projectCommentDTO.values
          commit(types.PROJECT_COMMENT_SET_ALL, result)
          return result
        }
      })
      .catch((err) => {
        commit(types.PROJECT_COMMENT_SET_ALL, null)
        throw err
      })
  },
  fetchUserProjectComments: ({ commit }, params) => {
    let url = `/api/v1/customer/{customerId}/node/{nodeId}/ProjectComments/Dashboard?`
    if (params.filter && params.filter.length > 0) {
      url += `,${params.filter}`
    }
    url += `filter.limit=${params.limit}&filter.start=${params.start}&filter.searchText=${
      params.keywords
    }&filter.dateFrom=${
      params.dateFrom ? new Date(params.dateFrom).toISOString() : ''
    }&filter.dateTo=${params.dateTo ? new Date(params.dateTo).toISOString() : ''}`
    if (params.status && params.status.length > 0) {
      url += `&status=${params.status}`
    }
    return api
      .get(url)
      .then((response) => {
        if (response.data.count > 0) {
          const result = response.data.data.projectCommentDTO.values
          commit(types.USER_PROJECT_COMMENT_SET_ALL, result)
          return result
        }
      })
      .catch((err) => {
        commit(types.USER_PROJECT_COMMENT_SET_ALL, null)
        throw err
      })
  }
}
export const mutations = {
  [types.PROJECT_COMMENT_SET_ALL](state, projectComments) {
    state.projectComments = projectComments
  },
  [types.USER_PROJECT_COMMENT_SET_ALL](state, projectComments) {
    state.userProjectComments = projectComments
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
