<template>
  <dashboard-list-component :id="'manager-project-counter'" :data="feedItems" :layout="layout" />
</template>
<script>
import dashboardListComponent from '@/views/dashboard/components/dashboard-list-component.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'dashboard-project-counter',
  components: {
    dashboardListComponent
  },
  props: {
    viewString: {
      type: String,
      default: 'ui.views.dashboard.dashboard-view.'
    },
    layout: String
  },
  computed: {
    ...mapGetters(['getDashboardwidgets']),
    feedItems() {
      const feedItems = []

      feedItems.push({
        name: 'Draft',
        amount: this.getDashboardwidgets?.allDraftProjects?.[0]?.count
      })

      feedItems.push({
        name: 'Planned',
        amount: this.getDashboardwidgets?.allPlannedProjects?.[0]?.count
      })

      feedItems.push({
        name: 'Published',
        amount: this.getDashboardwidgets?.allOpenProjects?.[0]?.count
      })

      feedItems.push({
        name: 'Closed',
        amount: this.getDashboardwidgets?.allClosedProjects?.[0]?.count
      })

      feedItems.push({
        name: 'Awarded',
        amount: this.getDashboardwidgets?.allAwardedProjects?.[0]?.count
      })

      feedItems.push({
        name: 'Active Contract',
        amount: this.getDashboardwidgets?.allActiveProjects?.[0]?.count
      })

      feedItems.push({
        name: 'Warranty Contract',
        amount: this.getDashboardwidgets?.allWarrantyProjects?.[0]?.count
      })

      return feedItems
    }
  }
}
</script>
<style lang="scss"></style>
