import * as types from '../../mutation-types.js'

export const state = () => ({
  userNavMenuOpen: false,
  activityFeedMenuOpen: false,
  messageCentreMenuOpen: false,
  projectSnapshotOpen: false
})

export const getters = {
  getUserNavMenuOpen: (state) => state.userNavMenuOpen,
  getActivityFeedMenuOpen: (state) => state.activityFeedMenuOpen,
  getMessageCentreMenuOpen: (state) => state.messageCentreMenuOpen,
  getProjectSnapshotOpen: (state) => state.projectSnapshotOpen
}

export const actions = {
  setUserNavMenuOpen: ({ commit, state }, isOpen) => {
    commit(types.USER_NAV_MENU_SET, isOpen)
    return Promise.resolve(state.userNavMenuOpen)
  },
  setActivityFeedMenuOpen: ({ commit }, isOpen) => {
    commit(types.ACTIVITY_FEED_MENU_SET, isOpen)
  },
  setMessageCentreMenuOpen: ({ commit }, isOpen) => {
    commit(types.MESSAGE_CENTRE_MENU_SET, isOpen)
  },
  setProjectSnapshotOpen: ({ commit }, isOpen) => {
    commit(types.PROJECT_SNAPSHOT_SET, isOpen)
  },
  toggleProjectSnapshotOpen: ({ commit }) => {
    commit(types.PROJECT_SNAPSHOT_TOGGLE)
  }
}

export const mutations = {
  [types.USER_NAV_MENU_SET](state, isOpen) {
    state.userNavMenuOpen = isOpen
  },
  [types.ACTIVITY_FEED_MENU_SET](state, isOpen) {
    state.activityFeedMenuOpen = isOpen
  },
  [types.MESSAGE_CENTRE_MENU_SET](state, isOpen) {
    state.messageCentreMenuOpen = isOpen
  },
  [types.PROJECT_SNAPSHOT_SET](state, isOpen) {
    state.projectSnapshotOpen = isOpen
  },
  [types.PROJECT_SNAPSHOT_TOGGLE](state) {
    state.projectSnapshotOpen = !state.projectSnapshotOpen
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
