import { api, uploadApi } from '@/api/api'
import * as types from '../../mutation-types'

const newEmail = {
  includeProjectUsers: false,
  projectUsers: [],
  contacts: [],
  template: null,
  reply: null,
  cc: [],
  bcc: [],
  attachments: [],
  subject: null,
  body: null,
  sendWhen: null,
  sendDate: null,
  sender: null
}

export const state = {
  emailAggregates: [],
  emailScheduledAggregates: [],
  newEmail,
  projectUsers: [],
  contacts: [],
  invitees: [],
  emailProject: {
    total: 0,
    emails: []
  },
  scheduledEmailProject: {
    total: 0,
    emails: []
  },
  emailVendor: {
    total: 0,
    emails: []
  },
  templates: [],
  tenderId: null,
  contractId: null,
  document: null,
  restrictedDomains: [],
  note: []
}

export const getters = {
  getEmailAggregates: (state) => state.emailAggregates,
  getEmailScheduledAggregates: (state) => state.emailScheduledAggregates,
  getEmailProject: (state) => state.emailProject,
  getScheduledEmailProject: (state) => state.scheduledEmailProject,
  getEmailVendor: (state) => state.emailVendor,
  getNewEmail: (state) => state.newEmail,
  getNewEmailProjectUsers: (state) => state.projectUsers,
  getNewEmailContacts: (state) => state.contacts,
  getNewEmailInvitees: (state) => state.invitees,
  getEmailTemplates: (state) => state.templates,
  getEmailContractId: (state) => state.contractId,
  getEmailDocument: (state) => state.document,
  getEmailTenderId: (state) => state.tenderId,
  getEmailRestrictedDomains: (state) => state.restrictedDomains,
  getEmailResendMessage: (state) => state.note
}
export const actions = {
  fetchEmailAggregates: ({ commit }, projectId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Emails/Aggregates?projectId=${projectId}`
    return api.get(url).then((response) => {
      const emailAggs = response?.data?.data?.elasticEmailAggregateDTO?.values || []
      commit(types.EMAIL_AGGREGATES_SET, emailAggs)
      return emailAggs
    })
  },
  fetchScheduledEmailAggregates: ({ commit }, projectId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Emails/ProjectScheduledEmailsAggregates?projectId=${projectId}`
    return api.get(url).then((response) => {
      const emailAggs = response?.data?.data?.emailScheduleDTO?.values || []
      commit(types.EMAIL_SCHEDULED_AGGREGATES_SET, emailAggs)
      return emailAggs
    })
  },
  fetchEmailProject: ({ commit }, param) => {
    const dateFrom = param.dateFrom ? new Date(param.dateFrom).setHours(0, 0, 0, 0) : null
    const dateTo = param.dateTo ? new Date(param.dateTo).setHours(23, 59, 59, 999) : null

    const url = `${
      '/api/v1/customer/{customerId}/node/{nodeId}/Emails/ProjectEmails?' + 'projectId='
    }${param.projectId}&subject=${param.subject}&filter.searchText=${
      param.searchText
    }&filter.start=${param.start}&filter.limit=${param.limit}&filter.sortcolumn=${
      param.sortcolumn
    }&filter.sortdirection=${param.sortdirection}&filter.dateFrom=${
      dateFrom ? new Date(dateFrom).toISOString() : null
    }&filter.dateTo=${dateTo ? new Date(dateTo).toISOString() : null}`

    return api.get(url).then((response) => {
      const emailProject = {
        total: 0,
        emails: []
      }

      if (response.data.count > 0) {
        emailProject.total = response.data.data.elasticEmailDTO.total
        emailProject.emails = response.data.data.elasticEmailDTO.values
      }

      commit(types.EMAIL_PROJECT_SET, emailProject)
      return emailProject
    })
  },
  fetchScheduledEmailProject: ({ commit }, param) => {
    const dateFrom = param.dateFrom ? new Date(param.dateFrom).setHours(0, 0, 0, 0) : null
    const dateTo = param.dateTo ? new Date(param.dateTo).setHours(23, 59, 59, 999) : null

    const url = `${
      '/api/v1/customer/{customerId}/node/{nodeId}/Emails/ProjectScheduledEmails?' + 'projectId='
    }${param.projectId}&filter.searchText=${param.searchText}&filter.start=${
      param.start
    }&filter.limit=${param.limit}&filter.sortcolumn=${param.sortcolumn}&filter.sortdirection=${
      param.sortdirection
    }&filter.dateFrom=${dateFrom ? new Date(dateFrom).toISOString() : null}&filter.dateTo=${
      dateTo ? new Date(dateTo).toISOString() : null
    }`

    return api.get(url).then((response) => {
      const emailProject = {
        total: 0,
        emails: []
      }

      if (response.data.count > 0) {
        emailProject.total = response.data.data.emailScheduleDTO.total
        emailProject.emails = response.data.data.emailScheduleDTO.values
      }

      commit(types.SCHEDULED_EMAIL_PROJECT_SET, emailProject)
      return emailProject
    })
  },
  deleteScheduledEmail: (_, param) => {
    return api.delete(
      `/api/v1/customer/{customerId}/node/{nodeId}/Emails/ProjectScheduledEmails?emailScheduleId=${param.id}&emailScheduleContactId=${param.emailScheduleContactId}`
    )
  },
  fetchEmailVendor: ({ commit }, param) => {
    const dateFrom = param.dateFrom ? new Date(param.dateFrom).setHours(0, 0, 0, 0) : null
    const dateTo = param.dateTo ? new Date(param.dateTo).setHours(23, 59, 59, 999) : null

    const url = `${
      '/api/v1/customer/{customerId}/node/{nodeId}/Emails/VendorEmails?' + 'vendorId='
    }${param.vendorId}&subject=${param.subject}&filter.searchText=${
      param.searchText
    }&filter.start=${param.start}&filter.limit=${param.limit}&filter.sortcolumn=${
      param.sortcolumn
    }&filter.sortdirection=${param.sortdirection}&filter.dateFrom=${
      dateFrom ? new Date(dateFrom).toISOString() : null
    }&filter.dateTo=${dateTo ? new Date(dateTo).toISOString() : null}`

    return api.get(url).then((response) => {
      const emailVendor = {
        total: 0,
        emails: []
      }

      if (response.data.count > 0) {
        emailVendor.total = response.data.data.elasticEmailDTO.total
        emailVendor.emails = response.data.data.elasticEmailDTO.values
      }

      commit(types.EMAIL_VENDOR_SET, emailVendor)
      return emailVendor
    })
  },
  updateNewEmail: ({ commit }, email) => {
    commit(types.EMAIL_UPDATE_NEW_EMAIL, email)
  },
  setNewEmailProjectUsers: ({ commit }, projectUsers) => {
    commit(types.EMAIL_SET_NEW_EMAIL_PROJECT_USERS, projectUsers)
  },
  setNewEmailContacts: ({ commit }, contacts) => {
    commit(types.EMAIL_SET_NEW_EMAIL_TEAM_CONTACTS, contacts)
  },
  setNewEmailInvitees: ({ commit }, invitees) => {
    commit(types.EMAIL_SET_NEW_EMAIL_INVITEES, invitees)
  },
  setNewEmailContractId: ({ commit }, contractId) => {
    commit(types.EMAIL_SET_NEW_EMAIL_CONTRACT_ID, contractId)
  },
  setNewEmailTenderId: ({ commit }, tenderId) => {
    commit(types.EMAIL_SET_NEW_EMAIL_TENDER_ID, tenderId)
  },
  setNewEmailDocument: ({ commit }, document) => {
    commit(types.EMAIL_SET_NEW_EMAIL_DOCUMENT, document)
  },
  fetchEmailTemplates: ({ commit }, groupName) => {
    const url = `${
      '/api/v1/customer/{customerId}/node/{nodeId}/Emails/Templates?' + 'groupName='
    }${groupName}`

    return api.get(url).then((response) => {
      const templates = response?.data?.data?.emailTemplateDTO?.values || []
      commit(types.EMAIL_SET_EMAIL_TEMPLATES, templates)
      return templates
    })
  },
  fetchEmailTemplatesAll: ({ commit }) => {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/Emails/Templates/All'

    return api.get(url).then((response) => {
      const templates = response?.data?.data?.emailTemplateDTO?.values || []
      commit(types.EMAIL_SET_EMAIL_TEMPLATES, templates)
      return templates
    })
  },
  saveTemplate({ commit }, emailTemplateDTO) {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/Emails/Templates'

    return uploadApi.put(url, emailTemplateDTO).then((response) => {
      const result = response?.data?.data?.emailTemplateDTO?.values?.[0] || null
      commit(types.EMAIL_UPDATE_EMAIL_TEMPLATES, result)
      return result
    })
  },
  scheduleNewEmail: (_, newEmailRequest) => {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/Emails/Schedule'

    return api.post(url, newEmailRequest)
  },
  uploadEmailAttachment(_, formData) {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/Documents'

    return uploadApi.post(url, formData).then((response) => {
      const result = response?.data?.data?.documentDTO?.values[0] || null
      return result
    })
  },
  fetchEmailRestrictedDomains: ({ commit }) => {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/Emails/RestrictedDomains'

    return api.get(url).then((response) => {
      const restrictedDomains = response?.data?.data?.vendorEmailManagementDTO?.values || []
      commit(types.EMAIL_SET_EMAIL_RESTRICTED_DOMAINS, restrictedDomains)
      return restrictedDomains
    })
  },
  saveRestrictedDomains({ commit }, vendorEmailManagementDTOList) {
    const url = '/api/v1/customer/{customerId}/node/{nodeId}/Emails/RestrictedDomains'

    return uploadApi.put(url, vendorEmailManagementDTOList).then((response) => {
      const restrictedDomains = response?.data?.data?.vendorEmailManagementDTO?.values || []
      commit(types.EMAIL_SET_EMAIL_RESTRICTED_DOMAINS, restrictedDomains)
      return restrictedDomains
    })
  },
  ReSendEmail: ({ commit }, emailId) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Emails/ReSend?emailId=${emailId}`

    return api.post(url).then((response) => {
      const noteDTO = response?.data?.data?.noteDTO?.values?.[0] || []
      commit(types.EMAIL_RESEND, noteDTO)
      return noteDTO
    })
  },
  ForwardEmail: ({ commit }, forwardParam) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Emails/Forward?emailId=${forwardParam.emailId}`

    return api.post(url, forwardParam.emailAddresses).then((response) => {
      const noteDTO = response?.data?.data?.noteDTO?.values?.[0] || []
      commit(types.EMAIL_RESEND, noteDTO)
      return noteDTO
    })
  }
}
export const mutations = {
  [types.EMAIL_AGGREGATES_SET](state, emailAggs) {
    state.emailAggregates = [...emailAggs]
  },
  [types.EMAIL_SCHEDULED_AGGREGATES_SET](state, emailAggs) {
    state.emailScheduledAggregates = [...emailAggs]
  },
  [types.EMAIL_PROJECT_SET](state, emailProject) {
    state.emailProject = emailProject
  },
  [types.SCHEDULED_EMAIL_PROJECT_SET](state, emailProject) {
    state.scheduledEmailProject = emailProject
  },
  [types.EMAIL_VENDOR_SET](state, emailVendor) {
    state.emailVendor = emailVendor
  },
  [types.EMAIL_UPDATE_NEW_EMAIL](state, email) {
    state.newEmail = email
  },
  [types.EMAIL_SET_NEW_EMAIL_PROJECT_USERS](state, projectUsers) {
    state.projectUsers = projectUsers
  },
  [types.EMAIL_SET_NEW_EMAIL_TEAM_CONTACTS](state, contacts) {
    state.contacts = contacts
  },
  [types.EMAIL_SET_NEW_EMAIL_INVITEES](state, invitees) {
    state.invitees = invitees
  },
  [types.EMAIL_SET_EMAIL_TEMPLATES](state, templates) {
    state.templates = templates
  },
  [types.EMAIL_UPDATE_EMAIL_TEMPLATES](state, template) {
    const matchedIndex = state.templates.findIndex((r) => r.id === template.id)
    if (matchedIndex !== -1) {
      // pop it off the array and push a new one returned by API
      state.templates.splice(matchedIndex, 1)
    }
    state.templates.push(template)
  },
  [types.EMAIL_SET_EMAIL_RESTRICTED_DOMAINS](state, restrictedDomains) {
    state.restrictedDomains = restrictedDomains
  },
  [types.EMAIL_UPDATE_EMAIL_RESTRICTED_DOMAINS](state, restrictedDomain) {
    const matchedIndex = state.restrictedDomains.findIndex((r) => r.id === restrictedDomain.id)
    if (matchedIndex !== -1) {
      // pop it off the array and push a new one returned by API
      state.restrictedDomains.splice(matchedIndex, 1)
    }
    state.restrictedDomains.push(restrictedDomain)
  },
  [types.EMAIL_SET_NEW_EMAIL_CONTRACT_ID](state, contractId) {
    state.contractId = contractId
  },
  [types.EMAIL_SET_NEW_EMAIL_TENDER_ID](state, tenderId) {
    state.tenderId = tenderId
  },
  [types.EMAIL_SET_NEW_EMAIL_DOCUMENT](state, document) {
    state.document = document
  },
  [types.EMAIL_RESEND](state, note) {
    state.note = note
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
