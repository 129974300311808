import PieChart from '@/components/pie-chart/pie-chart.vue'
import uiHandler from '@/utils/ui'
import DashboardComponent from '@/views/dashboard/components/dashboard-component/dashboard-component.vue'
import DashboardStat from '@/views/dashboard/components/dashboard-stat-component.vue'
import { performanceRatingScales } from '@/views/projects/project-view/project-sub-views/contract/contract-performance-view/contract-performance-view-definitions.js'
import { mapActions, mapGetters } from 'vuex'
import ChartDataCard from './supplier-dashboard-components/chart-data-card.vue'
import OverviewDataCard from './supplier-dashboard-components/overview-data-card.vue'
import PctSatisfactoryChart from './supplier-dashboard-components/pct-satisfactory-chart.vue'

const pctFormat = new Intl.NumberFormat('en-CA', {
  style: 'percent',
  maximumFractionDigits: 1,
  minimumFractionDigits: 1
})
const numFormat = new Intl.NumberFormat('en-CA', { style: 'decimal' })

export default {
  name: 'SupplierDashboardView',
  components: {
    DashboardComponent,
    DashboardStat,
    PieChart,
    PctSatisfactoryChart,
    OverviewDataCard,
    ChartDataCard
  },
  data() {
    return {
      viewString: 'ui.views.dashboard.supplier-dashboard-view.',
      performanceData: null,
      loaded: false,
      chartData: [],
      counter: -1,
      colorsArray: [
        // [foreground, background, text]
        ['#007a8d', '#66afbb', '#FFFFFF'],
        ['#6d7075', '#2f8a69', '#FFFFFF'],
        ['#6d7075', '#ffcd79', '#000000'],
        ['#6d7075', '#3c2e28', '#FFFFFF']
      ]
    }
  },
  watch: {
    contractPerformanceEnabled() {
      this.checkContractPerformanceEnabled()
    }
  },
  computed: {
    ...mapGetters([
      'getPerformanceSummary',
      'getTenderNode',
      'getCustomer',
      'getPerformanceStatusEvaluation'
    ]),
    contractPerformanceEnabled() {
      return this.getTenderNode?.contractorPerformance
    },
    evaluationData() {
      return this.getPerformanceStatusEvaluation
    },
    overviewData() {
      if (!this.performanceData) return []

      return [
        {
          marker: 'blue',
          heading: this.$t(`${this.viewString}supplierHeading`),
          icon: 'users',
          dataPct: pctFormat.format(this.performanceData.suppliers.pctEvaluated),
          data1Text: this.$t(`${this.viewString}supplierRegistered`),
          data1Value: numFormat.format(this.performanceData.suppliers.numRegistered),
          data2Text: this.$t(`${this.viewString}supplierEvaluated`),
          data2Value: numFormat.format(this.performanceData.suppliers.numEvaluated)
        },
        {
          marker: 'pink',
          heading: this.$t(`${this.viewString}contractHeading`),
          icon: 'file-text',
          dataPct: pctFormat.format(this.performanceData.contracts.pctEvaluated),
          data1Text: this.$t(`${this.viewString}contractRegistered`),
          data1Value: numFormat.format(this.performanceData.contracts.numRegistered),
          data2Text: this.$t(`${this.viewString}contractEvaluated`),
          data2Value: numFormat.format(this.performanceData.contracts.numEvaluated)
        },
        {
          marker: 'green',
          heading: this.$t(`${this.viewString}evaluationsHeading`),
          icon: 'edit',
          dataPct: numFormat.format(this.performanceData.evaluations.numEvaluated),
          data1Text: this.$t(`${this.viewString}evaluationsInterim`),
          data1Value: numFormat.format(this.performanceData.evaluations.numInterim),
          data2Text: this.$t(`${this.viewString}evaluationsFinal`),
          data2Value: numFormat.format(this.performanceData.evaluations.numFinal)
        }
      ]
    },
    ratingPerEvaluationScaleId() {
      return (
        this.getTenderNode?.performanceConfig?.ratingPerEvaluationScale?.id?.toLowerCase() || null
      )
    },
    showPerformanceEvaluationWidget() {
      const show =
        this.evaluationData !== null &&
        this.ratingPerEvaluationScaleId !== performanceRatingScales.AVG.toLowerCase() &&
        this.ratingPerEvaluationScaleId !== performanceRatingScales.SUM.toLowerCase() &&
        this.ratingPerEvaluationScaleId !== performanceRatingScales.LOWEST.toLowerCase()

      return show
    },
    templateLogo() {
      return this.getCustomer?.agencyImageUrl
    }
  },
  mounted() {
    uiHandler.showLoading(this)

    Promise.all([this.fetchPerformanceStatusSummary(), this.fetchPerformanceStatusEvaluation()])
      .then((response) => {
        this.performanceData = response[0]
        this.buildChartData()

        this.loaded = true
      })
      .finally(() => uiHandler.hideLoading(this))
  },
  methods: {
    ...mapActions(['fetchPerformanceStatusSummary', 'fetchPerformanceStatusEvaluation']),
    buildChartData() {
      if (!this.performanceData) return

      for (const d of this.performanceData.departments) {
        const [foreground, background, textColor] = this.getChartColors()

        this.chartData.push({
          heading: d.name,
          expired: `${this.$t(`${this.viewString}deptExpired`)} ${numFormat.format(d.numExpired)}`,
          inprogress: `${this.$t(`${this.viewString}deptInProgress`)} ${numFormat.format(
            d.numInProgress
          )}`,
          interim: `${this.$t(`${this.viewString}deptInterim`)} ${numFormat.format(d.numInterim)}`,
          final: `${this.$t(`${this.viewString}deptFinal`)} ${numFormat.format(d.numFinal)}`,
          custom: `${this.$t(`${this.viewString}deptCustom`)} ${numFormat.format(d.numCustom)}`,
          percentage: pctFormat.format(d.pctEvaluated),
          textColor,
          datasets: [
            {
              backgroundColor: [foreground, background],
              data: [d.pctEvaluated, 1 - d.pctEvaluated]
            }
          ]
        })
      }
    },
    getChartColors() {
      this.counter = this.counter + 1
      if (this.counter >= this.colorsArray.length) this.counter = 0
      return this.colorsArray[this.counter]
    },
    checkContractPerformanceEnabled() {
      if (!this.contractPerformanceEnabled) {
        this.$router.push('/')
      }
    }
  }
}
