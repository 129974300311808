<template>
  <div class="award-approval-actions has-padding-xlg">
    <div class="actions__left">
      <slot name="left">
        <app-title size="3">Hello {{ name }}</app-title>
        <p>Your approval is required for the following document(s).</p>
      </slot>
    </div>
    <div class="actions__right">
      <slot name="right">
        <app-button min-width @click="handleClickAction('approve')">
          <app-icon icon="check-circle" color="#008000" />
          Approve Award
        </app-button>
        <app-button min-width @click="handleClickAction('decline')">
          <app-icon icon="x-circle" color="#cf483e" />
          Decline Award
        </app-button>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'award-approval-actions',
  props: {
    name: {
      type: String,
      default: 'Team Member'
    }
  },
  methods: {
    handleClickAction(action) {
      this.$emit('action', action)
    }
  }
}
</script>

<style lang="scss" scoped>
.award-approval-actions {
  width: 100%;
  background: $white;
  display: flex;
  flex-direction: column;

  @include breakpoint('md') {
    flex-direction: row;
    align-items: flex-end;
  }

  .actions__right {
    button {
      color: #000000 !important;
    }
  }
}
.actions__left {
  width: 100%;
  margin-bottom: $gap;
  text-align: center;

  :deep(.app-title-heading) {
    justify-content: center;
    @include breakpoint('md') {
      justify-content: flex-start;
    }
  }

  @include breakpoint('md') {
    margin-bottom: 0;
    max-width: 50%;
    text-align: left;
  }
}

.actions__right {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include breakpoint('md') {
    flex-direction: row;
    margin-left: auto;
  }

  .app-button {
    width: 100%;
    margin-bottom: $gap-small;

    @include breakpoint('sm') {
      width: 50%;
    }

    @include breakpoint('md') {
      margin-bottom: 0;
      &:first-child {
        margin-right: $gap-small;
      }
    }
  }
}
</style>
