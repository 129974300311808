import { api } from '@/api/api'
import get from 'lodash/get'
import has from 'lodash/has'
import set from 'lodash/set'
import * as types from '../../mutation-types'

export const state = {
  language: null,
  messageBody: '',
  message: null,
  resourceMessage: null
}

export const getters = {
  getSystemContent: (state) => (field) => {
    if (!!field && has(state, field)) return get(state, field)
    return state
  },
  getResourceMessage: (state) => state.resourceMessage
}

export const actions = {
  /**
   * takes [field to update, value to assign]
   * returns [error, response]
   */
  updateSystemContent: ({ commit, state }, params) => {
    if (!Array.isArray(params) || params.length < 2) {
      // incorrect format, structure
      return new Promise((resolve) => {
        resolve([`updateSystemContent argument must be array`, null])
      })
    }

    const [field, value] = params

    if (!has(state, field)) {
      // incorrect format, structure
      return new Promise((resolve) => {
        resolve([`System content--no such field: [${field}]`, null])
      })
    }

    commit(types.CONTROL_PANEL_SYSTEM_CONTENT_SET_ITEM, { field, value })
    return new Promise((resolve) => {
      resolve([null, [field, value]])
    })
  },
  fetchResourceMessage: ({ commit }, params) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Resources?languageSuffix=${params.languageSuffix}&key=${params.key}`

    return api.get(url).then((response) => {
      const resourceMessage = response.data.data.searchResult.values[0].message
      commit(types.RESOURCE_MESSAGE_ALL_SET, resourceMessage)
      return resourceMessage
    })
  },
  updateResourceMessage(_, model) {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Resources`
    return api.put(url, model).then((response) => {
      const resourceMessage = response.data.data.searchResult.values
      return resourceMessage
    })
  }
}

export const mutations = {
  [types.CONTROL_PANEL_SYSTEM_CONTENT_SET_ITEM]: (state, { field, value }) => {
    set(state, field, value)
  },
  [types.RESOURCE_MESSAGE_ALL_SET](state, resourceMessage) {
    state.resourceMessage = resourceMessage
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
