import accountInfoImage from '@/assets/images/SVGs/account-info.svg'
import emailSentImage from '@/assets/images/SVGs/email-wizard-sent.svg'
import intakeUnvailableImage from '@/assets/images/SVGs/intake-unavailable.svg'
import projectSearchImage from '@/assets/images/SVGs/project-search.svg'
import projectValidationErrorImage from '@/assets/images/SVGs/project-validation-error.svg'
import uploadDocumentImage from '@/assets/images/SVGs/uploadDocument.svg'

export default function UseImages() {
  const images = {
    accountInfoImage,
    emailSentImage,
    intakeUnvailableImage,
    projectSearchImage,
    projectValidationErrorImage,
    uploadDocumentImage
  }

  return {
    ...images
  }
}
