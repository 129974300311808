<template>
  <div ref="wrapper" class="app-switch">
    <el-switch
      v-bind="$attrs"
      :value="value"
      :aria-checked="value ? 'true' : 'false'"
      :disabled="disabled || !hasPermissionToEdit"
      v-on="$listeners"
    />
    <app-permission-tooltip v-if="!hasPermissionToEdit" class="permission-icon" />
  </div>
</template>

<script>
import InputPermissionMixins from '@/mixins/input-permission-mixins'

export default {
  name: 'app-switch',
  mixins: [InputPermissionMixins],
  inheritAttrs: false,
  props: {
    value: [Boolean, Number, String, Array, Object],
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
.app-switch {
  position: relative;

  .permission-icon {
    position: absolute;
    right: -30px;
  }
}
</style>
