import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  contractCategories: [],
  contractCategoriesAll: [],
  sourceCategories: [],
  fetchingContractCategories: false,
  fetchingSourceCategories: false
}

export const getters = {
  getContractCategories: (state) => state.contractCategories,
  getContractCategoriesAll: (state) => state.contractCategoriesAll,
  getSourceCategories: (state) => state.sourceCategories,
  getContractCategoryFetchStatus: (state) => state.fetchingContractCategories,
  getSourceCategoryFetchStatus: (state) => state.fetchingSourceCategories
}

export const actions = {
  fetchContractCategories: ({ commit, state }, forceUpate = false) => {
    if (forceUpate) {
      commit(types.CONTRACT_CATEGORY_SET_ALL, [])
    }

    // For caching, exit early if already fetched
    if (state.contractCategories.length) {
      commit(types.CONTRACT_CATEGORY_SET_STATUS, false)
      return Promise.resolve(state.contractCategories)
    }

    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ContractCategories`

    // check fetch status
    if (state.fetchingContractCategories === true) {
      return Promise.resolve('')
    }
    // update the fetch status
    commit(types.CONTRACT_CATEGORY_SET_STATUS, true)

    // fallback cancel fetching status
    setTimeout(() => {
      if (state.fetchingContractCategories === true) {
        commit(types.CONTRACT_CATEGORY_SET_STATUS, false)
      }
    }, 60000)

    return api
      .get(url)
      .then((response) => {
        const contractCategories = response.data.data.contractCategoryDTO.values
        commit(types.CONTRACT_CATEGORY_SET_ALL, contractCategories)
        return contractCategories
      })
      .finally(() => commit(types.CONTRACT_CATEGORY_SET_STATUS, false))
  },
  updateContractCategories: ({ commit }) => {
    // when event received notifying contract categories have been updated.

    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ContractCategories`
    return api
      .get(url)
      .then((response) => {
        const contractCategories = response.data.data.contractCategoryDTO.values
        commit(types.CONTRACT_CATEGORY_SET_ALL, contractCategories)
        return contractCategories
      })
      .finally(() => commit(types.CONTRACT_CATEGORY_SET_STATUS, false))
  },
  fetchContractCategoriesAll: ({ commit }) => {
    // used in cpanel dropdowns
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ContractCategories/All`
    return api
      .get(url)
      .then((response) => {
        const contractCategories = response.data.data.contractCategoryDTO.values
        commit(types.CONTRACT_CATEGORIES_SET, contractCategories)
        return contractCategories
      })
      .finally(() => commit(types.CONTRACT_CATEGORY_SET_STATUS, false))
  },
  saveContractCategory: (_, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ContractCategories`
    return api.post(url, model).then((response) => {
      const contractCategory = response.data.data.contractCategoryDTO.values[0]
      return contractCategory
    })
  },
  updateContractCategory: (_, model) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ContractCategories`
    return api.put(url, model).then((response) => {
      const contractCategory = response.data.data.contractCategoryDTO.values[0]
      return contractCategory
    })
  },
  deleteContractCategory: (_, id) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ContractCategories?contractCategoryId=${id}`
    return api.delete(url)
  },
  fetchSourceCategories: ({ commit, state }, forceUpate = false) => {
    if (forceUpate) {
      commit(types.SOURCE_CATEGORY_SET_ALL, [])
    }

    // For caching, exit early if already fetched
    if (state.sourceCategories.length) {
      commit(types.SOURCE_CATEGORY_SET_STATUS, false)
      return Promise.resolve(state.sourceCategories)
    }
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Category`

    // check fetch status
    if (state.fetchingSourceCategories === true) {
      return Promise.resolve('')
    }
    // update the fetch status
    commit(types.SOURCE_CATEGORY_SET_STATUS, true)

    // fallback cancel fetching status
    setTimeout(() => {
      if (state.fetchingSourceCategories === true) {
        commit(types.SOURCE_CATEGORY_SET_STATUS, false)
      }
    }, 60000)

    return api
      .get(url)
      .then((response) => {
        const sourceCategories = response.data.data.categoryDTO.values
        commit(types.SOURCE_CATEGORY_SET_ALL, sourceCategories)
        return sourceCategories
      })
      .finally(() => commit(types.SOURCE_CATEGORY_SET_STATUS, false))
  },
  updateSourceCategories: ({ commit }) => {
    // when event received notifying categories have been updated.
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/Category`
    return api.get(url).then((response) => {
      const sourceCategories = response.data.data.categoryDTO.values
      commit(types.SOURCE_CATEGORY_SET_ALL, sourceCategories)
      return sourceCategories
    })
  }
}

export const mutations = {
  [types.CONTRACT_CATEGORY_SET_ALL](state, contractCategories) {
    state.contractCategories = contractCategories
  },
  [types.CONTRACT_CATEGORIES_SET](state, contractCategoriesAll) {
    state.contractCategoriesAll = contractCategoriesAll
  },
  [types.SOURCE_CATEGORY_SET_ALL](state, sourceCategories) {
    state.sourceCategories = sourceCategories
  },
  [types.CONTRACT_CATEGORY_SET_STATUS](state, status) {
    state.fetchingContractCategories = status
  },
  [types.SOURCE_CATEGORY_SET_STATUS](state, status) {
    state.fetchingSourceCategories = status
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
