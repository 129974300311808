import * as types from '../../mutation-types'

export const state = {
  supplierSearchTerm: null,
  supplierSearchResults: []
}

export const getters = {
  getSupplierSearchTerm: (state) => state.supplierSearchTerm,
  getSupplierSearchResults: (state) => state.supplierSearchResults
}

export const actions = {
  setSupplierSearchTerm: ({ commit }, searchTerm) => {
    commit(types.SUPPLIER_SEARCH_TERM_SET, searchTerm)
    return new Promise((resolve) => {
      resolve(searchTerm)
    })
  },
  setSupplierSearchResults: ({ commit }, searchResults) => {
    commit(types.SUPPLIER_SEARCH_RESULTS_SET, searchResults)
    return new Promise((resolve) => {
      resolve(searchResults)
    })
  }
}

export const mutations = {
  [types.SUPPLIER_SEARCH_RESULTS_SET](state, searchResults) {
    state.supplierSearchResults = searchResults
  },
  [types.SUPPLIER_SEARCH_TERM_SET](state, searchTerm) {
    state.supplierSearchTerm = searchTerm
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
