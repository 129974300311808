import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  documentTemplates: []
}
export const getters = {
  getDocumentTemplates: (state) => state.documentTemplates
}
export const actions = {
  fetchDocumentTemplate: ({ commit }, group) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/OnlineSubmissionTemplate/Search?groupName=${group}`
    return api.get(url).then((res) => {
      const templates = res?.data?.data?.onlineSubmissionTemplateDTO?.values || []
      commit(types.DOCUMENT_TEMPLATES_SET_ALL, templates)
      return res
    })
  }
}
export const mutations = {
  [types.DOCUMENT_TEMPLATES_SET_ALL]: (state, templates) => {
    state.documentTemplates = templates
  }
}
export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
