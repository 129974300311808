import { api } from '@/api/api'
import * as types from '../../mutation-types'

export const state = {
  tax: [],
  taxes: []
}

export const getters = {
  getTax: (state) => state.tax,
  getTaxes: (state) => state.taxes
}

export const actions = {
  fetchTax: ({ commit }, id) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ShoppingCart/Tax?tenderId=${id}`

    return api.get(url).then((response) => {
      const tax = response.data.data.searchResult.values

      commit(types.TAX_SET_ALL, tax)
      return tax
    })
  },
  fetchTaxes: ({ commit }) => {
    const url = `/api/v1/customer/{customerId}/node/{nodeId}/ShoppingCart/Taxes`

    return api.get(url).then((response) => {
      if (response.data.count > 0) {
        const tax = response.data.data.searchResult.values

        commit(types.TAXES_SET_ALL, tax)
        return tax
      }
    })
  }
}

export const mutations = {
  [types.TAX_SET_ALL](state, tax) {
    state.tax = tax
  },
  [types.TAXES_SET_ALL](state, taxes) {
    state.taxes = taxes
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
