<template>
  <span :class="{ 'is-square': type === 'square' }" class="app-tag">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'app-tag',
  props: {
    type: String
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss" src="./app-tag.scss"></style>
