<template>
  <el-dialog
    :visible="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    :title="isManageMode ? $t(viewString + 'manageAgency') : $t(viewString + 'addNewAgency')"
    append-to-body
    width="80vw"
  >
    <app-focus-trap :disabled="!visible">
      <div class="close-buttom">
        <app-button type="text" @click.native="handleClose">
          <app-icon icon="x" />
        </app-button>
      </div>
      <section>
        <el-tabs v-model="activeTab">
          <el-tab-pane :label="$t(viewString + 'details')" name="1">
            <AgencyDetails
              :view-string="viewString"
              :agency-details="agencyDetails"
              :is-manage-mode="isManageMode"
              @email-valid="setValidation"
            >
            </AgencyDetails>
          </el-tab-pane>
          <el-tab-pane v-if="isManageMode" :label="$t(viewString + 'purchasehistory')" name="2">
            <PurchaseHistory
              :view-string="viewString"
              :annual-billing-data="agencyDetails.billingPeriods"
            >
            </PurchaseHistory>
          </el-tab-pane>
          <el-tab-pane v-if="isManageMode" :label="$t(viewString + 'activity')" name="3">
            <AgencyActivity :activity-data="agencyDetails.activityData" :view-string="viewString">
            </AgencyActivity>
          </el-tab-pane>
        </el-tabs>
      </section>
      <div class="is-flex is-justified-end">
        <app-button min-width type="secondary" class="has-margin-right-small" @click="handleClose">
          {{ $t('ui.common.close') }}
        </app-button>
        <app-button :disabled="!isValid" min-width type="primary" @click="handleSave">
          {{ $t('ui.common.save') }}
        </app-button>
      </div>
    </app-focus-trap>
  </el-dialog>
</template>

<script>
import AgencyDetails from './agency-details/agency-details-section.vue'
import AgencyActivity from './agency-activity/agency-activity-section.vue'
import PurchaseHistory from './agency-purchase-history/agency-purchase-history-section.vue'
import errorHandler from '@/utils/error'
import uiHandler from '@/utils/ui'
import { mapActions } from 'vuex'
import dayjs from '@/utils/dayjs'

export default {
  name: 'agency-transactions-modal',
  components: {
    AgencyDetails,
    AgencyActivity,
    PurchaseHistory
  },
  props: {
    viewString: String,
    visible: Boolean,
    isManageMode: Boolean,
    agencyDetails: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      activeTab: '1',
      emailIsValid: true
    }
  },
  computed: {
    isValid() {
      return (
        this.emailIsValid &&
        this.agencyDetails.agencyId &&
        this.agencyDetails.contactEmail &&
        this.isBillingPeriodValid
      )
    },
    isBillingPeriodValid() {
      let valid = true
      let previousEndDate = null

      if (!this.agencyDetails.billingPeriods?.length) return false

      this.agencyDetails.billingPeriods.forEach((element) => {
        element.error = null

        if (!element.startDate || !element.endDate || !element.price || !element.purchased) {
          valid = false
          return
        }

        if (
          dayjs(element.startDate).format('YYYY-MM-DD') >=
          dayjs(element.endDate).format('YYYY-MM-DD')
        ) {
          valid = false
          element.error = 'invalidEndDate'
          return
        }

        if (
          previousEndDate &&
          dayjs(element.startDate).format('YYYY-MM-DD') <=
            dayjs(previousEndDate).format('YYYY-MM-DD')
        ) {
          valid = false
          element.error = 'invalidStartDate'
          return
        }

        if (element.purchased < element.transactionUsed) {
          valid = false
          element.error = 'invalidPurchasedTransaction'
          return
        }

        previousEndDate = element.endDate
      })

      return valid
    }
  },
  methods: {
    ...mapActions([
      'saveContractBuilderLicenseConfiguration',
      'setContractBuilderLicenseConfigurationFetchRequired'
    ]),
    handleClose() {
      this.$emit('close')
    },
    handleSave() {
      if (!this.isValid) return false

      uiHandler.showLoading(this)

      const contractBuilderLicenseConfigurationDTO = {}
      contractBuilderLicenseConfigurationDTO.id = this.agencyDetails.id
      contractBuilderLicenseConfigurationDTO.allowContractBuilder =
        this.agencyDetails.allowContractBuilder
      contractBuilderLicenseConfigurationDTO.contactEmail = this.agencyDetails.contactEmail
      contractBuilderLicenseConfigurationDTO.node = {
        id: this.agencyDetails.agencyId
      }

      if (this.agencyDetails.reminder && this.agencyDetails.reminder.length) {
        contractBuilderLicenseConfigurationDTO.reminders = []

        this.agencyDetails.reminder.forEach((element) => {
          if (element.val)
            contractBuilderLicenseConfigurationDTO.reminders.push({
              remainingTransactionCount: element.id
            })
        })
      }

      if (this.agencyDetails.billingPeriods && this.agencyDetails.billingPeriods.length) {
        contractBuilderLicenseConfigurationDTO.billingPeriods = []

        this.agencyDetails.billingPeriods.forEach((element) => {
          contractBuilderLicenseConfigurationDTO.billingPeriods.push({
            id: this.agencyDetails.agencyId ? element.id : null,
            dateStartUTC: element.startDate,
            dateEndUTC: element.endDate,
            pricePerTransaction: element.price,
            transactionPurchased: element.purchased
          })
        })
      }

      this.saveContractBuilderLicenseConfiguration(contractBuilderLicenseConfigurationDTO)
        .then(() => {
          this.setContractBuilderLicenseConfigurationFetchRequired(true)
          this.$notify({
            title: 'Success',
            message: this.$t(`${this.viewString}msgSaveSuccess`),
            type: 'success'
          })

          this.handleClose()
        })
        .catch((e) => {
          uiHandler.hideLoading(this)
          const errorMessages = []
          errorMessages[e.response.status] = e.response.data[0]
          errorHandler.handleApiError(this, e, errorMessages)
        })
    },
    setValidation(val) {
      this.emailIsValid = val
    }
  }
}
</script>
<style lang="scss">
.close-buttom {
  position: absolute;
  top: 15px;
  right: 15px;
}
</style>
